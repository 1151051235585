import FileUploader from 'Components/FileUploader';
import PhotoSelector from 'Components/PhotoSelector';
import { useTextFocus } from 'Pages/Home/Components/MyDataDoctor';
import { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { createOrUpdateAuctionFeedback, getDateHuman, getDateStr } from 'service/API';
import { Auction, AuctionFeedback, AuctionFeedbackRequest, DentFUID, DentFile } from 'service/Data/DentTypes';
import { TR_ } from 'service/T10N';
import { getDateUnixUTC } from './AuctionNew';
import AuctionViewHeader from './Respond/AuctionViewHeader';

interface LocationState {
    auction: Auction
    feedback: AuctionFeedback
}

const AuctionNewFeedback = () => {
    const location = useLocation<LocationState>(); // '/auction-feedback'
    const auction: Auction = location.state?.auction
    const feedback: AuctionFeedback = location.state?.feedback

    if (!auction)
        return null

    const history = useHistory()

    const [isDescriptionShown, setDescriptionShown] = useState(false)
    const [areContactsShown, setContactsShown] = useState(false)
    const [isOwn, setOwn] = useState(false)

    const untilDateRef = useRef<HTMLInputElement>(null)

    const [descriptionRef, setDescriptionFocus] = useTextFocus()

    const [maxBudget, setMaxBudget] = useState<string>(feedback?.maxBudget ? '' + feedback.maxBudget : '')
    const [untilDate, setUntilDate] = useState<string>(feedback?.untilDate ? getDateStr(feedback.untilDate) : '')
    const [untilDateHuman, setUntilDateHuman] = useState<string>(feedback?.untilDate ? getDateHuman(feedback.untilDate) : '')
    const [description, setDescription] = useState(feedback ? feedback.description : '')
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(feedback?.photos?.length ? feedback.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(feedback?.photos ? feedback.photos : [])
    const [files, setFiles] = useState<DentFile[]>(feedback?.files ? feedback.files : [])

    const [errorShown, setErrorShown] = useState(false)

    //console.log('UntilDateHuman: ' + untilDateHuman)


    function validateForm(): boolean {
        if (!description) {
            setErrorShown(true)
            setDescriptionFocus()
            return false
        }

        return true
    }

    const publishFeedback = async () => {
        if (!validateForm()) {
            //console.log('Validation error...')
            return
        }

        var request: AuctionFeedbackRequest = {
            feedbackId: feedback?.id,

            maxBudget: maxBudget ? +maxBudget : undefined,
            untilDate: untilDateRef.current?.value ? getDateUnixUTC(untilDate) : undefined,
            description: description,
            photos: photos,
            files: files,
        }

        //console.log('UntilDateHuman:')
        //console.log(untilDateRef.current?.value);

        //console.log('New auction feedback request:')
        //console.log(request)

        try {
            const reply = await createOrUpdateAuctionFeedback(auction.id, request)

            if (reply.feedback) {
                // Update success
                //console.log('Auction feedback was created')
                //history.push('/')
                history.push('/auction-view-feedback', { auction: auction, feedback: reply.feedback })
                return
            }
        } catch (e) { }

        // TODO: show error to user somehow
        console.log('Auction feedback creation error')
    }

    return (
        <main className="main">
            <div className="container">
                <AuctionViewHeader auction={auction} />
                <section className="respond">
                    <div className="respond-cover">
                        <h1 className="respond-title">{TR_('Ваш отклик')}</h1>
                        <div className="add">
                            <div className="respond__form">
                                <div className="respond__main">
                                    <button className="how" onClick={() => setDescriptionShown(true)}>
                                        {TR_('Как написать хороший отклик')}
                                    </button>
                                    <form className="new-add-form" action="post">
                                        <div className="field-flex">
                                            <div className="fiels-flex__item">
                                                <div className="field-set">
                                                    <p className="field-set__title">
                                                        {TR_('Бюджет')}
                                                    </p>
                                                    <div className="input">
                                                        <input placeholder={TR_('Не могу оценить стоимость')} type="number" value={maxBudget} onChange={(e) => setMaxBudget(e.target.value)} />
                                                    </div>
                                                    {/* <input type="checkbox" className="checkbox-e" id="period" />
                                                    <label htmlFor="period">{TR_('Не могу оценить стоимость')} </label> */}
                                                    <p className="fied-set__error"></p>
                                                </div>
                                            </div>
                                            <div className="fiels-flex__item">
                                                <div className="field-set">
                                                    <p className="field-set__title">
                                                        {TR_('Сроки (дедлайн)')}
                                                    </p>
                                                    <div className="input-date">
                                                        {/* <input
                                                            ref={untilDateRef}
                                                            placeholder={TR_('Не могу определиться с датой')}
                                                            type="text"
                                                            min={getDateStr(new Date())}
                                                            value={untilDateHuman}
                                                            onChange={(e) => {
                                                                setUntilDate(e.target.value)
                                                                if (untilDateRef.current)
                                                                    untilDateRef.current.type = "text"
                                                                setUntilDateHuman(getDateHuman(new Date(e.target.value)))
                                                            }}
                                                            onFocus={() => untilDateRef.current ? untilDateRef.current.type = "date" : null}
                                                            onBlur={() => untilDateRef.current ? untilDateRef.current.type = "text" : null}
                                                        /> */}
                                                        <input
                                                            ref={untilDateRef}
                                                            placeholder={TR_('Не могу определиться с датой')}
                                                            type="date"
                                                            min={getDateStr(new Date())}
                                                            value={untilDate}
                                                            onChange={(e) => {
                                                                setUntilDate(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <input type="checkbox" className="checkbox-e" id="deadline" />
                                                    <label htmlFor="deadline">{TR_('Не могу определиться с датой')} </label> */}
                                                    <p className="fied-set__error"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field-set">
                                            <p className="field-set__title">
                                                {TR_('Описание')}
                                            </p>
                                            <div className="textarea h--90" data-tooltip-id="description-tooltip">
                                                <textarea ref={descriptionRef}
                                                    name="some-text"
                                                    id="someText"
                                                    placeholder={TR_('Опишите Ваш опыт при выполнении подобных работ')}
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}>
                                                </textarea>
                                            </div>
                                            <p className="fied-set__error"></p>
                                            <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} />
                                        </div>
                                        <PhotoSelector
                                            photos={photos}
                                            setPhotos={setPhotos}
                                            starred={starredPhoto}
                                            setStarred={setStarredPhoto}
                                        />
                                        <FileUploader files={files} setFiles={setFiles} />
                                    </form>
                                </div>
                                <div className="add__footer">
                                    <button className="main-button is--blue too--wide" onClick={() => publishFeedback()}>
                                        {TR_('Опубликовать')}
                                    </button>
                                </div>
                            </div>
                            <div className={"respond__description" + (isDescriptionShown ? "" : " hide_me")}>
                                <div className=" respond__description-inner">
                                    <header className="add__header">
                                        <button className="add__header-btn" onClick={() => setDescriptionShown(false)}></button>
                                        <h2 className="add__header-title">{TR_('Как написать хороший отклик')}</h2>
                                    </header>
                                    <div className="add__main">
                                        <ol className="gide-list">
                                            <li className="gide__item">
                                                {TR_('Объективно оцените свои возможности.')}
                                            </li>
                                            <li className="gide__item">
                                                {TR_('Заполните предлагаемую форму.')}
                                            </li>
                                            <li className="gide__item">
                                                {TR_('Опишите свой опыт в выполнении подобных работ.')}
                                            </li>
                                            {/* <li className="gide__item"> ~~~ disabling files
                                                Приложите фото или иные материалы подтверждающие Ваши компетенции.
                                            </li> */}
                                        </ol>
                                    </div>
                                    <div className="add__footer">
                                        <button className="main-button is--blue  too--wide">{TR_('Закрыть')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default AuctionNewFeedback;