import { Session, SessionExt } from "AuthContext"
import axios, { AxiosError, HttpStatusCode } from "axios"
import FileResizer from "react-image-file-resizer"
import { _auctions, _myAuctions } from "./Data/AuctionsData"
import { Auction, AuctionFeedback, AuctionFeedbackRequest, AuctionFeedbacks, AuctionRequest, BlobCache, Contacts, DentFUID, DentFile, DentNotifications, Doctor, MyAuction, MyAuctionFeedback, MyOffer, MySale, MyVacancy, MyVacancyFeedback, NotificationRequest, Offer, OfferRequest, Rating, Role, Sale, SaleRequest, Technician, Trader, UnixTime, User, UserID, UserReviewReply, UserReviewRequest, UserReviews, Vacancy, VacancyFeedback, VacancyFeedbackRequest, VacancyFeedbacks, VacancyRequest, isDoctor, isTechnician, isTrader } from "./Data/DentTypes"
import { _offersData } from "./Data/OffersData"
import { _salesData } from "./Data/SalesData"
import { TR, TR_ } from "./T10N"

const fetchDelay = 500 //ms

// TODO: check axios to give upload progress
// const getData = async () => {
//     const data = await axios.get(
//         "https://jsonplaceholder.typicode.com/todos/1"
//     )
//     setFetchedData(data)
// }

// export const sendData = (url, data) => {
//     fetch(`${url}`, data)
//         .then(async response => response.json())
// }

export function waitTimeout(milliseconds: number) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
    })
}

export function dateToUnixTime(date: string | Date): number {
    var dateBuff: Date
    if (typeof date === 'string') {
        dateBuff = new Date(date)
    } else {
        dateBuff = date
    }

    const dateUTC = Date.UTC(
        dateBuff.getUTCFullYear(),
        dateBuff.getUTCMonth(),
        dateBuff.getUTCDate(),
        dateBuff.getUTCHours(),
        dateBuff.getUTCMinutes(),
        dateBuff.getUTCSeconds())
    return Math.floor(dateUTC / 1000) // utc unix timestamp
}

const getAuctionFilters = {
    find: "",
    forRole: "", // technician | trader
    cities: [],
    priceFrom: 0,
    priceTo: 0
}

const months = [
    TR('января'),
    TR('февраля'),
    TR('марта'),
    TR('апреля'),
    TR('мая'),
    TR('июня'),
    TR('июля'),
    TR('августа'),
    TR('сентября'),
    TR('октября'),
    TR('ноября'),
    TR('декабря')]

const monthsShort = [
    TR_('янв'),
    TR_('фев'),
    TR_('мар'),
    TR_('апр'),
    TR_('мая'),
    TR_('июн'),
    TR_('июл'),
    TR_('авг'),
    TR_('сен'),
    TR_('окт'),
    TR_('ноя'),
    TR_('дек')]

function getMonthHuman(month: number) {
    if (month < 0 || month > 11)
        return "???"

    return months[month]
}

function getMonthHumanShort(month: number) {
    if (month < 0 || month > 11)
        return "???"

    return monthsShort[month]
}

// "DD MONTH" если дата этого года и "DD MONTH YYYY" в противном случае
export function getDateHuman(date: Date | number | undefined, dateNow: Date = new Date()) {
    if (date === undefined) {
        return TR('Не указ.')
    }

    if (typeof date === 'number') {
        if (date == 0)
            return TR('Не указ.')

        date = new Date(date * 1000)
    }

    try {
        const yearStr = dateNow.getFullYear() == date.getFullYear() ? '' : ' ' + date.getFullYear()
        return date.getDate() + ' ' + getMonthHuman(date.getMonth()) + yearStr
    } catch (e) {
        return TR('Не указ.')
    }
}

// "DD MONTH" если дата этого года и "DD MONTH YYYY" в противном случае
export function getDateHuman2(date: Date | number | undefined, dateNow: Date = new Date()): string | undefined {
    if (date === undefined) {
        return undefined
    }

    if (typeof date === 'number') {
        if (date == 0)
            return undefined

        date = new Date(date * 1000)
    }

    try {
        const yearStr = dateNow.getFullYear() == date.getFullYear() ? '' : ' ' + date.getFullYear()
        return date.getDate() + ' ' + getMonthHuman(date.getMonth()) + yearStr
    } catch (e) {
        return undefined
    }
}

// "DD MONTH" если дата этого года и "DD MONTH YYYY" в противном случае
export function getDateHumanShort(date: Date | number | undefined): string | undefined {
    if (date === undefined) {
        return undefined
    }

    if (typeof date === 'number') {
        if (date == 0)
            return undefined

        date = new Date(date * 1000)
    }

    try {
        return date.getDate() + ' ' + getMonthHumanShort(date.getMonth())
    } catch (e) {
        return undefined
    }
}

// "DD MONTH" если дата этого года и "DD MONTH YYYY" в противном случае
export function getDateRangeHuman(from: Date | number | undefined, upto: Date | number | undefined): string {
    const fromStr = getDateHuman2(from)
    const uptoStr = getDateHuman2(upto)

    if (fromStr) {
        if (uptoStr) {
            return fromStr + ' - ' + uptoStr
        }

        return TR_('с') + ' ' + fromStr
    }

    if (uptoStr) {
        return TR_('до') + ' ' + uptoStr
    }

    return TR('Не указ.')
}

// "DD MONTH" если дата этого года и "DD MONTH YYYY" в противном случае
export function getDateRangeHumanShort(from: Date | number | undefined, upto: Date | number | undefined): string {
    const fromStr = getDateHumanShort(from)
    const uptoStr = getDateHumanShort(upto)

    if (fromStr) {
        if (uptoStr) {
            return fromStr + ' - ' + uptoStr
        }

        return TR_('с') + ' ' + fromStr
    }

    if (uptoStr) {
        return TR_('до') + ' ' + uptoStr
    }

    return TR('Не указ.')
}

// "DD MONTH" если дата этого года и "DD MONTH YYYY" в противном случае
export function getDateTimeHuman(date: Date | number | undefined, dateNow: Date = new Date()) {
    if (date === undefined) {
        return TR('Не указ.')
    }

    if (typeof date === 'number') {
        if (date == 0)
            return TR('Не указ.')

        date = new Date(date * 1000)
    }

    var hours = ('00' + date.getHours()).slice(-2)
    var minutes = ('00' + date.getMinutes()).slice(-2)

    try {
        const yearStr = dateNow.getFullYear() == date.getFullYear() ? '' : ' ' + date.getFullYear()
        return date.getDate() + ' ' + getMonthHuman(date.getMonth()) + yearStr // date
            + ' ' + hours + ':' + minutes
    } catch (e) {
        return TR('Не указ.')
    }
}

export function getDateStr(date: Date | number | undefined): string {
    if (date === undefined) {
        return ''
    }

    try {
        if (typeof date === 'number') {
            if (date == 0)
                return TR('Не указ.')

            date = new Date(date * 1000)
        }


        return date.toISOString().slice(0, 10)
    } catch (e) {
        return TR('Не указ.')
    }
}

const rusHours = [
    "часов", // 0
    "час",
    "часа",
    "часа",
    "часа",
    "часов",
    "часов",
    "часов",
    "часов",
    "часов",
]

const rusMinutes = [
    "минут", // 0
    "минута",
    "минуты",
    "минуты",
    "минуты",
    "минут",
    "минут",
    "минут",
    "минут",
    "минут",
]

const rusDays = [
    "дней", // 0
    "день",
    "дня",
    "дня",
    "дня",
    "дней",
    "дней",
    "дней",
    "дней",
    "дней",
]

const rusMonths = [
    "месяцев", // 0
    "месяц",
    "месяца",
    "месяца",
    "месяца",
    "месяцев",
    "месяцев",
    "месяцев",
    "месяцев",
    "месяцев",
]

function rusDaysHuman(days: number): string {
    if (days > 10 && days < 20) {
        return rusDays[0]
    }

    return rusDays[days % 10]
}

export function getDurationHumanMs(milliseconds: number) {
    if (milliseconds < 0)
        return "---"

    if (milliseconds < 86_400_000) // Less than 1 day
    {
        const hours = Math.floor(((milliseconds % 31_536_000_000) % 86_400_000) / 3600_000)
        //const minutes = Math.floor((((milliseconds % 31_536_000_000) % 86_400_000) % 3600_000) / 60_000)
        return hours + ' ' + rusHours[hours % 10] // + ' ' + minutes + ' ' + rusMinutes[minutes % 10]
    }

    const days = Math.floor((milliseconds % 31_536_000_000) / 86_400_000)
    if (days < 31) {
        return days + ' ' + rusDaysHuman(days)
    }

    const months = Math.floor(days / 30)
    return months + rusMonths[months % 10]
}

export function getDurationHuman(seconds: number) {
    if (seconds < 0)
        return "---"

    if (seconds < 86_400) // Less than 1 day
    {
        const hours = Math.floor((seconds % 86_400) / 3600)
        //const minutes = Math.floor(((seconds % 86_400) % 3600) / 60)
        return hours + ' ' + rusHours[hours % 10] // + ' ' + minutes + ' ' + rusMinutes[minutes % 10]
    }

    const days = Math.floor((seconds % 31_536_000) / 86_400)
    if (days < 31) {
        return days + ' ' + rusDaysHuman(days)
    }

    const months = Math.floor(days / 30)
    return months + rusMonths[months % 10]
}

// Duration in milliseconds since midnight, January 1, 1970 UTC.
export function timeNow(): number {
    return new Date().getTime()
}

// Duration in seconds since midnight, January 1, 1970 UTC.
export function timeNowUnix(): number {
    return Math.floor(timeNow() / 1000)
}

export async function getDoctors(
    find: string,
    cities: string[],
    chairs: number,
    hasScanner: boolean,
    sortBy: string): Promise<Doctor[]> {

    interface Request {
        find: string
        cities: string[]
        chairs?: number
        hasScanner?: boolean
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        doctors?: Doctor[]
    }

    const request: Request = {
        find: find,
        cities: cities,
        chairs: chairs,
        hasScanner: hasScanner,
        sortBy: ""
    }

    const reply = await net.post<Reply>('/api/v1/users/doctors', JSON.stringify(request))

    if (reply.doctors) {
        //console.log('Doctors list received')

        return reply.doctors
    }

    return []

    // await waitTimeout(fetchDelay)

    // return _doctors.filter(doctor => {
    //     let findResult = true
    //     if (find) {
    //         const FIND = find.toLocaleUpperCase()
    //         const NAME = doctor.name.toLocaleUpperCase()
    //         if (!NAME.includes(FIND))
    //             findResult = false
    //     }

    //     return findResult
    // })
}

export function getAvailableCities(): string[] {
    //return []
    return [
        "Москва",
        "Санкт-петербург",
        "Новосибирск",
        "Нижний новгород",
        "Краснодар",
        "Ижевск",
        "Подольск",
        "Нижнекамск",
    ]
}

export async function downloadFile(filePath: string, fileName: string) {
    try {
        const response = await fetch(filePath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })

        if (response.status != HttpStatusCode.Ok) {
            console.log('Error downloading \'' + filePath + '\'')
        }

        const blob = await response.blob()
        const url = window.URL.createObjectURL(new Blob([blob]))

        const link = document.createElement('a')
        link.href = url
        link.download = fileName

        document.body.appendChild(link)

        link.click()
        link.parentNode?.removeChild(link)
    } catch (e) {
        console.log('Error downloading \'' + filePath + '\'')
    }
}

export async function getTechnicians(
    find: string,
    cities: string[],
    hasPrice: boolean,
    hasCadcam: boolean,
    sortBy: string): Promise<Technician[]> {

    interface Request {
        find: string
        cities: string[]
        hasPrice?: boolean
        hasCadcam?: boolean
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        technicians?: Technician[]
    }

    const request: Request = {
        find: find,
        cities: cities,
        hasPrice: hasPrice,
        hasCadcam: hasCadcam,
        sortBy: ""
    }

    const reply = await net.post<Reply>('/api/v1/users/technicians', JSON.stringify(request))

    if (reply.technicians) {
        //console.log('Technicians list received')

        return reply.technicians
    }

    return []

    // await waitTimeout(fetchDelay)

    // return _technicians.filter(technician => {
    //     let findResult = true
    //     if (find) {
    //         const FIND = find.toLocaleUpperCase()
    //         const NAME = technician.name.toLocaleUpperCase()
    //         if (!NAME.includes(FIND))
    //             findResult = false
    //     }

    //     return findResult
    // })
}

export async function getTraders(
    find: string,
    cities: string[],
    tags: string[],
    sortBy: string): Promise<Trader[]> {

    interface Request {
        find: string
        cities: string[]
        tags: string[]
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        traders?: Trader[]
    }

    const request: Request = {
        find: find,
        cities: cities,
        tags: tags,
        sortBy: sortBy
    }

    const reply = await net.post<Reply>('/api/v1/users/traders', JSON.stringify(request))

    if (reply.traders) {
        //console.log('Traders list received')

        return reply.traders
    }

    return []

    // await waitTimeout(fetchDelay)

    // return _traders.filter(trader => {
    //     let findResult = true
    //     if (find) {
    //         const FIND = find.toLocaleUpperCase()
    //         const NAME = trader.name.toLocaleUpperCase()
    //         if (!NAME.includes(FIND))
    //             findResult = false
    //     }

    //     return findResult
    // })
}

export async function getUserReviews(userId: UserID): Promise<UserReviews> {
    // await waitTimeout(fetchDelay)

    // return { reviews: _userReviews, lastSeenFeedback: 0 }

    interface Request {
        user: UserID
    }

    type Reply = {
        status: string
        message?: string
        reviews?: UserReviews
    }

    const request: Request = {
        user: userId,
    }

    const reply = await net.post<Reply>('/api/v1/users/list_reviews', JSON.stringify(request))

    if (reply.reviews) {
        //console.log('Reviews list received')

        return reply.reviews
    }

    return {
        rateSum: 0,
        rateCount: 0,
        reviews: [],
        myReview: {
            id: 0,
            publicDate: 0,
            updateDate: 0,
            creator: {
                id: "",
                name: "",
                avatar: ""
            },
            rate: 1,
            description: ""
        },
        lastSeenReview: 0,
    }
}

export async function getAuctions(
    find: string,
    forRole: string,
    cities: string[],
    priceFrom: number,
    priceTo: number,
    sortBy: string): Promise<Auction[]> {

    interface Request {
        find: string
        forRole: string
        cities: string[]
        priceFrom: number
        priceTo: number
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        auctions?: Auction[]
    }

    const request: Request = {
        find: find,
        forRole: forRole,
        cities: cities,
        priceFrom: priceFrom,
        priceTo: priceTo,
        sortBy: sortBy
    }

    const reply = await net.post<Reply>('/api/v1/users/list_auctions', JSON.stringify(request))

    if (reply.auctions) {
        //console.log('Auctions list received')

        return reply.auctions
    }

    return []
}

export async function getMyAuctions(): Promise<MyAuction[]> {

    await waitTimeout(fetchDelay)

    return _myAuctions
}

export async function getDoctorAuctions(): Promise<Auction[]> {

    await waitTimeout(fetchDelay)

    return _auctions
}

export async function getTechnicianAuctions(): Promise<Auction[]> {

    await waitTimeout(fetchDelay)

    return _auctions.slice().reverse()
}

export async function getOffers(
    find: string,
    forRole: string,
    cities: string[],
    priceFrom: number,
    priceUpto: number,
    sortBy: string): Promise<Offer[]> {

    interface Request {
        find: string
        forRole: string
        cities: string[]
        priceFrom: number
        priceUpto: number
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        offers?: Offer[]
    }

    const request: Request = {
        find: find,
        forRole: forRole,
        cities: cities,
        priceFrom: priceFrom,
        priceUpto: priceUpto,
        sortBy: sortBy
    }

    const reply = await net.post<Reply>('/api/v1/users/list_offers', JSON.stringify(request))

    if (reply.offers) {
        //console.log('Offers list received')

        return reply.offers
    }

    return []

    // await waitTimeout(fetchDelay)

    // return _offersData.filter(offer => {
    //     let findResult = true
    //     if (find)
    //         if (!offer.title.includes(find))
    //             findResult = false

    //     let priceResult = true
    //     if (priceFrom || priceTo)
    //         if (offer.priceNew < priceFrom || offer.priceNew > priceTo)
    //             priceResult = false

    //     let roleResult = true
    //     if (forRole)
    //         roleResult = (forRole === offer.targetRole)

    //     return findResult && priceResult && roleResult
    // })
}

export async function getTraderOffers(): Promise<Offer[]> {

    await waitTimeout(fetchDelay)

    return _offersData
}

export async function getTechnicianOffers(): Promise<Offer[]> {

    await waitTimeout(fetchDelay)

    return _offersData.slice().reverse()
}

export async function getOffer(id: number): Promise<Offer> {
    await waitTimeout(fetchDelay)

    const result = _offersData.filter((offer) => offer.id == id)
    return result.length == 0 ? _offersData[0] : result[0]
}

export async function getSales(
    find: string,
    cities: string[],
    priceFrom: number,
    priceUpto: number,
    sortBy: string): Promise<Sale[]> {

    interface Request {
        find: string
        cities: string[]
        priceFrom: number
        priceUpto: number
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        sales?: Sale[]
    }

    const request: Request = {
        find: find,
        cities: cities,
        priceFrom: priceFrom,
        priceUpto: priceUpto,
        sortBy: sortBy
    }

    const reply = await net.post<Reply>('/api/v1/users/list_sales', JSON.stringify(request))

    if (reply.sales) {
        //console.log('Sales list received')

        return reply.sales
    }

    return []

    // await waitTimeout(fetchDelay)

    // return _salesData.filter(offer => {
    //     let findResult = true
    //     if (find)
    //         if (!offer.title.includes(find))
    //             findResult = false

    //     let priceResult = true
    //     if (priceFrom || priceTo)
    //         if (offer.price < priceFrom || offer.price > priceTo)
    //             priceResult = false

    //     return findResult && priceResult
    // })
}

export async function getSale(id: number): Promise<Sale> {
    await waitTimeout(fetchDelay)

    const result = _salesData.filter((sale) => sale.id == id)
    return result.length == 0 ? _salesData[0] : result[0]
}

export async function getVacancies(
    find: string,
    forRole: string,
    cities: string[],
    salaryFrom: number,
    salaryUpto: number,
    sortBy: string): Promise<Vacancy[]> {

    interface Request {
        find: string
        forRole: string
        cities: string[]
        salaryFrom: number
        salaryUpto: number
        sortBy: string
    }

    type Reply = {
        status: string
        message?: string
        vacancies?: Vacancy[]
    }

    const request: Request = {
        find: find,
        forRole: forRole,
        cities: cities,
        salaryFrom: salaryFrom,
        salaryUpto: salaryUpto,
        sortBy: sortBy
    }

    const reply = await net.post<Reply>('/api/v1/users/list_vacancies', JSON.stringify(request))

    if (reply.vacancies) {
        //console.log('Vacancies list received')

        return reply.vacancies
    }

    return []

    // await waitTimeout(fetchDelay)

    // return _vacanciesData.filter(vacancy => {
    //     let findResult = true
    //     if (find)
    //         if (!vacancy.title.includes(find))
    //             findResult = false

    //     let salaryResult = true
    //     if (salaryFrom || salaryTo)
    //         if (vacancy.salaryFrom < salaryFrom || vacancy.salaryFrom > salaryTo)
    //             salaryResult = false

    //     let roleResult = true
    //     if (forRole)
    //         roleResult = (forRole === vacancy.targetRole)

    //     return findResult && salaryResult && roleResult
    // })
}

// export async function getVacancy(id: number): Promise<Vacancy> {
//     await waitTimeout(fetchDelay)

//     const result = _vacanciesData.filter((vacancy) => vacancy.id == id)
//     return result.length == 0 ? _vacanciesData[0] : result[0]
// }

export async function getAuctionFeedbacks(auctionId: number): Promise<AuctionFeedbacks> {
    type Reply = {
        status: string
        message?: string
        feedbacks?: AuctionFeedbacks
    }

    const reply = await net.get<Reply>('/api/v1/users/auctions/' + auctionId + '/feedbacks')

    if (reply.feedbacks)
        return reply.feedbacks

    return {
        feedbacks: [],
        lastSeenFeedback: 0
    }
}

export async function getVacancyFeedbacks(vacancyId: number): Promise<VacancyFeedbacks> {
    type Reply = {
        status: string
        message?: string
        feedbacks?: VacancyFeedbacks
    }

    const reply = await net.get<Reply>('/api/v1/users/vacancies/' + vacancyId + '/feedbacks')

    if (reply.feedbacks)
        return reply.feedbacks

    return {
        feedbacks: [],
        lastSeenFeedback: 0
    }
}

export async function deleteAuctionFeedback(feedbackId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        // Note: vacancyId is not realy required, so we put 0
        const reply = await net.delete<Reply>('/api/v1/users/auctions/_/feedbacks/' + feedbackId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

export async function deleteVacancyFeedback(feedbackId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        // Note: vacancyId is not realy required, so we put 0
        const reply = await net.delete<Reply>('/api/v1/users/vacancies/_/feedbacks/' + feedbackId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

export const getOfferById = async (offerId: number) => {
    const result = _offersData.filter(offer => offer.id === offerId)
    return result.length ? result[0] : null
}

export async function request<TResponse>(url: string, config: RequestInit): Promise<TResponse> {
    const response = await fetch(url, config)

    const handler = gHandlers[response.status]
    if (handler)
        handler()

    return await response.json()
}

export const net = {
    get: <TResponse>(url: string) =>
        request<TResponse>(url, { method: 'GET' }),

    delete: <TResponse>(url: string) =>
        request<TResponse>(url, { method: 'DELETE' }),

    // Using `extends` to set a type constraint:
    // post: <TResponse, TBody extends BodyInit>(url: string, body: TBody) =>
    //     request<TResponse>(url, { method: 'POST', body }),
    post: <TResponse>(url: string, body: BodyInit) =>
        request<TResponse>(url, { method: 'POST', body }),

    update: <TResponse>(url: string, body: BodyInit) =>
        request<TResponse>(url, { method: 'UPDATE', body }),
}

export const resizeImage = (file: File, width: number, height: number) =>
    new Promise<string | Blob | File | ProgressEvent<FileReader>>((resolve) => {
        FileResizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            90,
            0,
            (uri) => {
                resolve(uri)
            },
            //"base64"
            "blob" // Result will be Blob
        )
    })

export async function uploadBlob(url: string, blob: Blob): Promise<DentFUID> {
    type Reply = {
        status: string
        url: DentFUID
    }

    const reply = await net.post<Reply>(url, blob)

    //console.log("Upload reply:")
    //console.log(reply)
    return reply.url
}

export async function uploadAvatar(file: Blob | File): Promise<DentFUID> {
    type Reply = {
        status: string
        fuid: DentFUID
        message?: string
    }

    const reply = await net.post<Reply>('/api/v1/users/avatar', file)

    // console.log("Upload reply:")
    // console.log(reply)

    if (reply.status !== 'success')
        throw new Error('Server error: "' + reply.message + '"')

    return reply.fuid
}

export async function uploadPhoto(file: Blob | File): Promise<DentFUID> {
    type Reply = {
        status: string
        fuid: DentFUID
        message?: string
    }

    const reply = await net.post<Reply>('/api/v1/users/photo', file)

    // console.log("Upload reply:")
    // console.log(reply)

    if (reply.status !== 'success')
        throw new Error('Server error: "' + reply.message + '"')

    return reply.fuid
}

export async function deletePhoto(fuid: DentFUID): Promise<boolean> {
    type Reply = {
        status: string
        message?: string
    }

    const reply = await net.delete<Reply>('/api/v1/users/photo/' + fuid)

    // console.log("Delete reply:")
    // console.log(reply)

    return reply.status === 'success'
}

function filterOut(obj: any, filter: string[]): any {
    interface Obj {
        [key: string]: any
    }

    var result: Obj = {}

    for (const key in obj) {
        if (filter.includes(key)) {
            continue
        }

        result[key] = obj[key]
    }

    return result
}

type ErrorHandler = () => void
//var gHandlers: Map<HttpStatusCode, ErrorHandler>
var gHandlers: { [key: number]: ErrorHandler } = {}


function registerHandler(sc: HttpStatusCode, handler: ErrorHandler) {
    gHandlers[sc] = handler
}

export function registerUnauthorizedHandler(handler: ErrorHandler) {
    gHandlers[HttpStatusCode.Unauthorized] = handler
}

function handleNetworkError(e: unknown): void {
    if (e instanceof AxiosError) {
        const statusCode = e.response?.status
        if (statusCode === HttpStatusCode.Unauthorized) {
            // Looks like session expired
            const handler = gHandlers[HttpStatusCode.Unauthorized]
            if (handler) {
                handler()
            }
        }
    }
}

function preparePhotos(formData: FormData, photos?: DentFUID[]) {
    var counter = 1
    if (photos) {
        for (var i in photos) {
            var photoFuid = photos[i]
            if (photoFuid instanceof BlobCache) {
                const blockName = "photo" + counter++
                formData.append(blockName, photoFuid.blob)
                photos[i] = blockName
                //photoFuid = name
            }
        }
    }
}

function prepareFiles(formData: FormData, files?: DentFile[]) {
    if (files) {// Preparing parts
        var counter = 1
        for (var i in files) {
            var file = files[i]
            if (file.fuid instanceof BlobCache) {
                const blockName = "file" + counter++
                //console.log('Adding file: ' + blockName)
                formData.append(blockName, file.fuid.blob)
                //console.log(file.fuid.blob)
                files[i] = { name: file.name, fuid: blockName }
            }
        }
    }
}

function prepareJson(formData: FormData, request: any) {
    {// Adding json
        const json = JSON.stringify(request)
        const blob = new Blob([json], {
            type: 'application/json'
        })

        formData.append("json", blob)
    }
}


type DefaultReply = {
    status: string
    message?: string
}

type CreateAuctionReply = DefaultReply
export async function createOrUpdateAuction(auction: AuctionRequest): Promise<CreateAuctionReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, auction.photos)
    prepareFiles(formData, auction.files)
    prepareJson(formData, auction)

    try {
        const reply = await axios.request<CreateAuctionReply>({
            method: 'post',
            url: '/api/v1/users/auctions',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

export async function deleteAuction(auctionId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        const reply = await net.delete<Reply>('/api/v1/users/auctions/' + auctionId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

export async function deleteOffer(offerId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        const reply = await net.delete<Reply>('/api/v1/users/offers/' + offerId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

export async function deleteSale(saleId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        const reply = await net.delete<Reply>('/api/v1/users/sales/' + saleId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

type CreateVacancyReply = DefaultReply
export async function createOrUpdateVacancy(vacancy: VacancyRequest): Promise<CreateVacancyReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, vacancy.photos)
    prepareFiles(formData, vacancy.files)
    prepareJson(formData, vacancy)

    try {
        const reply = await axios.request<CreateVacancyReply>({
            method: 'post',
            url: '/api/v1/users/vacancies',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

export async function deleteVacancy(vacancyId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        const reply = await net.delete<Reply>('/api/v1/users/vacancies/' + vacancyId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

type AuctionNewFeedbackReply = {
    status: string
    message?: string
    feedback?: AuctionFeedback
}

export async function createOrUpdateAuctionFeedback(auctionId: number, feedback: AuctionFeedbackRequest): Promise<AuctionNewFeedbackReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, feedback.photos)
    prepareFiles(formData, feedback.files)
    prepareJson(formData, feedback)

    try {
        const reply = await axios.request<AuctionNewFeedbackReply>({
            method: 'post',
            url: '/api/v1/users/auctions/' + auctionId + '/feedbacks',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
    }

    return {
        status: "fail"
    }
}

type VacancyNewFeedbackReply = {
    status: string
    message?: string
    feedback?: VacancyFeedback
}

export async function createOrUpdateVacancyFeedback(vacancyId: number, feedback: VacancyFeedbackRequest): Promise<VacancyNewFeedbackReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, feedback.photos)
    prepareFiles(formData, feedback.files)
    prepareJson(formData, feedback)

    try {
        const reply = await axios.request<VacancyNewFeedbackReply>({
            method: 'post',
            url: '/api/v1/users/vacancies/' + vacancyId + '/feedbacks',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
    }

    return {
        status: "fail"
    }
}

type AuctionGetFeedbackReply = AuctionNewFeedbackReply
export async function getAuctionFeedback(auctionId: number, user: UserID): Promise<AuctionGetFeedbackReply> {
    try {
        const reply = await axios.request<AuctionGetFeedbackReply>({
            method: 'get',
            url: '/api/v1/users/auctions/' + auctionId + '/feedback',
        })

        return reply.data
    } catch (e) {
        handleNetworkError(e)
        if (e instanceof AxiosError) {
            if (e.response?.status == HttpStatusCode.NotFound) {
                return {
                    status: "fail"
                }
            }
        }

        throw e
    }
}

type VacancyGetFeedbackReply = VacancyNewFeedbackReply
export async function getVacancyFeedback(vacancyId: number, user: UserID): Promise<VacancyGetFeedbackReply> {
    try {
        const reply = await axios.request<VacancyGetFeedbackReply>({
            method: 'get',
            url: '/api/v1/users/vacancies/' + vacancyId + '/feedback',
        })

        return reply.data
    } catch (e) {
        handleNetworkError(e)
        if (e instanceof AxiosError) {
            if (e.response?.status == HttpStatusCode.NotFound) {
                return {
                    status: "fail"
                }
            }
        }

        throw e
    }
}

type CreateOfferReply = DefaultReply
export async function createOrUpdateOffer(offer: OfferRequest): Promise<CreateOfferReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, offer.photos)
    prepareFiles(formData, offer.files)
    prepareJson(formData, offer)

    try {
        const reply = await axios.request<CreateOfferReply>({
            method: 'post',
            url: '/api/v1/users/offers',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

type CreateSaleReply = DefaultReply

export async function createOrUpdateSale(sale: SaleRequest): Promise<CreateSaleReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, sale.photos)
    prepareFiles(formData, sale.files)
    prepareJson(formData, sale)

    try {
        const reply = await axios.request<CreateSaleReply>({
            method: 'post',
            url: '/api/v1/users/sales',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

type CreateUserReviewReply = {
    status: string
    message?: string
    review?: UserReviewReply
}

export async function createOrUpdateUserReview(review: UserReviewRequest): Promise<UserReviewReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    prepareJson(formData, review)

    try {
        const reply = await axios.request<CreateUserReviewReply>({
            method: 'post',
            url: '/api/v1/users/reviews',
            data: formData,
        })

        //console.log(reply)

        if (reply.data.review) {
            return reply.data.review
        }

        throw 'Not review in reply'

    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

export async function deleteUserReview(reviewId: number): Promise<Rating> {
    type Reply = {
        status: string
        rating?: Rating
    }

    try {
        // Note: vacancyId is not realy required, so we put 0
        const reply = await net.delete<Reply>('/api/v1/users/reviews/' + reviewId)
        if (reply.rating) {
            return reply.rating
        }

    } catch (e) {

    }

    throw 'Delete error'
}

type UpdateMyDataReply = {
    status: string
    message?: string
    // session?: {
    //     avatar: string
    // }
    session?: Session
}

// Dashboard data
export interface DashData {
    myAuctions?: {
        actual: MyAuction[]
        archived: MyAuction[]
    }
    myOffers?: {
        actual: MyOffer[]
        archived: MyOffer[]
    }
    myVacancies?: {
        actual: MyVacancy[]
        archived: MyVacancy[]
    }
    mySales?: {
        actual: MySale[]
        archived: MySale[]
    }

    offersFromTraders?: Offer[]
    offersFromTechnicians?: Offer[]
    purchasesFromDoctors?: Auction[]
    purchasesFromTechnicians?: Auction[]

    myVacancyFeedbacks?: MyVacancyFeedback[]
    myAuctionFeedbacks?: MyAuctionFeedback[]
}

type DashDataReply = {
    status: string
    message?: string
    data?: DashData
}

export async function getDashData(requiredData: DashData): Promise<DashData> {
    const reply = await net.post<DashDataReply>('/api/v1/users/home_data', JSON.stringify(requiredData))
    if (reply.data)
        return reply.data

    throw reply.message
}

export async function updateMyData(userData: Doctor | Technician | Trader): Promise<UpdateMyDataReply> {
    const formData = new FormData()

    {// Preparing parts
        if (userData.avatar instanceof BlobCache) {
            formData.append("avatar", userData.avatar.blob)
            userData.avatar = "avatar"
        }

        var counter = 1
        for (var i in userData.photos) {
            var photoFuid = userData.photos[i]
            if (photoFuid instanceof BlobCache) {
                const blockName = "photo" + counter++
                formData.append(blockName, photoFuid.blob)
                userData.photos[i] = blockName
                //photoFuid = name
            }
        }

        var counter = 1
        if (isDoctor(userData)) {

            const licenses = userData.doctor.licenses
            for (var i in licenses) {
                var file = licenses[i]
                if (file.fuid instanceof BlobCache) {
                    const blockName = "file" + counter++
                    formData.append(blockName, file.fuid.blob)
                    licenses[i] = { name: file.name, fuid: blockName }
                }
            }
        }

        if (isTechnician(userData)) {
            const licenses = userData.technician.licenses
            for (var i in licenses) {
                var file = licenses[i]
                if (file.fuid instanceof BlobCache) {
                    const blockName = "file" + counter++
                    formData.append(blockName, file.fuid.blob)
                    licenses[i] = { name: file.name, fuid: blockName }
                }
            }
        }

        if (isTrader(userData) && userData.counterpartyCard) {
            const file = userData.counterpartyCard
            if (file.fuid instanceof BlobCache) {
                const blockName = "file" + counter++
                formData.append(blockName, file.fuid.blob)
                file.fuid = blockName
            }
        }
    }

    {// Adding json
        const json = JSON.stringify(filterOut(userData, ['id', 'rating']))
        const blob = new Blob([json], {
            type: 'application/json'
        })

        formData.append("json", blob)
    }

    try {
        const reply = await axios.request<UpdateMyDataReply>({
            method: 'post',
            url: '/api/v1/users/me',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

export type UserSignupRequest = {
    role: Role
    name: string
    avatar: DentFUID
    email: string
    password: string
}

type UserSignupReply = {
    status: string
    error_code?: number
    message?: string
    session?: Session
}

export async function userSignup(request: UserSignupRequest): Promise<UserSignupReply> {
    const formData = new FormData()

    {// Preparing parts
        if (request.avatar instanceof BlobCache) {
            formData.append("avatar", request.avatar.blob)
            request.avatar = "avatar"
        }
    }

    {// Adding json
        const json = JSON.stringify(request)
        const blob = new Blob([json], {
            type: 'application/json'
        })

        formData.append("json", blob)
    }

    try {
        const reply = await axios.request<UserSignupReply>({
            method: 'post',
            url: '/api/v1/auth/signup',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

export async function getDentNotifications(): Promise<DentNotifications> {
    type Reply = {
        status: string
        message?: string
        notifications?: DentNotifications
    }

    const reply = await net.get<Reply>('/api/v1/users/notifications')

    if (reply.notifications)
        return reply.notifications

    throw "Cannot get notifications"
}

type CreateNotificationReply = DefaultReply
export async function createOrUpdateNotification(notification: NotificationRequest): Promise<CreateNotificationReply> {
    const formData = new FormData()

    // Note: prepareJson should be the last one, since prepareXYZ functions are changing contents of the request
    preparePhotos(formData, notification.photos)
    prepareFiles(formData, notification.files)
    prepareJson(formData, notification)

    try {
        const reply = await axios.request<CreateNotificationReply>({
            method: 'post',
            url: '/api/v1/users/notifications',
            data: formData,
        })

        //console.log(reply)
        return reply.data
    } catch (e) {
        handleNetworkError(e)
        throw e
    }
}

export async function updateLastSeenNotification(lastSeenTime: UnixTime): Promise<boolean> {
    interface Request {
        lastSeenTime: UnixTime
    }

    type Reply = {
        status: string
        message?: string
    }

    const request: Request = {
        lastSeenTime: lastSeenTime
    }

    const reply = await net.post<Reply>('/api/v1/users/last_notification', JSON.stringify(request))

    if (reply.status === 'success') {
        return true
    }

    return false
}

export async function deleteNotification(notificationId: number): Promise<boolean> {
    type Reply = {
        status: string
    }

    try {
        // Note: vacancyId is not realy required, so we put 0
        const reply = await net.delete<Reply>('/api/v1/users/notifications/' + notificationId)
        return reply.status === 'success'
    } catch (e) {

    }

    return false
}

export async function uploadFile(filename: string, file: Blob | File, onProgress?: (p: number) => void): Promise<DentFile> {
    //throw new Error('Not implemented')
    type Reply = {
        status: string
        message?: string
    }

    //console.log("Filesize:" + file.size)

    const response = await axios.request<Reply>({
        method: "post",
        headers: {
            "X-Filename": "123.pdf"
        },
        url: "/api/v1/users/file",
        data: file,
        onUploadProgress: (p) => {
            const progress = file.size ? (p.loaded / file.size) : 1
            if (onProgress) {
                onProgress(progress)
            }

            //console.log('Progress:' + progress)
        }
    })

    //console.log(response.data)

    return { name: '', fuid: '' }
}

// ~~ Use getUserInfo
// export async function getCompanyInfo(companyId: UserID): Promise<User> {
//     await waitTimeout(fetchDelay)

//     return _technicians[1]
// }


export async function getUserInfo(companyId: UserID): Promise<User> {
    type UserInfoReply = {
        status: string
        message?: string
        info?: User
    }

    const reply = await net.get<UserInfoReply>('/api/v1/users/' + companyId + '/info')
    if (reply.info)
        return reply.info

    throw reply.message
}


export async function getUserContacts(companyId: UserID): Promise<Contacts> {
    type ContactsReply = {
        status: string
        message?: string
        contacts?: Contacts
    }

    const reply = await net.get<ContactsReply>('/api/v1/users/' + companyId + '/contacts')
    if (reply.contacts)
        return reply.contacts

    throw reply.message
}

export async function forgotPassword(email: string): Promise<boolean> {
    type ForgotPasswordReply = {
        status: string
        message?: string
    }

    const reply = await net.post<ForgotPasswordReply>('/api/v1/auth/forgot_password', JSON.stringify({ email: email }))

    if (reply.status == 'success')
        return true

    throw reply.message
}

export async function newPassword(password: string, passCheck: string): Promise<SessionExt> {
    type NewPasswordReply = {
        status: string
        message?: string
        error_code?: number
        session?: SessionExt
    }

    const reply = await net.post<NewPasswordReply>('/api/v1/auth/set_password', JSON.stringify({ password: password, passCheck: passCheck }))

    if (reply.session) {
        return reply.session
    }

    throw reply.error_code
}

// export function request<TResponse>(
//     url: string,
//     // `RequestInit` is a type for configuring 
//     // a `fetch` request. By default, an empty object.
//     config: RequestInit = {}
//     // This function is async, it will return a Promise:
// ): Promise<TResponse> {

//     // Inside, we call the `fetch` function with 
//     // a URL and config given:
//     return fetch(url, config)
//         // When got a response call a `json` method on it
//         .then((response) => response.json())
//         // and return the result data.
//         .then((data) => data as TResponse)

//     // We also can use some post-response
//     // data-transformations in the last `then` clause.
// }