import { dateToUnixTime } from "service/API";
import { Auction, AuctionFeedbacks, MyAuction } from "./DentTypes";

export const _auctions: Auction[] = [
    {
        id: 1,
        targetRole: 'technician',
        title: 'Изготовление хирургических шаблонов',
        description: `Требуется изготовление на постоянной основе шаблонов. В стоимость должно входить моделировка (планирование), печать разборного шаблона, фиксация втулок (направляющих), доставка. Система имплантатов - AnyRidge.`,
        maxBudget: 155000,
        creator: {
            id: '1',
            name: 'Зубной техник',
            avatar: '/assets/temp/avatars/33x33/1.png',
        },
        untilDate: dateToUnixTime("2023-05-30"),
        publicDate: dateToUnixTime("2023-04-16"),
        files: [
            { name: 'Хирург. шаблон 1', fuid: '#' },
            { name: 'Хирург. шаблон 2', fuid: '#' },
            { name: 'Чумадан.doc', fuid: '#' },
        ],
        targetCadcam: 0,
    },
    {
        id: 2,
        targetRole: 'trader',
        title: 'Требуется гипс на постоянной основе',
        description: `При подаче предложений просьба учитывать постоплату и доставку до дверей.`,
        maxBudget: 23000,
        creator: {
            id: '2',
            name: 'Зуботехническая лаборатория',
            avatar: '/assets/temp/avatars/33x33/2.png',
        },
        untilDate: 0,
        publicDate: dateToUnixTime("2023-04-16"),
        files: [
            { name: 'Гипс.doc', fuid: '#' },
        ],
        targetCadcam: 1,
    },
    {
        id: 3,
        targetRole: '',
        title: 'Замена стоматологических установок в количестве 5 шт',
        description: `С выбором производителя не определились. Просьба учитывать в своем предложении доставку, демонтаж, монтаж, пусконаладочные работы. Форма оплаты безналичный расчет, условия оплаты 50% предоплата 50% по факту выполненных работ.`,
        maxBudget: 0,
        creator: {
            id: '3',
            name: 'Клиника - Зубная Фея',
            avatar: '/assets/temp/avatars/33x33/3.png',
        },
        untilDate: dateToUnixTime("2023-05-29"),
        publicDate: dateToUnixTime("2023-04-16"),
        files: [
            { name: 'Замена.xml', fuid: '#' },
        ],
        targetCadcam: 2,
    },
    {
        id: 4,
        targetRole: 'technician',
        title: 'Тест 1',
        description: `Описание 1`,
        maxBudget: 0,
        creator: {
            id: '4',
            name: 'Шарашкина контора',
            avatar: '/assets/temp/avatars/33x33/4.png',
        },
        untilDate: dateToUnixTime("2023-05-28"),
        publicDate: dateToUnixTime("2023-04-16"),
        files: [
            { name: 'Тест 1', fuid: '#' },
        ],
        targetCadcam: 0,
    },
]

export const _myAuctions: MyAuction[] = [
    {
        id: 1,
        targetRole: 'technician',
        title: 'Изготовление хирургических шаблонов',
        description: `Требуется изготовление на постоянной основе шаблонов. В стоимость должно входить моделировка (планирование), печать разборного шаблона, фиксация втулок (направляющих), доставка. Система имплантатов - AnyRidge.`,
        maxBudget: 155000,
        untilDate: dateToUnixTime("2023-05-30"),
        publicDate: dateToUnixTime("2023-05-16"),
        files: [
            { name: 'Хирург. шаблон 1', fuid: '#' },
            { name: 'Хирург. шаблон 2', fuid: '#' },
            { name: 'Чумадан.doc', fuid: '#' },
        ],
        targetCadcam: 1,
        oldFeedbacks: 10,
        newFeedbacks: 1,
        lastSeenFeedback: dateToUnixTime("2023-04-30"),
    },
    {
        id: 2,
        targetRole: 'trader',
        title: 'Требуется гипс на постоянной основе',
        description: `При подаче предложений просьба учитывать постоплату и доставку до дверей.`,
        maxBudget: 23000,
        untilDate: dateToUnixTime("2023-05-30"),
        publicDate: dateToUnixTime("2023-05-15"),
        files: [
            { name: 'Гипс.doc', fuid: '#' },
        ],
        targetCadcam: 2,
        oldFeedbacks: 9,
        newFeedbacks: 2,
        lastSeenFeedback: dateToUnixTime("2023-04-30"),
    },
    {
        id: 3,
        targetRole: '',
        title: 'Замена стоматологических установок в количестве 5 шт',
        description: `С выбором производителя не определились. Просьба учитывать в своем предложении доставку, демонтаж, монтаж, пусконаладочные работы. Форма оплаты безналичный расчет, условия оплаты 50% предоплата 50% по факту выполненных работ.`,
        maxBudget: 0,
        untilDate: dateToUnixTime("2023-04-30"),
        publicDate: dateToUnixTime("2023-04-14"),
        files: [
            { name: 'Замена.xml', fuid: '#' },
        ],
        targetCadcam: 0,
        oldFeedbacks: 8,
        newFeedbacks: 3,
        lastSeenFeedback: dateToUnixTime("2023-04-30"),
    },
    {
        id: 4,
        targetRole: 'technician',
        title: 'Тест 1',
        description: `Описание 1`,
        maxBudget: 0,
        untilDate: dateToUnixTime("2023-04-30"),
        publicDate: dateToUnixTime("2023-04-11"),
        files: [
            { name: 'Тест 1', fuid: '#' },
        ],
        targetCadcam: 0,
        oldFeedbacks: 5,
        newFeedbacks: 5,
        lastSeenFeedback: dateToUnixTime("2023-04-30"),
    },
]

var nowPlus3h21m = new Date();
nowPlus3h21m.setTime(nowPlus3h21m.getTime() + (1000 * 60 * 201))

export const _auctionResponds: AuctionFeedbacks = {
    lastSeenFeedback: dateToUnixTime("2023-04-15"),
    feedbacks: [
        {
            id: 1,
            creator: {
                id: '1',
                name: 'Зубной техник',
                avatar: '/assets/temp/avatars/33x33/1.png',
            },
            publicDate: dateToUnixTime("2023-04-16"),
            updateDate: dateToUnixTime("2023-04-16"),
            untilDate: dateToUnixTime("2023-04-30"),
            description: `Абсолютно сходящийся ряд недействителен согласно закону. Банкротство, в первом приближении, непосредственно совершает бытовой подряд, что не имеет аналогов в англо-саксонской правовой системе. В турецких банях не принято купаться раздетыми, поэтому из полотенца сооружают юбочку, а вексель опровергает экстремум функции. Движимое имущество...`,
            maxBudget: 155000,
        },
        {
            id: 2,
            creator: {
                id: '1',
                name: 'Зуботехническая лабораторияя',
                avatar: '/assets/temp/avatars/33x33/2.png',
            },
            publicDate: dateToUnixTime("2023-04-15"),
            updateDate: dateToUnixTime("2023-04-16"),
            //untilDate: dateToUnixTime("2023-05-01"),
            untilDate: dateToUnixTime(nowPlus3h21m),
            description: `В турецких банях не принято купаться раздетыми, поэтому из полотенца сооружают юбочку. Абсолютно сходящийся ряд недействителен согласно закону. Банкротство, в первом приближении, непосредственно совершает бытовой подряд, что не имеет аналогов в англо-саксонской правовой системе. А вексель опровергает экстремум функции. Движимое имущество...`,
            maxBudget: 0,
        },
        {
            id: 3,
            creator: {
                id: '1',
                name: 'Зуботехническая лаборатория "Butterfly". ',
                avatar: '/assets/temp/avatars/33x33/3.png',
            },
            publicDate: dateToUnixTime("2023-04-14"),
            updateDate: dateToUnixTime("2023-04-16"),
            untilDate: dateToUnixTime("2023-05-10"),
            description: `Банкротство, в первом приближении, непосредственно совершает бытовой подряд, что не имеет аналогов в англо-саксонской правовой системе. В турецких банях не принято купаться раздетыми, поэтому из полотенца сооружают юбочку, а вексель опровергает экстремум функции. Движимое имущество... Абсолютно сходящийся ряд недействителен согласно закону.`,
            maxBudget: 0,
        },
        {
            id: 4,
            creator: {
                id: '1',
                name: 'Просто Карлсон',
                avatar: '/assets/temp/avatars/33x33/4.png',
            },
            publicDate: dateToUnixTime("2023-04-13"),
            updateDate: dateToUnixTime("2023-04-16"),
            untilDate: dateToUnixTime("2023-04-28"),
            description: `Вексель опровергает экстремум функции. Движимое имущество... Банкротство, в первом приближении, непосредственно совершает бытовой подряд, что не имеет аналогов в англо-саксонской правовой системе.`,
            maxBudget: 0,
        },
    ]
}