import AuthContext from 'AuthContext'
import PopupNotifications from 'Pages/Popups/PopupNotifications'
import PopupVideo from 'Pages/Popups/PopupVideo'
import { FC, Fragment, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { net, registerUnauthorizedHandler } from 'service/API'
import { User } from 'service/Data/DentTypes'
import { avatarUrl } from 'service/Globals'
import { TR } from 'service/T10N'
//import * as S from './Header.styles'

interface HeaderProps {
    showFullLogo?: boolean
}

const Header: FC<HeaderProps> = ({ showFullLogo }) => {
    const [isSidebarShown, setSidebarShown] = useState(false)
    const [isAvatarPopupShown, setAvatarPopupShown] = useState(false)
    const [isNotificationsPopupShown, setNotificationsPopupShown] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')
    const auth = useContext(AuthContext)
    let history = useHistory()

    const [unauthHandler] = useState('')
    useEffect(() => {
        registerUnauthorizedHandler(() => {
            auth.logout()
            history.push('/signin')
        })
    }, [unauthHandler])


    const sidebarButtonClick = (redirect: string) => {
        setSidebarShown(false)
        history.push(redirect)
    }

    interface ISideBarButton {
        to: string,
        title: string,
        desc: string,
        image: string,
        isLeft?: boolean
    }

    const SidebarButton = ({ to, title, desc, image, isLeft }: ISideBarButton) => {
        return (
            <button className={'site-item' + (isLeft ? ' list--order' : '')} onClick={() => sidebarButtonClick(to)}>
                <div className='site-item__inner'>
                    <img className='site-item__img' src={image} alt='dental portal' />
                    <div className='site-item__content'>
                        <h3 className='site-item__title ws--pre'>{title}</h3>
                        {typeof desc === 'string' && desc.startsWith('https://') ?
                            <a className='site-item__more ws--pre'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSidebarShown(false)
                                    setVideoUrl(desc)
                                }}>
                                {TR('Подробнее \n о разделе')}
                            </a>
                            :
                            <p className='site-item__desc ws--pre'>{desc}</p>
                        }
                    </div>
                </div>
            </button>
        )
    }

    const handleEditMyData = async () => {
        type Reply = {
            status: string
            message?: string
            user?: User
        }

        const reply = await net.get<Reply>('/api/v1/users/me')

        //console.log('Me:')
        //console.log(reply)

        // 15 385 962 557 226 008 000
        // 18 446 744 073 709 551 616
        // -9 223 372 036 854 775 808
        //  9 223 372 036 854 775 807


        if (reply.user) {
            //console.log("Reply me")
            history.push('/me', { user: reply.user })
        }
    }

    const AvatarPopup = () => {
        const history = useHistory()

        const [name, setName] = useState(auth.session?.name)
        const [avatar, setAvatar] = useState(auth.session?.avatar)

        return (
            <Fragment>
                <div className={'user-drop' + (isAvatarPopupShown ? ' is--visible' : '')}>
                    <div className='user-drop__data'>
                        <div className='user-drop__avatar-box'>
                            {/* <img src='/assets/icons/user-solid.svg' alt='' /> */}
                            <img src={avatar ? avatarUrl(avatar) : '/assets/icons/user-solid.svg'} alt='' />
                            {/* <button className='user-drop__avatar-btn'></button> */}
                            {/* <label className='user-drop__avatar-btn' htmlFor='user-drop__avatar-btn'></label>
                            <input id='user-drop__avatar-btn' type='file' /> */}
                        </div>
                        {name}
                    </div>
                    <ol className='user-drop__menu'>
                        {/* <li className='user-drop__menu-item ptr cabinet'
                            onClick={() => {
                                history.push('/')
                                setAvatarPopupShown(false)
                            }}>
                            {TR('Перейти в личный кабинет')}
                        </li> */}
                        <li className='user-drop__menu-item ptr edit'
                            onClick={() => {
                                handleEditMyData()
                                setAvatarPopupShown(false)
                            }}>
                            {TR('Редактировать личные данные')}
                        </li>
                    </ol>
                    <ol className='user-drop__menu'>
                        <li className='user-drop__menu-item ptr help'
                            onClick={() => {
                                history.push('/help')
                                setAvatarPopupShown(false)
                            }}>
                            {TR('Помощь')}
                        </li>
                    </ol>
                    <ol className='user-drop__menu'>
                        <li className='user-drop__menu-item ptr exit'
                            onClick={() => {
                                history.push('/')
                                setAvatarPopupShown(false)
                                auth.logout()
                            }}>
                            {TR('Выйти')}
                        </li>
                    </ol>
                </div>
                <div className='user-cover'
                    onMouseDown={() => {
                        //console.log('cover')
                        setAvatarPopupShown(false)
                    }} />
            </Fragment>

            // <>
            //     <div className={'user' + (isAvatarPopupShown ? ' is--visible' : '')}>
            //         <div className='user__inner'>
            //             <p className='user__name'>{name}</p>
            //             <div className='avatar__cont'>
            //                 <div className='user__img-container'>
            //                     <img className='user-img' src={avatar} alt='' />
            //                 </div>
            //                 <button className='btn__img'></button>
            //             </div>
            //             <p className='user__mail'>{email}</p>
            //             <div className='user__ctrl'>
            //                 <button className='user__ctrl-btn btn--location'></button>
            //                 <button className='user__ctrl-btn btn--pass'></button>
            //                 <button className='user__ctrl-btn btn--edit'></button>
            //             </div>
            //             <div className='user__txt'>
            //                 <p className='icon__txt synk--txt'>Настройки</p>
            //                 <p className='icon__txt edit--txt'>Помощь</p>
            //             </div>
            //             <span className='split-line'></span>
            //             <div className='user__txt'>
            //                 <p className='icon__txt synk--txt'>Тестовый элемент 1</p>
            //                 <p className='icon__txt edit--txt'>Тестовый элемент 2</p>
            //             </div>
            //         </div>
            //         <div className='user__logout'>
            //             <button className='user__txt' onClick={() => auth.logout()}>
            //                 <p className='icon__txt out--txt'>Выйти</p>
            //             </button>
            //         </div>
            //     </div>
            //     <div className='user-cover' onMouseDown={() => { console.log('cover') setAvatarPopupShown(false) }}></div>
            // </>
        )
    }

    interface IBurgerMenu {
        isVisible: boolean
        onClose: () => void
    }
    const BurgerMenu = ({ isVisible, onClose }: IBurgerMenu) =>
        <Fragment>
            <div className={'sidebar' + (isVisible ? ' is--visible' : '')}>
                <button className='side-hider' onClick={() => onClose()}><span>{TR('Меню')}</span></button>
                <nav className='side-menu'>
                    <SidebarButton
                        to='/doctors'
                        title={TR('Клиники \n Врачи')}
                        desc={TR('https://www.youtube.com/embed/JXU8rvXD1io?autoplay=1', 'Ютуб клиники & врачи')}
                        image='/assets/images/dentchair.png'
                        isLeft={true} />
                    <SidebarButton
                        to='/auctions'
                        title={TR('Закупки')}
                        desc={TR('Конкурсные процедуры \n на поставку товаров и услуг')}
                        image='/assets/images/hammer.png' />
                    <SidebarButton
                        to='/technicians'
                        title={TR('Лаборатории \n Техники')}
                        desc={TR('https://www.youtube.com/embed/-DpDx86E_i0?autoplay=1', 'Ютуб лаборатории & техники')}
                        image='/assets/images/f-lab.png'
                        isLeft={true} />
                    <SidebarButton
                        to='/offers'
                        title={TR('Предложения')}
                        desc={TR('Скидки и акции \n на товары и услуги')}
                        image='/assets/images/offers-f.png' />
                    <SidebarButton
                        to='/traders'
                        title={TR('Торговые \n компании')}
                        desc={TR('https://www.youtube.com/embed/w3OGGxbR4fM?autoplay=1', 'Ютуб торговые компании')}
                        image='/assets/images/instruments-f.png'
                        isLeft={true} />
                    <SidebarButton
                        to='/sales'
                        title={TR('Барахолка')}
                        desc={TR('Б/У товары \n от участников портала')}
                        image='/assets/images/box.png' />
                    <SidebarButton
                        to='/help'
                        title={TR('Помощь')}
                        desc={TR('Связь с нами, FAQ, \n изменения на платформе')}
                        image='/assets/images/safety-ring.png'
                        isLeft={true} />
                    <SidebarButton
                        to='/vacancies'
                        title={TR('Вакансии')}
                        desc={TR('Открытые вакансии \n лабораторий и клиник')}
                        image='/assets/images/notepad-f.png' />
                </nav>
            </div>
            <div className='sidebar-cover' onMouseDown={() => onClose()} />
        </Fragment>

    let clicks = 0

    const [showRegistration, setShowRegistration] = useState(true)

    // console.log('Session:')
    // console.log(auth.session)

    auth.isLoggedIn
    const handleSignup = () => {
        history.push('/signup')
    }

    const handleSignin = () => {
        history.push('/signin')
    }

    return (
        <Fragment>
            <header className='header'>
                <div className='container'>
                    <nav className='nav'>
                        <div className='nav__box'>
                            {auth.isLoggedIn() ?
                                <button className='burger' onClick={() => setSidebarShown(true)}>
                                    <span></span>
                                </button> : null
                            }
                            <button className='logo' onMouseDown={(e) => {
                                //console.log(e.button)

                                if (e.button === 1) {
                                    // Middle button click
                                    clicks += 1
                                } else {
                                    // Left mouse click
                                    if (clicks == 5) {
                                        history.push('/list-notifications', { notifications: auth.notifications })
                                        return
                                    }
                                    clicks = 0
                                    history.push('/')
                                }
                            }}>
                                {/* <img className='logo-img' src='/assets/images/logo.png' alt='dental portal image' />
                                <img src='/assets/images/logo.png' alt='dental portal image' /> */}
                                <img className='logo-img' src='/assets/images/logo1.png' alt='dental portal image' />
                                {showFullLogo ? <img className='logo-img' src='/assets/images/logo2.png' alt='dental portal image' /> : null}
                            </button>

                            {showFullLogo ?
                                <p className='portal-about ws--pre'>
                                    {TR('Портал участников рынка \n стоматологических услуг')}
                                </p>
                                :
                                null
                            }
                            <BurgerMenu isVisible={isSidebarShown} onClose={() => setSidebarShown(false)} />
                        </div>
                        {auth.isLoggedIn() ?
                            <div className='user-box'>
                                <div className='header-notiffication__box'>
                                    <button className='dash' onClick={() => history.push('/dash')}></button>
                                    <button className='chat' onClick={() => history.push('/chat')}></button>
                                    <Notifications />
                                    {/* <button className='note' onClick={() => setNotificationsPopupShown(true)}>
                                        <span>1</span>
                                    </button>
                                    {isNotificationsPopupShown &&
                                        <PopupNotifications onClose={() => setNotificationsPopupShown(false)} />
                                    } */}
                                </div>
                                <button
                                    className='header-avatar__box'
                                    onClick={() => {
                                        setAvatarPopupShown(!isAvatarPopupShown)
                                    }}>
                                    <span className='avatar__box'>
                                        <img className='avatar' src={auth.session?.avatar ? avatarUrl(auth.session.avatar) : '/assets/images/no_avatar.png'} alt='dental portal image' />
                                    </span>
                                    {/* <span className='user__nic'>Иван Петров</span> */}
                                </button>
                                <AvatarPopup />
                            </div>
                            :
                            <div className='user-box'>
                                {showRegistration ?
                                    <button className='auths' onClick={handleSignup}>{TR('Регистрация')}</button>
                                    :
                                    null
                                }
                                <button className='auths' onClick={handleSignin}>{TR('Войти')}</button>
                            </div>
                        }
                    </nav>
                    {videoUrl ? <PopupVideo url={videoUrl} onClose={() => setVideoUrl('')} /> : null}
                </div>
            </header>
        </Fragment>
    )
}

// const Header = (props) => {
//     const { headerTitle } = props
//     const auth = useContext(AuthContext)
//     let history = useHistory()

//     const register =
//         <S.UserBoxWrapper>
//             <Link to='/signin' style={{ textDecoration: 'inherit', color: 'inherit' }}>
//                 <S.HeaderText>Войти</S.HeaderText>
//             </Link>
//             <Link to='/signup' style={{ textDecoration: 'inherit', color: 'inherit' }}>
//                 <S.HeaderText>Регистрация</S.HeaderText>
//             </Link>
//         </S.UserBoxWrapper>

//     const cabinet =
//         <S.UserBoxWrapper>
//             <Link to='/cabinet' style={{ textDecoration: 'inherit', color: 'inherit' }}>
//                 <S.HeaderText>Кабинет</S.HeaderText>
//             </Link>
//         </S.UserBoxWrapper>

//     const logout =
//         <S.UserBoxWrapper>
//             <S.HeaderText onClick={() => { auth.logout() history.replace('/') }}>Выйти</S.HeaderText>
//         </S.UserBoxWrapper>

//     return (
//         <S.HeaderContainer>
//             <Helmet>
//                 <title>{headerTitle}</title>
//             </Helmet>
//             <Link to='/' style={{ textDecoration: 'inherit', color: 'inherit' }}>
//                 <S.LogoWrapper>

//                     <S.StyledLogo style={{ marginRight: '20px' }} alt='Dental logo' src='/playground_assets/logo232-4l9.svg' />
//                     <S.HeaderText>
//                         <span>Портал участников рынка <br /> стоматологических услуг</span>
//                     </S.HeaderText>

//                 </S.LogoWrapper>
//             </Link>
//             <S.MainNavigation>
//                 <S.HeaderText>
//                     <span>{cabinet}</span>
//                 </S.HeaderText>
//                 <S.HeaderText>
//                     <span>Аукционы</span>
//                 </S.HeaderText>
//                 <S.HeaderText>
//                     <span>Предложения</span>
//                 </S.HeaderText>
//                 <S.HeaderText>
//                     <span>Барахолка</span>
//                 </S.HeaderText>
//                 <S.HeaderText>
//                     <span>Помощь</span>
//                 </S.HeaderText>
//             </S.MainNavigation>
//             {auth.loggedIn ? logout : register}
//         </S.HeaderContainer>
//     )
// }

interface NotificationsProps {
}

const lastNotificationKey = "lsnt"

const Notifications: FC<NotificationsProps> = () => {

    const [isNotificationsPopupShown, setNotificationsPopupShown] = useState(false)

    const auth = useContext(AuthContext)

    return (
        <Fragment>
            <button className='note' onClick={() => {
                auth.onNotificationsOpened()
                setNotificationsPopupShown(true)
            }}>
                {auth.newNotifications > 0 ?
                    <span>{auth.newNotifications}</span>
                    :
                    null
                }
            </button>
            {isNotificationsPopupShown &&
                <PopupNotifications onClose={() => setNotificationsPopupShown(false)} notifications={auth.notifications} />
            }
        </Fragment>
    )
}

export default Header