import { useState } from 'react';
import CheckFilter from './SearchFilters/CheckFilter';
import OneOfFilter from './SearchFilters/OneofFilter';
import RangeFilter from './SearchFilters/RangeFilter';
import SubsetFilter from './SearchFilters/SubsetFilter';
import SubsetFilter2 from './SearchFilters/SubsetFilter2';
import TextFilter from './SearchFilters/TextFilter';

// Options example:
// const filterOptions = [
//     {
//         id: 'cities',
//         subset: {
//             from: [
//                 'Москва',
//                 'Санкт-петербург',
//                 'Новосибирск',
//                 'Нижний новгород',
//                 'Краснодар',
//                 'Ижевск',
//                 'Подольск',
//                 'Нижнекамск',
//             ],
//             value: cities,
//             setValue: setCities,
//             nonEmptyTitle: 'Выбранные города',
//             whenEmptyTitle: 'По всем городам',
//             popupTitle: 'Город',
//         }
//     },
//     {
//         id: 'chairs',
//         oneof: {
//             title: 'Количество установок',
//             from: [
//                 { text: 'Любое количество', value: 0 },
//                 { text: '1-3', icon: 'chair', value: 1 },
//                 { text: '3+', icon: 'chair', value: 2 }
//             ],
//             value: chairs,
//             setValue: setChairs
//         }
//     },
//     {
//         id: 'scanner',
//         checkbox: {
//             title: 'Интраоральный сканнер',
//             value: hasScanner,
//             setValue: setHasScanner
//         }
//     }
// ]

const SearchFilters2 = ({ options, onResetFilters = () => { } }) => {

    const [isSearchExpanded, setSearchExpanded] = useState(false)

    const parseOptions = (options = []) => {
        return options.map((item) => {
            if ('text' in item) {
                return <TextFilter key={item.id} {...item.text} />
            }

            if ('subset' in item) {
                return <SubsetFilter key={item.id} {...item.subset} />
            }

            if ('subset2' in item) {
                return <SubsetFilter2 key={item.id} {...item.subset2} />
            }

            if ('oneof' in item) {
                return <OneOfFilter key={item.id} {...item.oneof} />
            }

            if ('check' in item) {
                return <CheckFilter key={item.id} {...item.check} />
            }

            if ('range' in item) {
                return <RangeFilter key={item.id} {...item.range} />
            }

            return null
        })
    }

    if (!options.length)
        return null

    const head = options[0]
    //const tail = options.slice(1)
    const tail = options

    return (
        <div className='list-filters'>
            {/* <form className='filters'> wrong on click behaviour for cities */}
            <div className='filters'>
                {/* <h3 className='filter-title'>{TR_('Специализация')}</h3> */}
                {/* <button className='button-f h--44' onClick={() => { () => console.log("Spets"); setSearchExpanded(true) }}>{TR_('Спец')}</button> */}
                {/* {head.text ? <TextFilter onExpand={() => setSearchExpanded(true)} {...head.text} {...{ counter: tail.length }} /> : null} */}
                {/* <div className='filter-cover is--visible'> */}


                {/* <div className={'filter-cover' + (isSearchExpanded ? ' is--visible' : '')}>
                    <div className='filter-wrap'>
                        <button className='back-filters' onClick={() => setSearchExpanded(false)}>{TR('Фильтры')}</button> */}


                <ul className='rest-filters'>
                    {parseOptions(tail)}
                </ul>
                {/* <div className='filter-controls'>
                            <button className='button-e' type='reset' onClick={() => onResetFilters()}>{TR('Очистить фильтр')}</button>
                        </div> */}


                {/* </div>
                </div> */}



            </div>
            {/* </form> */}
            {/* <div className='filter-banner'>
                <img src='/assets/images/banner3.png' alt='dental portal' />
            </div> */}
        </div>
    )
}

export default SearchFilters2;