import { FC } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { User, isDoctor, isTechnician, isTrader } from "service/Data/DentTypes"
import MyDataDoctorMini from "./MyDataDoctorMini"
import MyDataTechnicianMini from "./MyDataTechnicianMini"
import MyDataTrader from "./MyDataTrader"

interface IMyData {
    user?: User
}

interface LocationState {
    user: User
}

const MyDataMini: FC<IMyData> = ({ user }) => {
    const history = useHistory()

    const location = useLocation<LocationState>(); // '/me'
    const userInfo = user ? user : location.state.user

    //console.log('In my data mini');
    //console.log(userInfo)

    return (
        isDoctor(userInfo) ? <MyDataDoctorMini user={userInfo} /> :
            isTechnician(userInfo) ? <MyDataTechnicianMini user={userInfo} /> :
                isTrader(userInfo) ? <MyDataTrader user={userInfo} /> : null
    )
}

export default MyDataMini;