import MyFeedbacks from "Pages/Home/Components/MyFeedbacks"
import MyOffers from "Pages/Home/Components/MyOffers"
import { useEffect, useState } from "react"
import { DashData, getDashData } from "service/API"
import MySales from "../Home/Components/MySales"

const DashTrader = () => {
    const [data, setData] = useState<DashData>()
    const [firstLoad] = useState()
    useEffect(() => {
        (async () => {
            //console.log('Dash data effect')
            const homeData = await getDashData({
                // myAuctions: { actual: [], archived: [] },
                myOffers: { actual: [], archived: [] },
                mySales: { actual: [], archived: [] },
                // myVacancies: { actual: [], archived: [] },
                myAuctionFeedbacks: [],
                myVacancyFeedbacks: [],
            })
            //console.log('Dash data received:')
            //console.log(homeData)
            setData(homeData)
        })()
    }, [firstLoad])

    return (
        <main className="main">
            <div className="container ws--pre">
                {/* Доска торговца<br /> */}
                <div className="main-flex">
                    <div className="main-flex__item">
                        <MyOffers {...data?.myOffers} />
                        <MySales {...data?.mySales} />
                    </div>
                    <div className="main-flex__item">
                        <MyFeedbacks
                            auctions={data?.myAuctionFeedbacks}
                            vacancies={data?.myVacancyFeedbacks} />
                    </div>
                    {/* <div className="main-flex__item">
                        <MyPurchases {...data?.myAuctions} />
                        <MySales {...data?.mySales} />
                        <MyOffers {...data?.myOffers} />
                        <MyVacancies {...data?.myVacancies} />
                    </div> */}
                </div>
            </div>
        </main>
    )
}

export default DashTrader