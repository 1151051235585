import { FC } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { User, isDoctor, isTechnician, isTrader } from "service/Data/DentTypes"
import MyDataDoctor from "./MyDataDoctor"
import MyDataTechnician from "./MyDataTechnician"
import MyDataTrader from "./MyDataTrader"

interface IMyData {
    user?: User
}

interface LocationState {
    user: User
}

const MyData: FC<IMyData> = ({ user }) => {
    const history = useHistory()

    const location = useLocation<LocationState>(); // '/me'
    const userInfo = user ? user : location.state.user

    //console.log('In my data');
    //console.log(userInfo)

    return (
        isDoctor(userInfo) ? <MyDataDoctor user={userInfo} /> :
            isTechnician(userInfo) ? <MyDataTechnician user={userInfo} /> :
                isTrader(userInfo) ? <MyDataTrader user={userInfo} /> : null
    )
}

export default MyData;