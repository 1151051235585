import { useHistory } from 'react-router-dom';

const SignupTechnician = () => {
    let history = useHistory();
    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="./elements.html" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--808">
                    <div className="edit-container">
                        <h3 className="user-type">Лаборатория или Техник</h3>
                        {/* <button className="no-button">Изменить</button> */}
                    </div>
                    <h1 className="sign-title">
                        Заполнение анкеты
                    </h1>
                    <p className="sign__sub-title">
                        Подробная информация позволит нам сделать работу портала максимально эффективной.
                    </p>
                    <form className="sign-form" action="post">
                        <div className="field-flex">
                            <div className="fiels-flex__item">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Название лаборатории (или ФИО)
                                    </p>
                                    <div className="input">
                                        <input type="text" placeholder="" />
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Город
                                    </p>
                                    <div className="input">
                                        <input type="text" placeholder="" />
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Улица, дом, офис
                                    </p>
                                    <div className="input">
                                        <input type="text" placeholder="" />
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                            </div>
                            <div className="fiels-flex__item">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Логотип или фотография
                                    </p>
                                    <div className="avatar-upload">
                                        <div className="avatar-upload__inner">
                                            <span className="avatar-upload__image"></span>
                                            <span>Ваш аватар</span>
                                        </div>
                                        <input type="file" id="avatarUpld" />
                                        <label className="button-27e" htmlFor="avatarUpld">Загрузить</label>
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set__cover">
                                    <div className="slide-flex">
                                        <input type="checkbox" className="checkbox-slide" id="chbxsld" />
                                        <label htmlFor="chbxsld"> <span></span> Наличие CAD/CAM</label>
                                    </div>
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            Название станка
                                        </p>
                                        <div className="input">
                                            <input type="text" placeholder="" />
                                        </div>
                                        <p className="fied-set__error"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="field-set">
                            <p className="field-set__title">
                                Лицензия для лаборатории или Диплом для техника
                            </p>
                            <p className="sub-field">
                                Если Вы творческий человек и освоили профессию зубного техника самостоятельно, просьба
                                обратиться к <a href="#">администратору</a> для
                                прохождения регистрации.
                            </p>
                            <div className="input-upload">
                                <div className="upload-data">
                                    <p className="upload__info">Перетащите файл в выбранную область или нажмите кнопку
                                        "загрузить файл"
                                    </p>
                                    <p className="upload__sub">Поддерживаемые форматы .pdf .xls — не более 50 мб.</p>
                                </div>
                                <img className="upload__img" src="/assets/images/document.svg" alt="dental portal" />
                                <input type="file" name="files" id="file" multiple={false} />
                                <label htmlFor="file" className="button-40">Загрузить файл</label>
                            </div>
                            <p className="fied-set__error"></p>
                        </div> */}
                        <button className="button-f h--44 w--335">Завершить</button>
                    </form>
                </div>
            </main>
        </div>
        // <main>
        //     <div className="Wrapper">
        //         <a className="logo" href="#">
        //             <img src="/playground_assets/Registration-logo.svg" width="238" height="26" alt="Логотип Dental Portal" />
        //         </a>
        //         <section className="layout RegistrationFillIn RegistrationFillIn--lab">
        //             <h2 className="RegistrationType"><span>Лаборатория или Техник</span><a href="#">Изменить</a></h2>
        //             <h1 className="title">Заполнение анкеты</h1>
        //             <p className="RegistrationSmallText">Подробная информация позволит нам сделать работу портала
        //                 максимально эффективной.</p>
        //             <form className="form formRegistrationFillIn" onSubmit={() => history.replace("/")}>
        //                 <div className="column2">
        //                     <fieldset className="formField textField">
        //                         <label className="label" htmlFor="name">Название клиники (или ФИО)</label>
        //                         <input type="text" name="name" id="name" />
        //                     </fieldset>
        //                     <fieldset className="formField textField">
        //                         <label className="label" htmlFor="city">Город</label>
        //                         <input type="text" name="city" id="city" />
        //                     </fieldset>
        //                     <fieldset className="formField textField">
        //                         <label className="label" htmlFor="address">Улица, дом, офис</label>
        //                         <input type="text" name="address" id="address" />
        //                     </fieldset>
        //                 </div>

        //                 <div className="column2">
        //                     <legend className="label">Логотип или фотография</legend>
        //                     <fieldset className="formField formFieldGreyBg avatarField">
        //                         <span className="avatarImg">
        //                             <img src="/playground_assets/Img_box.svg" width="20" height="20" alt="avatar" />
        //                         </span>
        //                         <span>Ваш аватар</span>
        //                         <label htmlFor="avatar" className="customInputFile">Загрузить
        //                             <input type="file" name="avatar" accept="image/png, image/jpeg" />
        //                         </label>
        //                     </fieldset>
        //                     <fieldset className="formField formFieldGreyBg formFieldSwitch">
        //                         <div className="switchField">
        //                             <span className="label">Наличие CAD/CAM</span>
        //                             <label className="switch">
        //                                 <input type="checkbox" id="switch" checked />
        //                                 <span className="slider round"></span>
        //                             </label>
        //                         </div>
        //                         <div className="formField  textField textFieldInverse">
        //                             <label className="label" htmlFor="cad">Название станка</label>
        //                             <input type="text" name="cad" id="cad" />
        //                         </div>
        //                     </fieldset>
        //                 </div>

        //                 <div className="column1">
        //                     <p className="label">Лицензия для лаборатории или Диплом для техника</p>
        //                     <p className="RegistrationSmallText RegistrationSmallText--lab">Если Вы творческий человек и
        //                         освоили профессию зубного техника самостоятельно, просьба<br />обратиться к <a href="#">администратору</a> для прохождения регистрации.</p>
        //                     <fieldset className="formField formFieldGreyBg formFieldStyled ClinicDocsField">
        //                         <p className="uploadInfo">Перетащите файлы в выбранную область или нажмите кнопку
        //                             "загрузить файл"
        //                             <small>Поддерживаемые форматы .pdf .xls .xls. — не более 50 мб.</small>
        //                         </p>
        //                         <label htmlFor="ClinicDocs" className="customInputFile customInputFileXL">Загрузить файл
        //                             <input type="file" name="ClinicDocs" accept="*.pdf, *.xls" />
        //                         </label>
        //                     </fieldset>
        //                 </div>
        //                 <button className="btn RegistrationBtn" type="submit">Завершить</button>
        //             </form>
        //         </section>
        //     </div>
        // </main>
    )
}

export default SignupTechnician;