import { TR } from 'service/T10N';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    email: Yup.string().email(TR('Неверный формат')).required(TR('Необходимое поле')),
    password: Yup.string().required(TR('Необходимое поле')),
});

const useSigninData = () => {
    return { schema };
}

export default useSigninData;