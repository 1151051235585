import PopupCompany from "Pages/Popups/PopupCompany"
import { FC, useState } from "react"
import { useHistory } from "react-router-dom"
import { downloadFile, getDateStr, getDateTimeHuman, getDurationHuman, getUserInfo, timeNowUnix } from "service/API"
import { AuctionFeedback, BlobCache, User } from "service/Data/DentTypes"
import { avatarUrl, fileUrl } from "service/Globals"
import { TR_ } from "service/T10N"
import { getPriceStr } from "service/TextTools"

export interface AuctionFeedbackProps {
    feedback: AuctionFeedback
    isNew: boolean
    isOwn: boolean
    onDelete: () => void
    onEdit: () => void
}

const AuctionFeedbackCard: FC<AuctionFeedbackProps> = ({ feedback, isNew, isOwn, onDelete, onEdit }) => {
    var { creator, description, maxBudget } = feedback

    const history = useHistory();

    const maxBudgetStr = maxBudget ? getPriceStr(maxBudget) : TR_('Не указ.')
    const untilDateStr = feedback.untilDate ? getDurationHuman(feedback.untilDate - timeNowUnix()) : TR_('Не указ.')

    const [companyInfo, setCompanyInfo] = useState<User | null>(null)

    const handleCompanyClick = async () => {
        try {
            setCompanyInfo(await getUserInfo(creator.id))
        }
        catch (error) {
            // set error
        }
    }

    return (
        <article className="reply-item">
            <div className="reply-item__top">
                <div className="reply__holder" onClick={() => handleCompanyClick()} style={{ cursor: "pointer" }}>
                    <img className="reply__holder-logo" src={avatarUrl(creator.avatar)} alt="dental portal" />
                    <h3 className="reply__holder-name">{creator.name}</h3>
                </div>
                <div className="reply__time">
                    {isNew ? <span className="reply-new">{TR_('Новый!')}</span> : null}
                    <time className="reply-date" dateTime={getDateStr(feedback.publicDate)}>{getDateTimeHuman(feedback.publicDate)}</time>
                </div>
                {companyInfo ?
                    <PopupCompany onClose={() => setCompanyInfo(null)} user={companyInfo} /> : null
                }
            </div>
            <div className="reply-item__mid">
                {description}
            </div>
            <div className="bid__addons">
                {feedback.files?.map((file) => {
                    return (
                        <button
                            key={file.fuid instanceof BlobCache ? file.fuid.blobUrl : file.fuid}
                            className="button-link-doc"
                            onClick={() => downloadFile(fileUrl(file.fuid), file.name)}
                        ><span></span>{file.name}</button>
                    )
                })}
            </div>
            <br />
            <div className="reply-item__bot">
                <div className="reply-idents">
                    <div className="reply__ident ident__money">{maxBudgetStr}</div>
                    <div className="reply__ident ident__time">{untilDateStr}</div>
                </div>
                {isOwn ?
                    <div className="reply-controls">
                        <button className="button-27e" onClick={() => onDelete()}>{TR_('Удалить')}</button>
                        <button className="button-27f" onClick={() => onEdit()}>{TR_('Редактировать')}</button>
                    </div>
                    :
                    <div className="reply-controls">
                        <button className="button-27f" onClick={() => { history.push('/chat') }}>{TR_('Начать чат')}</button>
                    </div>
                }
            </div>
        </article>
    )
}

export default AuctionFeedbackCard