import AuthContext from 'AuthContext';
import ResultsCounter from 'Components/ResultsCounter';
import SearchFilters from 'Components/SearchFilters';
import TopSwitch from 'Components/TopSwitch';
import { useContext, useEffect, useState } from 'react';
import { getVacancies } from 'service/API';
import { Vacancy } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';
import VacancyCard2 from './Components/VacancyCard2';

const Vacancies = () => {
    const auth = useContext(AuthContext);

    const [justInitialized, setJustInitizlized] = useState(true);
    const [vacancies, setVacancies] = useState<Vacancy[] | null>(null);

    const [findText, setFindText] = useState('')
    const [forRole, setForRole] = useState('')
    const [cities, setCities] = useState([])
    const [priceFrom, setPriceFrom] = useState('')
    const [priceTo, setPriceTo] = useState('')
    const [sortBy, setSortBy] = useState('date')

    const resetFilters = () => {
        setFindText('')
        setForRole('')
        setCities([])
        setPriceFrom('')
        setPriceTo('')
        setSortBy('date')
    }

    useEffect(() => {
        (async () => {
            //console.log('Vacancies effect')
            //console.log(forRole)
            const vacancies = await getVacancies(findText, forRole, cities, parseInt(priceFrom), parseInt(priceTo), sortBy)
            //setVacancies(vacancies.slice(0, 0))
            setVacancies(vacancies)
            setJustInitizlized(false)
        })();
    }, [findText, forRole, cities, priceFrom, priceTo, sortBy]);

    const filter = [
        {
            id: 'text',
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
                // onSearch: handleSearchButton,
            }
        },
        // {
        //     id: 'cities',
        //     subset: {
        //         from: getAvailableCities(),
        //         value: cities,
        //         setValue: setCities,
        //         nonEmptyTitle: TR('Выбранные города'),
        //         whenEmptyTitle: TR('По всем городам'),
        //         popupTitle: TR('Город'),
        //     }
        // },
        {
            id: 'priceRange',
            range: {
                title: TR('Зарплата'),
                from: priceFrom,
                to: priceTo,
                setFrom: setPriceFrom,
                setTo: setPriceTo,
                fromPlaceholder: TR('От ₽'),
                toPlaceholder: TR('До ₽'),
            }
        },
    ]

    const switchData = {
        switchName: 'roles',
        value: forRole,
        setValue: setForRole,
        items: [
            { title: TR('Все'), value: '' },
            { title: TR_('Для врачей'), value: 'doctor' },
            { title: TR_('Для техников'), value: 'technician' },
        ]
    }

    const ownUserId: string = auth.session ? auth.session.id : ''

    return (
        <main className='main'>
            <div className='container'>
                <h1 className='section-title'>{TR('Вакансии')}</h1>
                <div className='list-controls'>
                    <TopSwitch {...switchData} />
                    <div className='list-controls__sort'>
                        {/* <SortBy value={sortBy} setValue={setSortBy} /> */}
                        <ResultsCounter value={vacancies ? vacancies.length : 0} />
                    </div>
                </div>
                <div className='list-base'>
                    <SearchFilters options={filter} onResetFilters={resetFilters} />
                    <div className='list-ads'>
                        <div className='list-map' /* add class list-map--open */ >
                        </div>
                        {vacancies?.length ?
                            vacancies.map((vacancy) => {
                                return <VacancyCard2 key={vacancy.id} vacancy={vacancy} userId={ownUserId} />
                            })
                            :
                            justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Vacancies;