import { useEffect } from 'react';

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <main className="main">
            <div className="container">
                <section className="politic">
                    <div className="politic__inner">
                        <h1 className="politic-head__title">Политика конфиденциальности и обработки персональных данных
                            пользователей
                            Интернет-портала «Дентал Портал»</h1>
                        <p className="politic__item-text">Настоящая Политика определяет порядок, условия обработки
                            персональных данных пользователей
                            Интернет-портала «Dental
                            Portal» (далее «Дентал Портал») и устанавливает требования по обеспечению безопасности
                            персональных данных
                            пользователей Администрацией Интернет-портала «Дентал Портал».</p>
                        <p className="politic__item-text">Политика разработана и реализуется в соответствии со следующими
                            нормативными правовыми актами
                            в области обработки и
                            обеспечения безопасности персональных данных:</p>
                        <ul className="politic__list">
                            <li className="politic__item">Конституция Российской Федерации;</li>
                            <li className="politic__item">Федеральный закон от 27.07.2006 N 152-ФЗ "О персональных данных";
                            </li>
                            <li className="politic__item">Постановление Правительства Российской Федерации от 15.09.2008 N
                                687 "Об утверждении
                                Положения об особенностях
                                обработки персональных данных, осуществляемой без использования средств автоматизации";
                            </li>
                            <li className="politic__item">Постановление Правительства Российской Федерации от 01.11.2012 N
                                1119 "Об утверждении
                                требований к защите
                                персональных данных при их обработке в информационных системах персональных данных".
                            </li>
                        </ul>
                        <h2 className="politic-title">Определения</h2>
                        <p className="politic__item-text">
                            <b>Персональные данные</b> – любая информация, относящаяся к
                            прямо или косвенно определённому или
                            определяемому физическому
                            лицу (гражданину). К такой информации, могут относиться: Ф.И.О., год, месяц, дата и место
                            рождения, почтовый адрес,
                            адрес электронной почты, номер телефона, сведения о семейном, социальном, имущественном
                            положении, сведения об
                            образовании, профессии, доходах, сведения о состоянии здоровья, а также другая информация.
                        </p>
                        <p className="politic__item-text">
                            <b>Обработка персональных данных</b> – любое действие с персональными данными, совершаемое с
                            использованием средств
                            автоматизации или без использования таких средств. К таким действиям могут относиться: сбор,
                            получение, запись,
                            систематизация, накопление, хранение, обновление, изменение, извлечение, использование,
                            передача (распространение,
                            предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
                            данных.
                        </p>
                        <p className="politic__item-text">
                            <b>Пользователь</b> – физическое лицо или юридическое лицо, получающее доступ к функционалу
                            Интернет-портала «Дентал Портал».
                        </p>
                        <ol className="list-number">
                            <li className="list-number__item">
                                Обработка персональных данных пользователей Интернет-портала «Дентал Портал»
                                производится с соблюдением следующих
                                принципов:
                                <ul className="politic__list">
                                    <li className="politic__item">законности;</li>
                                    <li className="politic__item">ограничения обработки персональных данных достижением
                                        конкретных, заранее определённых и
                                        законных целей;</li>
                                    <li className="politic__item">недопущения обработки персональных данных, несовместимой с
                                        целями сбора персональных
                                        данных;</li>
                                    <li className="politic__item">недопущения объединения баз данных, содержащих
                                        персональные данные, обработка которых
                                        осуществляется в целях,
                                        несовместимых между собой.</li>
                                </ul>
                            </li>
                            <li className="list-number__item">
                                В процессе обработки персональных данных пользователей со стороны Администрации
                                Интернет-портала «Дентал Портал»
                                предпринимаются необходимые и достаточные правовые, организационные и технические меры
                                для защиты персональных данных от
                                неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования,
                                копирования, предоставления,
                                распространения персональных данных, а также от иных неправомерных действий в отношении
                                персональных данных.
                            </li>
                            <li className="list-number__item">
                                При обработке персональных данных пользователей используются технические средства и
                                технологические решения,
                                направленные на обезличивание субъектов персональных данных при доступе к содержащей
                                персональные данные информации лиц,
                                непосредственно задействованных в процессе обработки персональных данных.
                            </li>
                            <li className="list-number__item">
                                Персональные данные пользователей хранятся на серверах находящихся на территории
                                Российской Федерации в полном соответствии с правовыми и нормативно-техническими
                                требованиями, установленными
                                действующим законодательством Российской Федерации. Допускается копирование на
                                иностранные сервера и экстерриториальная
                                (трансграничная) передача сведений о персональных данных в другие государства, если эти
                                сведения были собраны с
                                соблюдением требований российского законодательства на российских серверах.
                                Информационный обмен предусмотрен с
                                государствами, подписавшими Европейскую Конвенцию о защите физических лиц при
                                автоматизированной обработке персональных
                                данных (СДСЕ №108).
                            </li>
                            <li className="list-number__item">
                                Администрация Интернет-портала «Дентал Портал» не осуществляет обработку биометрических
                                и специальных категорий
                                персональных данных пользователей.
                            </li>
                            <li className="list-number__item">
                                Администрация Интернет-портала «Дентал Портал» вправе поручить обработку персональных
                                данных пользователей третьим
                                лицам, на основании договора. Лица, осуществляющие обработку персональных данных по
                                поручению Администрации
                                Интернет-портала «Дентал Портал», обязуются соблюдать принципы и правила обработки и
                                защиты персональных данных,
                                предусмотренные Федеральным законом № 152-ФЗ «О персональных данных» и настоящей
                                политикой.
                            </li>
                            <li className="list-number__item">
                                При изменении своих персональных данных пользователь соглашается, что такое изменение
                                происходит на тех же условиях, что
                                и первоначальное предоставление им своих персональных данных, не требует оформления
                                дополнительного согласия, и
                                изменённые персональные данные обрабатываются в том же порядке, что и первоначально
                                предоставленные персональные данные.
                            </li>
                            <li className="list-number__item">
                                При регистрации или авторизации на Интернет-портале «Дентал Портал» с использованием
                                учётной записи стороннего
                                Интернет-ресурса, пользователь соглашается с тем, что в таких случаях персональные
                                данные передаются им добровольно, по
                                своей собственной инициативе в порядке, на условиях и в объёме, предусмотренными
                                правилами соответствующего OpenID
                                провайдера.
                            </li>
                            <li className="list-number__item">
                                Администрация Интернет-портала «Дентал Портал» гарантирует и обеспечивает полную
                                конфиденциальность персональных данных
                                пользователей, обрабатываемых при предоставлении доступа к функционалу Интернет-портала
                                «Дентал Портал», за исключением
                                случаев, прямо предусмотренных настоящей Политикой или действующим законодательством
                                Российской Федерации.
                            </li>
                            <li className="list-number__item">
                                Условия настоящей Политики о конфиденциальности не распространяются на персональные
                                данные, в отношении которых самим
                                пользователем (субъектом) установлен открытый и свободный режим доступа любых третьих
                                лиц (персональные данные общего
                                доступа).
                            </li>
                            <li className="list-number__item">
                                К персональным данным общего доступа относятся персональные данные пользователя,
                                размещаемые им самим, по его поручению
                                или иным явным образом выраженной воле пользователя посредством использования учётной
                                записи пользователя на
                                веб-страницах Интернет-портала «Дентал Портал», в том числе:
                                <ul className="politic__list">
                                    <li className="politic__item">общедоступная часть профиля пользователя;</li>
                                    <li className="politic__item">контактная и иная информация на веб-страницах
                                        представляемых пользователем компаний (карточки компаний, карточки
                                        пользователей и пр.), размещённых на Интернет-портале «Дентал Портал»;</li>
                                    <li className="politic__item">персональные данные, размещаемые пользователем
                                        посредством своей учётной записи в соответствующих разделах
                                        Интернет-портала «Дентал Портал»: в том числе новости компаний, блоги, чаты,
                                        отзывы
                                        и другие материалы.</li>
                                </ul>
                            </li>
                            <li className="list-number__item">
                                Пользователь обязан предпринимать все зависящие от него меры по обеспечению безопасности
                                и неразглашению третьим лицам
                                данных, необходимых для получения доступа к своей учётной записи. Любые действия,
                                совершенные с использованием учётной
                                записи пользователя и любая информация, распространённая с использованием учётной записи
                                пользователя, считаются,
                                соответственно, совершенными и (или) распространёнными самим пользователем, и
                                ответственность за любые последствия их
                                совершения и (или) распространения не может быть возложена на Администрацию
                                Интернет-портала «Дентал Портал».
                            </li>
                            <li className="list-number__item">
                                Администрация Интернет-портала «Дентал Портал» осуществляет обработку только тех
                                персональных данных, которые необходимы
                                для идентификации пользователя, обеспечения нормального использования функционала
                                Интернет-портала «Дентал Портал», а
                                также персональных данных общего доступа.
                            </li>
                            <li className="list-number__item">
                                Для целей статистической обработки информации, оптимизации и дальнейшего развития
                                функционала Интернет-портала «Дентал
                                Портал» создаются и используются файлы «cookie», фиксируются сведения о сетевых адресах,
                                с которых пользователями
                                производится регистрация и авторизация на Интернет-портале «Дентал Портал», а также
                                может сохраняться информация о
                                действиях пользователей, совершаемых ими в процессе использования функционала
                                Интернет-портала «Дентал Портал».
                            </li>
                            <li className="list-number__item">
                                В процессе использования функционала Интернет-портала «Дентал Портал» осуществляется
                                определение географического
                                местоположения пользователя, сведения о котором могут быть использованы исключительно
                                для целей, прямо указанных в
                                тексте Пользовательского соглашения Интернет-портала «Дентал Портал» или предусмотренных
                                настоящей Политикой.
                            </li>
                            <li className="list-number__item">
                                Персональные данные пользователей могут использоваться в следующих целях:
                                <ol className="list-number">
                                    <li className="list-number__item">
                                        Идентификация пользователя для обеспечения доступа к функционалу
                                        Интернет-портала «Дентал Портал».
                                    </li>
                                    <li className="list-number__item">
                                        Осуществление связи с пользователем по вопросам предоставления доступа к
                                        Интернет-порталу «Дентал Портал», использования
                                        дополнительного функционала Интернет-портала «Дентал Портал» (в том числе
                                        предоставление платных услуг пользователю), в
                                        том числе направление уведомлений, запросов и информации, связанных с оказанием
                                        услуг, а также обработка запросов и
                                        заявок от пользователя.
                                    </li>
                                    <li className="list-number__item">
                                        Осуществление информационных, новостных и рекламных рассылок в адрес
                                        пользователя, если это предусмотрено условиями
                                        Пользовательского соглашения или имеется иным образом полученное согласие
                                        пользователя на получение таких рассылок.
                                        Пользователь в любой момент может отказаться от любых рассылок, на которые он
                                        был подписан или иным образом давал своё
                                        согласие.
                                    </li>
                                    <li className="list-number__item">
                                        Проведение статистических и иных видов исследований на основе обезличенных
                                        персональных данных пользователей.
                                    </li>
                                </ol>
                            </li>
                            <li className="list-number__item">
                                Администрация Интернет-портала «Дентал Портал» вправе передавать персональные данные
                                пользователей третьим лицам в
                                следующих случаях:
                                <ol className="list-number">
                                    <li className="list-number__item">
                                        Пользователь явно выразил своё согласие на такие действия.
                                    </li>
                                    <li className="list-number__item">
                                        Передача необходима в рамках использования пользователем функционала
                                        Интернет-портала «Дентал Портал» либо для оказания
                                        услуги пользователю.
                                    </li>
                                    <li className="list-number__item">
                                        Передача предусмотрена действующим законодательством.
                                    </li>
                                </ol>
                            </li>
                            <li className="list-number__item">
                                Пользователь имеет право в любой момент потребовать от Администрации Интернет-портала
                                «Дентал Портал» прекращения
                                обработки его персональных данных.
                            </li>
                            <li className="list-number__item">
                                Обработка персональных данных пользователя также прекращается при удалении учётной
                                записи пользователя, при этом
                                хранение некоторых персональных данных может продолжать осуществляться в порядке и в
                                сроки, установленные ст.10.1.
                                Федерального закона № 149-ФЗ от 27.07.2006г. «Об информации, информационных технологиях
                                и защиты информации».
                            </li>
                        </ol>
                        <p className="politic__item-text">
                            <b>Администрация портала:</b>
                        </p>
                        <p className="politic__item-text">ИП Солодкая А.И. - ИНН 502416532556, ОГРН 321774600718817.</p>
                        <p className="politic__item-text">
                            Адрес:109462, г. Москва, ул. Волжский бульвар, квартал 114А, корпус 3, кв. 292 <a
                                href="mailto:ceo@dentalportal.pro">ceo@dentalportal.pro</a>
                        </p>
                        <p className="politic__item-text">
                            Адрес размещения в сети Интернет: <a
                                href="https://dentalportal.ru">dentalportal.ru</a>
                        </p>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default PrivacyPolicy;