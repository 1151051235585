import { FC } from 'react';
import { TR } from 'service/T10N';

interface SortByProps {
    value: string
    setValue: (value: string) => void
}

const SortBy: FC<SortByProps> = ({ value, setValue }) => {
    return (
        <div className="sort-box">
            <label htmlFor="sort">{TR('Сортировка')}:</label>
            <span />
            <select name="sort" id="sort" value={value} onChange={e => { setValue(e.target.value) }}>
                <option key="rating" value="rating">{TR('По популярности')}</option>
                <option key="date" value="date">{TR('По дате')}</option>
            </select>
        </div>
    )
}

export default SortBy