import AuthContext from 'AuthContext';
import ResultsCounter from 'Components/ResultsCounter';
import SearchFilters from 'Components/SearchFilters';
import TopSwitch from 'Components/TopSwitch';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getAuctions } from 'service/API';
import { Auction } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';
import AuctionCard2 from './Components/AuctionCard2';

const Auctions = () => {
    const auth = useContext(AuthContext);

    const [justInitialized, setJustInitizlized] = useState(true);
    const [auctions, setAuctions] = useState<Auction[] | null>(null);

    const [findText, setFindText] = useState<string>('')
    const [forRole, setForRole] = useState('')
    const [cities, setCities] = useState([])
    const [priceFrom, setPriceFrom] = useState('')
    const [priceTo, setPriceTo] = useState('')
    const [sortBy, setSortBy] = useState('date')

    const resetFilters = () => {
        setFindText('')
        setForRole('')
        setCities([])
        setPriceFrom('')
        setPriceTo('')
        setSortBy('date')
    }

    const [counter, setCounter] = useState(0)
    useEffect(() => {
        //console.log('Auctions effect: ' + counter)
        const timeout = counter == 0 ? 0 : 1 * 1000
        setCounter(counter + 1)

        {// Refetch on change logic
            const timer = setTimeout(() => {
                //console.log('Effect timeout')
                getAuctions(findText, forRole, cities, parseInt(priceFrom), parseInt(priceTo), sortBy).
                    then(result => {
                        //setAuctions(result.slice(0, 0))
                        setAuctions(result)
                        setJustInitizlized(false)
                    })
            }, timeout)

            return () => {
                clearTimeout(timer)
            };
        }

    }, [forRole, findText, cities, priceFrom, priceTo, sortBy]);

    const filter = [
        {
            id: "text",
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
                // onSearch: handleSearchButton,
            }
        },
        // {
        //     id: "cities",
        //     subset: {
        //         from: getAvailableCities(),
        //         value: cities,
        //         setValue: setCities,
        //         nonEmptyTitle: TR('Выбранные города'),
        //         whenEmptyTitle: TR('По всем городам'),
        //         popupTitle: TR('Город'),
        //     }
        // },
        {
            id: "priceRange",
            range: {
                title: TR_('Бюджет'),
                from: priceFrom,
                to: priceTo,
                setFrom: setPriceFrom,
                setTo: setPriceTo,
                fromPlaceholder: TR('От ₽'),
                toPlaceholder: TR('До ₽'),
            }
        },
    ]

    const switchData = {
        switchName: 'roles',
        value: forRole,
        setValue: setForRole,
        items: [
            { title: TR('Все'), value: '' },
            //{ title: TR_('Для лабораторий и техников'), value: 'technician' },
            { title: TR_('Для лабораторий'), value: 'technician' },
            { title: TR('Для торговых компаний'), value: 'trader' },
        ]
    }



    return (
        <main className="main">
            <div className="container">
                <h1 className="section-title">{TR('Закупки')}</h1>
                <div className="list-controls">
                    <TopSwitch {...switchData} />
                    <div className="list-controls__sort">
                        {/* <SortBy value={sortBy} setValue={setSortBy} /> */}
                        {!isMobile ?
                            <ResultsCounter value={auctions ? auctions.length : 0} />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="list-base">
                    <SearchFilters options={filter} onResetFilters={resetFilters} />
                    <div className="list-ads">
                        {/* add class list-map--open */}
                        <div className="list-map">
                        </div>
                        {auctions?.length ?
                            auctions.map((auction) => {
                                return <AuctionCard2 key={auction.id} auction={auction} />
                            })
                            :
                            justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Auctions;