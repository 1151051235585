import AuthContext from 'AuthContext';
import { firstPhoto } from 'Pages/Offers/Components/OfferCard';
import PopupFeedbackNew from 'Pages/Popups/PopupFeedbackNew';
import { OwnCompanyCard, SomeCompanyCard } from 'Pages/Sales/SaleView';
import { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createOrUpdateUserReview, deleteUserReview, getDateHuman, getDateStr, net } from 'service/API';
import { UnixTime, User, UserReview, UserReviews, isDoctor, isTechnician, isTrader } from 'service/Data/DentTypes';
import { TR_ } from 'service/T10N';
import { DoctorAddon, TechnicianAddon, TraderAddon } from './Components/UserCard';

// const DoctorAddon = ({ scanner = '', chairs = 0 }) => {
//     return (
//         <div className='list-add__adons view--adons'>
//             <div className='list-add__doc'>
//                 <p className='scan'>
//                     {TR('Интраоральный сканер')}:{scanner ?
//                         <span className='scan__data'> <span className='scan__is'>{TR('Да')}</span>{scanner}</span>
//                         :
//                         <span className='scan__data'> <span className='blind--text'>{TR('Нет')}</span></span>
//                     }
//                 </p>
//             </div>
//             {chairs > 3 ?
//                 <p className='chair'>3+</p> : <p className='chair'>1-3</p>
//             }
//         </div>
//     )
// }

interface LocationState {
    user: User
    reviews: UserReviews
}

const UserViewReviews = () => {
    const auth = useContext(AuthContext)
    const history = useHistory()

    const location = useLocation<LocationState>() // '/user-view-reviews'
    const user = location.state.user
    const reviews = location.state.reviews // look UserReviews

    const [isNewFeedbackShown, setNewFeedbackShown] = useState(false)

    const { name, city, street, description } = user

    if (!location.state) {
        //console.log('No state in UserViewReviews')
        return null
    }

    //console.log('User:')
    //console.log(user)



    const [isOwn, setOwn] = useState(auth.session?.id === user.id)

    const [newReviews, oldReviews] = reviews.reviews.reduce((result, curr) => {
        //console.log(result)
        if (curr.publicDate > reviews.lastSeenReview) {
            result[0].push(curr)
        }
        else {
            result[1].push(curr)
        }

        return result
    }, [[], []] as UserReview[][])


    // Scroll to top once on page load
    const [scrollToTop] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToTop])

    const [filter, setFilter] = useState('')

    const handleEdit = async () => {
        type Reply = {
            status: string
            message?: string
            user?: User
        }

        const reply = await net.get<Reply>('/api/v1/users/me')

        //console.log('Me:')
        //console.log(reply)

        // 15 385 962 557 226 008 000
        // 18 446 744 073 709 551 616
        // -9 223 372 036 854 775 808
        //  9 223 372 036 854 775 807


        if (reply.user) {
            //console.log("Reply me")
            history.push('/me', { user: reply.user })
        }
    }

    const handleDelete = async () => {
        // const success = await deleteSale(sale.id)

        // if (success) {
        //     history.push('/dash')
        //     return
        // }

        //TODO: show error somehow
    }

    const handleDeleteReview = async () => {
        if (myReview) {
            const newRating = await deleteUserReview(myReview.id)

            setMyReview(undefined)
            setRateSum(newRating.rateSum)
            setRateCount(newRating.rateCount)
            //updateRating()
        }
    }

    const [myReview, setMyReview] = useState<UserReview | undefined>(reviews.myReview)
    const [rateSum, setRateSum] = useState(reviews.rateSum)
    const [rateCount, setRateCount] = useState(reviews.rateCount)
    const rating = (rateCount ? (rateSum / rateCount) : 0.0)

    const popupText = myReview ? myReview.description : ''
    const popupStars = myReview ? myReview.rate : 1
    const isNewReview = myReview ? false : true

    return (
        <main className='main'>
            <div className='container'>
                <section className='view--add'>
                    <div className='preview-flex'>
                        <div className='view__details'>
                            <h1 className='section-title mb--20'>{name}</h1>
                            <p className='location location--clear'>
                                {city}
                                <span className='blind--text'>{street}</span>
                            </p>
                            <p>
                                {description}
                            </p>
                            <br />
                            {isDoctor(user) ? <DoctorAddon {...user.doctor} /> :
                                isTechnician(user) ? <TechnicianAddon {...user.technician} /> :
                                    isTrader(user) ? <TraderAddon catalog={user.trader.catalog} counterpartyCard={user.counterpartyCard} /> : null}
                        </div>
                        <div className="view__media">
                            <div className="view__media-logo">
                                <img className="inner-img" src={firstPhoto(user.photos)} />
                            </div>
                        </div>
                        {/* <div className='view__media'>
                            <div className='view__media-inner'>
                                <Swiper
                                    className="swiper mySwiper2"
                                    style={{
                                        // @ts-ignore
                                        "--swiper-navigation-color": "#fff",
                                        "--swiper-pagination-color": "#fff",
                                    }}
                                    spaceBetween={2}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                >
                                    <div className="swiper-wrapper">
                                        {
                                            user.photos?.map((photo, index) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <img src={photoUrl(photo)} />
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="swiper-pagination"></div>
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                </Swiper>
                            </div>
                        </div> */}
                    </div>
                    <div className='preview-flex-cont'>
                        <div className='view__serv'>
                            <header className='popup-header'>
                                <div className='feedback-title__box'>
                                    <h1 className='popup-title'>
                                        {TR_('Отзывы')}
                                    </h1>
                                    <div className='rate-box'>
                                        <div className='rate__stars'>
                                            <div className='rating'>
                                                <div className='rating__inner rating__base'></div>
                                                {/* <div className='rating__inner rating__low' style={{ width: '30%' }}></div> */}
                                                <div className='rating__inner rating__hight' style={{ width: ((rating * 20) + '%') }}></div>
                                            </div>
                                        </div>
                                        <span className='rate__number'>{rating.toFixed(1)}</span>
                                        <span className='rate__amo'>({rateCount})</span>
                                    </div>
                                    {!isOwn ?
                                        <button
                                            className='button-27e'
                                            onClick={() => setNewFeedbackShown(true)}>
                                            {myReview ? TR_('Изменить отзыв') : TR_('Оставить отзыв')}
                                        </button>
                                        :
                                        null
                                    }
                                    {myReview ?
                                        <button
                                            className='button-27e'
                                            onClick={() => handleDeleteReview()}>
                                            {TR_('Удалить отзыв')}
                                        </button>
                                        :
                                        null
                                    }
                                    {isNewFeedbackShown ?
                                        <PopupFeedbackNew
                                            title={name}
                                            text={popupText}
                                            stars={popupStars}
                                            isNew={isNewReview}
                                            onClose={() => { setNewFeedbackShown(false); }}
                                            onFeedback={async (stars, text) => {
                                                // console.log(stars);
                                                // console.log(text);

                                                const reply = await createOrUpdateUserReview({
                                                    reviewId: myReview?.id,
                                                    forWhom: user.id,
                                                    rate: stars,
                                                    description: text
                                                })

                                                if (reply.rating) {
                                                    setRateSum(reply.rating.rateSum)
                                                    setRateCount(reply.rating.rateCount)
                                                }

                                                setMyReview(reply)
                                                setNewFeedbackShown(false)
                                            }} />
                                        :
                                        null}
                                </div>
                            </header>
                            <div className='popup-body'>
                                <div className='feedback-list'>
                                    {/* <article className='feedback__item'>
                                        <h2 className='v--hidden'>feedback</h2>
                                        <div className='feedback__item-title'>
                                            <p className='feedback-user'>Елена Г.</p>
                                            <div className='feedback__inner-title'>
                                                <time dateTime='2021-10-12'>12 октября 2021</time>
                                                <div className='rate__stars'>
                                                    <div className='rating'>
                                                        <div className='rating__inner rating__base'></div>
                                                        <div className='rating__inner rating__low' style={{ width: '30%' }}></div>
                                                        <div className='rating__inner rating__hight' style={{ width: '50%' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='feedback__auction'>
                                            Аукцион:<span>Название аукциона</span>
                                        </p>
                                        <p className='feedback__text'>
                                            Франшиза, в первом приближении, начинает гарантийный винил. Развивая эту тему, акцентуация
                                            вызывает возрастной фьюжн,
                                            следовательно тенденция к конформизму связана с менее низким интеллектом.

                                        </p>
                                    </article> */}
                                    {isOwn ?
                                        newReviews.map((review) => {
                                            return <ReviewItem
                                                key={review.id}
                                                name={review.creator.name}
                                                publicDate={review.publicDate}
                                                rate={review.rate}
                                                description={review.description}
                                                isNew={true} />
                                        })
                                        :
                                        null
                                    }
                                    {isOwn ?
                                        oldReviews.map((review) => {
                                            return <ReviewItem
                                                key={review.id}
                                                name={review.creator.name}
                                                publicDate={review.publicDate}
                                                rate={review.rate}
                                                description={review.description}
                                                isNew={false} />
                                        })
                                        :
                                        null
                                    }
                                    {!isOwn && myReview && auth.session ?
                                        <ReviewItem
                                            key={myReview.id}
                                            name={TR_('Ваш отзыв')}
                                            publicDate={myReview.publicDate}
                                            rate={myReview.rate}
                                            description={myReview.description}
                                            isNew={false} />
                                        :
                                        null
                                    }
                                    {!isOwn ?
                                        reviews.reviews.map((review) => {
                                            return <ReviewItem
                                                key={review.id}
                                                name={review.creator.name}
                                                publicDate={review.publicDate}
                                                rate={review.rate}
                                                description={review.description}
                                                isNew={false} />
                                        })
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className='view__controls-inner'>
                            <div className='view-chat'>
                                <div className='view-chat__flex'>
                                    <div className='view-chat__flex-item'>
                                        <div className='view-chat__company'>
                                            <img className='view-chat__company-img' src='/assets/images/company1.png' alt='' />
                                            Зуботехническая
                                            лаборатория
                                        </div>
                                    </div>
                                    <div className='view-chat__flex-item'>
                                        <div className='view-chat__date'>
                                            <p className='view-chat__date-title'>Добавлено:</p>
                                            <time className='view-chat__date-add' dateTime='2021-10-12'>25 марта</time>
                                        </div>
                                    </div>
                                </div>
                                <button className='main-button is--blue  m-0'>Начать чат</button>
                                <div className='view-chat__ctrl'>
                                    <button className='view-chat__show'>Показать контакты</button>
                                    <button className='view-chat__complain'>Пожаловаться</button>
                                </div>
                            </div>
                        </div> */}
                        {isOwn ?
                            <OwnCompanyCard
                                publicDate={user.createdAt}
                                handleEdit={handleEdit} />
                            :
                            <SomeCompanyCard createdAt={user.createdAt} company={user} />}
                    </div>
                </section>
            </div>
        </main>
    )
}

interface ReviewItemProps {
    name: string
    publicDate: UnixTime
    rate: number
    description: string
    isNew: boolean
}

const ReviewItem: FC<ReviewItemProps> = ({ name, publicDate, rate, description, isNew }) => {
    return (
        <article className='feedback__item'>
            <h2 className='v--hidden'>feedback</h2>
            <div className='feedback__item-title'>
                <p className='feedback-user'>{name}</p>
                <div className='feedback__inner-title'>
                    {isNew ? <span className="reply-new">{TR_('Новый!')}</span> : null}
                    <time dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
                    <div className='rate__stars'>
                        <div className='rating'>
                            <div className='rating__inner rating__base'></div>
                            {/* <div className='rating__inner rating__low' style={{ width: '30%' }}></div> */}
                            <div className='rating__inner rating__hight' style={{ width: ((rate * 20) + '%') }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <p className='feedback__auction'>
                {/* Аукцион:<span>Название аукциона</span> */}
            </p>
            <p className='feedback__text'>
                {description}
            </p>
        </article>
    )
}

export default UserViewReviews