import { firstPhoto } from 'Pages/Offers/Components/OfferCard';
import PopupCompany from 'Pages/Popups/PopupCompany';
import PopupContacts from 'Pages/Popups/PopupContacts';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDateHuman, getDateStr, getUserContacts, getUserInfo } from 'service/API';
import { Contacts, Sale, User } from 'service/Data/DentTypes';
import { avatarUrl } from 'service/Globals';
import { TR } from 'service/T10N';
import { getPriceStr } from 'service/TextTools';

interface SaleCardProps {
    sale: Sale
    ownId: string
}

const SaleCard: FC<SaleCardProps> = ({ sale, ownId }) => {
    var { id, title, city, street, photos, description, price, creator, publicDate } = sale
    const history = useHistory();

    const [userInfoShown, setUserInfoShown] = useState(false)
    const [userInfo, setUserInfo] = useState<User | null>(null)

    const [contactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts | null>(null)

    const handleCompanyClick = async () => {
        try {
            if (!userInfo) {
                setUserInfo(await getUserInfo(creator.id))
                setUserInfoShown(true)
                return
            }

            setUserInfoShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleContactsClick = async () => {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(creator.id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    return (
        <article className="list-add">
            <div className="list-add__media">
                {/* <div className="list-add__image-box">
                    <img className="list-add__image" src={firstPhoto(photos)} alt="dental portal" />
                </div> */}
                <div className="list-add__image-box">
                    <div className="view__media-logo">
                        <img className="inner-img" src={firstPhoto(photos)} />
                    </div>
                </div>
                <div className="list-add__sub">
                    <button className="company-box" onClick={() => handleCompanyClick()}>
                        <span className="company-image__box">
                            <img src={avatarUrl(creator.avatar)} alt="dental portal" />
                        </span>
                        {creator.name}
                    </button>
                    {userInfo && userInfoShown ?
                        <PopupCompany onClose={() => setUserInfoShown(false)} user={userInfo} /> : null
                    }
                </div>
            </div>
            <div className="list-add__content">
                <div className="list-add__content-details">
                    <h2 className="list-add__title" style={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push('/sale-view', { sale: sale })
                        }}>{title}
                    </h2>
                    <p className="location">
                        {city ? city : TR("Не указ.")}
                        <span className="blind--text">{street}</span>
                    </p>
                    <p>
                        {description}
                    </p>
                </div>
                <div className="list-add__controls">
                    <div className="list-add__adons">
                        <div className="reply-idents">
                            <div className="reply__ident ident__money">{getPriceStr(price)}</div>
                            {/* <div className="reply__ident ident__time">{untilDateStr}</div> */}
                        </div>
                        {/* <div className="list-add__price">
                            {getPriceStr(price)}
                        </div> */}
                        <div className="list-add__date">
                            {TR('Опубликовано')} <br />
                            <time dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
                        </div>
                    </div>

                    <div className="list-add__buttons">
                        <button className="colbut ws--pre" onClick={() => handleContactsClick()}>{TR('Показать \n контакты')}</button>
                        {sale.creator.id != ownId ?
                            <button className="button-27e" onClick={() => history.push('/chat')}>{TR('Начать чат')}</button>
                            :
                            null
                        }
                    </div>
                    {contacts && contactsShown ?
                        <PopupContacts
                            contacts={contacts}
                            onClose={() => setContactsShown(false)}
                        />
                        :
                        null
                    }
                </div>
            </div>
        </article>
    )
}

export default SaleCard;