import { Fragment } from 'react';

// const oneOfOptions = {
//     title: "Количество установок",
//     from: [
//         { text: "Любое количество", value: 0 },
//         { text: "1-3", icon: "chair", value: 1 },
//         { text: "3+", icon: "chair", value: 2 }
//     ],
//     value: chairs,
//     setValue: setChairs
// }

const OneOfFilter = ({ title, from, value, setValue }) => {

    const parseOptions = () => {
        return from.map((item) => {
            return (
                <Fragment key={item.value}>
                    <input className="radio-ball" type="radio" id={item.value.toString()} readOnly
                        checked={item.value === value} onClick={() => setValue(item.value)} />
                    <label htmlFor={item.value.toString()}><span />{item.icon ? <div className={item.icon} /> : null} {item.text}</label>
                </Fragment>)
        })
    }

    return (
        <li className="filter__list-item">
            <div className="filter-radio">
                <p className="filter-type__title">{title}</p>
                <div className="radio-balls">
                    {parseOptions()}
                </div>
            </div>
        </li>
    )
}

export default OneOfFilter;