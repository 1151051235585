import AuthContext from 'AuthContext'
import AvatarSelector from 'Components/AvatarSelector'
import FileUploader from 'Components/FileUploader'
import PhotoSelector from 'Components/PhotoSelector'
import { FC, RefObject, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { resizeImage, updateMyData } from 'service/API'
import { BlobCache, DentFUID, DentFile, Doctor } from 'service/Data/DentTypes'
import { gAvatarSize } from 'service/Globals'
import { TR, TR_ } from 'service/T10N'

export function useLabelFocus(): [RefObject<HTMLLabelElement>, () => void] {
    const htmlElRef = useRef<HTMLLabelElement>(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

export function useInputFocus(): [RefObject<HTMLInputElement>, () => void] {
    const htmlElRef = useRef<HTMLInputElement>(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

export function useTextFocus(): [RefObject<HTMLTextAreaElement>, () => void] {
    const htmlElRef = useRef<HTMLTextAreaElement>(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

interface MyDataDoctorProps {
    user: Doctor
}

export const MAX_SITE_LENGTH = 45 // <- sync with DB
export const MAX_EMAIL_LENGTH = 45 // <- sync with DB
export const MAX_NAME_LENGTH = 50 // <- sync with DB
export const MAX_DESCR_LENGTH = 250 // <- sync with DB

const MyDataDoctor: FC<MyDataDoctorProps> = ({ user }) => {
    const history = useHistory()
    const auth = useContext(AuthContext);

    const [nameRef, setNameFocus] = useInputFocus()
    const [avatarRef, setAvatarFocus] = useLabelFocus()
    const [photosRef, setPhotosFocus] = useLabelFocus()
    const [phoneRef, setPhoneFocus] = useInputFocus()
    const [emailRef, setEmailFocus] = useInputFocus()
    const [cityRef, setCityFocus] = useInputFocus()
    const [streetRef, setStreetFocus] = useInputFocus()
    const [descriptionRef, setDescriptionFocus] = useTextFocus()
    const [licensesRef, setLicensesFocus] = useLabelFocus()

    const [errorShown, setErrorShown] = useState(false);
    const [errorText, setErrorText] = useState("");
    const setError = (text: string) => {
        setErrorText(text)
        setErrorShown(true)
    }

    const [isHelpShown, setHelpShown] = useState(false)

    const [name, setName] = useState(user.name)
    const [avatar, setAvatar] = useState(user.avatar)
    //const [avatar, setAvatar] = useState<DentFUID>('')
    const [city, setCity] = useState(user.city)
    const [street, setStreet] = useState(user.street)
    const [phone, setPhone] = useState(user.contacts.phone)
    const [hasTelegram, setHasTelegram] = useState(user.contacts.hasTelegram)
    const [hasWhatsapp, setHasWhatsapp] = useState(user.contacts.hasWhatsapp)

    const [site, setSite] = useState(user.contacts.site)
    const [email, setEmail] = useState(user.contacts.email)
    const [youtube, setYoutube] = useState(user.contacts.youtube)
    const [description, setDescription] = useState(user.description)

    //const [hasScanner, setHasScanner] = useState(!!user.doctor.ioscanner)
    const [scanner, setScanner] = useState(user.doctor.scanner)
    const [chairs, setChairs] = useState(user.doctor.chairs)

    const [starredPhoto, setStarredPhoto] = useState(user.photos.length ? user.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(user.photos)

    const [licenses, setLicenses] = useState<DentFile[]>(user.doctor.licenses)

    //console.log('Avatar: \'' + user.avatar + '\'');

    function validateForm(): boolean {
        if (!name) {
            setErrorShown(true)
            setNameFocus()
            return false
        }

        if (!avatar) {
            setErrorShown(true)
            setAvatarFocus()
            return false
        }

        // if (!photos.length) {
        //     setErrorShown(true)
        //     setPhotosFocus()
        //     return false
        // }

        // if (!phone) {
        //     setErrorShown(true)
        //     setPhoneFocus()
        //     return false
        // }

        // if (!email) {
        //     setErrorShown(true)
        //     setEmailFocus()
        //     return false
        // }

        // if (!city) {
        //     setErrorShown(true)
        //     setCityFocus()
        //     return false
        // }

        // if (!street) {
        //     setErrorShown(true)
        //     setStreetFocus()
        //     return false
        // }

        // if (!description) {
        //     setErrorShown(true)
        //     setDescriptionFocus()
        //     return false
        // }

        // if (!licenses.length) {
        //     setErrorShown(true)
        //     setLicensesFocus()
        //     return false
        // }

        return true
    }

    const doSave = async () => {
        if (!validateForm()) {
            //console.log('Validation error...')
            return
        }

        const doctor: Doctor = {
            doctor: {
                chairs: chairs,
                scanner: scanner,
                licenses: [...licenses]
            },
            createdAt: user.createdAt,
            city: city,
            street: street,
            description: description,
            id: '',
            role: 'doctor',
            name: name,
            avatar: avatar,
            photos: [...photos],
            contacts: {
                email: email,
                phone: phone,
                hasTelegram: hasTelegram,
                hasWhatsapp: hasWhatsapp,
                site: site,
                youtube: youtube,
            },
        }

        //console.log('Updating my data:')
        //console.log(doctor)

        try {
            const reply = await updateMyData(doctor)

            if (reply.session) {
                // Update success
                //console.log('Successful reply with avatar: ' + reply.session.avatar)

                auth.updateSession(reply.session)
                history.push('/')
            }
        } catch (e) {
            if (!auth.session?.avatar) {
                auth.logout()
                history.push('/signin')
            }
        }
    }

    return (
        <main className='main' onMouseDown={() => setErrorShown(false)}>
            <div className='container'>
                <section className='respond'>
                    <div className='respond-cover'>
                        <h1 className='respond-title'>{TR('Мои данные')}</h1>
                        <div className='add'>
                            <div className='respond__form'>
                                <div className='respond__main'>
                                    <button className='how' onClick={() => setHelpShown(true)}>
                                        {TR('Рекомендация по заполнению')}
                                    </button>
                                    <form className='data__form'>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={nameRef}
                                                    tooltip_id='name-tooltip'
                                                    title={TR('Название клиники (или ФИО)')}
                                                    value={name}
                                                    maxLength={MAX_NAME_LENGTH}
                                                    setValue={setName} />
                                                <Tooltip id="name-tooltip" isOpen={errorShown && !name} content={TR_("Укажите название")} />
                                            </div>
                                            <div className='contact-item'>
                                                <div className='field-set'>
                                                    <p className='field-set__title'>
                                                        {TR_('Аватар')}
                                                    </p>
                                                    <AvatarSelector reff={avatarRef} tooltip="avatar-tooltip" value={avatar} onFileSelected={async (file) => {
                                                        try {
                                                            //console.log(file)
                                                            const blob = await resizeImage(file, gAvatarSize, gAvatarSize)
                                                            if (blob instanceof Blob) {
                                                                //const fuid = await uploadAvatar(blob)
                                                                //const url = avatarUrl(fuid)
                                                                // const url = URL.createObjectURL(blob)
                                                                // console.log(url)
                                                                setAvatar(new BlobCache(blob))
                                                                //auth.setAvatar(url)
                                                            }
                                                        }
                                                        catch (error) {
                                                            //console.log('error aru1')
                                                            //console.log(error)
                                                        }

                                                    }} />
                                                    <Tooltip id="avatar-tooltip" isOpen={errorShown && !avatar} content={TR_("Загузите ваш аватар")} />
                                                    <p className='fied-set__error'></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='field-set'>
                                            <span className='field-set__title flex--between'>
                                                {TR_('Фотографии')}
                                                <div className='main-foto' id='main-foto' />
                                                <label htmlFor='main-foto'>{TR('Главное фото')}</label>
                                            </span>
                                            <PhotoSelector
                                                reff={photosRef}
                                                tooltip='photo-tooltip'
                                                photos={photos}
                                                setPhotos={setPhotos}
                                                starred={starredPhoto}
                                                setStarred={setStarredPhoto}
                                            />
                                            {/* <Tooltip id="photo-tooltip" isOpen={errorShown && !photos.length} content={TR_("Загузите хотя бы одно фото")} /> */}
                                        </div>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <div className='field-set'>
                                                    <p className='field-set__title flex--between'>
                                                        {TR('Телефон')}
                                                        <input type='checkbox' className='checkbox-slide' id='chbxsld1' checked={hasTelegram} onChange={(e) => setHasTelegram(!hasTelegram)} />
                                                        <label htmlFor='chbxsld1'> <span></span> {TR('Telegram')}</label>
                                                        <input type='checkbox' className='checkbox-slide' id='chbxsld2' checked={hasWhatsapp} onChange={(e) => setHasWhatsapp(!hasWhatsapp)} />
                                                        <label htmlFor='chbxsld2'> <span></span> {TR('WhatsApp')}</label>
                                                    </p>
                                                    <div className='input' data-tooltip-id="phone-tooltip">
                                                        <input ref={phoneRef} type='text' placeholder='' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                    </div>
                                                    {/* <Tooltip id="phone-tooltip" isOpen={errorShown && !phone} content={TR_("Укажите телефон")} /> */}
                                                    <p className='fied-set__error'></p>
                                                </div>
                                            </div>
                                            <div className='contact-item'>
                                                <TextInput
                                                    title={TR('Сайт')}
                                                    value={site}
                                                    setValue={setSite} />
                                            </div>
                                        </div>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={emailRef}
                                                    tooltip_id='email-tooltip'
                                                    title={TR('E-mail')}
                                                    value={email}
                                                    setValue={setEmail}
                                                />
                                                {/* <Tooltip id="email-tooltip" isOpen={errorShown && !email} content={TR_("Укажите ваш email")} /> */}
                                            </div>
                                            <div className='contact-item'>
                                                <TextInput
                                                    title={TR('YouTube')}
                                                    value={youtube}
                                                    setValue={setYoutube}
                                                />
                                            </div>
                                        </div>

                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={cityRef}
                                                    tooltip_id='city-tooltip'
                                                    title={TR('Город')}
                                                    value={city}
                                                    setValue={setCity}
                                                />
                                                {/* <Tooltip id="city-tooltip" isOpen={errorShown && !city} content={TR_("Укажите город")} /> */}
                                                <TextInput
                                                    reff={streetRef}
                                                    tooltip_id='street-tooltip'
                                                    title={TR('Улица, дом, офис')}
                                                    value={street}
                                                    setValue={setStreet}
                                                />
                                                {/* <Tooltip id="street-tooltip" isOpen={errorShown && !street} content={TR_("Укажите улицу")} /> */}
                                            </div>
                                            <div className='contact-item'>
                                                <div className='scan-fieldset'>
                                                    <input type='checkbox' className='checkbox-slide' id='chbxsld' readOnly checked={!!scanner} />
                                                    <label className='label--split' htmlFor='chbxsld'>
                                                        <p className='field-set__title'>
                                                            {TR('Интраоральный сканер')}
                                                        </p><span></span>
                                                    </label>
                                                    <div className='field-set field--white'>
                                                        <div className='input'>
                                                            <input type='text' placeholder={TR('Название сканера')} value={scanner} onChange={(e) => setScanner(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='field-set'>
                                                    <p className='field-set__title'>
                                                        {TR('Количество установок')}
                                                    </p>
                                                    <div className='radio-string'>
                                                        <input className='radio-ball' type='radio' id='less3' name='amo' readOnly checked={chairs == 0} onClick={() => setChairs(0)} />
                                                        <label htmlFor='less3'><span></span><div className='chair' />1-3</label>
                                                        <input className='radio-ball' type='radio' id='more3' name='amo' readOnly checked={chairs == 4} onClick={() => setChairs(4)} />
                                                        <label htmlFor='more3'><span></span><div className='chair' />3+</label>
                                                    </div>
                                                    <p className='fied-set__error'></p>
                                                </div>
                                            </div>
                                        </div>
                                        <TextAreaInput
                                            title={TR_('Описание')}
                                            value={description}
                                            setValue={setDescription}
                                            maxLength={MAX_DESCR_LENGTH}
                                            tooltip_id='description-tooltip'
                                        />
                                        {/* <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Необходимо описание")} /> */}
                                        <div className='field-set'>
                                            <p className='field-set__title'>
                                                {TR('Лицензия для клиники или Диплом для врача')}
                                            </p>
                                            <FileUploader reff={licensesRef} files={licenses} setFiles={setLicenses} />
                                            {/* <Tooltip id="file-tooltip" isOpen={errorShown && !licenses.length} content={TR_("Загузите хотябы один файл")} /> */}
                                            <p className="red-note">
                                                {TR_('Этот документ будет доступен только для администрации портала, что позволит определить истинную принадлежность участника к стоматологии.')}
                                            </p>
                                        </div>
                                    </form>
                                </div>
                                <div className='add__footer'>
                                    <div className='data-controls'>
                                        <button className='button-e h--44' onClick={() => history.push('/')}>{TR('Отмена')}</button>
                                        <button className='button-f h--44' onClick={() => doSave()}>{TR('Сохранить')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className={'respond__description data__info' + (isHelpShown ? '' : ' hide_me')}>
                                <div className=' respond__description-inner'>
                                    <header className='add__header'>
                                        <button className='add__header-btn' onClick={() => setHelpShown(false)}></button>
                                        <h2 className='add__header-title'>{TR('Рекомендация по заполнению')}</h2>
                                    </header>
                                    <div className='add__main'>
                                        <ol className='gide-list'>
                                            <li className='gide__item'>
                                                {TR_('Укажите название и адрес Вашей компании. Загрузите логотип.')}
                                            </li>
                                            <li className='gide__item'>
                                                {TR_('Размещайте информативные и качественные фотографии в своём профиле.')}
                                            </li>
                                            <li className='gide__item'>
                                                {TR_('Указывайте актуальные контактные данные, это повысит эффективность Вашего профиля.')}
                                            </li>
                                            <li className='gide__item'>
                                                {TR_('В описании деятельности своей компании укажите на сильные стороны. Кратко сформулируйте Ваши отличительные особенности по сравнению с конкурентами.')}
                                            </li>
                                            {/* <li className='gide__item'>
                                                {TR_('Обязательным условием регистрации на портале является предоставление документов подтверждающих Вашу деятельность на рынке стоматологических услуг (лицензия, диплом, карточка контрагента).')}
                                            </li> */}
                                        </ol>
                                    </div>
                                    <div className='add__footer'>
                                        <button className='main-button is--blue  too--wide'>{TR('Закрыть')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main >
    )
}

interface TextInputProps {
    reff?: any
    tooltip_id?: string
    title: string
    placeholder?: string
    inputType?: string
    value: string
    maxLength?: number,
    setValue?: (v: string) => void
    readonly?: boolean
}

export const TextInput: FC<TextInputProps> = ({ reff, tooltip_id, title, placeholder = '', inputType = 'text', value, setValue, maxLength, readonly: ro }) => {
    //const { title, placeholder, value, setValue } = props
    return (
        <div className='field-set'>
            <p className='field-set__title'>{title}</p>
            <div className='input' data-tooltip-id={tooltip_id}>
                <input
                    maxLength={maxLength}
                    ref={reff}
                    type={inputType}
                    placeholder={placeholder}
                    value={value} onChange={(e) => {
                        if (setValue) { setValue(e.target.value) }
                    }} readOnly={ro} />
            </div>
            {/* <p className='fied-set__error'></p> */}
        </div>
    )
}

interface TextAreaInputProps {
    reff?: any
    tooltip_id?: string
    title: string
    placeholder?: string
    value: string
    maxLength?: number,
    setValue?: (v: string) => void
}

export const TextAreaInput: FC<TextAreaInputProps> = ({ reff, tooltip_id, title, placeholder = '', value, setValue, maxLength }) => {
    return (
        <div className='field-set'>
            <p className='field-set__title'>{title}</p>
            <textarea
                maxLength={maxLength}
                ref={reff}
                data-tooltip-id={tooltip_id}
                className='textarea textarea--my-data'
                name='description-text'
                id='description-text'
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    if (setValue) setValue(e.target.value)
                }} />
            <p className='fied-set__error'></p>
        </div>
    )
}

export default MyDataDoctor