import { useEffect, useState } from "react";
import { DashData, getDashData } from "service/API";
import { announcement } from "service/Globals";
import PurchasesDoctor from "./Components/PurchasesDoctor";
import PurchasesTechnician from "./Components/PurchasesTechnician";

const HomeTrader = () => {

    const [data, setData] = useState<DashData>()
    const [firstLoad] = useState()
    useEffect(() => {
        (async () => {
            //console.log('Home trader effect')
            const homeData = await getDashData({
                //myAuctions: { actual: [], archived: [] },
                // myOffers: { actual: [], archived: [] },
                // mySales: { actual: [], archived: [] },
                //myVacancies: { actual: [], archived: [] },
                // offersFromTechnicians: [],
                // offersFromTraders: [],
                purchasesFromDoctors: [],
                purchasesFromTechnicians: []
            })
            setData(homeData)
        })();
    }, [firstLoad]);

    // const myOffers = {
    //     actual: _myOffers.slice(0, 3),
    //     archived: _myOffers.slice(3, 4),
    // }

    // const mySales = {
    //     actual: _mySales.slice(0, 3),
    //     archived: _mySales.slice(3, 4),
    // }

    //const myAuctions = data?.myAuctions ? data?.myAuctions : { actual: [], archived: [] }
    const myOffers = data?.myOffers ? data?.myOffers : { actual: [], archived: [] }
    const mySales = data?.mySales ? data?.mySales : { actual: [], archived: [] }
    //const myVacancies = data?.myVacancies ? data?.myVacancies : { actual: [], archived: [] }

    return (
        <main className="main">
            <div className="container">
                {/* Кабинет для торговых компаний */}
                {announcement ? <div className="home temp-header">{announcement}</div> : null}
                {/* {TR('Портал начинает свою работу с 29 мая 2023\n Осталось NN дней')} */}
                <div className="main-flex">
                    <div className="main-flex__item">
                        <PurchasesDoctor thePurchases={data?.purchasesFromDoctors} />
                    </div>
                    <div className="main-flex__item">
                        <PurchasesTechnician thePurchases={data?.purchasesFromTechnicians} />
                    </div>
                </div>
            </div>
        </main >
    )
}

export default HomeTrader;