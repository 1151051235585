import { fallbackLanguage, language } from "./Lang"

fallbackLanguage
//const json = JSON.parse(loc.toString())
const json = require('l10n.json')

export function TR(s: string, comment?: string): string {
    //return s

    const item = json.texts[bi_hash_rt(s)]

    if (item) {
        const str = item[language]
        if (str) {
            return str
        }

        const fallbackStr = item[fallbackLanguage]
        if (fallbackStr)
            return fallbackStr
    }

    return ''

    if (comment)
        return s

    return s.replace(/[А-ЯA-Z]/g, 'O').replace(/[а-яa-z]/g, 'o').replace(/[0-9]/g, 'N');
    //return s.replace(/[А-ЯA-Z]/g, '_').replace(/[а-яa-z]/g, '_').replace(/[0-9]/g, 'N');
}

export function TR_(s: string, comment?: string): string {
    return s
}

function Uint32(n: number): number {
    return n >>> 0
}

function Int8(n: number): number {
    return n << 24 >> 24
}

const utf8 = new TextEncoder();
utf8.encode("abc");

function bi_hash_rt(s: string): number {
    const bytes = utf8.encode(s)
    const basis = 2166136261
    const prime = 16777619

    let hash = basis
    for (var i = 0; i < bytes.length; i++) {
        hash = Uint32(hash ^ Int8(bytes[i]))
        hash = Math.imul(hash, prime)
    }

    return Uint32(hash);
}