import AuthContext from 'AuthContext';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DentNotification } from 'service/Data/DentTypes';
import { TR_ } from 'service/T10N';
import { NotificationItem } from './Popups/PopupNotifications';

interface LocationState {
    notifications?: DentNotification[]
}

const ListNotifications = () => {
    //console.log('ListNotifications')

    const location = useLocation<LocationState>(); // '/list-notifications'
    const notifications: DentNotification[] | undefined = location.state?.notifications

    if (notifications === undefined) {
        return null
    }

    const auth = useContext(AuthContext)
    const history = useHistory()

    const handleNewNotification = () => {
        var n: DentNotification = {
            title: "",
            description: "",
            photos: [],
            files: [],
        }
        history.push('/notification-new', { notification: n })
    }

    return (
        <main className="main">
            <div className="container">
                <section className="notification-section">
                    <div className="flex-between">
                        <h1 className="section-title">{TR_('Уведомления')}</h1>
                        <button className="button-f" onClick={() => handleNewNotification()}>{TR_('Добавить новое')}</button>
                    </div>
                    <div className="notifications__inner">
                        {
                            auth.notifications.map((notification) => <NotificationItem key={notification.id} notification={notification} isImportant={false} canEdit={true} />)
                        }
                        {/* <article className="notification__item">
                            <h4 className="notification__item-title">
                                Новый отклик по аукциону “Поиск лаборатории на разработку...”
                            </h4>
                            <div className="notification__image-box view__media-logo">
                                <img src="./styles/assets/images/office.jpg" alt="" />
                            </div>
                            <div className="notification__btn-container">
                                <a className="button-link-doc" href="#"><span></span>Stl файл</a>
                                <a className="button-link-doc" href="#"><span></span>Stl файл</a>
                            </div>
                            <p className="notification__item-text">
                                Развивая эту тему, акцентуация вызывает возрастной фьюжн,
                                следовательно тенденция к конформизму связана с менее низким
                                интеллектом.
                            </p>
                            <div className="flex-between">
                                <time className="notification__item-date" dateTime="2021-10-12">12 сент., 2022</time>
                                <div className="notes-ctrls">
                                    <button>
                                        <img src='/assets/icons/Edit.svg' />
                                    </button>
                                    <button>
                                        <img src='/assets/icons/Delete.svg' />
                                    </button>
                                </div>
                            </div>
                        </article> */}
                    </div>
                </section>
            </div>
        </main>
    )
}

export default ListNotifications