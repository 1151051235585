import { FC, Fragment, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { DentFUID } from 'service/Data/DentTypes';
import { avatarUrl } from 'service/Globals';
import { TR } from 'service/T10N';
import OutsideClicker from './OutsideClicker';
//import * as S from './Header.styles';

interface AvatarSelectorProps {
    reff?: any
    tooltip?: string
    onFileSelected: (file: File) => void
    value: DentFUID
    maxFileSize?: number
    formats?: RegExp
}

const AvatarSelector: FC<AvatarSelectorProps> = ({ reff, tooltip, onFileSelected, value, maxFileSize = 512000, formats = /\.(jpg|jpeg|png)$/i }) => {
    const [shown, setShown] = useState(false);
    const [errorText, setErrorText] = useState("");
    const setError = (text: string) => {
        setErrorText(text)
        setShown(true)
    }

    const handleFiles = (files: FileList | null) => {
        if (files) {
            const file = files[0]

            if (!file)
                return

            if (!file.name.match(formats)) {
                setError(TR('Поддерживаемые форматы изображений .png и .jpg'))
                return
            }

            onFileSelected(file)
        }
    }

    return (
        <Fragment>
            <div className="avatar-upload" data-tooltip-id={tooltip}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    handleFiles(e.dataTransfer.files)
                }}>
                <div className="avatar-upload__inner">
                    {value ?
                        <img
                            alt="not found"
                            className="avatar-upload__image"
                            width={"250px"}
                            //src={URL.createObjectURL(selectedImage)}
                            src={avatarUrl(value)}
                        />
                        :
                        <span className="avatar-upload__image"></span>
                    }
                </div>
                <span>{TR('Ваш аватар')}</span>
                <input type="file" id="avatarUpld"
                    onChange={(event) => {
                        handleFiles(event.target.files)
                    }}
                />
                <label tabIndex={0} ref={reff} className="button-27e" htmlFor="avatarUpld">{TR('Загрузить')}</label>
                {/* <button ref={reff} className="button-27e">{TR('Загрузить')}</button> */}
            </div>
            <OutsideClicker onClickOutside={() => setShown(false)} />
            <Tooltip id="avatar-tooltip" isOpen={!!errorText && shown} content={errorText} />
        </Fragment>
    )
}

export default AvatarSelector;