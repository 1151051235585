import { correctLink } from 'Pages/Users/Components/UserCard'
import React from 'react'
import { Contacts } from 'service/Data/DentTypes'
import { TR } from 'service/T10N'

export interface PopupContactsProps {
    contacts: Contacts
    onClose: () => void
}

const PopupContacts: React.FC<PopupContactsProps> = ({ contacts, onClose }) => {

    const { email, site, phone, hasWhatsapp, hasTelegram, youtube } = contacts

    let [sd, siteDomain] = site.split('//')
    let [yd, youtubeDomain] = youtube.split('//')

    if (!siteDomain)
        // Protocol is not specified
        siteDomain = site

    if (!youtubeDomain)
        // Protocol is not specified
        youtubeDomain = youtube

    return (
        <div className="cover" onMouseDown={() => onClose()}>
            <div className="pop-up popup--600" onMouseDown={(e) => e.stopPropagation()}>
                <button className="killer" onClick={() => onClose()}></button>
                <header className="popup-header">
                    <h1 className="popup-title">
                        {TR('Контакты')}
                    </h1>
                </header>
                <div className="popup-body">
                    <ul className="contact-list">
                        <li className="contact__item">
                            <span className="contact__item-title">{TR('E-mail')}:</span>
                            <p className="contact__item-data">{email}</p>
                        </li>
                        <li className="contact__item">
                            <span className="contact__item-title">{TR('Телефон')}:</span>
                            <p className="contact__item-data">
                                <span className="phone-wrap">{phone}</span>
                                <span className="messengers">
                                    {hasWhatsapp ? <button className="whatsup" /> : null}
                                    {hasTelegram ? <button className="telegram" /> : null}
                                </span>
                            </p>
                        </li>
                        {site ?
                            <li className="contact__item">
                                <span className="contact__item-title">{TR('Сайт')}:</span>
                                {/* <a className="contact__item-web" href={siteUrl.href} target="_blank">{siteUrl.host}</a> */}
                                <a className="contact__item-web" href={correctLink(site)} target="_blank">{siteDomain}</a>
                            </li>
                            :
                            null
                        }
                        {youtube ?
                            <li className="contact__item">
                                <span className="contact__item-title">{'YouTube:'}</span>
                                {/* <a className="contact__item-web" href={siteUrl.href} target="_blank">{siteUrl.host}</a> */}
                                <a className="contact__item-web" href={correctLink(youtube)} target="_blank">{youtubeDomain}</a>
                            </li>
                            :
                            null
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PopupContacts