import AuthContext from 'AuthContext';
import PopupCompany from 'Pages/Popups/PopupCompany';
import PopupContacts from 'Pages/Popups/PopupContacts';
import { FC, Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteAuction, downloadFile, getDateHuman, getDateStr, getUserContacts, getUserInfo } from 'service/API';
import { Auction, Contacts, User, UserID } from 'service/Data/DentTypes';
import { avatarUrl, fileUrl } from 'service/Globals';
import { TR, TR_ } from 'service/T10N';

export interface AuctionViewHeaderProps {
    auction: Auction
    //isOwn: boolean
}

const AuctionViewHeader: FC<AuctionViewHeaderProps> = ({ auction }) => {
    const auth = useContext(AuthContext)
    const history = useHistory()
    const isOwn = auth.session?.id === auction.creator.id

    const { title, description, files, maxBudget, creator, untilDate, publicDate } = auction
    const [isDescriptionShown, setDescriptionShown] = useState(false)

    const [companyInfo, setCompanyInfo] = useState<User | null>(null)

    const [areContactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts>()

    async function handleContactsClick(id: UserID) {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleCompanyClick = async () => {
        try {
            setCompanyInfo(await getUserInfo(creator.id))
        }
        catch (error) {
            // set error
        }
    }

    const handleEdit = async () => {
        history.push('/auction-new', { auction: auction })
    }

    const handleDelete = async () => {
        const success = await deleteAuction(auction.id)

        if (success) {
            history.push('/dash')
            return
        }

        //TODO: show error somehow
    }

    // const myLocation = window.location.href
    // const myLocURL = new URL(myLocation)
    // console.log('Location:')
    // console.log(myLocation)
    // console.log(myLocURL.pathname)
    // myLocURL.pathname = '/somepath/123.pdf'
    // console.log(myLocURL.toString())

    function getFullPath(pathname: string): string {
        const currentLocationURL = new URL(window.location.href)
        currentLocationURL.pathname = pathname
        return currentLocationURL.toString()
    }

    // async function downloadFile(filePath: string, fileName: string) {
    //     try {
    //         const response = await fetch(filePath, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/pdf',
    //             },
    //         })

    //         if (response.status != HttpStatusCode.Ok) {
    //             console.log('Error downloading \'' + filePath + '\'')
    //         }

    //         const blob = await response.blob()
    //         const url = window.URL.createObjectURL(new Blob([blob]))

    //         const link = document.createElement('a')
    //         link.href = url
    //         link.download = fileName

    //         document.body.appendChild(link)

    //         link.click()
    //         link.parentNode?.removeChild(link)
    //     } catch (e) {
    //         console.log('Error downloading \'' + filePath + '\'')
    //     }
    // }

    return (
        <section className='bid-flex'>
            <div className='bid__general'>
                <h1 className='section-title'>{title}</h1>
                <p className='bid__txt'>
                    {description}
                </p>
                {files ?
                    <div className='bid__addons'>
                        {
                            files.map((file) =>
                                <button
                                    key={fileUrl(file.fuid)}
                                    className='button-link-doc'
                                    onClick={() => downloadFile(fileUrl(file.fuid), file.name)}
                                    // href={getFullPath(fileUrl(file.fuid))}
                                    // download={file.name}
                                    // target='_blank'
                                    rel='noopener noreferrer'>
                                    <span />{file.name}</button>)
                        }
                    </div>
                    : null
                }
            </div>
            <div className='bid__details'>
                <div className='bid__details-item'>
                    <span className='bid__details-item__data'>Бюджет</span>
                    <span className='bid__details-item__value'>{maxBudget ? maxBudget + ' ' + TR('₽') : TR_('Не указано')}</span>
                </div>
                <div className='bid__details-item'>
                    <span className='bid__details-item__data'>Сроки</span>
                    <span className='bid__details-item__value'>{untilDate && untilDate.valueOf() ? TR('До') + ' ' + getDateHuman(untilDate) : TR_('Не указано')}</span>
                </div>
                {isOwn ?
                    <Fragment>
                        <div className='bid__controls mt--30'>
                            <button className='button-27f' onClick={() => handleEdit()}>{TR('Редактировать')}</button>
                            <button className='button-27e' onClick={() => handleDelete()}>{TR('Снять с публикации')}</button>
                        </div>
                        <div className='bid__date'>
                            {TR('Опубликовано')}: <time dateTime={getDateStr(auction.publicDate)}>{getDateHuman(auction.publicDate)}</time>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className='bid__controls mt--30 mb--20 pad--20'>
                            <div className='view-chat__flex'>
                                <div className='view-chat__flex-item'>
                                    <div className='view-chat__company' onClick={() => handleCompanyClick()} style={{ cursor: 'pointer' }}>
                                        <img className='view-chat__company-img' src={avatarUrl(creator.avatar)} alt='' />
                                        {creator.name}
                                    </div>
                                    {companyInfo ?
                                        <PopupCompany onClose={() => setCompanyInfo(null)} user={companyInfo} /> : null
                                    }
                                </div>
                                <div className='view-chat__flex-item'>
                                    <div className='view-chat__date'>
                                        <p className='view-chat__date-title'>{TR('Добавлено')}:</p>
                                        <time className='view-chat__date-add' dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-chat__ctrl">
                            <button className="view-chat__show" onClick={() => handleContactsClick(auction.creator.id)}>{TR_('Показать контакты')}</button>
                            <button className="view-chat__complain">{TR_('Пожаловаться')}</button>
                        </div>
                        {areContactsShown && contacts ?
                            <PopupContacts
                                contacts={contacts}
                                onClose={() => setContactsShown(false)}
                            />
                            :
                            null
                        }
                    </Fragment>
                }
            </div>
        </section>
    )
}

export default AuctionViewHeader;