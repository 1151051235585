import { useHistory } from 'react-router-dom';
import { TR } from 'service/T10N';

const Footer = () => {
    let history = useHistory();

    return (
        <footer className="footer">
            <div className="container footer--bg">
                <nav className="footer-nav">
                    <button className="logo" onClick={() => { history.push('/'); }}>
                        <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                    </button>
                    {/* <a className="footer__link" href="#">Клинки & Врачи</a>
                    <a className="footer__link" href="#">Лаборатории & Техники</a>
                    <a className="footer__link" href="#">Торговые компании</a> */}
                </nav>
                <nav className="politic-flex">
                    <div className="politic-flex__item">
                        {/* <a className="politic-flex__item-link" href='/policy'> */}
                        {/* <a className="politic-flex__item-link ptr" onClick={() => history.push('/policy')}>
                            {TR('Политика конфиденциальности')}
                        </a> */}
                        <a className="politic-flex__item-link ptr" href={TR('/docs/ru/policy.pdf', 'Политика конфиденциальности')} target='_blank'>
                            {TR('Политика конфиденциальности')}
                        </a>
                        <a className="politic-flex__item-link ptr" onClick={() => history.push('/help')}>
                            {TR('Еще ссылки')}
                        </a>
                    </div>
                    <p className="copyright">{TR('Dental Portal. Все права защищены. 2023')}</p>
                </nav>
            </div>
        </footer>
    )
}

export default Footer;