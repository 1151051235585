import AuthContext from 'AuthContext';
import AvatarSelector from 'Components/AvatarSelector';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { net, resizeImage } from 'service/API';
import { BlobCache, DentFUID } from 'service/Data/DentTypes';
import { gAvatarSize } from 'service/Globals';

const SignupDoctor = () => {
    const history = useHistory();
    const auth = useContext(AuthContext);
    const [isAvatarTooltipShown, setAvatarTooltipShown] = useState(false)
    const [avatarTooltipText, setAvatarTooltipText] = useState("")

    const [errorStr, setErrorStr] = useState('');

    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [selectedImage, setSelectedImage] = useState<DentFUID>('')
    const [scanner, setScanner] = useState('')
    const [chairs, setChairs] = useState(1)

    const [nameError, setNameError] = useState('')
    useEffect(() => {
        setNameError('')
    }, [name])

    const [cityError, setCityError] = useState('')
    useEffect(() => {
        setCityError('')
    }, [city])

    const [streetError, setStreetError] = useState('')
    useEffect(() => {
        setStreetError('')
    }, [street])

    const [avatarError, setAvatarError] = useState('')
    useEffect(() => {
        setAvatarError('')
    }, [selectedImage])

    const [scannerError, setScannerError] = useState('')
    useEffect(() => {
        setScannerError('')
    }, [scanner])

    const handleSubmit = async () => {
        console.log('Handling...')
        if (!name) {
            setNameError("Не указано название клиники")
            return
        }
        if (!city) {
            setCityError("Не указан город")
            return
        }
        if (!street) {
            setStreetError("Не указана улица")
            return
        }
        if (!selectedImage) {
            setAvatarError("Загрузите ваше фото")
            return
        }

        type Reply = {
            status: string
            url?: string
            message?: string
        }

        const reply = await net.post<Reply>('/api/v1/users/doc', JSON.stringify({
            name: name,
            city: city,
            street: street,
            avatar: selectedImage,
            scanner: scanner,
            chairs: chairs,
        }))

        if (reply.status !== 'success') {
            setErrorStr(reply.message ? reply.message : '')
            return
        }

        history.push('/')
    }

    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="/" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--808">
                    <div className="edit-container">
                        <h3 className="user-type">Клиника или Врач</h3>
                    </div>
                    <h1 className="sign-title">
                        Заполнение анкеты
                    </h1>
                    <p className="sign__sub-title">
                        Подробная информация позволит нам сделать работу портала максимально эффективной.
                    </p>
                    <div className="sign-form">
                        <div className="field-flex">
                            <div className="fiels-flex__item">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Название клиники (или ФИО)
                                    </p>
                                    <div className="input" data-tooltip-id="name-tooltip">
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <Tooltip id="name-tooltip" isOpen={!!nameError} content={nameError} />
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Город
                                    </p>
                                    <div className="input" data-tooltip-id="city-tooltip">
                                        <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                    </div>
                                    <Tooltip id="city-tooltip" isOpen={!!cityError} content={cityError} />
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Улица, дом, офис
                                    </p>
                                    <div className="input" data-tooltip-id="street-tooltip">
                                        <input type="text" value={street} onChange={(e) => setStreet(e.target.value)} />
                                    </div>
                                    <Tooltip id="street-tooltip" isOpen={!!streetError} content={streetError} />
                                    <p className="fied-set__error"></p>
                                </div>
                            </div>
                            <div className="fiels-flex__item">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Логотип или фотография
                                    </p>
                                    <AvatarSelector data-tooltip-id="avatar-tooltip" value={selectedImage} onFileSelected={async (file) => {
                                        try {
                                            console.log(file)
                                            const blob = await resizeImage(file, gAvatarSize, gAvatarSize)
                                            if (blob instanceof Blob) {
                                                //console.log("Image resized!")
                                                //console.log(blob)
                                                //const fuid = await uploadBlob('/api/v1/users/avatar', blob)
                                                setSelectedImage(new BlobCache(blob))
                                                // console.log(fuid)
                                                // setSelectedImage(fuid)
                                                // auth.setAvatar(fuid)
                                            }
                                        }
                                        catch (error) {
                                            console.log("error aru1")
                                            console.log(error)
                                        }

                                    }} />
                                    <Tooltip id="avatar-tooltip" isOpen={!!avatarError} content={avatarError} />
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set__cover">
                                    <div className="slide-flex">
                                        <input type="checkbox" className="checkbox-slide" id="chbxsld" readOnly checked={!!scanner} onClick={() => scannerError ? setScannerError('') : setScannerError("Укажите название вашего сканера")} />
                                        <label htmlFor="chbxsld"> <span></span> Интраоральный сканер</label>
                                    </div>
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            Название сканера
                                        </p>
                                        <div className="input" data-tooltip-id="scanner-tooltip">
                                            <input type="text" value={scanner} onChange={(e) => setScanner(e.target.value)} />
                                        </div>
                                        <Tooltip id="scanner-tooltip" isOpen={!!scannerError} content={scannerError} />
                                        <p className="fied-set__error"></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field-set">
                            <p className="field-set__title">
                                Количество установок
                            </p>
                            <div className="aparat-amount">
                                <input className="radio-ball" type="radio" id="less3" name="amo" readOnly checked={chairs == 1} onClick={() => setChairs(1)} />
                                <label htmlFor="less3"><span></span><div className="chair" />1-3</label>
                                <input className="radio-ball" type="radio" id="more3" name="amo" readOnly checked={chairs == 4} onClick={() => setChairs(4)} />
                                <label htmlFor="more3"><span></span><div className="chair" />3+</label>
                            </div>
                            <p className="fied-set__error">{errorStr}</p>
                        </div>
                        {/* <div className="field-set">
                            <p className="field-set__title">
                                Лицензия для клиники или Диплом для врача
                            </p>
                            <FileSelector onFileSelected={(file) => console.log(file)} />
                            <p className="fied-set__error"></p>
                        </div> */}
                        <button className="button-f h--44 w--335" onClick={() => handleSubmit()}>Завершить</button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default SignupDoctor;