import AuthContext from 'AuthContext';
import { TextInput } from 'Pages/Home/Components/MyDataDoctor';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { newPassword } from 'service/API';
import { TR_ } from 'service/T10N';
//import 'Pages/Signup/style.css'

type Params = {
    verification: string;
}

const NewPassword = () => {
    const { verification } = useParams<Params>()

    const history = useHistory()
    const [errorStr, setErrorStr] = useState("")
    const auth = useContext(AuthContext);

    const [errorText, setErrorText] = useState('')

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const handleSubmit = async () => {
        if (password1 !== password2) {
            setErrorText(TR_('Пароли должны совпадать'))
            return
        }

        if (password1.length < 6) {
            setErrorText(TR_('Пароль должен быть не менее 6 символов'))
            return
        }

        try {
            const session = await newPassword(password1, verification)
            console.log('Password was set');
            auth.signin(session)
            history.replace('/newpasswordsuccess')
            return
        } catch (e) {
            if (typeof (e) == 'number') {
                setErrorText(TR_('Ошибка сервера') + ' ' + e)
            } else {
                setErrorText(TR_('Ошибка сервера'))
            }
        }
    }

    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="/" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--in">
                    <h1 className="sign-title">
                        {TR_('Новый пароль')}
                    </h1>
                    <div className="sign-form">
                        <div className="sign-fields">
                            <TextInput
                                //reff={emailRef}
                                tooltip_id='pass1-tooltip'
                                title={TR_('Придумайте новый пароль')}
                                inputType='password'
                                value={password1}
                                setValue={setPassword1}
                            />
                            <Tooltip id='pass1-tooltip' isOpen={!!errorText} content={errorText} />
                            {/* <div className="field-set">
                                <p className="field-set__title">
                                    {TR_('Придумайте новый пароль')}
                                </p>
                                <div className="input">
                                    <input type="password" placeholder="" />
                                </div>
                                <p className="fied-set__error"></p>
                            </div> */}
                            <TextInput
                                //reff={emailRef}
                                tooltip_id='pass2-tooltip'
                                title={TR_('Повторите новый пароль')}
                                inputType='password'
                                value={password2}
                                setValue={setPassword2}
                            />
                            {/* <div className="field-set">
                                <p className="field-set__title">
                                    {TR_('Повторите новый пароль')}
                                </p>
                                <div className="input">
                                    <input type="password" placeholder="" />
                                </div>
                                <p className="fied-set__error"></p>
                            </div> */}
                        </div>
                        <button className="button-f w--100 h--44"
                            onClick={() => handleSubmit()}>
                            {TR_('Продолжить')}
                        </button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default NewPassword;