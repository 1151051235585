import AuthContext from "AuthContext";
import { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDateHuman, getDateStr } from "service/API";
import { Creator, MyOffer, maxCabinetCardItems } from "service/Data/DentTypes";
import { TR, TR_ } from "service/T10N";
import { getPriceStr } from "service/TextTools";

interface MyOffersProps {
    actual?: MyOffer[]
    archived?: MyOffer[]
}

const MyOffers: FC<MyOffersProps> = ({ actual, archived }) => {
    actual = actual ? actual : []
    archived = archived ? archived : []

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isArchive, setIsArchive] = useState(false)
    const [maxItems, setMaxItems] = useState(maxCabinetCardItems)

    async function fetchOffer(myOffer: MyOffer) {
        try {
            //const offer = await getOffer(myOffer)
            const creator: Creator = {
                id: auth.session ? auth.session.id : '',
                name: '',
                avatar: '',
            }
            const offer = { ...myOffer, creator: creator }
            history.push('/offer-view', { offer: offer })
        } catch (e) {
            console.log(e)
        }
    }

    const Item = (myOffer: MyOffer) => {
        const { id, title, dateUpto, priceNew, priceOld } = myOffer
        const [isPopupMoreShown, setPopupMoreShown] = useState(false)
        return (
            <article className="card__text-item">
                <div className="card-data-box" onClick={() => { fetchOffer(myOffer) }}>
                    <h3 className="card-item__title">{title}</h3>
                    <p className="card-item__details">
                        <span>{getPriceStr(priceNew)}</span>
                        <span className="striked">{getPriceStr(priceOld)}</span>
                        <time dateTime={getDateStr(dateUpto)}>{TR('до')} {getDateHuman(dateUpto)}</time>
                    </p>
                </div>
                {/* <div className="data-controls-box">
                    <button className="push-btn"></button>
                    <button className="edit-btn"></button>
                    <button className="delete-btn"></button>
                </div> */}
            </article>
        )
    }

    const items = isArchive ? archived : actual

    return (
        <section className="section-wrapper">
            <div className="card-header">
                <h2 className="card-title">{TR('Мои предложения')}
                    {/* <span className="card-title__counter">{actual.length}</span> */}
                </h2>
                <button className="button-27e" onClick={() => history.push('/offer-new')}>{TR('Создать предложение')}</button>
            </div>
            <div className="card-filter">
                <div className="swiper-checkbox">
                    <input type="checkbox" id="oldPromo" checked={isArchive} onChange={() => setIsArchive(!isArchive)} />
                    <label htmlFor="oldPromo">
                        <span className="actual">{TR('Актуальные')}</span>
                        <span className="not__actual">{TR('Архив')}</span>
                    </label>
                </div>
            </div>
            <div className="card-body">
                {items.slice(0, maxItems).map((item) => <Item key={item.id} {...item} />)}
                {items.length > maxCabinetCardItems && maxItems == maxCabinetCardItems ?
                    <button className="rest-items" onClick={() => setMaxItems(items.length)}>
                        <span>{TR_('Ещё') + ' ' + (items.length - maxItems)}</span>
                        <span />
                        <span>{TR('Смотреть все')}</span>
                    </button>
                    : maxItems != maxCabinetCardItems ?
                        <button className="rest-items" onClick={() => setMaxItems(maxCabinetCardItems)}>
                            <span />
                            <span>{TR_('Свернуть')}</span>
                        </button>
                        :
                        null
                }
            </div>
        </section>
    )
}

export default MyOffers;