import AuthContext from 'AuthContext';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TR_ } from 'service/T10N';

const ForgotPasswordSuccess = () => {
    const history = useHistory();
    const [errorStr, setErrorStr] = useState("")
    const auth = useContext(AuthContext);

    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="/" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--fin">
                    <img className="final-img" src="/assets/icons/final-img.svg" alt="" />
                    <div className="final-msg final-msg--pass">
                        <h2 className="final-msg__title">{TR_('Письмо отправлено!')}</h2>
                        <p className="final-msg__txt">
                            {TR_('Пожалуйста проверьте вашу почту')}
                        </p>
                        {/* <button className="button-f h--44 w--335">{TR_('Продолжить')}</button> */}
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ForgotPasswordSuccess