import { FC, useState } from 'react';
import { UserRate } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';

export interface PopupFeedbackNewProps {
    title: string
    text: string
    stars: UserRate
    isNew: boolean
    onClose: () => void
    onFeedback: (stars: UserRate, text: string) => void
}

const PopupFeedbackNew: FC<PopupFeedbackNewProps> = ({ title, stars, text, isNew, onClose, onFeedback }) => {
    const [rating, setRating] = useState<UserRate>(stars < 1 ? 1 : stars > 5 ? 5 : stars)
    const [description, setDescription] = useState(text)

    const handleSubmit = () => {
        if (description) {
            onFeedback(rating, description)
        }
    }

    return (
        <div className="cover" onMouseDown={() => onClose()}>
            <div className="pop-up popup--600" onMouseDown={(e) => e.stopPropagation()}>
                <button className="killer" onClick={() => onClose()}></button>
                <header className="popup-header">
                    <div className="feedback-title__box">
                        <h1 className="popup-title">
                            {TR('Оставить отзыв')}
                        </h1>
                    </div>
                    <p className="rate-company__name">{title}</p>
                </header>
                <div className="popup-body">
                    <div className="new-feedback">
                        <div className="field-set">
                            <p className="field-set__title">
                                {TR_('Ваш отзыв')}
                            </p>
                            <textarea className="textarea" name="some-text" id="someText" placeholder=""
                                style={{ height: "127px" }} value={description} onChange={(e) => { setDescription(e.target.value) }}></textarea>
                        </div>
                        <div className="rate-it">
                            <span className={"rate-it__item" + (rating >= 1 ? " marked" : "")} onClick={() => setRating(1)}></span>
                            <span className={"rate-it__item" + (rating >= 2 ? " marked" : "")} onClick={() => setRating(2)}></span>
                            <span className={"rate-it__item" + (rating >= 3 ? " marked" : "")} onClick={() => setRating(3)}></span>
                            <span className={"rate-it__item" + (rating >= 4 ? " marked" : "")} onClick={() => setRating(4)}></span>
                            <span className={"rate-it__item" + (rating >= 5 ? " marked" : "")} onClick={() => setRating(5)}></span>
                        </div>
                        <button
                            type="submit"
                            className="button-f"
                            onClick={() => handleSubmit()}>
                            {isNew ? TR('Оставить отзыв') : TR_('Обновить отзыв')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupFeedbackNew;