import AuthContext from 'AuthContext';
import FileUploader from 'Components/FileUploader';
import PhotoSelector from 'Components/PhotoSelector';
import { MAX_DESCR_LENGTH, MAX_NAME_LENGTH, useInputFocus, useTextFocus } from 'Pages/Home/Components/MyDataDoctor';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { createOrUpdateSale } from 'service/API';
import { DentFUID, DentFile, Sale, SaleRequest } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';

interface LocationState {
    sale?: Sale
}

const SaleNew = () => {
    const location = useLocation<LocationState>(); // '/sale-new'
    const sale: Sale | undefined = location.state?.sale

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isDescriptionShown, setDescriptionShown] = useState(false)

    const [titleRef, setTitleFocus] = useInputFocus()
    const [descriptionRef, setDescriptionFocus] = useTextFocus()

    const [errorShown, setErrorShown] = useState(false);

    // Scroll to top once on page load
    const [scrollToTop] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToTop])

    const [title, setTitle] = useState(sale ? sale.title : '')
    const [price, setPrice] = useState(sale?.price ? sale.price : '')
    const [city, setCity] = useState(sale?.city ? sale.city : auth.session ? auth.session.city : '')
    const [street, setStreet] = useState(sale?.street ? sale.street : auth.session ? auth.session.street : '')
    const [description, setDescription] = useState(sale ? sale.description : '')
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(sale?.photos.length ? sale.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(sale?.photos ? sale.photos : [])
    const [files, setFiles] = useState<DentFile[]>(sale?.files ? sale.files : [])

    function validateForm(): boolean {
        if (!title) {
            setErrorShown(true)
            setTitleFocus()
            return false
        }

        if (!description) {
            setErrorShown(true)
            setDescriptionFocus()
            return false
        }

        return true
    }

    const publish = async () => {
        if (!validateForm()) {
            console.log('Validation error...')
            return
        }

        var request: SaleRequest = {
            saleId: sale?.id,
            title: title,
            city: city,
            street: street,
            description: description,
            price: price ? +price : 0,
            photos: photos,
            files: files
        }

        console.log(request);

        const reply = await createOrUpdateSale(request)

        if (reply.status === 'success') {
            // Update success
            console.log('Sale was created');
            history.push('/dash')
        }
        else {
            // TODO: show error to user somehow
            console.log('Sale creation error');
        }
    }

    const saleTitle = sale ? TR_('Изменить объявление') : TR('Создать объявление')

    return (
        <main className='main'>
            <div className='container'>
                <div className='add-cover'>
                    <h1 className='add-title'>{saleTitle}</h1>
                    <div className='add'>
                        <div className='add__form'>
                            <header className='add__header'>
                                <button className='add__header-btn' onClick={history.goBack}></button>
                                <h2 className='add__header-title'>{saleTitle}</h2>
                            </header>
                            <div className='add__main'>
                                <button className='how' onClick={() => setDescriptionShown(true)}>
                                    {TR('Как создать новое объявление')}
                                </button>
                                <form className='new-add-form'>
                                    <div className='field-flex'>
                                        <div className='fiels-flex__item'>
                                            <div className='field-set'>
                                                <p className='field-set__title'>
                                                    {TR('Название')}
                                                </p>
                                                <div className='input' data-tooltip-id="title-tooltip">
                                                    {/* <div className='input class--error'> */}
                                                    <input ref={titleRef} maxLength={MAX_NAME_LENGTH} type='text' placeholder='' value={title} onChange={(e) => setTitle(e.target.value)} />
                                                </div>
                                                {/* <p className='fied-set__error'>Error message</p> */}
                                                <Tooltip id="title-tooltip" isOpen={errorShown && !title} content={TR_("Заголовок не может быть пустым")} />
                                            </div>
                                        </div>
                                        <div className='fiels-flex__item flex--small'>
                                            <div className='field-set'>
                                                <p className='field-set__title'>
                                                    {TR('Цена, ₽')}
                                                </p>
                                                <div className='input'>
                                                    <input type='number' placeholder='' value={price} onChange={(e) => setPrice(e.target.value)} />
                                                </div>
                                                <p className='fied-set__error'></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='field-flex'>
                                        <div className='fiels-flex__item'>
                                            <div className='field-set'>
                                                <p className='field-set__title'>
                                                    {TR('Город')}
                                                </p>
                                                <div className='input'>
                                                    <input type='text' placeholder='' value={city} onChange={(e) => setCity(e.target.value)} />
                                                </div>
                                                <p className='fied-set__error'></p>
                                            </div>
                                        </div>
                                        <div className='fiels-flex__item'>
                                            <div className='field-set'>
                                                <p className='field-set__title'>
                                                    {TR_('Улица')}
                                                </p>
                                                <div className='input'>
                                                    <input type='text' placeholder='' value={street} onChange={(e) => setStreet(e.target.value)} />
                                                </div>
                                                <p className='fied-set__error'></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='field-set'>
                                        <p className='field-set__title'>
                                            {TR('Описание')}
                                        </p>
                                        <div className='textarea h--90' data-tooltip-id="description-tooltip">
                                            <textarea ref={descriptionRef} maxLength={MAX_DESCR_LENGTH} name='some-text' id='someText' placeholder={TR_('Опишите детально Ваше предложение')} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                        </div>
                                        <p className='fied-set__error'></p>
                                        <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} />
                                    </div>
                                    <PhotoSelector
                                        photos={photos}
                                        setPhotos={setPhotos}
                                        starred={starredPhoto}
                                        setStarred={setStarredPhoto}
                                    />
                                    <FileUploader files={files} setFiles={setFiles} />
                                </form>
                            </div>
                            <div className='add__footer'>
                                <button className='main-button is--blue  too--wide' onClick={() => publish()}>{TR('Опубликовать')}</button>
                            </div>
                        </div>
                        <div className={'add__description' + (isDescriptionShown ? '' : ' hide_me')}>
                            <header className='add__header'>
                                <button className='add__header-btn' onClick={() => setDescriptionShown(false)} />
                                <h2 className='add__header-title'>{TR('Как создать успешное объявление')}</h2>
                            </header>
                            <div className='add__main'>
                                <ol className='gide-list'>
                                    <li className='gide__item'>
                                        {TR('Название Вашего объявления должно быть кратким и понятным для участников портал.')}
                                    </li>
                                    <li className='gide__item'>
                                        {TR('Обязательно указывайте стоимость товара.')}
                                    </li>
                                    <li className='gide__item'>
                                        {TR('В описательной части дайте полную информацию о товаре, его состоянии и сроке службы.')}
                                    </li>
                                    <li className='gide__item'>
                                        {TR('Наличие фото делает Ваше объявление более привлекательным, а также позволяет Заказчику удостовериться в том, что именно этот товар его интересует. Просьба не загружать фотографии не имеющие отношение к продаваемому образцу.')}
                                    </li>
                                </ol>
                            </div>
                            <div className='add__footer'>
                                <button className='main-button is--blue  too--wide'>{TR('Закрыть')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SaleNew;