import 'react-tooltip/dist/react-tooltip.css'
import './styles/style.css'

import { useContext } from 'react'
import ReactDOM from 'react-dom'
//import ReactDOM from 'react-dom'
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom'

import Footer from 'Components/Footer'
import AuctionNew from 'Pages/Auctions/AuctionNew'
import AuctionNewFeedback from 'Pages/Auctions/AuctionNewFeedback'
import AuctionViewFeedback from 'Pages/Auctions/AuctionViewFeedback'
import AuctionViewFeedbacks from 'Pages/Auctions/AuctionViewFeedbacks'
import Auctions from 'Pages/Auctions/Auctions'
import Chat from 'Pages/Chat'
import Dashboard from 'Pages/Dash/Dashboard'
import HelpPage from 'Pages/HelpPage'
import MyData from 'Pages/Home/Components/MyData'
import Home from 'Pages/Home/Home'
import HomeDoctor from 'Pages/Home/HomeDoctor'
import HomeTechnician from 'Pages/Home/HomeTechnician'
import HomeTrader from 'Pages/Home/HomeTrader'
import PrivacyPolicy from 'Pages/Home/PrivacyPolicy'
import ListNotifications from 'Pages/ListNotifications'
import NotificationNew from 'Pages/NotificationNew'
import OfferNew from 'Pages/Offers/OfferNew'
import OfferView from 'Pages/Offers/OfferView'
import Offers from 'Pages/Offers/Offers'
import PopupChecklist from 'Pages/Popups/PopupChecklist'
import PopupCity from 'Pages/Popups/PopupCity'
import PopupContacts from 'Pages/Popups/PopupContacts'
import PopupContract from 'Pages/Popups/PopupContract'
import PopupFeedback from 'Pages/Popups/PopupFeedback'
import PopupFeedbackNew from 'Pages/Popups/PopupFeedbackNew'
import PopupMore from 'Pages/Popups/PopupMore'
import PopupMyFotos from 'Pages/Popups/PopupMyData/MyFotos'
import PopupMyData from 'Pages/Popups/PopupMyData/PopupMyData'
import SaleNew from 'Pages/Sales/SaleNew'
import SaleView from 'Pages/Sales/SaleView'
import Sales from 'Pages/Sales/Sales'
import ForgotPassword from 'Pages/Signup/ForgotPassword'
import ForgotPasswordSuccess from 'Pages/Signup/ForgotPasswordSuccess'
import NewPassword from 'Pages/Signup/NewPassword'
import NewPasswordSuccess from 'Pages/Signup/NewPasswordSuccess'
import SignupDoctor from 'Pages/Signup/SignupDoctor'
import SignupTechnician from 'Pages/Signup/SignupTechnician'
import SignupThanks from 'Pages/Signup/SignupThanks'
import SignupTrader from 'Pages/Signup/SignupTrader'
import ListDoctors from 'Pages/Users/ListDoctors'
import ListTechnicians from 'Pages/Users/ListTechnicians'
import ListTraders from 'Pages/Users/ListTraders'
import UserViewReviews from 'Pages/Users/UserViewReviews'
import Vacancies from 'Pages/Vacancies/Vacancies'
import VacancyNew from 'Pages/Vacancies/VacancyNew'
import VacancyNewFeedback from 'Pages/Vacancies/VacancyNewFeedback'
import VacancyViewFeedback from 'Pages/Vacancies/VacancyViewFeedback'
import VacancyViewFeedbacks from 'Pages/Vacancies/VacancyViewFeedbacks'
import { Doctor } from 'service/Data/DentTypes'
import AuthContext, { AuthContextProvider } from './AuthContext'
import Header from './Components/Header'
import Signin from './Pages/Signup/Signin'
import Signup from './Pages/Signup/Signup'
import SignupCheck from './Pages/Signup/SignupCheck'
import Welcome from './Pages/Welcome'


//import HeaderRegistration from './Components/HeaderRegistration';
//import Cabinet from './Pages/Cabinet/Cabinet';

const App = () => {
    const check = false
    const auth = useContext(AuthContext);
    let history = useHistory();

    const doctor: Doctor = {
        doctor: {
            chairs: 0,
            scanner: '',
            licenses: []
        },
        createdAt: 0,
        city: '',
        street: '',
        description: '',
        id: '',
        role: 'doctor',
        name: '',
        avatar: '',
        photos: [],
        contacts: {
            email: 'test@email.com',
            phone: '',
            hasTelegram: false,
            hasWhatsapp: false,
            site: '',
            youtube: '',
        },
        rating: {
            rateSum: 0,
            rateCount: 0
        },
    }

    const TestLink = (path: string) => {
        window.location.href = path
        return null
    }

    return (
        < Switch >
            <Route exact path='/welcome' >
                <Header showFullLogo={true} />
                < Welcome />
                <Footer />
            </Route>

            < Route exact path='/home-doctor' >
                <Header />
                < HomeDoctor />
                <Footer />
            </Route>

            < Route exact path='/home-tech' >
                <Header />
                < HomeTechnician />
                <Footer />
            </Route>

            < Route exact path='/home-trader' >
                <Header />
                < HomeTrader />
                <Footer />
            </Route>

            < Route exact path='/doctors' >
                <Header />
                < ListDoctors />
                <Footer />
            </Route>

            < Route exact path='/technicians' >
                <Header />
                < ListTechnicians />
                <Footer />
            </Route>

            < Route exact path='/traders' >
                <Header />
                < ListTraders />
                <Footer />
            </Route>

            < Route exact path='/user-view-reviews' >
                <Header />
                < UserViewReviews />
                <Footer />
            </Route>

            < Route exact path='/sales' >
                <Header />
                < Sales />
                <Footer />
            </Route>

            < Route exact path='/sale-new' >
                <Header />
                < SaleNew />
                <Footer />
            </Route>

            < Route exact path='/sale-view' >
                <Header />
                < SaleView />
                <Footer />
            </Route>

            < Route exact path='/offers' >
                <Header />
                < Offers />
                <Footer />
            </Route>

            < Route exact path='/offer-new' >
                <Header />
                < OfferNew />
                <Footer />
            </Route>

            < Route exact path='/offer-view' >
                <Header />
                < OfferView />
                <Footer />
            </Route>

            < Route exact path='/auctions' >
                <Header />
                < Auctions />
                <Footer />
            </Route>

            < Route exact path='/auction-new' >
                <Header />
                < AuctionNew />
                <Footer />
            </Route>

            < Route exact path='/notification-new' >
                <Header />
                < NotificationNew />
                <Footer />
            </Route>

            < Route exact path='/list-notifications' >
                <Header />
                < ListNotifications />
                <Footer />
            </Route>

            < Route exact path='/auction-view-feedbacks' >
                <Header />
                < AuctionViewFeedbacks />
                <Footer />
            </Route>

            < Route exact path='/auction-view-feedback' >
                <Header />
                < AuctionViewFeedback />
                <Footer />
            </Route>

            < Route exact path='/auction-new-feedback' >
                <Header />
                < AuctionNewFeedback />
                <Footer />
            </Route>

            < Route exact path='/vacancies' >
                <Header />
                < Vacancies />
                <Footer />
            </Route>

            < Route exact path='/vacancy-new' >
                <Header />
                < VacancyNew />
                <Footer />
            </Route>


            < Route exact path='/vacancy-view-feedback' >
                <Header />
                < VacancyViewFeedback />
                <Footer />
            </Route>

            < Route exact path='/vacancy-view-feedbacks' >
                <Header />
                < VacancyViewFeedbacks />
                <Footer />
            </Route>

            < Route exact path='/vacancy-new-feedback' >
                <Header />
                < VacancyNewFeedback />
                <Footer />
            </Route>

            < Route component={Home} exact path='/home' />
            <Route component={Signin} exact path='/signin' />
            <Route component={Signup} exact path='/signup' />
            <Route component={SignupCheck} path='/verifyemail/:verification' />
            <Route component={ForgotPassword} exact path='/forgotpassword' />
            <Route component={NewPassword} exact path='/passcheck/:verification' />
            <Route component={NewPasswordSuccess} exact path='/newpasswordsuccess' />
            <Route component={ForgotPasswordSuccess} exact path='/forgotpasswordsuccess' />

            <Route exact path='/dash' >
                <Dashboard />
            </Route>

            <Route exact path='/signup/doctor' >
                <SignupDoctor />
            </Route>

            < Route exact path='/signup/technician' >
                <SignupTechnician />
            </Route>

            < Route exact path='/signup/trader' >
                <SignupTrader />
            </Route>

            < Route exact path='/popup-thanks' >
                <SignupThanks title='Спасибо за регистрацию!' buttonText='Прочитал внимательно, жду!' verifyUrl='#'>
                    Нам потребуется некоторое время, чтобы проверить указанную Вами информацию.Вы получите сообщение о
                    результатах проверки на почту < b > name@mail.ru.</b> После прохождения проверки Вам будут доступны все
                    возможности платформы.
                </SignupThanks>
            </Route>

            < Route exact path='/popup-more' >
                <PopupMore onClose={() => { }} />
                < Header />
                <Footer />
            </Route>

            < Route exact path='/popup-mydata' >
                <PopupMyData />
                < Header />
                <Footer />
            </Route>

            < Route exact path='/me' >
                <Header />
                < MyData user={undefined} />
                <Footer />
            </Route>

            < Route exact path='/popup-myfotos' >
                <Header />
                < PopupMyFotos />
                <Footer />
            </Route>

            < Route exact path='/popup-contract' >
                <Header />
                < PopupContract />
                <Footer />
            </Route>

            < Route exact path='/popup-feedback' >
                <Header />
                < PopupFeedback
                    cardId={''}
                    rate={0}
                    rateCount={0}
                    title={''}
                    onClose={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onNewFeedback={function (): void {
                        throw new Error('Function not implemented.')
                    }} />
                <Footer />
            </Route>

            < Route exact path='/popup-feedback-new' >
                <Header />
                < PopupFeedbackNew
                    title={''}
                    onClose={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onFeedback={function (stars: number, text: string): void {
                        throw new Error('Function not implemented.')
                    }} text={''} stars={1} isNew={false} />
                <Footer />
            </Route>

            < Route exact path='/popup-contacts' >
                <Header />
                < PopupContacts
                    contacts={doctor.contacts}
                    onClose={function (): void {
                        throw new Error('Function not implemented.')
                    }} />
                <Footer />
            </Route>

            < Route exact path='/popup-city' >
                <Header />
                < PopupCity />
                <Footer />
            </Route>

            < Route exact path='/chat' >
                <Header />
                < Chat />
                <Footer />
            </Route>

            < Route exact path='/help' >
                <Header />
                < HelpPage />
                <Footer />
            </Route>

            < Route exact path='/policy' >
                <Header />
                < PrivacyPolicy />
                <Footer />
            </Route>

            < Route exact path='/popup-checklist' >
                <Header />
                < PopupChecklist />
                <Footer />
            </Route>

            {/* Dont move from bottom, some Route recursive error appears */}
            <Route exact path='/' >
                {
                    //auth.loggedIn ? <Home /> : <Redirect to='/welcome' />
                    auth.isLoggedIn() ? <Home /> : <Redirect to='/welcome' />
                }
            </ Route >

            <Route path='*' >
                <Redirect to='/' />
            </Route>
        </Switch >
    )
}


ReactDOM.render(
    <div className='body-container' >
        {/* <AuthContextProvider> moved inside Router to be able to use history.push */}
        <Router>
            <AuthContextProvider>
                <App />
            </AuthContextProvider>
        </Router>
        {/* </AuthContextProvider> */}
    </div>
    , document.getElementById('app'))
