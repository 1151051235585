import AuthContext from "AuthContext";
import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { deleteVacancyFeedback } from "service/API";
import { Vacancy, VacancyFeedback } from "service/Data/DentTypes";
import VacancyFeedbackCard from "./Components/VacancyFeedbackCard";
import VacancyViewHeader from "./Components/VacancyViewHeader";

interface LocationState {
    vacancy: Vacancy
    feedback: VacancyFeedback
}

const VacancyViewFeedback = () => {
    const history = useHistory();
    const auth = useContext(AuthContext)

    const location = useLocation<LocationState>(); '/vacancy-view-feedback'
    const vacancy = location.state.vacancy
    const feedback = location.state.feedback // look VacancyFeedback

    if (!vacancy || !feedback || !auth.session) {
        console.log('No state in VacancyViewResponds')
        return null
    }

    const isOwnFeedback = auth.session.id === feedback.creator.id
    const isOwnVacancy = auth.session.id === vacancy.creator.id

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const handleFeedbackDelete = async (feedbackId: number) => {
        if (await deleteVacancyFeedback(feedbackId)) {
            history.push('/vacancies')
            return
        }

        // TODO: show error to user somehow
        console.log("Feedback deleting error");
    }

    const handleFeedbackEdit = async (feedback: VacancyFeedback) => {
        history.push('/vacancy-new-feedback', {
            vacancy: vacancy,
            feedback: feedback
        })
    }

    return (
        <main className="main">
            <div className="container">
                <VacancyViewHeader vacancy={vacancy} isOwn={isOwnVacancy} />
                <section className="reply-box">
                    <h2 className="reply__title mb--20">Ваш отклик </h2>
                    <VacancyFeedbackCard
                        feedback={feedback}
                        isNew={false}
                        isOwn={isOwnFeedback}
                        onDelete={async () => {
                            handleFeedbackDelete(feedback.id)
                        }}
                        onEdit={() => {
                            handleFeedbackEdit(feedback)
                        }}
                    />
                </section>
            </div>
        </main>
    )
}

export default VacancyViewFeedback;