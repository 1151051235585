import { FC, Fragment, useState } from 'react'
import { TR } from 'service/T10N'

export interface SubsetCityItem { // TODO: rename to just SubsetItem
    text: string,
    tag: string
}

function contains(items: SubsetCityItem[], item: SubsetCityItem) {
    for (var i of items) {
        if (i.text === item.text)
            return true
    }
}

interface CitiesPopupProps {
    title: string
    cities: SubsetCityItem[]
    selectedCities: SubsetCityItem[]
    onCity: (v: SubsetCityItem) => void
    onClose: () => void
}

const CitiesPopup: FC<CitiesPopupProps> = ({ title, cities = [], selectedCities, onCity, onClose }) => {
    const [search, setSearch] = useState('')

    interface ItemProps {
        city: SubsetCityItem
    }

    const Item: FC<ItemProps> = ({ city }) => {
        return (<button
            key={city.text}
            className="city__item"
            onClick={() => { onCity(city) }}>
            {city.text}
        </button>)
    }

    const Item2: FC<ItemProps> = ({ city }) => {
        return (
            <div className="check-list__item">
                <input
                    type="checkbox"
                    className="checkbox-e"
                    id={"chbx-" + city.text}
                    checked={contains(selectedCities, city)}
                    // onClick={(e) => {
                    //     console.log('On click')

                    //     onCity(city)
                    // }}
                    onChange={(e) => {
                        console.log('On change')
                        console.log('Checkbox value:')
                        console.log(e.target.checked)
                        if (e.target.value)
                            onCity(city)
                    }}
                //defaultChecked={false}
                />
                <label htmlFor={"chbx-" + city.text}>{city.text}</label>
            </div>)
    }

    return (
        <Fragment>
            <div className="sideup is--visible">
                {/* <button className='back-filters' onClick={() => onClose()}>{title}</button> */}
                <header className="sideup-header">
                    <button className="killer" onClick={() => onClose()}></button>
                    <h1 className="sideup-title">
                        {title}
                    </h1>
                </header>
                <div className="sideup-body">
                    <div className="side-form__flex">
                        <div className="side-search">
                            <div className="input-search">
                                <input type="text" placeholder={TR('Поиск')} value={search} onChange={(e) => setSearch(e.target.value)} />
                                <button className="loop"></button>
                            </div>
                        </div>
                        {/* <div className="city-list"> */}
                        <div className="check-list">
                            {(search ?
                                cities.filter((city) => city.text.toLowerCase().includes(search.toLowerCase()))
                                :
                                cities)
                                .map((city) => <Item2 key={city.text} city={city} />)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="sideup-cover" onMouseDown={() => onClose()}></div>
        </Fragment>
    )
}

// const subsetOptions = {
//     from: [
//         "Москва",
//         "Санкт-петербург",
//         "Новосибирск",
//         "Нижний новгород",
//         "Краснодар",
//         "Ижевск",
//         "Подольск",
//         "Нижнекамск",
//     ],
//     value: subsetValue,
//     setValue: setSubsetValue,
//     nonEmptyTitle: "Выбранные города",
//     whenEmptyTitle: "По всем городам",
//     popupTitle: "Город",
// }

interface SubsetFilter2Props {
    from: SubsetCityItem[]
    value: SubsetCityItem[]
    setValue: (sv: (v: SubsetCityItem[]) => SubsetCityItem[]) => void
    nonEmptyTitle: string
    whenEmptyTitle: string
    popupTitle: string
    setResetFunc: (f: () => void) => void
}

const SubsetFilter2: FC<SubsetFilter2Props> = ({ from, value, setValue, nonEmptyTitle, whenEmptyTitle, popupTitle, setResetFunc = (func) => { } }) => {
    const [isCitiesShown, setCitiesShown] = useState(false)

    const reset = () => {
        setValue((prevState: SubsetCityItem[]) => { return [] })
    }

    setResetFunc(reset)

    const toggleCity = (city: SubsetCityItem) => {
        setValue((prevState: SubsetCityItem[]) => {
            return contains(prevState, city) ?
                prevState.filter((c) => c.text !== city.text) // Filtering out city
                :
                [...prevState, city] // Adding city
        })
    }

    const removeCity = (removeCity: SubsetCityItem) => {
        setValue((prevState) => {
            return prevState.filter((city) => city.text !== removeCity.text)
        })
    }

    return (
        <Fragment>
            <li className="filter__list-item">
                <button className="filter-item" onClick={() => setCitiesShown(true)}>
                    <span className="filter-item__name">{value.length ? nonEmptyTitle : whenEmptyTitle}</span>
                    <span className="filter-item__mark"></span>
                </button>
                {isCitiesShown ?
                    <CitiesPopup
                        title={popupTitle}
                        cities={from}
                        selectedCities={value}
                        onClose={() => setCitiesShown(false)}
                        onCity={(city) => toggleCity(city)} />
                    :
                    null
                }
            </li>
            {value.length ?
                <li className="filter__list-item">
                    <div className="filter-selected">
                        {
                            value.map((city) =>
                                <button key={city.text}
                                    className="filter-selected__item"
                                    onClick={() => removeCity(city)}>
                                    {city.text}
                                </button>)
                        }
                    </div>
                </li>
                :
                null
            }
        </Fragment>
    )
}

export default SubsetFilter2