export const maxCabinetCardItems = 3

interface IBlobCache {
    blob: Blob
    blobUrl?: string
}

export class BlobCache {
    blob: Blob;
    blobUrl: string;

    constructor(private b: Blob) {
        this.blob = b
        this.blobUrl = URL.createObjectURL(b)
    }
}

export const DEFAULT_IMAGE = '/assets/images/upload-im.svg'

export interface DentFile {
    name: string
    fuid: DentFUID
}

export type UserID = string
export type UserRate = 1 | 2 | 3 | 4 | 5
export type DentURL = string
export type UnixTime = number // seconds from 00:00 1 Jan 1970 UTC

// Dent File Unique Identifier (xxxxxxxxxxxxxx.ext)
// FUID is filename part of URL
export type DentFUID = string | BlobCache

export type PhotoUrl = string
export type FilesInfo = DentFile[]

export interface Contacts {
    email: string
    phone: string
    hasTelegram: boolean
    hasWhatsapp: boolean
    site: string
    youtube: string
}

export interface Creator {
    id: UserID
    name: string
    avatar: DentFUID
}

export interface Company {
    id: UserID
    createdAt: UnixTime
    role: Role
    name: string
    avatar: DentFUID
    photos: DentFUID[]
    counterpartyCard?: DentFile // Only for trading companies
    contacts: Contacts
}

export interface Rating {
    rateSum: number
    rateCount: number
}

export interface Participant extends Company {
    city: string
    street: string
    description: string
    rating?: Rating
}

export type Role = 'doctor' | 'technician' | 'trader'

export interface Doctor extends Participant {
    doctor: {
        chairs: number
        scanner: string // Scanner name | ''
        licenses: DentFile[] // Private
    }
}

export interface Technician extends Participant {
    technician: {
        cadcam: string // Tech name | ''
        pricelist: DentURL
        licenses: DentFile[] // Private
    }
}

export type TraderSpecTags = string

export interface Trader extends Participant {
    trader: {
        goods: number
        catalog: DentURL
        tags: TraderSpecTags
        // counterpartyCard: DentFile // Moved to 'Company'
    }
}

export type User = Doctor | Technician | Trader;

export function isDoctor(user: User): user is Doctor {
    return 'doctor' in user
}

export function isTechnician(user: User): user is Technician {
    return 'technician' in user
}

export function isTrader(user: User): user is Trader {
    return 'trader' in user
}

// export interface Review {
//     id: number
//     name: string
//     date: Date
//     stars: number
//     //title: string
//     description: string
// }


export type BooleanTarget = 0 | 1 | 2 // 0 - all, 1 - don't has something, 2 - has something

export function boolToTarget(value?: boolean): BooleanTarget {
    if (value == undefined) {
        return 0
    }

    return value ? 2 : 1
}

export type AuctionTargetRole = '' | 'technician' | 'trader'

export interface AuctionCommon {
    id: number
    targetRole: AuctionTargetRole
    title: string
    description: string
    maxBudget?: number
    untilDate?: UnixTime
    publicDate: UnixTime
    photos?: DentFUID[]
    files?: FilesInfo
    targetCadcam: BooleanTarget
}

export interface Auction extends AuctionCommon {
    creator: Creator
}

export interface MyAuction extends AuctionCommon {
    oldFeedbacks: number
    newFeedbacks: number
    lastSeenFeedback: UnixTime
}

export interface AuctionRequest {
    auctionId?: number
    targetRole: AuctionTargetRole
    targetCadcam: BooleanTarget
    title: string
    description: string
    maxBudget?: number
    untilDate?: UnixTime // unix timestamp
    photos: DentFUID[]
    files?: FilesInfo
}

// export interface AuctionFeedback {
//     id: number
//     creator: Company
//     publicDate: UnixTime
//     untilDate: UnixTime
//     description: string
//     maxBudget: number
// }

export interface AuctionFeedbacks {
    feedbacks: AuctionFeedback[]
    lastSeenFeedback: UnixTime
}

// export interface OfferResponds {
//     responds: OfferRespond[]
//     lastSeenRespond: Date
// }

// export interface OfferRespond {
//     id: number
//     company: Company
//     publicDate: Date
//     untilDate: Date
//     description: string
//     maxBudget: number
// }

export interface ThumbedImage {
    image: PhotoUrl
    thumb: PhotoUrl
}

type ThumbedImages = ThumbedImage[]

export type OfferTargetRole = '' | 'technician' | 'doctor'

export interface OfferRequest {
    offerId?: number
    title: string
    city: string
    street: string
    photos: DentFUID[]
    description: string
    dateFrom?: UnixTime
    dateUpto?: UnixTime
    files?: FilesInfo

    priceNew: number
    priceOld: number

    targetRole: OfferTargetRole // '' | 'technician' | 'doctor'
    targetCadcam: BooleanTarget
    targetScanner: BooleanTarget
}

export interface Offer extends MyOffer {
    creator: Creator
}

export interface MyOffer {
    id: number
    publicDate: UnixTime
    updatedAt?: UnixTime

    title: string
    city: string
    street: string
    photos: DentFUID[]
    description: string
    //untilDate: Date

    dateFrom?: UnixTime
    dateUpto?: UnixTime
    files?: FilesInfo

    targetRole: OfferTargetRole
    targetCadcam: BooleanTarget
    targetScanner: BooleanTarget

    priceNew: number
    priceOld: number
}

//export type SaleTargetRole = '' | 'technician' | 'doctor'

export interface SaleRequest {
    saleId?: number,
    title: string
    city: string
    street: string
    description: string
    price: number
    photos: DentFUID[]
    files?: FilesInfo
}

export interface Sale extends MySale {
    creator: Creator
}

export interface MySale {
    id: number
    title: string
    city: string
    street: string
    photos: DentFUID[]
    description: string
    //untilDate: Date
    updatedAt: UnixTime
    publicDate: UnixTime
    files?: FilesInfo

    price: number
}

export type VacancyTargetRole = '' | 'doctor' | 'technician'

export interface VacancyCommon {
    id: number
    title: string
    city: string
    //street: string
    // starredImage: string
    // images: ThumbedImages
    description: string
    //untilDate: Date
    publicDate: Date
    photos: DentFUID[]
    files?: FilesInfo

    targetRole: VacancyTargetRole

    salaryFrom: number
    salaryUpto: number
}

export interface Vacancy extends VacancyCommon {
    creator: Creator
}

export interface MyVacancy extends VacancyCommon {
    oldFeedbacks: number
    newFeedbacks: number
    lastSeenFeedback: UnixTime
}

export interface AuctionFeedback {
    id: number

    publicDate: UnixTime
    updateDate: UnixTime

    creator: Creator

    maxBudget?: number
    untilDate?: UnixTime
    description: string
    photos?: DentFUID[]
    files?: FilesInfo
}

export interface MyAuctionFeedback {
    auction: Auction
    feedback: AuctionFeedback
}

export interface VacancyFeedback {
    id: number

    publicDate: UnixTime
    updateDate: UnixTime

    creator: Creator

    description: string
    photos?: DentFUID[]
    files?: FilesInfo
}

export interface UserReviewRequest {
    reviewId?: number
    forWhom: UserID
    rate: UserRate
    description: string
}

export interface UserReviewReply extends UserReview {
    rating?: Rating
}

export interface UserReview {
    id: number

    publicDate: UnixTime
    updateDate: UnixTime

    creator: Creator

    rate: UserRate
    description: string
}

export interface MyVacancyFeedback {
    vacancy: Vacancy
    feedback: VacancyFeedback
}

export interface AuctionFeedbacks {
    feedbacks: AuctionFeedback[]
    lastSeenFeedback: UnixTime
}

export interface VacancyFeedbacks {
    feedbacks: VacancyFeedback[]
    lastSeenFeedback: UnixTime
}

export interface UserReviews extends Rating {
    reviews: UserReview[]
    myReview?: UserReview
    lastSeenReview: UnixTime
}

export interface VacancyRequest {
    vacancyId?: number
    targetRole: VacancyTargetRole
    title: string
    city: string
    description: string
    salaryFrom: number
    salaryUpto: number
    photos: DentFUID[]
    files?: FilesInfo
}

export interface AuctionFeedbackRequest {
    // TODO: <- correct fields
    feedbackId?: number

    maxBudget?: number
    untilDate?: UnixTime
    description: string
    photos?: DentFUID[]
    files?: FilesInfo
}

export interface VacancyFeedbackRequest {
    feedbackId?: number
    description: string
    photos?: DentFUID[]
    files?: FilesInfo
}

export interface DentNotification {
    id?: number
    title?: string
    description?: string
    publicDate?: UnixTime
    photos?: DentFUID[]
    files?: FilesInfo
}

export interface DentNotifications {
    notifications: DentNotification[]
    lastSeenTime: UnixTime
}

export type NotificationRequest = DentNotification