import { useEffect } from 'react';
import { TR, TR_ } from 'service/T10N';

const Chat = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const Persona = ({ name, avatarSrc, lastMessage, lastMessageTime, unreadMessagesCount }) => {
        return (
            <div className="persona">
                <div className="persona__ava">
                    <img className="persona__ava-img" src={avatarSrc} alt="dental portal" />
                </div>
                <div className="persona__details">
                    <div className="persona__details-main">
                        <p className="persona__name">{name}</p>
                        <p className="persona__sub">{lastMessage}</p>
                    </div>
                    <div className="persona__details-info">
                        <span className="persona__time">{lastMessageTime}</span>
                        {unreadMessagesCount ?
                            <span className="persona__indicator">{unreadMessagesCount}</span> : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    const ChatMessage = ({ time, isMine = false, children }) => {
        // TODO: correct dateTime in <time> tag
        return (
            <div className={"message__item" + (isMine ? " mine" : "")}>
                <div className="message">
                    <p className="message__txt">{children}</p>
                    <time className="message__time" dateTime="2021-10-12">{time}</time>
                </div>
            </div>)
    }

    return (
        // <div className="body-container">
        <main className="main">
            <div className="container">
                <section className="chat-container">
                    <aside className="chat-sidebar">
                        <div className="chat-sidebar__inner">
                            <h2 className="chat-title">{TR('Чаты')}</h2>
                            <div className="search-chat">
                                <div className="input-search">
                                    <input type="text" placeholder={TR('Поиск')} />
                                    <button className="loop"></button>
                                </div>
                            </div>
                        </div>
                        <div className="chat-items">
                            <Persona
                                name={TR('Торговая компания')}
                                avatarSrc="/assets/temp/avatars/33x33/1.png"
                                lastMessage={TR('Здравствуйте.')}
                                lastMessageTime=""
                                unreadMessagesCount=""
                            />
                        </div>
                    </aside>
                    <div className="chat-body is--visible">
                        <div className="chat-body__header">
                            <div className="whos-chatting">
                                <div className="whos__logo__box">
                                    <img src="/assets/temp/avatars/33x33/2.png" alt="" className="whos__img" />
                                </div>
                                <div className="whos__data">
                                    <p className="whos__name">DentalPortal</p>
                                    <p className="was__here">{TR('Был в сети сегодня')}</p>
                                </div>
                            </div>
                            <button className="no-button">{TR('Заблокировать')}</button>
                        </div>
                        <div className="chat-body__body">
                            <div className="chat-day">
                                <h6 className="chat-day__date"><time dateTime="2023-04-24">{TR_('15 августа 2023')}</time></h6>
                                <ChatMessage time="07:41" isMine>{TR('Здравствуйте!')}</ChatMessage>
                                <ChatMessage time="07:42">{TR('Здравствуйте! Чат в настоящий момент не работает. Проводится тестироватие.')}</ChatMessage>
                            </div>
                        </div>
                        <div className="chat-body__message">
                            <input className="message__attach" id="message-attach" type="file" />
                            <label className="attach__button" htmlFor="message-attach"></label>
                            <div className="input-message">
                                <input type="text" placeholder="" />
                                <button></button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="chat-body">
                        <div className="chat-body__header">
                            <div className="whos-chatting">
                                <div className="whos__logo__box">
                                    <img src="/assets/temp/avatars/33x33/2.png" alt="" className="whos__img" />
                                </div>
                                <div className="whos__data">
                                    <p className="whos__name">Тризуб</p>
                                    <p className="was__here">Был в сети вчера</p>
                                </div>
                            </div>
                            <button className="no-button">Заблокировать</button>
                        </div>
                        <div className="chat-body__body">
                            <div className="chat-day">
                                <h6 className="chat-day__date"><time dateTime="2021-10-12">12 октября 2021</time></h6>
                                <ChatMessage time="12 октября 2021" isMine>Здравствуйте!</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать по Вашему адресу 03.12.22 к 15.00 часам. После уточнения деталей мы сможем назвать стоимость работ.</ChatMessage>
                            </div>
                            <div className="chat-day">
                                <h6 className="chat-day__date"><time dateTime="2021-10-12">12 октября 2021</time></h6>
                                <ChatMessage time="12 октября 2021" isMine>Здравствуйте!</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать по Вашему адресу 03.12.22 к 15.00 часам. После уточнения деталей мы сможем назвать стоимость работ.</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021">Наш специалист готов выехать</ChatMessage>
                                <ChatMessage time="12 октября 2021" isMine>Вас что заело?</ChatMessage>
                            </div>
                        </div>
                        <div className="chat-body__message">
                            <input className="message__attach" id="message-attach" type="file" />
                            <label className="attach__button" htmlFor="message-attach"></label>
                            <div className="input-message">
                                <input type="text" placeholder="" />
                                <button></button>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="chat-banners">
                    <h2 className="v--hidden">banners</h2>
                    {/* <div className="banner__item">
                        <img src="/assets/images/banner2.png" alt="" />
                    </div>
                    <div className="banner__item">
                        <img src="/assets/images/banner3.png" alt="" />
                    </div> */}
                </section>
            </div>
        </main>
        // </div>
    )
}

export default Chat
