import { FC } from 'react';
import { TR } from 'service/T10N';

interface Props {
    value: number
}

const ResultsCounter: FC<Props> = ({ value }) => {
    return (
        <div className="result-box">
            {TR('Найдено')}: <span className="result-counter">{value}</span>
        </div>
    )
}

export default ResultsCounter