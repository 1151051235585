import PopupContacts from "Pages/Popups/PopupContacts";
import { FC, Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteVacancy, downloadFile, getDateHuman, getDateStr, getUserContacts } from "service/API";
import { Contacts, UserID, Vacancy } from "service/Data/DentTypes";
import { avatarUrl, fileUrl } from "service/Globals";
import { TR, TR_ } from "service/T10N";
import { getSalaryStr } from "service/TextTools";

export interface VacancyViewHeaderProps {
    vacancy: Vacancy
    isOwn: boolean
}

const VacancyViewHeader: FC<VacancyViewHeaderProps> = ({ vacancy, isOwn }) => {
    const history = useHistory()
    const [areContactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts>()
    // const [isOwn, setOwn] = useState(false)

    async function handleContactsClick(id: UserID) {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleEdit = async () => {
        history.push('/vacancy-new', { vacancy: vacancy })
    }

    const handleDelete = async () => {
        const success = await deleteVacancy(vacancy.id)

        if (success) {
            history.push('/dash')
            return
        }

        //TODO: show error somehow
    }

    return (
        <section className="bid-flex">
            <div className="bid__general">
                <h1 className="section-title">{vacancy.title}</h1>
                <p className="bid__txt">
                    {vacancy.description}
                </p>
                {vacancy.files ?
                    <div className='bid__addons'>
                        {
                            vacancy.files.map((file) =>
                                <button
                                    key={fileUrl(file.fuid)}
                                    className='button-link-doc'
                                    onClick={() => downloadFile(fileUrl(file.fuid), file.name)}
                                    // href={getFullPath(fileUrl(file.fuid))}
                                    // download={file.name}
                                    // target='_blank'
                                    rel='noopener noreferrer'>
                                    <span />{file.name}</button>)
                        }
                    </div>
                    : null
                }
            </div>
            <div className="bid__details">
                <div className="bid__details-item">
                    <span className="bid__details-item__data">{TR_('Зарплата')}</span>
                    <span className="bid__details-item__value">{getSalaryStr(vacancy.salaryFrom, vacancy.salaryUpto)}</span>
                </div>
                {isOwn ?
                    <Fragment>
                        <div className="bid__controls mt--30">
                            <button className='button-27f' onClick={() => handleEdit()}>{TR('Редактировать')}</button>
                            <button className='button-27e' onClick={() => handleDelete()}>{TR('Снять с публикации')}</button>
                        </div>
                        <div className="bid__date">
                            {TR_('Опубликовано')}: <time dateTime={getDateStr(vacancy.publicDate)}>{getDateHuman(vacancy.publicDate)}</time>
                        </div>
                    </Fragment>
                    : // not isOwn
                    <Fragment>
                        <div className="bid__controls mt--30 mb--20 pad--20">
                            <div className="view-chat__flex">
                                <div className="view-chat__flex-item">
                                    <div className="view-chat__company">
                                        <img className="view-chat__company-img" src={avatarUrl(vacancy.creator.avatar)} alt="" />
                                        {vacancy.creator.name}
                                    </div>
                                </div>
                                <div className="view-chat__flex-item">
                                    <div className="view-chat__date">
                                        <p className="view-chat__date-title">{TR_('Добавлено')}:</p>
                                        <time className="view-chat__date-add" dateTime={getDateStr(vacancy.publicDate)}>{getDateHuman(vacancy.publicDate)}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-chat__ctrl">
                            <button className="view-chat__show" onClick={() => handleContactsClick(vacancy.creator.id)}>{TR_('Показать контакты')}</button>
                            <button className="view-chat__complain">{TR_('Пожаловаться')}</button>
                        </div>
                        {areContactsShown && contacts ?
                            <PopupContacts
                                contacts={contacts}
                                onClose={() => setContactsShown(false)}
                            />
                            :
                            null
                        }
                    </Fragment>

                }

            </div>
        </section>
    )
}
export default VacancyViewHeader;