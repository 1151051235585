import { useHistory } from 'react-router-dom';

const SignupTrader = () => {
    let history = useHistory();
    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="./elements.html" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--808">
                    <div className="sign-top-flex">
                        <div className="edit-container">
                            <h3 className="user-type">Торговая компания</h3>
                            {/* <button className="no-button">Изменить</button> */}
                        </div>
                        <h1 className="sign-title">
                            Заполнение анкеты
                        </h1>
                    </div>
                    <p className="sign__sub-title">
                        Подробная информация позволит нам сделать работу портала максимально эффективной.
                    </p>
                    <form className="sign-form" action="post">
                        <div className="field-flex">
                            <div className="fiels-flex__item">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Название компании (или ФИО)
                                    </p>
                                    <div className="input">
                                        <input type="text" placeholder="" />
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Город
                                    </p>
                                    <div className="input">
                                        <input type="text" placeholder="" />
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Улица, дом, офис
                                    </p>
                                    <div className="input">
                                        <input type="text" placeholder="" />
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                            </div>
                            <div className="fiels-flex__item">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        Логотип или фотография
                                    </p>
                                    <div className="avatar-upload">
                                        <div className="avatar-upload__inner">
                                            <span className="avatar-upload__image"></span>
                                            <span>Ваш аватар</span>
                                        </div>
                                        <input type="file" id="avatarUpld" />
                                        <label className="button-27e" htmlFor="avatarUpld">Загрузить</label>
                                    </div>
                                    <p className="fied-set__error"></p>
                                </div>
                                <div className="field-set__cover">
                                    <p className="field-set__title w--between">
                                        Организационно-правовая форма
                                        <button className="button-info"></button>
                                    </p>
                                    <div className="radio-balls">
                                        <input className="radio-ball" type="radio" id="less3" name="amo" />
                                        <label htmlFor="less3"><span></span> Индивидуальный предприниматель</label>
                                        <input className="radio-ball" type="radio" id="more3" name="amo" />
                                        <label htmlFor="more3"><span></span> Общество с ограниченной ответственностью</label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <div className="field-set">
                            <p className="field-set__title">
                                Карточка контрагента
                                <button className="button-info"></button>
                            </p>
                            <div className="input-upload">
                                <div className="upload-data">
                                    <p className="upload__info">Перетащите файл в выбранную область или нажмите кнопку
                                        "загрузить файл"
                                    </p>
                                    <p className="upload__sub">Поддерживаемые форматы .pdf .xls — не более 50 мб.</p>
                                </div>
                                <img className="upload__img" src="/assets/images/document.svg" alt="dental portal" />
                                <input type="file" name="files" id="file" multiple={false} />
                                <label htmlFor="file" className="button-40">Загрузить файл</label>
                            </div>
                            <p className="fied-set__error"></p>
                        </div> */}
                        <button className="button-f h--44 w--335">Завершить</button>
                    </form>
                </div>
            </main>
        </div>
        // <main>
        //     <div className="Wrapper">
        //         <a className="logo" href="#">
        //             <img src="/playground_assets/Registration-logo.svg" width="238" height="26" alt="Логотип Dental Portal" />
        //         </a>
        //         <section className="layout RegistrationFillIn RegistrationFillIn--company">
        //             <h2 className="RegistrationType"><span>Торговая компания</span><a href="#">Изменить</a></h2>
        //             <h1 className="title">Заполнение анкеты</h1>
        //             <p className="RegistrationSmallText">Подробная информация позволит нам сделать работу портала
        //                 максимально эффективной.</p>
        //             <form className="form formRegistrationFillIn" onSubmit={() => history.replace("/")}>
        //                 <div className="row row--spaceBetween">

        //                     <div className="column2">
        //                         <fieldset className="formField textField">
        //                             <label className="label" htmlFor="name">Название клиники (или ФИО)</label>
        //                             <input type="text" name="name" id="name" />
        //                         </fieldset>
        //                         <fieldset className="formField textField">
        //                             <label className="label" htmlFor="city">Город</label>
        //                             <input type="text" name="city" id="city" />
        //                         </fieldset>
        //                         <fieldset className="formField textField">
        //                             <label className="label" htmlFor="address">Улица, дом, офис</label>
        //                             <input type="text" name="address" id="address" />
        //                         </fieldset>
        //                     </div>

        //                     <div className="column2">
        //                         <legend className="label">Логотип или фотография</legend>
        //                         <fieldset className="formField formFieldGreyBg avatarField">
        //                             <span className="avatarImg">
        //                                 <img src="/playground_assets/Img_box.svg" width="20" height="20" alt="avatar" />
        //                             </span>
        //                             <span>Ваш аватар</span>
        //                             <label htmlFor="avatar" className="customInputFile">Загрузить
        //                                 <input type="file" name="avatar" accept="image/png, image/jpeg" />
        //                             </label>
        //                         </fieldset>
        //                         <fieldset className="formField formFieldGreyBg formFieldGoods">
        //                             <p className="label">Номенклатура товаров</p>
        //                             <button type="button" className="btn infoBtn infoBtnRegistration"
        //                                 title="info"></button>
        //                             <ul className="RegistrationList">
        //                                 <li>
        //                                     <input type="radio" name="RegistrationItem" id="Item1"
        //                                         value="Менее 5-ти товаров" checked />
        //                                     <label htmlFor="Item1">Менее 5-ти товаров</label>
        //                                 </li>
        //                                 <li>
        //                                     <input type="radio" name="RegistrationItem" id="Item2"
        //                                         value="Более 5-ти товаров" />
        //                                     <label htmlFor="Item2">Более 5-ти товаров</label>
        //                                 </li>
        //                             </ul>
        //                         </fieldset>
        //                     </div>
        //                 </div>
        //                 <div className="column1">
        //                     <div className="row">
        //                         <p className="label">Карточка контрагента</p>
        //                         <button type="button" className="btn infoBtn infoBtnRegistration" title="info"></button>
        //                     </div>
        //                     <fieldset className="formField formFieldGreyBg formFieldStyled ClinicDocsField">
        //                         <p className="uploadInfo">Перетащите файлы в выбранную область или нажмите кнопку
        //                             "загрузить файл"
        //                             <small>Поддерживаемые форматы .pdf .xls .xls. — не более 50 мб.</small>
        //                         </p>
        //                         <label htmlFor="ClinicDocs" className="customInputFile customInputFileXL">Загрузить файл
        //                             <input type="file" name="ClinicDocs" accept="*.pdf, *.xls" />
        //                         </label>
        //                     </fieldset>
        //                 </div>
        //                 <button className="btn RegistrationBtn" type="submit">Продолжить</button>
        //             </form>
        //         </section>
        //     </div>
        // </main>
    )
}

export default SignupTrader;