import { Fragment } from 'react';

const PopupMore = ({ onClose }) => {
    return (
        <Fragment>
            <div className="popy popy-more is--visible">
                <header className="popy-header">
                    <button className="popy-killer" onClick={() => onClose()}></button>
                    <h2 className="popy-title">Хотите больше просмотров?</h2>
                </header>
                <main className="popy-main">
                    <form action="">
                        <div className="services">
                            <div className="service-item">
                                <div className="service-item__main">
                                    <div className="check__box">
                                        <input type="checkbox" className="checkbox-e" id="chbx-e" />
                                        <label htmlFor="chbx-e">Поднять вверх </label>
                                    </div>
                                    <div className="inf__box">
                                        7 дней
                                        <span className="service-fee">199 ₽</span>
                                    </div>
                                    <a className="service-info" href="#"></a>
                                </div>
                                <p className="service-item__text">Сохраняя свои объявления в верхних строчках списка, Вы
                                    повышаете их эффективность</p>
                            </div>
                            <div className="service-item">
                                <div className="service-item__main">
                                    <div className="check__box">
                                        <input type="checkbox" className="checkbox-e" id="chbx-e1" />
                                        <label htmlFor="chbx-e1">Выделить цветом </label>
                                    </div>
                                    <div className="inf__box">
                                        7 дней
                                        <span className="service-fee">49 ₽</span>
                                    </div>
                                    <a className="service-info" href="#"></a>
                                </div>
                                <p className="service-item__text">Особое оформление позволяет повысить эффективность Ваших
                                    объявлений.</p>
                            </div>
                        </div>
                    </form>
                </main>
                <footer className="popy-footer">
                    {/* <button className="main-button is--gray">Продолжить без изменений</button> */}
                    <button className="main-button is--blue" onClick={() => onClose()}>Перейти к оплате</button>
                </footer>
            </div>
            <div className="popup-cover" onMouseDown={() => onClose()}>

            </div>
        </Fragment>
    )
}

export default PopupMore;