import AuthContext from 'AuthContext';
import FileUploader from 'Components/FileUploader';
import PhotoSelector from 'Components/PhotoSelector';
import { MAX_DESCR_LENGTH, MAX_NAME_LENGTH, useInputFocus } from 'Pages/Home/Components/MyDataDoctor';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createOrUpdateNotification } from 'service/API';
import { DentFUID, DentFile, DentNotification, NotificationRequest } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';

interface LocationState {
    notification?: DentNotification
}

const NotificationNew = () => {
    const location = useLocation<LocationState>(); // '/notification-new'
    const notification: DentNotification | undefined = location.state?.notification

    if (!notification) {
        return null
    }

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isNotificationsPopupShown, setNotificationsPopupShown] = useState(false)
    const [isDescriptionShown, setDescriptionShown] = useState(false)

    const [titleRef, setTitleFocus] = useInputFocus()

    const [errorShown, setErrorShown] = useState(false);

    // Scroll to top once on page load
    const [scrollToTop, setScrollToTop] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToTop])

    const [title, setTitle] = useState(notification?.title ? notification.title : '')
    const [description, setDescription] = useState(notification?.description ? notification.description : '')
    const [photos, setPhotos] = useState<DentFUID[]>(notification?.photos ? notification.photos : [])
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(notification?.photos?.length ? notification.photos[0] : '')
    const [files, setFiles] = useState<DentFile[]>(notification?.files ? notification.files : [])

    function validateForm(): boolean {
        // if (!title) {
        //     setErrorShown(true)
        //     setTitleFocus()
        //     return false
        // }

        return true
    }

    const publish = async () => {
        if (!validateForm()) {
            //console.log('Validation error...')
            return
        }

        var request: NotificationRequest = {
            id: notification?.id,
            title: title,
            description: description,
            photos: photos,
            files: files,
        }

        //console.log(request);

        const reply = await createOrUpdateNotification(request)

        if (reply.status === 'success') {
            // Update success
            auth.doNotificationsUpdate() // Forcing update of notifications from server
            //console.log('Notification was created');
            history.push('/list-notifications', { notifications: auth.notifications })
        }
        else {
            // TODO: show error to user somehow
            console.log('Notification creation error');
        }
    }

    return (
        <main className="main">
            <div className="container">
                <div className="add-cover">
                    <h1 className="add-title">{TR_('Создать уведомление')}</h1>
                    <div className="add">
                        <div className="add__form">
                            <header className="add__header">
                                <button className="add__header-btn" onClick={history.goBack}></button>
                                <h2 className="add__header-title">{TR('Создать уведомление')}</h2>
                            </header>
                            <div className="add__main">
                                {/* <button className="how" onClick={() => setDescriptionShown(true)}>
                                    {TR('Как создать успешную закупку')}
                                </button> */}
                                <form className="new-add-form" action="post">
                                    <div className="field-set mt--30">
                                        <p className="field-set__title">
                                            {TR('Заголовок')}
                                        </p>
                                        <div className="input" data-tooltip-id="title-tooltip">
                                            <input ref={titleRef} maxLength={MAX_NAME_LENGTH} type="text" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                        <p className="fied-set__error"></p>
                                        {/* <Tooltip id="title-tooltip" isOpen={errorShown && !title} content={TR_("Заголовок не может быть пустым")} /> */}
                                    </div>
                                    <div className="field-flex">
                                        {/* <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR_('Максимальный бюджет')}
                                                </p>
                                                <div className="input">
                                                    <input placeholder={TR('Не могу оценить стоимость')} type="number" value={budget} onChange={(e) => setBudget(e.target.value)} />
                                                </div>
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div> */}
                                        {/* <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR('Сроки (дедлайн)')}
                                                </p>
                                                <div className="input-date">
                                                    <input
                                                        ref={deadlineRef}
                                                        placeholder={TR('Заказ с открытой датой завершения')}
                                                        type="date"
                                                        min={getDateStr(new Date())}
                                                        value={deadline}
                                                        onChange={(e) => setDeadline(e.target.value)}
                                                    />
                                                </div>
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            {TR('Описание')}
                                        </p>
                                        <div className="textarea h--90" data-tooltip-id="description-tooltip">
                                            <textarea maxLength={MAX_DESCR_LENGTH} name="some-text" id="someText" placeholder={TR_('Опишите детально, что Вам нужно')} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                        </div>
                                        <p className="fied-set__error"></p>
                                        {/* <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} /> */}
                                    </div>
                                    <PhotoSelector
                                        photos={photos}
                                        setPhotos={setPhotos}
                                        starred={starredPhoto}
                                        setStarred={setStarredPhoto}
                                    />
                                    <FileUploader files={files} setFiles={setFiles} />
                                    {/* <div className="slide-flex mt--30">
                                        {targetRole == 'technician' ?
                                            <Fragment>
                                                <input type="checkbox" className="checkbox-slide" id="chbxsld" checked={isCadCam} onChange={() => setIsCadCam(!isCadCam)} />
                                                <label htmlFor="chbxsld"> <span></span> {TR_('CAD/CAM технология')}</label>
                                            </Fragment>
                                            :
                                            null
                                        }
                                    </div> */}
                                </form>
                            </div>
                            <div className="add__footer" style={{ gap: "10px" }}>
                                <button className="button-e h--44" onClick={() => setNotificationsPopupShown(true)}>{TR_('Посмотреть')}</button>
                                <button className="main-button is--blue  too--wide" onClick={() => publish()}>{TR_('Опубликовать')}</button>
                            </div>
                        </div>
                        <div className={"add__description" + (isDescriptionShown ? "" : " hide_me")}>
                            <header className="add__header">
                                <button className="add__header-btn" onClick={() => setDescriptionShown(false)}></button>
                                <h2 className="add__header-title">{TR_('Не забываем')}</h2>
                            </header>
                            <div className="add__main">
                                <ol className="gide-list">
                                    <li className="gide__item">
                                        {TR_('Заголовок должен быть кратким и понятным для участников портала.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR_('Описание, фото и файлы могут быть опущены.')}
                                    </li>
                                </ol>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide">{TR('Закрыть')}</button>
                            </div>
                        </div>
                        {/* {isNotificationsPopupShown &&
                            <PopupNotifications onClose={() => setNotificationsPopupShown(false)} />
                        } */}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NotificationNew