import AuthContext from 'AuthContext';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import { Fragment, useContext } from 'react';
import { Doctor, Participant, Technician, Trader } from 'service/Data/DentTypes';
import MyData from '../Home/Components/MyData';
import DashDoctor from './DashDoctor';
import DashTechnician from './DashTechnician';
import DashTrader from './DashTrader';

const DoLogout = () => {
    const auth = useContext(AuthContext);
    auth.logout()
    return null
}

function getDefaultData(role: string): Doctor | Technician | Trader | null {
    const participant: Participant = {
        createdAt: 0,
        city: '',
        street: '',
        description: '',
        id: '',
        role: 'doctor',
        name: '',
        avatar: '',
        photos: [],
        contacts: {
            email: '',
            phone: '',
            hasTelegram: false,
            hasWhatsapp: false,
            site: '',
            youtube: '',
        },
    }

    if (role === 'doctor') {
        const doctor: Doctor = {
            ...participant,
            role: 'doctor',
            doctor: {
                chairs: 0,
                scanner: '',
                licenses: []
            },
        }

        return doctor
    }

    if (role === 'technician') {
        const technician: Technician = {
            ...participant,
            role: 'technician',
            technician: {
                cadcam: '',
                pricelist: '',
                licenses: []
            },
        }
        return technician
    }

    if (role === 'trader') {
        const trader: Trader = {
            ...participant,
            role: 'trader',
            trader: {
                goods: 6,
                catalog: '',
                tags: '',
            },
        }
        return trader
    }

    return null
}

const Dashboard = () => {
    //console.log('Dashboard')

    const auth = useContext(AuthContext);

    const hasAvatar = auth.session?.avatar ? true : false

    const userDefaultData = auth.session?.role ? getDefaultData(auth.session.role) : undefined

    return (
        <Fragment>
            {hasAvatar ?
                <Fragment>
                    <Header />
                    {
                        auth.session?.role === 'doctor' ? <DashDoctor /> :
                            auth.session?.role === 'technician' ? <DashTechnician /> :
                                auth.session?.role === 'trader' ? <DashTrader /> : <DoLogout />
                    }
                    <Footer />
                </Fragment>
                :
                userDefaultData ? <MyData user={userDefaultData} /> : <DoLogout />
            }
        </Fragment>
    )
}

export default Dashboard;