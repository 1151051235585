import { useHistory } from 'react-router-dom';
import { TR, TR_ } from 'service/T10N';

const HelpPage = () => {
    const history = useHistory()

    return (
        <main className='main'>
            <div className='container'>
                <section className='help'>
                    <h1 className='section-title'>{TR('Помощь')}</h1>
                    <div className='help__inner'>
                        <div className='help__inner-item'>
                            <div className='help__item'>
                                <h2 className='help__item-title'>
                                    {TR('Связь с нами')}
                                    <button className='button-27e' onClick={() => history.push('/chat')}>{TR('Открыть чат с техподдержкой')}</button>
                                </h2>
                                <p className='help__item__subtitle'>{TR('Напишите нам, и мы вам поможем')}</p>
                                <ul className='support-list'>
                                    <li className='support__item'>
                                        {TR('Техподдержка:')} <a href='mailto:support@dentalportal.pro'>support@dentalportal.pro</a>
                                    </li>
                                    <li className='support__item'>
                                        {TR('Реклама:')} <a href='mailto:promotion@dentalportal.pro'>promotion@dentalportal.pro</a>
                                    </li>
                                    <li className='support__item'>
                                        {TR('Администрация:')} <a href='mailto:ceo@dentalportal.pro'>ceo@dentalportal.pro</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='help__item'>
                                <h2 className='help__item-title'>
                                    {TR('Правила портала')}
                                </h2>
                                <ul className='support-list'>
                                    <li className='support__item'>
                                        <a className='pdf-link' href={TR('/docs/ru/forbidden.pdf', 'Запрещённые товары')} target='_blank'>{TR('Перечень товаров, запрещённых к размещению на портале')}</a>
                                    </li>
                                    <li className='support__item'>
                                        <a className='pdf-link' href={TR('/docs/ru/terms_of_use.pdf', 'Пользовательское соглашение')} target='_blank'>{TR('Пользовательское соглашение')}</a>
                                    </li>
                                    <li className='support__item'>
                                        <a className='pdf-link' href={TR('/docs/ru/rules.pdf', 'Правила размещения информации')} target='_blank'>{TR('Правила размещения информации')}</a>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className='help__item'>
                                <h2 className='help__item-title'>
                                    Часто задаваемые вопросы
                                </h2>
                                <div className='faq-list'>
                                    <div className='faq__item active'>
                                        <button className='faq__title'>Что такое 'Dental Portal'? <span className='faq__arrow'></span></button>
                                        <div className='faq__item-description'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere sunt veniam mollitia sed natus
                                                iure ducimus, autem doloremque ab quidem?
                                            </p>
                                        </div>
                                    </div>
                                    <div className='faq__item'>
                                        <button className='faq__title'>Что такое 'Dental Portal'? <span className='faq__arrow'></span></button>
                                        <div className='faq__item-description'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere sunt veniam mollitia sed natus
                                                iure ducimus, autem doloremque ab quidem?
                                            </p>
                                        </div>
                                    </div>
                                    <div className='faq__item'>
                                        <button className='faq__title'>Что такое 'Dental Portal'? <span className='faq__arrow'></span></button>
                                        <div className='faq__item-description'>
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere sunt veniam mollitia sed natus
                                                iure ducimus, autem doloremque ab quidem?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='help__inner-item'>
                            <div className='help__item'>
                                <h2 className='help__item-title'>
                                    {TR('Часто задаваемые вопросы')}
                                </h2>
                                <ul className='whats-new'>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>{TR('Что такое Dental Portal?')}</h2>
                                        <p className='whats-new__desc'>{TR('Dental Portal - портал участников рынка стоматологических услуг. Основная цель портала - сократить время и расходы участников при поиске товаров и услуг.')}</p>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>{TR('На что обратить внимание при регистрации?')}</h2>
                                        <p className='whats-new__desc'>{TR('При регистрации важно правильно определить свою «роль» - Клиника, Лаборатория, Компания (ИП, ООО). От этого будет зависеть структура и возможности Вашего личного кабинета.')}</p>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>{TR('Как работает раздел «Закупки»?')}</h2>
                                        <p className='whats-new__desc'>{TR('Участники портала в личном кабинете создают заявки на закупку товара, работ и услуг. В ответ на эти заявки получают отклики от торговых компаний или лабораторий. Заказчик  сравнивает входящие предложения и осуществляет свой выбор. После того как победитель определён, конкурс считается завершённым.')}</p>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>{TR('Как работает раздел «Предложения»?')}</h2>
                                        <p className='whats-new__desc'>{TR('Торговые компании и лаборатории публикуют свои актуальные предложения для выбранной целевой аудитории. Предложения видны участникам в личных кабинетах или общим списком в разделе «Предложения» в зависимости от того, оплачена публикация или нет.')}</p>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>{TR_('Как подтвердить свою учетную запись?')}</h2>
                                        <p className='whats-new__desc'>{TR_('Участникам, которые заполнили анкетные данные и наполнили свой профиль фото, ссылками и файлами, присваивается статус подтвержденного аккаунта.')}</p>
                                    </li>
                                    {/* <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>{TR('Как подтвердить свою принадлежность к стоматологии без диплома об образовании?')}</h2>
                                        <p className='whats-new__desc'>{TR('Если в момент регистрации у участника отсутствует диплом об образовании (для техника), то ему необходимо обратиться в техническую поддержку для прохождения процедуры верификации.')}</p>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        {/* <div className='help__inner-item'>
                            <div className='help__item'>
                                <h2 className='help__item-title'>
                                    Что изменилось
                                </h2>
                                <ul className='whats-new'>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>Новый раздел “Барахолка”</h2>
                                        <p className='whats-new__desc'>Мы добавили новый раздел 'БАРАХОЛКА' в Вашем личном кабинете</p>
                                        <time className='whats-new__time' dateTime='2021-10-12'>12 октября 2021</time>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>Добавлен раздел “Чаты”</h2>
                                        <p className='whats-new__desc'>Теперь у Вас есть возможность моментального обмена сообщениями со своими
                                            партнерами</p>
                                        <time className='whats-new__time' dateTime='2021-10-12'>12 октября 2021</time>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>Версия 3.0”</h2>
                                        <p className='whats-new__desc'>Глобальное изменение сайта в котором мы добавили новые алгоритмы поиска и
                                            ранжирования рекламы</p>
                                        <time className='whats-new__time' dateTime='2021-10-12'>12 октября 2021</time>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>Исправили баги.”</h2>
                                        <ol className='whats-new__sublist'>
                                            <li>
                                                Поиск теперь работает исправно
                                            </li>
                                            <li>
                                                Письма больше не прилетают в спам
                                            </li>
                                            <li>
                                                Чаты работают теперь как положено
                                            </li>
                                        </ol>
                                        <time className='whats-new__time' dateTime='2021-10-12'>12 октября 2021</time>
                                    </li>
                                    <li className='whats-new__item'>
                                        <h2 className='whats-new__title'>Мы запустились</h2>
                                        <p className='whats-new__desc'>Глобальное изменение сайта в котором мы добавили новые алгоритмы поиска и
                                            ранжирования рекламы</p>
                                        <time className='whats-new__time' dateTime='2021-10-12'>12 октября 2021</time>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </section>
            </div>
        </main>
    )
}

export default HelpPage
