import AuthContext from 'AuthContext';
import { firstPhoto } from 'Pages/Offers/Components/OfferCard';
import PopupContacts from 'Pages/Popups/PopupContacts';
import { FC, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteSale, downloadFile, getDateHuman, getDateStr, getUserContacts } from 'service/API';
import { Contacts, Creator, Sale, UnixTime, UserID } from 'service/Data/DentTypes';
import { avatarUrl, fileUrl } from 'service/Globals';
import { TR, TR_ } from 'service/T10N';
import { getPriceStr } from 'service/TextTools';

interface SomeCompanyProps {
    company: Creator
    createdAt: UnixTime
}

export const SomeCompanyCard: FC<SomeCompanyProps> = ({ company, createdAt }) => {
    const history = useHistory();

    const [areContactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts>()

    async function handleContactsClick(id: UserID) {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    return (
        <div className="view__controls-inner">
            <div className="view-chat">
                <div className="view-chat__flex">
                    <div className="view-chat__flex-item">
                        <div className="view-chat__company">
                            <img className="view-chat__company-img" src={avatarUrl(company.avatar)} alt="" />
                            {company.name}
                        </div>
                    </div>
                    <div className="view-chat__flex-item">
                        <div className="view-chat__date">
                            <p className="view-chat__date-title">{TR_('Регистрация')}:</p>
                            <time className="view-chat__date-add" dateTime={getDateStr(createdAt)}>{getDateHuman(createdAt)}</time>
                        </div>
                    </div>
                </div>
                <button className="main-button is--blue  m-0" onClick={() => { history.push('/chat') }}>{TR('Начать чат')}</button>
                <div className="view-chat__ctrl">
                    <button className="view-chat__show" onClick={() => handleContactsClick(company.id)}>{TR('Показать контакты')}</button>
                    <button className="view-chat__complain">{TR('Пожаловаться')}</button>
                </div>
                {areContactsShown && contacts ?
                    <PopupContacts
                        contacts={contacts}
                        onClose={() => setContactsShown(false)}
                    />
                    :
                    null
                }
            </div>
        </div>
    )
}

interface OwnCompanyProps {
    publicDate: UnixTime
    handleEdit: () => void
    handleDelete?: () => void
}

export const OwnCompanyCard: FC<OwnCompanyProps> = ({ publicDate, handleEdit, handleDelete }) => {
    return (
        <div className="view__controls-inner">
            <div className="view-add-controls">
                <div className="view-add__flex">
                    <button className='button-27f' onClick={() => handleEdit()}>{TR('Редактировать')}</button>
                    {handleDelete ? <button className='button-27e' onClick={() => handleDelete()}>{TR('Снять с публикации')}</button> : null}
                </div>
                <p><span className="blind--text">{TR_('Регистрация')}:</span> {getDateHuman(publicDate)}</p>
            </div>
        </div>
    )
}

interface LocationState {
    sale: Sale
}

const SaleView = () => {
    const auth = useContext(AuthContext)
    const history = useHistory();

    const location = useLocation<LocationState>(); '/sale-view'
    const sale = location.state.sale

    //console.log('Sale-view:')
    //console.log(sale)


    const [isOwn, setOwn] = useState(auth.session?.id === sale.creator.id)

    const [areContactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts>()

    async function handleContactsClick(id: UserID) {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleEdit = async () => {
        history.push('/sale-new', { sale: sale })
    }

    const handleDelete = async () => {
        const success = await deleteSale(sale.id)

        if (success) {
            history.push('/dash')
            return
        }

        //TODO: show error somehow
    }

    const SomeSale =
        <div className="view__controls-inner">
            <div className="view-chat">
                <div className="view-chat__flex">
                    <div className="view-chat__flex-item">
                        <div className="view-chat__company">
                            <img className="view-chat__company-img" src={avatarUrl(sale.creator.avatar)} alt="" />
                            {sale.creator.name}
                        </div>
                    </div>
                    <div className="view-chat__flex-item">
                        <div className="view-chat__date">
                            <p className="view-chat__date-title">{TR('Добавлено')}:</p>
                            <time className="view-chat__date-add" dateTime={getDateStr(sale.publicDate)}>{getDateHuman(sale.publicDate)}</time>
                        </div>
                    </div>
                </div>
                <button className="main-button is--blue  m-0" onClick={() => { history.push('/chat') }}>{TR('Начать чат')}</button>
                <div className="view-chat__ctrl">
                    <button className="view-chat__show" onClick={() => handleContactsClick(sale.creator.id)}>{TR('Показать контакты')}</button>
                    <button className="view-chat__complain">{TR('Пожаловаться')}</button>
                </div>
                {areContactsShown && contacts ?
                    <PopupContacts
                        contacts={contacts}
                        onClose={() => setContactsShown(false)}
                    />
                    :
                    null
                }
            </div>
        </div>

    const OwnSale =
        <div className="view__controls-inner">
            <div className="view-add-controls">
                <div className="view-add__flex">
                    <button className='button-27f' onClick={() => handleEdit()}>{TR('Редактировать')}</button>
                    <button className='button-27e' onClick={() => handleDelete()}>{TR('Снять с публикации')}</button>
                </div>
                <p><span className="blind--text">{TR('Опубликовано')}:</span> {getDateHuman(sale.publicDate)}</p>
            </div>
        </div>

    return (
        <main className="main top--0">
            <div className="container">
                <section className="view--add">
                    <div className="preview-flex">
                        <div className="view__details">
                            <h1 className="section-title mb--20">{sale.title}</h1>
                            <p className="location">
                                {sale.city}
                                <span className="blind--text">{sale.street}</span>
                            </p>
                            <p className="view-price">{getPriceStr(sale.price)}</p>
                            <p>
                                {sale.description}
                            </p>
                            <br />
                            {sale.files ?
                                <div className='bid__addons'>
                                    {
                                        sale.files.map((file) =>
                                            <button
                                                key={fileUrl(file.fuid)}
                                                className='button-link-doc'
                                                onClick={() => downloadFile(fileUrl(file.fuid), file.name)}
                                                // href={getFullPath(fileUrl(file.fuid))}
                                                // download={file.name}
                                                // target='_blank'
                                                rel='noopener noreferrer'>
                                                <span />{file.name}</button>)
                                    }
                                </div>
                                : null
                            }
                        </div>
                        <div className="view__media">
                            <div className="view__media-logo">
                                <img className="inner-img" src={firstPhoto(sale.photos)} />
                            </div>
                        </div>

                    </div>
                    <div className="preview-flex-cont">
                        <div className="view__serv"></div>
                        {isOwn ?
                            <OwnCompanyCard
                                publicDate={sale.publicDate}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete} />
                            :
                            <SomeCompanyCard
                                createdAt={sale.publicDate}
                                company={sale.creator} />}
                    </div>
                </section>
            </div>
        </main>
    )
}

// <div className="view__media">
//     <div className="view__media-inner">
//         {/* <div style={{ "--swiper-pagination-color": "#fff", "--swiper-navigation-color": "#fff" }} className="swiper mySwiper2"> */}
//         <Swiper
//             className="swiper mySwiper2"
//             style={{
//                 // @ts-ignore
//                 "--swiper-navigation-color": "#fff",
//                 "--swiper-pagination-color": "#fff",
//             }}
//             spaceBetween={2}
//             navigation={{
//                 nextEl: ".swiper-button-next",
//                 prevEl: ".swiper-button-prev",
//             }}
//         >
//             <div className="swiper-wrapper">
//                 {
//                     sale.photos?.map((photo, index) => {
//                         return (
//                             <SwiperSlide key={index}>
//                                 <img src={photoUrl(photo)} />
//                             </SwiperSlide>
//                         )
//                     })
//                 }
//             </div>
//             <div className="swiper-pagination"></div>
//             <div className="swiper-button-next"></div>
//             <div className="swiper-button-prev"></div>
//         </Swiper>
//         {/* <div className="swiper mySwiper hideon--mobile">
//             <div thumbsSlider="" className="swiper mySwiper hideon--mobile">
//             <div className="swiper-wrapper">
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
//                 </div>
//                 <div className="swiper-slide">
//                     <img src="https://swiperjs.com/demos/images/nature-10.jpg" />
//                 </div>
//             </div>
//         </div> */}
//     </div>
// </div>

export default SaleView;