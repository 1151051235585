import SearchFilters from 'Components/SearchFilters';
import { Fragment, useEffect, useState } from 'react';

import ResultsCounter from 'Components/ResultsCounter';
import { SubsetCityItem } from 'Components/SearchFilters/SubsetFilter2';
import SortBy from 'Components/SortBy';
import TopSwitch from 'Components/TopSwitch';
import { gFilterTraders, updateFilter } from 'Pages/Home/Components/MyDataTrader';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { getTraders } from 'service/API';
import { Trader } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';
import UserCard from './Components/UserCard';

const ListTraders = () => {
    let history = useHistory();

    const [isMapShown, setMapShown] = useState(false);

    const [justInitialized, setJustInitizlized] = useState(true);
    const [traders, setTraders] = useState<Trader[] | null>(null)

    const [findText, setFindText] = useState('')
    const [cities, setCities] = useState([])
    const [cadcams, setCadcams] = useState<SubsetCityItem[]>([])
    const [stomatologs, setStomatologs] = useState<SubsetCityItem[]>([])
    const [zubtechs, setZubtechs] = useState<SubsetCityItem[]>([])
    const [implants, setImplants] = useState<SubsetCityItem[]>([])
    const [furnitures, setFurnitures] = useState<SubsetCityItem[]>([])
    const [sortBy, setSortBy] = useState('date')

    updateFilter(gFilterTraders, 'cadcams', cadcams, setCadcams)
    updateFilter(gFilterTraders, 'stomatologs', stomatologs, setStomatologs)
    updateFilter(gFilterTraders, 'zubtechs', zubtechs, setZubtechs)
    updateFilter(gFilterTraders, 'implants', implants, setImplants)
    updateFilter(gFilterTraders, 'furnitures', furnitures, setFurnitures)

    //const [ttest, setTtest] = useState<SubsetCityItem[]>([])

    useEffect(() => {
        (async () => {
            const traders = await getTraders(findText, cities, collectTraderTags(), sortBy)
            //setTraders(traders.slice(0, 0)) // ~~ disabling list
            setTraders(traders)
            setJustInitizlized(false)
        })()
    }, [findText, cities, cadcams, stomatologs, zubtechs, implants, furnitures, sortBy]);

    const resetFilters = () => {
        setFindText('')
        setCities([])
        setCadcams([])
        setStomatologs([])
        setZubtechs([])
        setImplants([])
        setFurnitures([])
    }

    function collectTraderTags(): string[] {
        function collectTags(items: SubsetCityItem[][]): string[] {
            var result: string[] = []
            for (var item of items) {
                for (var v of item) {
                    result = [...result, v.tag]
                }
            }
            return result
        }

        return collectTags([
            cadcams,
            stomatologs,
            zubtechs,
            implants,
            furnitures,
        ])
    }

    const filters = [
        {
            id: "text",
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
            }
        },
        ...gFilterTraders
    ]

    // const filterTraders = [
    //     {
    //         id: "text",
    //         text: {
    //             value: findText,
    //             setValue: setFindText,
    //             placeholder: TR('Поиск'),
    //         }
    //     },
    //     // {
    //     //     id: "cities",
    //     //     subset: {
    //     //         from: getAvailableCities(),
    //     //         value: cities,
    //     //         setValue: setCities,
    //     //         nonEmptyTitle: TR('Выбранные города'),
    //     //         whenEmptyTitle: TR('По всем городам'),
    //     //         popupTitle: TR('Город'),
    //     //     }
    //     // },
    //     {
    //         id: "cadcam",
    //         subset: {
    //             from: [
    //                 TR('3D принтеры'),
    //                 TR('Лабораторные сканеры'),
    //                 TR('Интраоральные сканеры'),
    //                 TR('Фрезерные станки'),
    //                 TR('Диски'),
    //             ],
    //             value: cadcams,
    //             setValue: setCadcams,
    //             whenEmptyTitle: TR('CAD/CAM технологии'),
    //             nonEmptyTitle: TR('Выбранные CAD/CAM технологии'),
    //             popupTitle: "CAD/CAM",
    //         }
    //     },
    //     {
    //         id: "stomatolog",
    //         subset: {
    //             from: [
    //                 TR('Стоматологические установки'),
    //                 TR('Наконечники и микромоторы'),
    //                 TR('Стулья'),
    //                 TR('Компрессоры и помпы'),
    //                 TR('Стерилизационное оборудование'),
    //                 TR('Рентгендиагностика'),
    //                 TR('Светильники'),
    //                 TR('Микроскопы'),
    //                 TR('Инструменты'),
    //                 TR('Шприцы и иглы'),
    //                 TR('Анестетик'),
    //                 TR('Слепочная масса'),
    //                 TR('Композит'),
    //                 TR('Нить ретракционная'),
    //                 TR('Цемент'),
    //                 TR('Реставрационный материал'),
    //                 TR('Пломбировачный материал'),
    //             ],
    //             value: stomatologs,
    //             setValue: setStomatologs,
    //             whenEmptyTitle: TR('Стоматологическое оборудование и материалы'),
    //             nonEmptyTitle: TR('Стоматологическое оборудование и материалы'),
    //             popupTitle: TR('Стоматологическое оборудование и материалы'),
    //         }
    //     },
    //     {
    //         id: "zuboteh",
    //         subset: {
    //             from: [
    //                 TR('Артикуляторы'),
    //                 TR('Вакуумные смесители'),
    //                 TR('Вибростолы'),
    //                 TR('Воскотопки'),
    //                 TR('Микромоторы и турбины'),
    //                 TR('Печи'),
    //                 TR('Пароструйные аппараты'),
    //                 TR('Пескоструйные аппараты'),
    //                 TR('Керамические массы'),
    //                 TR('Воска'),
    //                 TR('Гипсы'),
    //                 TR('Сплавы'),
    //                 TR('Инструменты'),
    //             ],
    //             value: zubtechs,
    //             setValue: setZubtechs,
    //             whenEmptyTitle: TR('Зуботехническое оборудование и материалы'),
    //             nonEmptyTitle: TR('Зуботехническое оборудование и материалы'),
    //             popupTitle: TR('Зуботехническое оборудование и материалы'),
    //         }
    //     },
    //     {
    //         id: "implants",
    //         subset: {
    //             from: [
    //                 "Astra Tech",
    //                 "Sewon Medix",
    //                 "Ankylos",
    //                 "Strauman",
    //                 "Anthogyr",
    //                 "Alpha Bio",
    //                 "Nobel Biocare",
    //                 "Osstem",
    //                 "Geo Medi",
    //                 "Anyridge",
    //                 "Biotech"
    //             ],
    //             value: implants,
    //             setValue: setImplants,
    //             whenEmptyTitle: TR('Имплантология и хирургия'),
    //             nonEmptyTitle: TR('Имплантология и хирургия'),
    //             popupTitle: TR('Имплантология и хирургия'),
    //         }
    //     },
    //     {
    //         id: "furniture",
    //         subset: {
    //             from: [
    //                 TR('Для стоматологий'),
    //                 TR('Для зуботехнических лабораторий'),
    //             ],
    //             value: furnitures,
    //             setValue: setFurnitures,
    //             whenEmptyTitle: TR('Мебель'),
    //             nonEmptyTitle: TR('Мебель'),
    //             popupTitle: TR('Мебель'),
    //         }
    //     },
    //     {
    //         id: "ttest",
    //         subset2: {
    //             from: [
    //                 { text: TR('3D принтеры'), tag: 'cadcam-3dprinters' },
    //                 { text: TR('Фрезерные станки'), tag: 'cadcam-frezstan' },
    //             ],
    //             value: ttest,
    //             setValue: setTtest,
    //             whenEmptyTitle: TR_('_CAD/CAM технологии'),
    //             nonEmptyTitle: TR_('_Выбранные CAD/CAM технологии'),
    //             popupTitle: "_CAD/CAM",
    //         }
    //     },
    // ]

    const [role, setRole] = useState('trader')

    useEffect(() => {
        if (role !== 'trader')
            history.push('/' + role + 's')
    }, [role])

    const switchData = {
        switchName: 'roles',
        value: role,
        setValue: setRole,
        items: [
            { title: TR_('Клиники'), value: 'doctor' },
            { title: TR_('Лаборатории'), value: 'technician' },
            { title: TR('Торговые компании'), value: 'trader' },
        ]
    }

    return (
        <main className="main">
            <div className="container">
                <h1 className="section-title">{TR('Торговые компании')}</h1>
                <div className="list-controls">
                    <div className="list-controls__switch">
                        <TopSwitch {...switchData} />
                        {/* <div className="from-who-box"> ~~ temporarily disabling
                            <input className="from-who" type="radio" id="card" name="cards" onClick={() => setMapShown(false)} defaultChecked />
                            <label htmlFor="card">{TR('Карточки')}</label>
                            <input className="from-who" type="radio" id="map" name="cards" onClick={() => setMapShown(true)} />
                            <label htmlFor="map">{TR('На карте')}</label>
                        </div> */}
                    </div>
                    <div className="list-controls__sort">
                        {!isMobile ?
                            < Fragment >
                                < SortBy value={sortBy} setValue={setSortBy} />
                                <ResultsCounter value={traders ? traders.length : 0} />
                            </Fragment>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="list-base">
                    <SearchFilters options={filters} onResetFilters={resetFilters} banner={2} />
                    <div className="list-ads">
                        {/* add class list-map--open */}
                        <div className={"list-map" + (isMapShown ? " list-map--open" : "")}></div>
                        {/* <div className="list-map list-map--open" hidden={!isMapShown} /> */}
                        {traders ? traders.map((trader) =>
                            <UserCard key={trader.id} user={trader} />)
                            : justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </main >
    )
}

export default ListTraders
