import { FC, Fragment } from 'react';

interface Item {
    name: string
    title: string
    choosen: string // The value will be set to choosen on item click
    value: string
    setValue: (value: string) => void
}

interface Items {
    // title: string
    // choosen: string // The value will be set to choosen on item click
    switchName: string
    value: string
    setValue: (value: string) => void
    items: { title: string, value: string }[]
}

const Item: FC<Item> = ({ name, value, setValue, title, choosen }) => {
    return (
        <Fragment key={title}>
            <input className="from-who" type="radio" id={'c_' + choosen} name={name} readOnly checked={value === choosen} onClick={() => setValue(choosen)} />
            <label htmlFor={'c_' + choosen}>{title}</label>
        </Fragment>
    )
}

const TopSwitch: FC<Items> = ({ switchName, value, setValue, items }) => {
    return (
        // <div className="list-controls__switch">
        <div className="from-who-box">
            {items.length ?
                <Fragment>
                    <Item name={switchName} value={value} setValue={setValue} title={items[0].title} choosen={items[0].value} />
                    {items.slice(1).map((item) => {
                        return (
                            <Fragment key={item.title}>
                                <span />
                                <Item name={switchName} value={value} setValue={setValue} title={item.title} choosen={item.value} />
                            </Fragment>
                        )
                    })}
                </Fragment>
                : null
            }
        </div>
        // </div>
    )
}

export default TopSwitch;