import { useEffect, useState } from "react";
import { DashData, getDashData } from "service/API";
import { announcement } from "service/Globals";
import OffersFromTechnicians from "./Components/OffersTechnicians";
import OffersFromTraders from "./Components/OffersTraders";

const HomeDoctor = () => {
    const [isMyDataPopupShown, setMyDataPopupShown] = useState(false)

    const [data, setData] = useState<DashData>()
    const [firstLoad] = useState()
    useEffect(() => {
        (async () => {
            //console.log('Home doctor effect')
            const homeData = await getDashData({
                //myAuctions: { actual: [], archived: [] },
                //myOffers: { actual: [], archived: [] },
                //mySales: { actual: [], archived: [] },
                //myVacancies: { actual: [], archived: [] },
                offersFromTraders: [],
                offersFromTechnicians: [],
                // purchasesDoctors: [],
                // purchasesTechnicians: []
            })
            console.log('Home data received:')
            console.log(homeData);
            setData(homeData)
        })();
    }, [firstLoad]);

    // const myAuctions = {
    //     actual: _myAuctions.slice(0, 3),
    //     archived: _myAuctions.slice(3, 4)
    // }

    //const myAuctions = data?.myAuctions ? data?.myAuctions : { actual: [], archived: [] }
    //const myOffers = data?.myOffers ? data?.myOffers : { actual: [], archived: [] }
    //const mySales = data?.mySales ? data?.mySales : { actual: [], archived: [] }
    //const myVacancies = data?.myVacancies ? data?.myVacancies : { actual: [], archived: [] }

    //const offersFromTraders = data?.offersFromTraders ? data.offersFromTraders : []

    return (
        <main className="main">
            <div className="container ws--pre">
                {/* Кабинет доктора<br /> */}
                {/* style={{ fontSize: "30px" }} */}
                {announcement ? <div className="home temp-header">{announcement}</div> : null}
                {/* {TR('Портал начинает свою работу с 29 мая 2023\n Осталось NN дней, NN часов NN минут NN секунд')} */}
                <div className="main-flex">
                    <div className="main-flex__item">
                        <OffersFromTechnicians theOffers={data?.offersFromTechnicians} />
                    </div>
                    <div className="main-flex__item">
                        <OffersFromTraders theOffers={data?.offersFromTraders} />
                    </div>
                    {/* <div className="main-flex__item">
                        <MyPurchases {...myAuctions} />
                        <MyOffers {...myOffers} />
                        <MySales {...mySales} />
                        <MyVacancies {...myVacancies} />
                        <MakeMoreVisible />
                    </div>
                    <div className="main-flex__item">
                        <OffersTraders />
                        <OffersTechnicians />
                        <PurchasesDoctor />
                        <PurchasesTechnician />
                        <MySpeciality />
                        <Banner />
                    </div> */}
                </div>
            </div>
        </main>
    )
}

export default HomeDoctor;