import { dateToUnixTime } from "service/API"
import { MyOffer, Offer } from "./DentTypes"



export const _offersData: Offer[] = [
    {
        id: 1,
        targetRole: '',
        targetCadcam: 0,
        targetScanner: 0,
        title: 'Фрезерный станок Roland 52D',
        city: 'Москва, Коломенская',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale1.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Продается Б/У фрезерный станок. Требуется замена шпинделя и техническое обслуживание. Был в работе 12 месяцев. Торг при осмотре. Цена 1 800 000р.`,
        priceNew: 1800000,
        priceOld: 1900000,
        creator: {
            id: '1',
            name: 'Зуботехническая лаборатория',
            avatar: '/assets/temp/avatars/33x33/1.png',
        },
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Хирург. шаблон 1', fuid: '#' },
            { name: 'Хирург. шаблон 2', fuid: '#' },
            { name: 'Чумадан.doc', fuid: '#' },
        ]
    },
    {
        id: 2,
        targetRole: 'doctor',
        targetCadcam: 1,
        targetScanner: 1,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale1.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        priceNew: 500000,
        priceOld: 670000,
        creator: {
            id: '1',
            name: 'Торговая компания',
            avatar: '/assets/temp/avatars/33x33/2.png',
        },
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 3,
        targetRole: 'doctor',
        targetCadcam: 2,
        targetScanner: 2,
        title: '(3)Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `(3)Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        priceNew: 500000,
        priceOld: 670000,
        creator: {
            id: '1',
            name: '(3)Торговая компания',
            avatar: '/assets/temp/avatars/33x33/3.png',
        },
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 4,
        targetRole: 'technician',
        targetCadcam: 0,
        targetScanner: 1,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale3.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        priceNew: 500000,
        priceOld: 670000,
        creator: {
            id: '1',
            name: '(4)Торговая компания',
            avatar: '/assets/temp/avatars/33x33/4.png',
        },
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },


]

export const _myOffers: MyOffer[] = [
    {
        id: 1,
        targetRole: '',
        targetCadcam: 1,
        targetScanner: 0,
        title: 'Фрезерный станок Roland 52D',
        city: 'Москва, Коломенская',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale1.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Продается Б/У фрезерный станок. Требуется замена шпинделя и техническое обслуживание. Был в работе 12 месяцев. Торг при осмотре. Цена 1 800 000р.`,
        priceNew: 1800000,
        priceOld: 1900000,
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Хирург. шаблон 1', fuid: '#' },
            { name: 'Хирург. шаблон 2', fuid: '#' },
            { name: 'Чумадан.doc', fuid: '#' },
        ]
    },
    {
        id: 2,
        targetRole: 'doctor',
        targetCadcam: 2,
        targetScanner: 2,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale1.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        priceNew: 500000,
        priceOld: 670000,
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 3,
        targetRole: 'doctor',
        targetCadcam: 2,
        targetScanner: 2,
        title: '(3)Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `(3)Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        priceNew: 500000,
        priceOld: 670000,
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 4,
        targetRole: 'technician',
        targetCadcam: 2,
        targetScanner: 2,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale3.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        priceNew: 500000,
        priceOld: 670000,
        publicDate: dateToUnixTime("2023-07-30"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },


]