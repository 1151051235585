import AuthContext from 'AuthContext';
import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getVacancyFeedbacks } from 'service/API';
import { MyVacancy, Vacancy, maxCabinetCardItems } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';
import { getSalaryStr } from 'service/TextTools';

interface MyVacanciesProps {
    actual?: MyVacancy[]
    archived?: MyVacancy[]
}

const MyVacancies: FC<MyVacanciesProps> = ({ actual, archived }) => {
    actual = actual ? actual : []
    archived = archived ? archived : []

    const auth = useContext(AuthContext)
    const history = useHistory()

    const [isArchive, setIsArchive] = useState(false)
    const [maxItems, setMaxItems] = useState(maxCabinetCardItems)

    async function fetchVacancy(myVacancy: MyVacancy) {
        try {
            if (auth.session) {
                const vacancy: Vacancy = { ...myVacancy, creator: { ...auth.session } }
                const feedbacks = await getVacancyFeedbacks(myVacancy.id)
                history.push('/vacancy-view-feedbacks', { vacancy: vacancy, feedbacks: feedbacks })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const Item = (vacancy: MyVacancy) => {
        const { id, title, salaryFrom, salaryUpto, oldFeedbacks, newFeedbacks } = vacancy
        return (
            <article className='card__text-item'>
                <div className='card-data-box' onClick={() => { fetchVacancy(vacancy) }}>
                    <h3 className='card-item__title'>{title}</h3>
                    <p className='card-item__details'>
                        <span>{getSalaryStr(salaryFrom, salaryUpto)}</span>
                    </p>
                </div>
                <div className="card-rank-box">
                    {oldFeedbacks}
                    {newFeedbacks ? <span>+{newFeedbacks}</span> : null}
                </div>
                {/* <div className='data-controls-box'>
                    <button className='push-btn'></button>
                    <button className='edit-btn'></button>
                    <button className='delete-btn'></button>
                </div> */}
            </article>
        )
    }

    const items = isArchive ? archived : actual

    return (
        <section className='section-wrapper'>
            <div className='card-header'>
                <h2 className='card-title'>{TR('Мои вакансии')}
                    {/* <span className='card-title__counter'>{actual.length}</span> */}
                </h2>
                <button className='button-27e' onClick={() => history.push('/vacancy-new')}>{TR('Создать вакансию')}</button>
            </div>
            <div className='card-filter'>
                <div className='swiper-checkbox'>
                    <input type='checkbox' id='vacNewOld' checked={isArchive} onChange={() => setIsArchive(!isArchive)} />
                    <label htmlFor='vacNewOld'>
                        <span className='actual'>{TR('Актуальные')}</span>
                        <span className='not__actual'>{TR('Архив')}</span>
                    </label>
                </div>
            </div>
            <div className='card-body'>
                {items.slice(0, maxItems).map((item) => <Item key={item.id} {...item} />)}
                {items.length > maxCabinetCardItems && maxItems == maxCabinetCardItems ?
                    <button className="rest-items" onClick={() => setMaxItems(items.length)}>
                        <span>{TR_('Ещё') + ' ' + (items.length - maxItems)}</span>
                        <span />
                        <span>{TR('Смотреть все')}</span>
                    </button>
                    : maxItems != maxCabinetCardItems ?
                        <button className="rest-items" onClick={() => setMaxItems(maxCabinetCardItems)}>
                            <span />
                            <span>{TR_('Свернуть')}</span>
                        </button>
                        :
                        null
                }
            </div>
        </section>
    )
}

export default MyVacancies;