const PopupContract = (props) => {
    return (
        <div className="popup-cover">
            <div className="popy popy-agreement">
                <header className="popy-header">
                    <button className="popy-killer"></button>
                    <h2 className="popy-title">Тариф на 3 месяца</h2>
                </header>
                <main className="popy-main to--dark">
                    <div className="agreement-downloads">
                        <a className="agreement-file" href="#">Скачать договор</a>
                    </div>
                    <div className="agreement-box">
                        <div className="agreement-data">
                            <h2 className="agreement__title">Договор</h2>
                            <p className="agreement-data__item">
                                Исходя из данного утверждения, бизнес-модель категорически экономит антропологический анализ
                                рыночных цен. Общество
                                потребления оправдывает эксклюзивный политический процесс в современной России, в итоге
                                возможно
                                появление обратной
                                связи и самовозбуждение системы. Призма, при адиабатическом изменении параметров,
                                интегрирована.
                                Системный анализ,
                                суммируя приведенные примеры, асферично детерминирует формирование имиджа. Идеология вращает
                                межатомный поток.
                            </p>

                            <p className="agreement-data__item">
                                Наряду с этим, силовое поле формирует из ряда вон выходящий атом. Механизм власти
                                стабилизирует
                                конструктивный луч.
                                Вещество определяет медиамикс. Отсюда естественно следует, что галактика определяет
                                постиндустриализм. Колебание, как
                                того требуют законы термодинамики, однократно.
                            </p>

                            <p className="agreement-data__item">
                                Исходя из данного утверждения, бизнес-модель категорически экономит антропологический анализ
                                рыночных цен. Общество
                                потребления оправдывает эксклюзивный политический процесс в современной России, в итоге
                                возможно
                                появление обратной
                                связи и самовозбуждение системы. Призма, при адиабатическом изменении параметров,
                                интегрирована.
                                Системный анализ,
                                суммируя приведенные примеры, асферично детерминирует формирование имиджа. Идеология вращает
                                межатомный поток.
                            </p>

                            <p className="agreement-data__item">
                                Наряду с этим, силовое поле формирует из ряда вон выходящий атом. Механизм власти
                                стабилизирует
                                конструктивный луч.
                                Вещество определяет медиамикс. Отсюда естественно следует, что галактика определяет
                                постиндустриализм. Колебание, как
                                того требуют законы термодинамики, однократно.
                            </p>
                        </div>
                    </div>
                    <div className="popy-agree">
                        <input type="checkbox" className="checkbox-e" id="agreement" />
                        <label className="is-agree" htmlFor="agreement">Я изучил договор и принимаю его условия </label>
                    </div>
                </main>
                <footer className="popy-footer">
                    {/* <button className="main-button is--gray">Продолжить без изменений</button> */}
                    <button className="main-button is--blue">Перейти к оплате</button>
                </footer>
            </div>
        </div>
    )
}

export default PopupContract;