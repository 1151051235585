import AuthContext from 'AuthContext';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteAuctionFeedback } from 'service/API';
import { Auction, AuctionFeedback } from 'service/Data/DentTypes';
import { TR_ } from 'service/T10N';
import AuctionFeedbackCard from './Respond/AuctionFeedbackCard';
import AuctionViewHeader from './Respond/AuctionViewHeader';

interface LocationState {
    auction: Auction
    feedback: AuctionFeedback
}

const AuctionViewFeedback = () => {
    const history = useHistory();
    const auth = useContext(AuthContext)

    const location = useLocation<LocationState>(); // '/auction-view-feedback'
    const auction = location.state.auction
    const feedback = location.state.feedback // look AuctionFeedback

    if (!auction || !feedback || !auth.session)
        return null

    const isOwnFeedback = auth.session.id === feedback.creator.id
    const isOwnAuction = auth.session.id === auction.creator.id

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const handleFeedbackDelete = async (feedbackId: number) => {
        if (await deleteAuctionFeedback(feedbackId)) {
            history.push('/auctions')
            return
        }

        // TODO: show error to user somehow
        console.log("Feedback deleting error");
    }

    const handleFeedbackEdit = async (feedback: AuctionFeedback) => {
        history.push('/auction-new-feedback', {
            auction: auction,
            feedback: feedback
        })
    }

    return (
        <main className="main">
            <div className="container">
                <AuctionViewHeader auction={auction} />
                <section className="reply-box">
                    <h2 className="reply__title mb--20">{TR_('Ваш отклик')} </h2>
                    <AuctionFeedbackCard
                        feedback={feedback}
                        isNew={false}
                        isOwn={isOwnFeedback}
                        onDelete={async () => {
                            handleFeedbackDelete(feedback.id)
                        }}
                        onEdit={() => {
                            handleFeedbackEdit(feedback)
                        }}
                    />
                </section>
            </div>
        </main>
    )
}

export default AuctionViewFeedback;