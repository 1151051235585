import { BlobCache, DentFUID } from "./Data/DentTypes"

export const gAvatarSize = 96
export const gMaxPhotoSize = 1280

//export const announcement = 'Portal 31 Mayıs 2023 tarihinde çalışmaya başlayacak.'
//export const announcement = 'Portal 12 Haziran 2023\'te çalışmaya başlayacak.'
//export const announcement = 'Портал работает в режиме альфа тестирования'//'Портал начинает свою работу с 12 июня 2023'
export const announcement = ''

export function fileUrl(fuid: DentFUID) {
    return fuid instanceof BlobCache ?
        fuid.blobUrl :
        '/public/files/' + fuid
}

export function photoUrl(fuid: DentFUID) {
    return fuid instanceof BlobCache ?
        fuid.blobUrl :
        '/public/photos/' + fuid
}

export function avatarUrl(fuid: DentFUID) {
    return fuid instanceof BlobCache ?
        fuid.blobUrl :
        '/public/avatars/' + fuid
}