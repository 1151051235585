import { firstPhoto } from "Pages/Offers/Components/OfferCard"
import { useHistory } from "react-router-dom"
import { getDateHuman, getDateStr } from "service/API"
import { Offer } from "service/Data/DentTypes"
import { getPriceStr } from "service/TextTools"

const OfferMiniCard = (offer: Offer) => {
    const history = useHistory()

    return (
        <li className="add-list__item">
            <article className="add-item" onClick={() => history.push('/offer-view', { offer: offer })} style={{ cursor: "pointer" }}>
                <div className="add-item__image-box">
                    <img className="add-item__image" src={firstPhoto(offer.photos)}
                        alt="dental portal image" />
                </div>
                <div className="add-item__content-box">
                    <a className="add-item__title">{offer.title}</a>
                    <div className="add-item__details">
                        <div className="add-price__box">
                            <span className="add__price">{getPriceStr(offer.priceNew)}</span>
                            <span className="striked">{getPriceStr(offer.priceOld)}</span>
                        </div>
                        <time className="blind--text" dateTime={getDateStr(offer.publicDate)}>{getDateHuman(offer.publicDate)}</time>
                    </div>
                </div>
            </article>
        </li>
    )
}

export default OfferMiniCard