import AuthContext from 'AuthContext';
import FileUploader from 'Components/FileUploader';
import PhotoSelector from 'Components/PhotoSelector';
import TopSwitch from 'Components/TopSwitch';
import { getDateUnixUTC } from 'Pages/Auctions/AuctionNew';
import { MAX_DESCR_LENGTH, MAX_NAME_LENGTH, useInputFocus, useTextFocus } from 'Pages/Home/Components/MyDataDoctor';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { createOrUpdateOffer, getDateStr } from 'service/API';
import { DentFUID, DentFile, Offer, OfferRequest, OfferTargetRole, boolToTarget } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';

interface LocationState {
    offer?: Offer
}

const OfferNew = () => {
    const location = useLocation<LocationState>(); // '/offer-new'
    const offer: Offer | undefined = location.state?.offer

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isDescriptionShown, setDescriptionShown] = useState(false)

    const [titleRef, setTitleFocus] = useInputFocus()
    const [descriptionRef, setDescriptionFocus] = useTextFocus()

    const [errorShown, setErrorShown] = useState(false);

    // Scroll to top once on page load
    const [scrollToTop, setScrollToTop] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToTop])

    const [targetRole, setTargetRole] = useState<OfferTargetRole>(offer ? offer.targetRole : '')
    const [title, setTitle] = useState(offer ? offer.title : '')
    const [priceNew, setPriceNew] = useState(offer?.priceNew ? offer.priceNew : '')
    const [priceOld, setPriceOld] = useState(offer?.priceOld ? offer.priceOld : '')
    const [dateFrom, setDateFrom] = useState(offer?.dateFrom ? getDateStr(offer?.dateFrom) : '')
    const [dateUpto, setDateUpto] = useState(offer?.dateUpto ? getDateStr(offer?.dateUpto) : '')
    const [city, setCity] = useState(offer ? offer.city : auth.session ? auth.session.city : '')
    const [street, setStreet] = useState(offer ? offer.street : auth.session ? auth.session.street : '')
    const [description, setDescription] = useState(offer ? offer.description : '')
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(offer?.photos.length ? offer.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(offer?.photos ? offer.photos : [])
    const [files, setFiles] = useState<DentFile[]>(offer?.files ? offer.files : [])
    const [hasCadCam, setHasCadCam] = useState<boolean>(offer ? offer.targetCadcam == 2 : false)
    const [hasScanner, setHasScanner] = useState<boolean>(offer ? offer.targetScanner == 2 : false)

    const switchData = {
        switchName: 'role',
        value: targetRole as string,
        setValue: setTargetRole as (v: string) => void,
        items: [
            { title: TR('Для всех'), value: '' },
            { title: TR('Для лабораторий'), value: 'technician' },
            { title: TR('Для клиник'), value: 'doctor' },
        ]
    }

    function validateForm(): boolean {
        if (!title) {
            setErrorShown(true)
            setTitleFocus()
            return false
        }

        if (!description) {
            setErrorShown(true)
            setDescriptionFocus()
            return false
        }

        return true
    }

    const publish = async () => {
        if (!validateForm()) {
            console.log('Validation error...')
            return
        }

        var request: OfferRequest = {
            offerId: offer?.id,
            title: title,
            city: city,
            street: street,
            photos: photos,
            files: files,
            description: description,
            priceNew: priceNew ? +priceNew : 0,
            priceOld: priceOld ? +priceOld : 0,
            targetRole: targetRole,
            targetCadcam: boolToTarget(hasCadCam),
            targetScanner: boolToTarget(hasScanner)
        }

        request.dateFrom = getDateUnixUTC(dateFrom)
        request.dateUpto = getDateUnixUTC(dateUpto)

        console.log(request);

        const reply = await createOrUpdateOffer(request)

        if (reply.status === 'success') {
            // Update success
            console.log('Offer was created');
            history.push('/dash')
        }
        else {
            // TODO: show error to user somehow
            console.log('Offer creation error');
        }
    }

    const offerTitle = offer ? TR_('Изменить предложение') : TR('Создать предложение')

    return (
        <main className="main">
            <div className="container">
                <div className="add-cover">
                    <h1 className="add-title">{offerTitle}</h1>
                    <div className="add">
                        <div className="add__form">
                            <header className="add__header">
                                <button className="add__header-btn" onClick={history.goBack}></button>
                                <h2 className="add__header-title">{offerTitle}</h2>
                            </header>
                            <div className="add__main">
                                <button className="how" onClick={() => setDescriptionShown(true)}>
                                    {TR('Как создать успешное предложение')}
                                </button>
                                <form className="new-add-form">
                                    <TopSwitch {...switchData} />
                                    <div className="field-flex">
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR('Название')}
                                                </p>
                                                {/* <div className="input class--error"> */}
                                                <div className="input" data-tooltip-id="title-tooltip">
                                                    <input maxLength={MAX_NAME_LENGTH} ref={titleRef} type="text" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
                                                </div>
                                                {/* <p className="fied-set__error">Error message</p> */}
                                                <Tooltip id="title-tooltip" isOpen={errorShown && !title} content={TR_("Заголовок не может быть пустым")} />
                                            </div>
                                        </div>
                                        <div className="fiels-flex__item">
                                            <div className="split-flex">
                                                <div className="field-set">
                                                    <p className="field-set__title">
                                                        {TR('Цена старая, ₽')}
                                                    </p>
                                                    <div className="input">
                                                        <input type="text" placeholder="" value={priceOld} onChange={(e) => setPriceOld(e.target.value)} />
                                                    </div>
                                                    <p className="fied-set__error"></p>
                                                </div>
                                                <div className="field-set">
                                                    <p className="field-set__title">
                                                        {TR('Цена новая, ₽')}
                                                    </p>
                                                    <div className="input">
                                                        <input type="text" placeholder="" value={priceNew} onChange={(e) => setPriceNew(e.target.value)} />
                                                    </div>
                                                    <p className="fied-set__error"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-flex">
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR('Дата начала')}
                                                </p>
                                                <div className="input-date">
                                                    <input
                                                        placeholder={TR('Не выбрано')}
                                                        type="date"
                                                        min={getDateStr(new Date())}
                                                        value={dateFrom}
                                                        onChange={(e) => setDateFrom(e.target.value)} />
                                                </div>
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR('Дата окончания')}
                                                </p>
                                                <div className="input-date">
                                                    <input
                                                        placeholder="Не выбрано"
                                                        type="date"
                                                        min={getDateStr(new Date())}
                                                        value={dateUpto}
                                                        onChange={(e) => setDateUpto(e.target.value)} />
                                                </div>
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-flex">
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR('Город')}
                                                </p>
                                                <div className="input">
                                                    <input type="text" placeholder="" value={city} onChange={(e) => setCity(e.target.value)} />
                                                </div>
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR_('Улица')}
                                                </p>
                                                <div className="input">
                                                    <input type="text" placeholder="" value={street} onChange={(e) => setStreet(e.target.value)} />
                                                </div>
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            {TR('Описание')}
                                        </p>
                                        <div className="textarea h--90" data-tooltip-id="description-tooltip">
                                            <textarea ref={descriptionRef} maxLength={MAX_DESCR_LENGTH} name="some-text" id="someText" placeholder={TR_('Опишите детально Ваше предложение')} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                        </div>
                                        <p className="fied-set__error"></p>
                                        <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} />
                                    </div>
                                    <PhotoSelector
                                        photos={photos}
                                        setPhotos={setPhotos}
                                        starred={starredPhoto}
                                        setStarred={setStarredPhoto}
                                    />
                                    <FileUploader files={files} setFiles={setFiles} />
                                    <div className="slide-flex mt--30">
                                        {targetRole == 'technician' ?
                                            <Fragment>
                                                <input type="checkbox" className="checkbox-slide" id="chbxsld" checked={hasCadCam} onChange={() => setHasCadCam(!hasCadCam)} />
                                                <label htmlFor="chbxsld"> <span></span> {TR_('CAD/CAM технология')}</label>
                                            </Fragment>
                                            :
                                            null
                                        }
                                        {targetRole == 'doctor' ?
                                            <Fragment>
                                                <input type="checkbox" className="checkbox-slide" id="chbxsld1" checked={hasScanner} onChange={() => setHasScanner(!hasScanner)} />
                                                <label htmlFor="chbxsld1"> <span></span> {TR('Интраоральный сканер')}</label>
                                            </Fragment>
                                            :
                                            null
                                        }
                                    </div>
                                </form>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide" onClick={() => publish()}>{TR('Опубликовать')}</button>
                            </div>
                        </div>
                        <div className={"add__description" + (isDescriptionShown ? "" : " hide_me")}>
                            <header className="add__header">
                                <button className="add__header-btn" onClick={() => setDescriptionShown(false)} />
                                <h2 className="add__header-title">{TR('Как создать успешное предложение')}</h2>
                            </header>
                            <div className="add__main">
                                <ol className="gide-list">
                                    <li className="gide__item">
                                        {TR('Название Вашего объявления должно быть кратким и понятным для участников портал.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Обязательно указывайте стоимость товара (работы, услуг).')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('В описательной части раскройте все преимущества Вашего предложения, не забывая о сроках поставки, форме оплаты, условиях возврата.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Наличие фото делает Ваше объявление более привлекательным, а также позволяет Заказчику удостовериться в том, что именно этот товар (работа, услуга) его интересует.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Перед публикацией определите категорию потенциальных клиентов по данному предложению - Лаборатория/Клиники/Для всех.')}
                                    </li>
                                </ol>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide">{TR('Закрыть')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default OfferNew;