import { Fragment } from 'react';
import { TR } from 'service/T10N';

const PopupVideo = ({ url, onClose }) => {
    return (
        <Fragment>
            <div className="popy popy-video is--visible">
                <header className="popy-header">
                    <button className="popy-killer" onClick={() => { onClose(); console.log('closing') }} />
                    <h2 className="popy-title popy-title--hod">{TR('Назад')}</h2>
                </header>
                <main className="popy-main">
                    <div className="video-container">
                        <iframe className="video-item" width="100%" height="100%" src={url}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </div>
                </main>
            </div>
            <div className="popup-cover" onMouseDown={() => onClose()} />
        </Fragment>
    )

}

export default PopupVideo;