import AuthContext from 'AuthContext';
import FileUploader from 'Components/FileUploader';
import PhotoSelector from 'Components/PhotoSelector';
import TopSwitch from 'Components/TopSwitch';
import { MAX_DESCR_LENGTH, MAX_NAME_LENGTH, useInputFocus, useTextFocus } from 'Pages/Home/Components/MyDataDoctor';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { createOrUpdateVacancy } from 'service/API';
import { DentFUID, DentFile, Vacancy, VacancyRequest, VacancyTargetRole } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';

interface LocationState {
    vacancy?: Vacancy
}

const VacancyNew = () => {
    const location = useLocation<LocationState>(); // '/vacancy-new'
    const vacancy: Vacancy | undefined = location.state?.vacancy

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isHelpShown, setDescriptionShown] = useState(false)

    const [titleRef, setTitleFocus] = useInputFocus()
    const [descriptionRef, setDescriptionFocus] = useTextFocus()

    const [errorShown, setErrorShown] = useState(false);

    // Scroll to top once on page load
    const [scrollToTop, setScrollToTop] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToTop])

    const [targetRole, setTargetRole] = useState<VacancyTargetRole>(vacancy ? vacancy.targetRole : '')
    const [title, setTitle] = useState(vacancy ? vacancy.title : '')
    const [salaryFrom, setSalaryFrom] = useState(vacancy ? vacancy.salaryFrom : '')
    const [salaryUpto, setSalaryUpto] = useState(vacancy ? vacancy.salaryUpto : '')
    const [city, setCity] = useState(vacancy ? vacancy.city : auth.session ? auth.session.city : '')
    const [description, setDescription] = useState(vacancy ? vacancy.description : '')
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(vacancy?.photos.length ? vacancy.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(vacancy?.photos ? vacancy.photos : [])
    const [files, setFiles] = useState<DentFile[]>(vacancy?.files ? vacancy.files : [])

    useEffect(() => {

    }, [title])

    const switchData = {
        switchName: 'role',
        value: targetRole as string,
        setValue: setTargetRole as (v: string) => void,
        items: [
            { title: TR('Для всех'), value: '' },
            { title: TR('Для врачей'), value: 'doctor' },
            { title: TR('Для техников'), value: 'technician' },
        ]
    }

    function validateForm(): boolean {
        if (!title) {
            setErrorShown(true)
            setTitleFocus()
            return false
        }

        if (!description) {
            setErrorShown(true)
            setDescriptionFocus()
            return false
        }

        return true
    }

    // const [salaryType, setSalaryType] = useState('')
    // const salarySwitchData = {
    //     switchName: 'salary',
    //     value: salaryType,
    //     setValue: setSalaryType,
    //     items: [
    //         { title: 'Точная', value: 'exact' },
    //         { title: 'От', value: 'from' },
    //         { title: 'До', value: 'upto' },
    //     ]
    // }

    const publish = async () => {
        if (!validateForm()) {
            console.log('Validation error...')
            return
        }

        var request: VacancyRequest = {
            vacancyId: vacancy?.id,
            targetRole: targetRole,
            title: title,
            city: city,
            salaryFrom: salaryFrom ? +salaryFrom : 0,
            salaryUpto: salaryUpto ? +salaryUpto : 0,
            description: description,
            photos: photos,
            files: files,
        }

        console.log(request);

        const reply = await createOrUpdateVacancy(request)

        if (reply.status === 'success') {
            // Update success
            console.log('Vacancy was created');
            history.push('/dash')
        }
        else {
            // TODO: show error to user somehow
            console.log('Vacancy creation error');
        }
    }

    const headerTitle = vacancy ? TR_('Редактировать вакансию') : TR('Создать вакансию')

    return (
        <main className="main">
            <div className="container">
                <div className="add-cover">
                    <h1 className="add-title">{headerTitle}</h1>
                    <div className="add">
                        <div className="add__form">
                            <header className="add__header">
                                <button className="add__header-btn" onClick={history.goBack}></button>
                                <h2 className="add__header-title">{headerTitle}</h2>
                            </header>
                            <div className="add__main">
                                <button className="how" onClick={() => setDescriptionShown(true)}>
                                    {TR('Как создать новую вакансию')}
                                </button>
                                <form className="new-add-form" action="post">
                                    <TopSwitch {...switchData} />
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            {TR('Заголовок')}
                                        </p>
                                        <div className="input" data-tooltip-id="title-tooltip">
                                            <input ref={titleRef} maxLength={MAX_NAME_LENGTH} type="text" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                        <p className="fied-set__error"></p>
                                        <Tooltip id="title-tooltip" isOpen={errorShown && !title} content={TR_("Заголовок не может быть пустым")} />
                                    </div>
                                    <div className="field-flex">
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <div className="title-flexbox">
                                                    <p className="field-set__title" style={{ height: "36px" }}>
                                                        {TR('Зарплата, ₽')}
                                                    </p>
                                                    {/* <TopSwitch {...salarySwitchData} /> */}
                                                </div>
                                                <div className="range-box">
                                                    <div className="input">
                                                        <input type="number" placeholder={TR('От')} value={salaryFrom} onChange={(e) => setSalaryFrom(e.target.value)} />
                                                    </div>
                                                    <div className="input">
                                                        <input type="number" placeholder={TR('До')} value={salaryUpto} onChange={(e) => setSalaryUpto(e.target.value)} />
                                                    </div>
                                                </div>
                                                {/* <div className="input">
                                                    <input placeholder="От" type="text" />
                                                </div>
                                                <div className="input">
                                                    <input placeholder="До" type="text" />
                                                </div> */}
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title" style={{ height: "36px" }}>
                                                    {TR('Город')}
                                                </p>
                                                <div className="input">
                                                    <input placeholder={TR('Не имеет значения')} type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                                </div>
                                                {/* <input type="checkbox" className="checkbox-e" id="chbx-e" />
                                                <label htmlFor="chbx-e">{TR('Не имеет значения')} </label> */}
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            {TR('Описание')}
                                        </p>
                                        <div className="textarea h--90" data-tooltip-id="description-tooltip">
                                            <textarea ref={descriptionRef} maxLength={MAX_DESCR_LENGTH} name="some-text" id="someText" placeholder={TR('Опишите детально, что Вам нужно')} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                        </div>
                                        <p className="fied-set__error"></p>
                                        <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} />
                                    </div>
                                    <PhotoSelector
                                        photos={photos}
                                        setPhotos={setPhotos}
                                        starred={starredPhoto}
                                        setStarred={setStarredPhoto}
                                    />
                                    <FileUploader files={files} setFiles={setFiles} />
                                </form>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide" onClick={() => publish()}>{TR('Опубликовать')}</button>
                            </div>
                        </div>
                        <div className={"add__description" + (isHelpShown ? "" : " hide_me")}>
                            <header className="add__header">
                                <button className="add__header-btn" onClick={() => setDescriptionShown(false)} />
                                <h2 className="add__header-title">{TR('Как создать успешную вакансию')}</h2>
                            </header>
                            <div className="add__main">
                                <ol className="gide-list">
                                    <li className="gide__item">
                                        {TR('Определите категорию потенциальных Исполнителей по данной Вакансии - Для всех/Для врачей/Для техников.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Название должно быть кратким и понятным для участников портала.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Укажите пределы зарплаты (от/до). Одно из полей можно не указывать. Если вы хотите указать точную зарплату, то укажите её в обоих полях.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Укажите город, если вакансия ограничена территориально.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('В описательной части Вакансии укажите на все особенности и требования, которые необходимо соблюсти.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR_('Обязательно подгружайте файлы и фотографии, позволяющие судить о сложности и специфике выполняемых работ.')}
                                    </li>

                                </ol>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide">{TR('Закрыть')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default VacancyNew;