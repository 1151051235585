import { FC } from "react";
import { Auction } from "service/Data/DentTypes";
import { TR } from "service/T10N";
import AuctionMiniCard from "./Common/AuctionMiniCard";

interface PurchasesDoctorProps {
    thePurchases?: Auction[]
}

const PurchasesDoctor: FC<PurchasesDoctorProps> = ({ thePurchases }) => {
    const auctions = thePurchases ? thePurchases : []

    // const [auctions, setAuctions] = useState<Auction[]>([])

    // // Fetching offers once
    // const [fetcher] = useState(false)
    // useEffect(() => {
    //     (async () => {
    //         const auctions = await getDoctorAuctions()
    //         setAuctions(auctions.slice(0, 0))
    //     })();
    // }, [fetcher])

    return (
        <section className="section-wrapper">
            <div className="card-header">
                <h2 className="card-title">
                    <img className="card-title__icon" src="/assets/icons/hammer.svg" alt="dental portal image" />
                    {TR('Закупки клиник')}
                </h2>
            </div>
            <div className="card-body">
                {auctions.map((auction) => {
                    return <AuctionMiniCard key={auction.id} {...auction} />
                })}
            </div>
        </section>
    )
}

export default PurchasesDoctor;