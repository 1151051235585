import { useContext, useState } from 'react';

import AuthContext from 'AuthContext';
import { useHistory } from 'react-router-dom';
import { TR } from 'service/T10N';
import PopupVideo from './Popups/PopupVideo';

const Welcome = () => {
    const history = useHistory()
    const [videoUrl, setVideoUrl] = useState("")

    const auth = useContext(AuthContext);

    //console.log('Auth: ')
    console.log(auth.isLoggedIn())

    return (
        <main className="main">
            <div className="container">
                <section className="h-section">
                    <h1 className="h-title">{TR('Онлайн-платформа для поиска заказов, товаров и услуг')}</h1>
                    <p className="h-subtitle"><span>{TR('100% целевая аудитория.')}</span><span>{TR('100% релевантные предложения.')}</span></p>
                    <div className="h-flex">
                        {/* <article className="h-item" onClick={() => history.push('/doctors')}> */}
                        <article className="h-item">
                            <img className="h__img img--88" src="/assets/images/dentchair.png" alt="" />
                            <h2 className="h-item__title ws--pre">{TR("Клиники \n Врачи")}</h2>
                            <a className="h-details ptr ws--pre" onClick={(e) => {
                                setVideoUrl(TR('https://www.youtube.com/embed/JXU8rvXD1io?autoplay=1'))
                                e.stopPropagation();
                            }}>{TR('Подробнее \n о разделе')}</a>
                        </article>
                        {/* <article className="h-item" onClick={() => history.push('/technicians')}> */}
                        <article className="h-item">
                            <img className="h__img img--96" src="/assets/images/lab.png" alt="" />
                            <h2 className="h-item__title ws--pre">{TR('Лаборатории \n Техники')}</h2>
                            {/* <a className="h-details" href="https://www.youtube.com/watch?v=-DpDx86E_i0">Подробнее <br /> о разделе</a> */}
                            {/* <a className="h-details" href="https://www.youtube.com/watch?v=-DpDx86E_i0" onClick={(e) => e.stopPropagation()}>Подробнее <br /> о разделе</a> */}
                            <a className="h-details ptr ws--pre" onClick={(e) => {
                                setVideoUrl(TR("https://www.youtube.com/embed/-DpDx86E_i0?autoplay=1"))
                                e.stopPropagation();
                            }}>{TR('Подробнее \n о разделе')}</a>
                        </article>
                        {/* <article className="h-item" onClick={() => history.push('/traders')}> */}
                        <article className="h-item">
                            <img className="h__img" src="/assets/images/instruments.png" alt="" />
                            <h2 className="h-item__title ws--pre">{TR('Торговые \n компании')}</h2>
                            {/* <a className="h-details" href="https://www.youtube.com/watch?v=w3OGGxbR4fM" onClick={(e) => e.stopPropagation()}>Подробнее <br /> о разделе</a> */}
                            <a className="h-details ptr ws--pre" onClick={(e) => {
                                setVideoUrl(TR("https://www.youtube.com/embed/w3OGGxbR4fM?autoplay=1"))
                                e.stopPropagation();
                            }}>{TR('Подробнее \n о разделе')}</a>
                        </article>
                    </div>
                </section>
                {videoUrl ? <PopupVideo url={videoUrl} onClose={() => setVideoUrl("")} /> : null}
                {/* Disabling banners for presentation */}
                {/* <section className="h-banners">
                    <h2 className="h-banner-title">
                        Предложения наших партнеров
                    </h2>
                    <div className="h-banner-flex">
                        <a className="h-banner__item" href="#">
                            <img className="h-banner__image" src="/assets/images/banner3.png" alt="" />
                        </a>
                        <a className="h-banner__item" href="#">
                            <img className="h-banner__image" src="/assets/images/banner2.png" alt="" />
                        </a>
                    </div>
                </section> */}
            </div>
        </main>
    )
}

export default Welcome
