import { useEffect, useState } from "react";
import { DashData, getDashData } from "service/API";
import { announcement } from "service/Globals";
import OffersFromTechnicians from "./Components/OffersTechnicians";
import OffersFromTraders from "./Components/OffersTraders";
import PurchasesDoctor from "./Components/PurchasesDoctor";
import PurchasesTechnician from "./Components/PurchasesTechnician";

const HomeTechnician = () => {
    const [data, setData] = useState<DashData>()
    const [firstLoad] = useState()
    useEffect(() => {
        (async () => {
            //console.log('Home technician effect')
            const homeData = await getDashData({
                // myAuctions: { actual: [], archived: [] },
                // myOffers: { actual: [], archived: [] },
                // mySales: { actual: [], archived: [] },
                // myVacancies: { actual: [], archived: [] },
                offersFromTechnicians: [],
                offersFromTraders: [],
                purchasesFromDoctors: [],
                purchasesFromTechnicians: []
            })
            setData(homeData)
        })();
    }, [firstLoad]);

    return (
        <main className="main">
            <div className="container ws--pre">
                {/* Кабинет для техников */}
                {announcement ? <div className="home temp-header">{announcement}</div> : null}
                {/* {TR('Портал начинает свою работу с 29 мая 2023\n Осталось NN дней')} */}
                <div className="main-flex">
                    <div className="main-flex__item">
                        <OffersFromTechnicians theOffers={data?.offersFromTechnicians} />
                        <PurchasesDoctor thePurchases={data?.purchasesFromDoctors} />
                    </div>
                    <div className="main-flex__item">
                        <OffersFromTraders theOffers={data?.offersFromTraders} />
                        <PurchasesTechnician thePurchases={data?.purchasesFromTechnicians} />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default HomeTechnician;