import SearchFilters from 'Components/SearchFilters';
import { Fragment, useEffect, useState } from 'react';

import ResultsCounter from 'Components/ResultsCounter';
import SortBy from 'Components/SortBy';
import TopSwitch from 'Components/TopSwitch';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { getTechnicians } from 'service/API';
import { Technician } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';
import UserCard from './Components/UserCard';

const ListTechnicians = () => {
    let history = useHistory();

    const [isMapShown, setMapShown] = useState(false);

    const [justInitialized, setJustInitizlized] = useState(true);
    const [technicians, setTechnicians] = useState<Technician[] | null>(null)

    const resetFilters = () => {
        setFindText('')
        setCities([])
        setHasPrice(false)
        setHasCadCam(false)
    }

    const [findText, setFindText] = useState('')
    const [cities, setCities] = useState([])
    const [hasPrice, setHasPrice] = useState(false)
    const [hasCadCam, setHasCadCam] = useState(false)
    const [sortBy, setSortBy] = useState('date')

    useEffect(() => {
        (async () => {
            const technicians = await getTechnicians(findText, cities, hasPrice, hasCadCam, sortBy)
            //setTechnicians(technicians.slice(0, 0)) // ~~ disabling list
            setTechnicians(technicians)
            setJustInitizlized(false)
        })()
    }, [findText, cities, hasPrice, hasCadCam, sortBy]);

    const filterTechnicians = [
        {
            id: "text",
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
                // onSearch: handleSearchButton,
            }
        },
        // {
        //     id: "cities",
        //     subset: {
        //         from: getAvailableCities(),
        //         value: cities,
        //         setValue: setCities,
        //         nonEmptyTitle: TR('Выбранные города'),
        //         whenEmptyTitle: TR('По всем городам'),
        //         popupTitle: TR('Город'),
        //     }
        // },
        {
            id: "hasPrice",
            check: {
                id: "hasPrice",
                title: TR('Наличие прайса'),
                value: hasPrice,
                setValue: setHasPrice
            }
        },
        {
            id: "hasCadCam",
            check: {
                id: "hasCadCam",
                title: "CAD/CAM",
                value: hasCadCam,
                setValue: setHasCadCam
            }
        }
    ]

    const [role, setRole] = useState('technician')

    useEffect(() => {
        if (role !== 'technician')
            history.push('/' + role + 's')
    }, [role])

    const switchData = {
        switchName: 'roles',
        value: role,
        setValue: setRole,
        items: [
            { title: TR_('Клиники'), value: 'doctor' },
            { title: TR_('Лаборатории'), value: 'technician' },
            { title: TR('Торговые компании'), value: 'trader' },
        ]
    }

    return (
        <main className="main">
            <div className="container">
                <h1 className="section-title">{TR('Лаборатории & Техники')}</h1>
                <div className="list-controls">
                    <div className="list-controls__switch">
                        <TopSwitch {...switchData} />
                        {/* <div className="from-who-box"> ~~~ temporarily disabling map
                            <input className="from-who" type="radio" id="card" name="cards" onClick={() => setMapShown(false)} defaultChecked />
                            <label htmlFor="card">{TR('Карточки')}</label>
                            <input className="from-who" type="radio" id="map" name="cards" onClick={() => setMapShown(true)} />
                            <label htmlFor="map">{TR('На карте')}</label>
                        </div> */}
                    </div>
                    <div className="list-controls__sort">
                        {!isMobile ?
                            < Fragment >
                                < SortBy value={sortBy} setValue={setSortBy} />
                                <ResultsCounter value={technicians ? technicians.length : 0} />
                            </Fragment>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="list-base">
                    <SearchFilters options={filterTechnicians} onResetFilters={resetFilters} banner={2} />
                    <div className="list-ads">
                        {/* add class list-map--open */}
                        <div className={"list-map" + (isMapShown ? " list-map--open" : "")}></div>
                        {/* <div className="list-map list-map--open" hidden={!isMapShown} /> */}
                        {technicians ? technicians.map((technician) =>
                            <UserCard key={technician.id} user={technician} />)
                            : justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ListTechnicians
