import FileUploader from 'Components/FileUploader'
import PhotoSelector from 'Components/PhotoSelector'
import { useTextFocus } from 'Pages/Home/Components/MyDataDoctor'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { createOrUpdateVacancyFeedback } from 'service/API'
import { DentFUID, DentFile, Vacancy, VacancyFeedback, VacancyFeedbackRequest } from 'service/Data/DentTypes'
import { TR_ } from 'service/T10N'
import VacancyViewHeader from './Components/VacancyViewHeader'

interface LocationState {
    vacancy: Vacancy
    feedback: VacancyFeedback
}

const VacancyNewFeedback = () => {
    const location = useLocation<LocationState>() // '/vacancy-new-feedback'
    const vacancy: Vacancy = location.state?.vacancy
    const feedback: VacancyFeedback = location.state?.feedback

    if (!vacancy)
        return null

    const history = useHistory()

    const [isDescriptionShown, setDescriptionShown] = useState(false)
    const [areContactsShown, setContactsShown] = useState(false)
    const [isOwn, setOwn] = useState(false)

    const [descriptionRef, setDescriptionFocus] = useTextFocus()

    const [description, setDescription] = useState(feedback ? feedback.description : '')
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(feedback?.photos?.length ? feedback.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(feedback?.photos ? feedback.photos : [])
    const [files, setFiles] = useState<DentFile[]>(feedback?.files ? feedback.files : [])

    const [errorShown, setErrorShown] = useState(false)

    function validateForm(): boolean {
        if (!description) {
            setErrorShown(true)
            setDescriptionFocus()
            return false
        }

        return true
    }

    const publishFeedback = async () => {
        if (!validateForm()) {
            //console.log('Validation error...')
            return
        }

        var request: VacancyFeedbackRequest = {
            feedbackId: feedback?.id,
            description: description,
            photos: photos,
            files: files,
        }

        //console.log(request)

        try {
            const reply = await createOrUpdateVacancyFeedback(vacancy.id, request)

            if (reply.feedback) {
                // Update success
                //console.log('Vacancy feedback was created')
                //history.push('/')
                history.push('/vacancy-view-feedback', { vacancy: vacancy, feedback: reply.feedback })
                return
            }
        } catch (e) { }

        // TODO: show error to user somehow
        console.log('Vacancy feedback creation error')
    }

    return (
        <main className="main">
            <div className="container">
                <VacancyViewHeader vacancy={vacancy} isOwn={isOwn} />
                <section className="respond">
                    <div className="respond-cover">
                        <h1 className="respond-title">{TR_('Ваш отклик')}</h1>
                        <div className="add">
                            <div className="respond__form">
                                <div className="respond__main">
                                    <button className="how" onClick={() => setDescriptionShown(true)}>
                                        {TR_('Как написать хороший отклик')}
                                    </button>
                                    <form className="new-add-form">
                                        <div className="field-set">
                                            <p className="field-set__title">
                                                {TR_('Сопроводительное письмо')}
                                            </p>
                                            <div className="textarea h--90" data-tooltip-id="description-tooltip">
                                                <textarea
                                                    ref={descriptionRef}
                                                    name="some-text"
                                                    id="someText"
                                                    placeholder={TR_('Опишите Ваш опыт при выполнении подобных работ')}
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}>
                                                </textarea>
                                            </div>
                                            <p className="fied-set__error"></p>
                                            <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} />
                                        </div>
                                        <p className="field-set__title">
                                            {TR_('Резюме')}
                                        </p>
                                        <PhotoSelector
                                            photos={photos}
                                            setPhotos={setPhotos}
                                            starred={starredPhoto}
                                            setStarred={setStarredPhoto}
                                        />
                                        <FileUploader files={files} setFiles={setFiles} />
                                    </form>
                                </div>
                                <div className="add__footer">
                                    <button className="main-button is--blue too--wide" onClick={() => publishFeedback()}>
                                        {TR_('Опубликовать')}
                                    </button>
                                </div>
                            </div>
                            <div className={"respond__description" + (isDescriptionShown ? "" : " hide_me")}>
                                <div className=" respond__description-inner">
                                    <header className="add__header">
                                        <button className="add__header-btn" onClick={() => setDescriptionShown(false)}></button>
                                        <h2 className="add__header-title">{TR_('Как написать хороший отклик')}</h2>
                                    </header>
                                    <div className="add__main">
                                        <ol className="gide-list">
                                            <li className="gide__item">
                                                {TR_('Объективно оцените свои возможности.')}
                                            </li>
                                            <li className="gide__item">
                                                {TR_('Заполните предлагаемую форму.')}
                                            </li>
                                            <li className="gide__item">
                                                {TR_('Опишите свой опыт в выполнении подобных работ.')}
                                            </li>
                                            <li className="gide__item">
                                                {TR_('Приложите фото или иные материалы подтверждающие Ваши компетенции.')}
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="add__footer">
                                        {/* <button className="main-button is--blue  too--wide">Закрыть</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </main>
    )
}

export default VacancyNewFeedback