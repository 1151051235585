import { dateToUnixTime } from "service/API"
import { MySale, Sale } from "./DentTypes"

export const _salesData: Sale[] = [
    {
        id: 1,
        title: 'Фрезерный станок Roland 52D',
        city: 'Москва, Коломенская',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale1.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Продается Б/У фрезерный станок. Требуется замена шпинделя и техническое обслуживание. Был в работе 12 месяцев. Торг при осмотре. Цена 1 800 000р.`,
        price: 1800000,
        creator: {
            id: '1',
            name: 'Зуботехническая лаборатория',
            avatar: '/assets/temp/avatars/33x33/1.png',
        },
        //untilDate: new Date("2023-04-30"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Хирург. шаблон 1', fuid: '#' },
            { name: 'Хирург. шаблон 2', fuid: '#' },
            { name: 'Чумадан.doc', fuid: '#' },
        ]
    },
    {
        id: 2,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale1.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        price: 670000,
        creator: {
            id: '1',
            name: 'Торговая компания',
            avatar: '/assets/temp/avatars/33x33/2.png',
        },
        //untilDate: new Date("2023-04-29"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 3,
        title: '(3)Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `(3)Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        price: 670000,
        creator: {
            id: '1',
            name: '(3)Торговая компания',
            avatar: '/assets/temp/avatars/33x33/3.png',
        },
        //untilDate: new Date("2023-04-25"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 4,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale3.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        price: 500000,
        creator: {
            id: '1',
            name: '(4)Торговая компания',
            avatar: '/assets/temp/avatars/33x33/4.png',
        },
        //untilDate: new Date("2023-04-29"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
]

export const _mySales: MySale[] = [
    {
        id: 1,
        title: 'Фрезерный станок Roland 52D',
        city: 'Москва, Коломенская',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale1.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Продается Б/У фрезерный станок. Требуется замена шпинделя и техническое обслуживание. Был в работе 12 месяцев. Торг при осмотре. Цена 1 800 000р.`,
        price: 1800000,
        //untilDate: new Date("2023-04-30"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Хирург. шаблон 1', fuid: '#' },
            { name: 'Хирург. шаблон 2', fuid: '#' },
            { name: 'Чумадан.doc', fuid: '#' },
        ]
    },
    {
        id: 2,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale1.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        price: 670000,
        //untilDate: new Date("2023-04-29"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 3,
        title: '(3)Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale2.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `(3)Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        price: 670000,
        //untilDate: new Date("2023-04-25"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
    {
        id: 4,
        title: 'Продается стоматологическая установка AJAX - AJ16',
        city: 'Москва, ВДНХ',
        street: 'Нагатинская набережная, д.35',
        photos: [
            '/assets/temp/images/411x365/Sale3.png',
            '/assets/temp/images/411x365/Sale2.png',
        ],
        description: `Новая установка с дефектом при транспортировке (рваная обшивка кресла). Без торга. Самовывоз. `,
        price: 500000,
        //untilDate: new Date("2023-04-29"),
        updatedAt: dateToUnixTime("2023-04-29"),
        publicDate: dateToUnixTime("2023-04-29"),
        files: [
            { name: 'Файл 1', fuid: '#' },
        ]
    },
]