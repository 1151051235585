
const PopupChecklist = (props) => {
    return (
        <div className="cover">
            <div className="sideup">
                <button className="killer"></button>
                <header className="sideup-header">
                    <h1 className="sideup-title">
                        Имплантология
                        и хирургия
                    </h1>
                </header>
                <div className="sideup-body">
                    <form action="">
                        <div className="side-search">
                            <div className="input-search">
                                <input type="text" placeholder="Поиск" />
                                <button className="loop"></button>
                            </div>
                        </div>
                        <div className="check-list">
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e1" />
                                <label htmlFor="chbx-e1">Astra Tech </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e2" />
                                <label htmlFor="chbx-e2">Sewon Medix </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e3" />
                                <label htmlFor="chbx-e3">Ankylos </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e4" />
                                <label htmlFor="chbx-e4">Ankylos </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e5" />
                                <label htmlFor="chbx-e5">Ankylos </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e6" />
                                <label htmlFor="chbx-e6">Ankylos </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e7" />
                                <label htmlFor="chbx-e7">Ankylos </label>
                            </div>
                            <div className="check-list__item">
                                <input type="checkbox" className="checkbox-e" id="chbx-e8" />
                                <label htmlFor="chbx-e8">Ankylos </label>
                            </div>
                        </div>
                        <div className="sideup-controls">
                            <button className="button-e not--active w--100">Применить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PopupChecklist;