import AuthContext from 'AuthContext';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteNotification, downloadFile, getDateHuman, getDateStr } from 'service/API';
import { DentNotification } from 'service/Data/DentTypes';
import { fileUrl, photoUrl } from 'service/Globals';
import { TR } from 'service/T10N';

interface PopupNotificationProps {
    notifications: DentNotification[]
    onClose: () => void
}

const PopupNotifications: FC<PopupNotificationProps> = ({ onClose = () => { }, notifications }) => {
    return (
        <Fragment>
            <div className='notifications is--visible'>
                <button className='user__close' onClick={onClose}></button>
                <h2 className='notification__title'>{TR('Уведомления')}</h2>
                <div className='notifications__inner'>
                    {
                        notifications.map((notification) => <NotificationItem key={notification.id} notification={notification} isImportant={false} />)
                    }
                </div>
            </div>
            <div className='notifications-cover' onClick={onClose}></div>
        </Fragment>
    )
}

interface NotificationItemProps {
    notification: DentNotification
    isImportant?: boolean
    canEdit?: boolean // can edit
}

export const NotificationItem: FC<NotificationItemProps> = ({ notification, isImportant = false, canEdit }) => {
    const { id, title, description, publicDate, photos, files } = notification

    const history = useHistory()
    const auth = useContext(AuthContext)

    const handleEdit = (notification: DentNotification) => {
        history.push('/notification-new', { notification: notification })
    }

    const [canDelete, setCanDelete] = useState(false)
    const handleDelete = async (notification: DentNotification) => {
        if (canDelete && notification.id) {
            const deleted = await deleteNotification(notification.id)
            if (deleted) {
                auth.doNotificationsUpdate()
            }

            return
        }

        setCanDelete(true)
    }

    useEffect(() => {
        {// Refetch on change logic
            const timeout = 2 * 1000
            const timer = setTimeout(async () => {
                setCanDelete((prev) => false)
            }, timeout)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [canDelete])

    return (
        <article className="notification__item">
            {title ?
                <h4 className={'notification__item-title' + (isImportant ? ' is--note' : '')}>{title}</h4>
                :
                null
            }
            {photos && photos.length ?
                <div className={(canEdit ? "notification__image-box " : "") + "view__media-logo"}>
                    <img src={photoUrl(photos[0])} alt="" />
                </div>
                :
                null
            }
            {description ?
                <p className="notification__item-text">
                    {description}
                </p>
                :
                null
            }
            {files && files.length ?
                <div className="notification__btn-container">
                    {
                        files.map((file) =>
                            <button className="button-link-doc"
                                key={fileUrl(file.fuid)}
                                onClick={() => downloadFile(fileUrl(file.fuid), file.name)}
                                rel='noopener noreferrer'>
                                <span />{file.name}</button>)
                    }
                </div>
                : null
            }
            {canEdit ?
                <div className="flex-between">
                    <time className="notification__item-date" dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
                    <div className="notes-ctrls">
                        <button onClick={() => handleEdit(notification)}>
                            <img src='/assets/icons/Edit.svg' />
                        </button>
                        <button onClick={() => handleDelete(notification)}>
                            {canDelete ?
                                <img src='/assets/icons/Delete-red.svg' />
                                :
                                <img src='/assets/icons/Delete.svg' />
                            }

                        </button>
                    </div>
                </div>
                :
                <time className="notification__item-date" dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
            }

        </article>
    )
}

export default PopupNotifications;