import { FC } from "react"
import { Offer } from "service/Data/DentTypes"
import { TR } from "service/T10N"
import OfferMiniCard from "./Common/OfferMiniCard"

interface OffersTradersProps {
    theOffers?: Offer[]
}

const OffersFromTraders: FC<OffersTradersProps> = ({ theOffers }) => {
    const offers = theOffers ? theOffers : []

    return (
        <section className="section-wrapper">
            <div className="card-header">
                <h2 className="card-title">
                    <img className="card-title__icon" src="/assets/icons/Discount.svg"
                        alt="dental portal image" />
                    {TR('Предложения поставщиков')}
                </h2>
            </div>
            <div className="card-body">
                <ul className="add-list">
                    {offers.map((offer) => {
                        return <OfferMiniCard key={offer.id} {...offer} />
                    })}
                </ul>
            </div>
        </section>
    )
}

export default OffersFromTraders;