import { yupResolver } from '@hookform/resolvers/yup';
import AuthContext, { Session } from 'AuthContext';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { net } from 'service/API';
import { TR } from 'service/T10N';
import useSigninData from './hooks/useSigninData';
//import 'Pages/Signup/style.css'

interface SigninForm {
    email: string
    password: string
}

const Signin = () => {
    const history = useHistory();

    const defaultValues = {
        email: '',
        password: '',
    }

    const [errorStr, setErrorStr] = useState("")
    const auth = useContext(AuthContext);
    const { schema } = useSigninData();
    const methods = useForm<SigninForm>({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const submitHandler = async (data: SigninForm) => {

        //console.log(data)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        }

        try {
            type Reply = {
                status: string
                message?: string
                session?: Session
            }

            const reply = await net.post<Reply>('/api/v1/auth/signin', JSON.stringify(data))

            // const response = await fetch('/api/v1/auth/signin', requestOptions)
            //     .then((response) => response.json())

            console.log(reply)

            if (reply.session) {
                methods.reset()
                auth.signin({ email: data.email, ...reply.session })
                history.replace("/")
            } else {
                // TODO: implement error codes?
                console.log(reply)
                if ('message' in reply) {
                    setErrorStr("Login error...") // ~~~ english
                    //setErrorStr(response.message)
                }
            }
        }
        catch (error) {
            //console.log(error)
            setErrorStr("Server error...") // ~~~ english
        }
    }

    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="./elements.html" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--up">
                    <h1 className="sign-title">
                        {TR('Войти')}
                    </h1>
                    <FormProvider {...methods}>
                        <form className="sign-form" onSubmit={methods.handleSubmit(submitHandler)}>
                            <div className="sign-fields">
                                <div className="field-set">
                                    <p className="field-set__title">
                                        {TR('E-mail')}
                                    </p>
                                    <div className="input" data-tooltip-id="email-tooltip">
                                        <input type="email" {...methods.register('email')} placeholder={TR('Электронная почта...')} />
                                    </div>
                                    <Tooltip id="email-tooltip" isOpen={methods.formState.errors.email ? true : false} content={methods.formState.errors.email?.message} />
                                    <p className="fied-set__error">{errorStr}</p>
                                </div>
                                <div className="field-set">
                                    <p className="field-set__title">
                                        {TR('Пароль')}
                                    </p>
                                    <div className="input" data-tooltip-id="pass-tooltip">
                                        <input type="password" {...methods.register('password')} placeholder={TR('Ваш пароль...')} />
                                    </div>
                                    <Tooltip id="pass-tooltip" isOpen={!!methods.formState.errors.password} content={methods.formState.errors.password?.message} />
                                    {/* <p className="fied-set__error"></p> */}
                                </div>
                            </div>
                            <button className="button-f w--100 h--44" type="submit">{TR('Войти')}</button>
                            {/* <a className="button-e w--100 h--44" onClick={() => history.push("/signup")}>{TR('Зарегистрироваться')}</a> */}
                            <button className="no-button w--100 forgot--button" onClick={() => history.push("/forgotpassword")}>{TR('Забыли пароль?')}</button>
                        </form>
                    </FormProvider>
                </div>
            </main>
        </div>
        // <main>
        //     <div className="Wrapper">
        //         <a className="logo" href="index.html">
        //             <img src="/playground_assets/Registration-logo.svg" width="238" height="26" alt="Логотип Dental Portal" />
        //         </a>
        //         <section className="layout login">
        //             <FormProvider {...methods}>
        //                 <form className="form formSignin" onSubmit={methods.handleSubmit(submitHandler)}>
        //                     <h1 className="title">Войти</h1>

        //                     <fieldset className="formField textField">
        //                         <label htmlFor="email" className="label">E-mail</label>
        //                         <input type="email" {...methods.register('email')} name="email" id="email" placeholder="Введите ваш email..." required />
        //                         <p>{methods.formState.errors.email?.message}</p>
        //                     </fieldset>

        //                     <fieldset className="formField textField">
        //                         <label htmlFor="password" className="label">Пароль</label>
        //                         <input type="password" {...methods.register('password')} name="password" id="password" placeholder="Введите пароль..." required />
        //                         <p className="passwordInfo error">{methods.formState.errors.password?.message || errorStr}</p>
        //                     </fieldset>

        //                     <button className="btn RegistrationBtn" type="submit">Войти</button>
        //                     <a className="btn loginBtn" href="/signup">Зарегистрироваться</a>
        //                     <a href="#" className="link passwordLink">Забыли пароль?</a>
        //                 </form>
        //             </FormProvider>
        //         </section>
        //     </div>
        // </main>
    )
}

export default Signin;