import { moneySymbol } from "./Lang";

export const humanFileSize = (size, digits = 2) => {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(digits) + ['B', 'Kb', 'Mb', 'Gb', 'Tb'][i];
}

  /**
   * Formats a number to human readable form
   * @param value The value to format
   * @param thousandSeparator The separator to use between thousands
   * @param decimalSeparator The separator to use before decimals
   * @param significantDigits The number of significant digits to show
   * @param showTrailingZeros Whether to show trailing zeros for significant digits (i.e. 1,00 if significant digits is 2)
   * @returns
   */
  export const getHumanNumber = (
    value,
    thousandSeparator = ' ',
    decimalSeparator = ',',
    significantDigits = 0,
    showTrailingZeros = false) => {

    const significantDigitsExponent = 10 ** significantDigits;
    const valueWithSignificantDigits = showTrailingZeros
      ? // If significant digits is 2 then this is e.g. 1.00, 1.10, 1.11
        value.toFixed(significantDigits)
      : // If significant digits is 2 then this is e.g. 1, 1.1, 1.11
        `${Math.round((value + Number.EPSILON) * significantDigitsExponent) / significantDigitsExponent}`;
  
    // Split the value into the parts before and after the decimal point
    const [integerPart, fractionalPart] = valueWithSignificantDigits.toString().split('.');
    // Replace thousand separator in integer part
    const formattedIntegerPart = `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)}`;
    // Add decimal separator and fractional part if needed
    const formattedValue = fractionalPart
      ? `${formattedIntegerPart}${decimalSeparator}${fractionalPart}`
      : formattedIntegerPart;
  
    return formattedValue;
  };

  export const getPriceStr = (
    value,
    thousandSeparator = ' ',
    decimalSeparator = ',',
    significantDigits = 0,
    showTrailingZeros = false,
    symbol = moneySymbol,
    symbolPosition = 'after',
    showSymbolSpace = true) => {

    const formattedValue = getHumanNumber(value, thousandSeparator, decimalSeparator, significantDigits, showTrailingZeros)
  
    // Add symbol
    const formattedValueWithSymbol =
        symbolPosition === 'after' ? `${formattedValue} ${symbol}` : `${symbol} ${formattedValue}`;
    return showSymbolSpace ? formattedValueWithSymbol : formattedValueWithSymbol.replace(' ', '');
  };

  export const getSalaryStr = (salaryFrom , salaryUpto) => {
    return salaryFrom === salaryUpto ?
        getPriceStr(salaryUpto)
        : salaryFrom === 0 ?
            'до ' + getPriceStr(salaryUpto)
            : salaryUpto === 0 ?
                'от ' + getPriceStr(salaryFrom)
                :
                getHumanNumber(salaryFrom) + ' – ' + getPriceStr(salaryUpto)
  }