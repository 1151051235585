import { FC, Fragment, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { resizeImage } from 'service/API';
import { BlobCache, DentFUID } from 'service/Data/DentTypes';
import { gMaxPhotoSize, photoUrl } from 'service/Globals';
import { TR, TR_ } from 'service/T10N';
import OutsideClicker from './OutsideClicker';
//import * as S from './Header.styles';

interface PhotoSelectorProps {
    reff?: any
    tooltip?: string
    photos: DentFUID[]
    setPhotos: (v: DentFUID[]) => void
    starred: DentFUID
    setStarred: (v: DentFUID) => void
    maxFileSize?: number
    formats?: RegExp
}

const PhotoSelector: FC<PhotoSelectorProps> = ({ reff, tooltip, photos, setPhotos, starred, setStarred, maxFileSize = 1048576, formats = /\.(jpg|jpeg|png)$/i }) => {
    const [shown, setShown] = useState(false);
    const [errorText, setErrorText] = useState("");

    const setError = (text: string) => {
        setErrorText(text)
        setShown(true)
    }

    const handleFiles = async (files: FileList | null) => {
        if (files) {
            const file = files[0]

            if (!file)
                return

            if (!file.name.match(formats)) {
                setError(TR('Поддерживаемые форматы изображений .png и .jpg'))
                return
            }

            //console.log(file)

            // Getting image dimensions
            const bmp = await createImageBitmap(file)
            const { width, height } = bmp
            bmp.close()

            const newSize = Math.min(Math.max(width, height), gMaxPhotoSize)

            // Resizing image and converting to jpeg format
            const blob = await resizeImage(file, newSize, newSize)
            if (blob instanceof Blob) {
                //const photoFuid = await uploadPhoto(file)
                //const newPhotos = photos.concat(photoFuid)
                //const blobUrl = URL.createObjectURL(blob)
                const bc = new BlobCache(blob)
                const newPhotos = photos.concat(bc)
                console.log(newPhotos)
                setPhotos(newPhotos)

                if (newPhotos.length == 1)
                    setStarred(bc)
            }
        }
    }

    const handlePhotoRemove = async (removeFuid: DentFUID) => {
        console.log("Removing photo: " + removeFuid)
        const newPhotos = photos.filter((fuid) => fuid !== removeFuid)
        console.log(newPhotos)
        setPhotos(newPhotos)

        if (starred === removeFuid) {
            // Starred photo was just removed, setting new starred photo
            setStarred(newPhotos ? newPhotos[0] : '')
        }
    }

    const Item = ({ fuid, index }: { fuid: DentFUID, index: number }) => {
        return (
            <div className='media__item' data-tooltip-id={tooltip}>
                {/* <img className='media__img' src={photoUrl(fuid)} alt='dental portal image' /> */}

                <div className="view__media-logo media__img">
                    <img className="inner-img" src={photoUrl(fuid)} />
                </div>

                <div className='media__controls'>
                    <input
                        className='favy'
                        type='radio'
                        id={('favy' + index)}
                        name='favy'
                        checked={fuid === starred}
                        onChange={() => {
                            setStarred(fuid)
                        }} />
                    <label htmlFor={('favy' + index)}></label>
                    <button className="delete-btn-white"
                        onClick={(e) => {
                            e.preventDefault();
                            handlePhotoRemove(fuid)
                        }} />
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <div className='input-upload' data-tooltip-id="photo-tooltip"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    handleFiles(e.dataTransfer.files)
                }}>
                <div className='upload-data'>
                    <p className='upload__info'>{TR('Перетащите фото в выбранную область или нажмите кнопку "загрузить фото"')}</p>
                    <p className='upload__sub'>{TR_('Пропорция 18x16')}<br />{TR_('Поддерживаемые форматы .png, .jpg.')}</p>
                </div>
                <img className='upload__img' src='/assets/images/upload-im.png' alt='dental portal' />
                <input type='file' name='photos' id='photo'
                    onChange={(event) => {
                        handleFiles(event.target.files)
                    }} />
                <label ref={reff} tabIndex={0} htmlFor='photo' className='button-40'>{TR('Загрузить фото')}</label>
            </div>
            <OutsideClicker onClickOutside={() => setShown(false)} />
            <Tooltip id="photo-tooltip" isOpen={!!errorText && shown} content={errorText} />
            <div className='media-container'>
                {photos.map((fuid, index) => {
                    return <Item key={"k" + index} fuid={fuid} index={index} />
                })}
                {/* <div className='media__item'>
                    <img className='media__img' src='/assets/images/R3.png' alt='dental portal image' />
                    <div className='media__controls'>
                        <input className='favy' type='radio' id='favy1' name='favy' defaultChecked />
                        <label htmlFor='favy1'></label>
                    </div>
                </div>
                <div className='media__item'>
                    <img className='media__img' src='/assets/images/R3.png' alt='dental portal image' />
                    <div className='media__controls'>
                        <input className='favy' type='radio' id='favy2' name='favy' />
                        <label htmlFor='favy2'></label>
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
}

export default PhotoSelector;