import AuthContext from 'AuthContext';
import PopupContacts from 'Pages/Popups/PopupContacts';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteOffer, downloadFile, getDateHuman, getDateRangeHumanShort, getDateStr, getUserContacts } from 'service/API';
import { Contacts, Offer, UserID } from 'service/Data/DentTypes';
import { avatarUrl, fileUrl } from 'service/Globals';
import { TR, TR_ } from 'service/T10N';
import { getPriceStr } from 'service/TextTools';
import { firstPhoto } from './Components/OfferCard';

interface LocationState {
    offer: Offer
}

const OfferView = () => {
    const auth = useContext(AuthContext)
    const history = useHistory();

    const location = useLocation<LocationState>(); '/offer-view'
    const offer = location.state.offer
    // const { title, city, street, imageBig, description, priceNew, } = offer



    useEffect(() => {
        window.scrollTo(0, 0)
    })

    if (!location.state) {
        return null
    }

    //console.log('Offer')
    //console.log(offer)


    const [isOwn, setOwn] = useState(auth.session?.id === offer.creator.id)
    const [notFound, setNotFound] = useState(false)

    const [areContactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts>()

    async function handleContactsClick(id: UserID) {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleEdit = async () => {
        history.push('/offer-new', { offer: offer })
    }

    const handleDelete = async () => {
        const success = await deleteOffer(offer.id)

        if (success) {
            history.push('/dash')
            return
        }

        //TODO: show error somehow
    }

    const OwnOffer =
        <div className="view__controls-inner">
            <div className="view-add-controls">
                <p className="valid-date">{TR('Действует до')}:<time dateTime={getDateStr(offer.dateUpto)}>{getDateHuman(offer.dateUpto)}</time></p>
                <div className="view-add__flex">
                    <button className='button-27f' onClick={() => handleEdit()}>{TR('Редактировать')}</button>
                    <button className='button-27e' onClick={() => handleDelete()}>{TR('Снять с публикации')}</button>
                </div>
                <p> <span className="blind--text">{TR('Опубликовано')}:</span> {getDateHuman(offer.publicDate)}</p>
            </div>
        </div>

    const SomeOffer =
        <div className="view__controls-inner">
            <div className="view-chat">
                <div className="view-chat__flex">
                    <div className="view-chat__flex-item">
                        <div className="view-chat__company">
                            <img className="view-chat__company-img" src={avatarUrl(offer.creator.avatar)} alt="" />
                            {offer.creator.name}
                        </div>
                    </div>
                    <div className="view-chat__flex-item">
                        <div className="view-chat__date">
                            {/* <p className="view-chat__date-title">{TR('Добавлено')}:</p>
                            <time className="view-chat__date-add" dateTime="2021-10-12">{getDateHuman(offer.publicDate)}</time> */}
                            <p className="view-chat__date-title">{TR_('Действует')}:</p>
                            <time className="view-chat__date-add" dateTime="2021-10-12">{getDateRangeHumanShort(offer.dateFrom, offer.dateUpto)}</time>
                        </div>
                    </div>
                </div>
                <button className="main-button is--blue  m-0" onClick={() => { history.push('/chat') }}>{TR('Начать чат')}</button>
                <div className="view-chat__ctrl">
                    <button className="view-chat__show" onClick={() => handleContactsClick(offer.creator.id)}>{TR('Показать контакты')}</button>
                    <button className="view-chat__complain">{TR('Пожаловаться')}</button>
                </div>
                {areContactsShown && contacts ?
                    <PopupContacts
                        contacts={contacts}
                        onClose={() => setContactsShown(false)}
                    />
                    :
                    null
                }
            </div>
        </div>

    return (
        <main className="main top--0">
            <div className="container">
                <section className="view--add">
                    <div className="preview-flex">
                        <div className="view__details">
                            <h1 className="section-title">{offer.title}</h1>
                            <p className="location">
                                {offer.city}
                                <span className="blind--text">{offer.street}</span>
                            </p>
                            <p className="view-price price--modify">{getPriceStr(offer.priceNew)}</p>
                            <p>
                                {offer.description}
                            </p>
                            <br />
                            {offer.files ?
                                <div className='bid__addons'>
                                    {
                                        offer.files.map((file) =>
                                            <button
                                                key={fileUrl(file.fuid)}
                                                className='button-link-doc'
                                                onClick={() => downloadFile(fileUrl(file.fuid), file.name)}
                                                // href={getFullPath(fileUrl(file.fuid))}
                                                // download={file.name}
                                                // target='_blank'
                                                rel='noopener noreferrer'>
                                                <span />{file.name}</button>)
                                    }
                                </div>
                                : null
                            }
                        </div>
                        <div className="view__media">
                            <div className="view__media-logo">
                                <img className="inner-img" src={firstPhoto(offer.photos)} />
                            </div>
                        </div>
                    </div>
                    <div className="preview-flex-cont">
                        <div className="view__serv"></div>
                        {isOwn ? OwnOffer : SomeOffer}
                    </div>
                </section>
            </div>
        </main>

    )
}

export default OfferView;