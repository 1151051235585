import AuthContext from 'AuthContext';
import { firstPhoto } from 'Pages/Offers/Components/OfferCard';
import PopupCompany from 'Pages/Popups/PopupCompany';
import PopupContacts from 'Pages/Popups/PopupContacts';
import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDateHuman, getDateStr, getUserContacts, getUserInfo, getVacancyFeedback, getVacancyFeedbacks } from 'service/API';
import { Contacts, User, Vacancy } from 'service/Data/DentTypes';
import { avatarUrl } from 'service/Globals';
import { TR } from 'service/T10N';
import { getSalaryStr } from 'service/TextTools';

interface VacancyCard2Props {
    vacancy: Vacancy
    userId: string
}

const VacancyCard2: FC<VacancyCard2Props> = ({ vacancy, userId }) => {
    var { id, title, photos, description, salaryFrom, salaryUpto, creator, publicDate, files } = vacancy

    const auth = useContext(AuthContext)
    const history = useHistory();

    const [userInfoShown, setUserInfoShown] = useState(false)
    const [userInfo, setUserInfo] = useState<User | null>(null)

    const [contactsShown, setContactsShown] = useState(false)
    const [contacts, setContacts] = useState<Contacts | null>(null)

    const handleCompanyClick = async () => {
        try {
            if (!userInfo) {
                setUserInfo(await getUserInfo(creator.id))
                setUserInfoShown(true)
                return
            }

            setUserInfoShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleContactsClick = async () => {
        try {
            if (!contacts) {
                setContacts(await getUserContacts(creator.id))
                setContactsShown(true)
                return
            }

            setContactsShown(true)
        }
        catch (error) {
            // set error
        }
    }

    const handleVacancyFeedback = async () => {
        if (auth.session) {
            if (vacancy.creator.id == userId) {// Own vacancy, list feedbacks
                //const vacancy: Vacancy = { ...myVacancy, creator: { ...auth.session } }
                const feedbacks = await getVacancyFeedbacks(vacancy.id)
                //console.log('Got feedbacks:');
                //console.log(feedbacks);
                history.push('/vacancy-view-feedbacks', { vacancy: vacancy, feedbacks: feedbacks })
                return
            }

            // Not our own vacancy
            const reply = await getVacancyFeedback(vacancy.id, auth.session.id)

            //console.log('Get feedback reply:');
            //console.log(reply);

            // TODO: Check if it is possible to unite '/vacancy-view-feedback' and '/vacancy-new-feedback'
            if (reply.feedback) { // We've already written feedback before
                history.push('/vacancy-view-feedback', { vacancy: vacancy, feedback: reply.feedback })
                return
            }

            history.push('/vacancy-new-feedback', { vacancy: vacancy })
        }
    }

    return (
        <article className="list-add">
            <div className="list-add__media">
                {/* <div className="list-add__image-box">
                    <img className="list-add__image" src={firstPhoto(photos)} alt="dental portal" />
                </div> */}
                <div className="list-add__image-box">
                    <div className="view__media-logo">
                        <img className="inner-img" src={firstPhoto(photos)} />
                    </div>
                </div>
                <div className="list-add__sub">
                    <button className="company-box" onClick={() => handleCompanyClick()}>
                        <span className="company-image__box">
                            <img src={avatarUrl(creator.avatar)} alt="dental portal" />
                        </span>
                        {creator.name}
                    </button>
                    {userInfo && userInfoShown ?
                        <PopupCompany onClose={() => setUserInfoShown(false)} user={userInfo} /> : null
                    }
                </div>
            </div>
            <div className="list-add__content">
                <div className="list-add__content-details">
                    <h2 className="list-add__title" style={{ cursor: 'pointer' }}
                        onClick={() => handleVacancyFeedback()}>{title}
                    </h2>
                    {/* <p className="location">
                        {city}
                        <span className="blind--text">{street}</span>
                    </p> */}

                    <p style={{ marginTop: '4px' }}>
                        {description}
                    </p>
                </div>
                <div className="list-add__controls">
                    {/* <div className="reply-item__bot"> */}
                    {/* <div className="reply-idents">
                            <div className="reply__ident ident__money">{maxBudgetStr}</div>
                            <div className="reply__ident ident__time">{untilDateStr}</div>
                        </div> */}
                    {/* {isOwn ?
                            <div className="reply-controls">
                                <button className="button-27e" onClick={() => onDelete()}>{TR_('Удалить')}</button>
                                <button className="button-27f" onClick={() => onEdit()}>{TR_('Редактировать')}</button>
                            </div>
                            :
                            <div className="reply-controls">
                                <button className="button-27f" onClick={() => { history.push('/chat') }}>{TR_('Начать чат')}</button>
                            </div>
                        } */}
                    {/* </div> */}
                    <div className="list-add__adons">
                        <div className="reply-idents">
                            <div className="reply__ident ident__money">{getSalaryStr(salaryFrom, salaryUpto)}</div>
                            {/* <div className="reply__ident ident__time">{untilDateStr}</div> */}
                        </div>
                        {/* <div className="list-add__price">
                            {maxBudget ? <span>{maxBudget + ' ' + TR('₽')}</span> : <span className="blind--text">{TR('Не указ.')}</span>}
                            {untilDate && untilDate.valueOf() ? <span>{TR('До') + ' ' + getDateHuman(untilDate)}</span> : <span className="blind--text">{TR('Не указ.')}</span>}
                        </div> */}
                        <div className="list-add__date">
                            {TR('Опубликовано')} <br />
                            <time dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
                        </div>
                    </div>
                    <div className="list-add__buttons">
                        <button className="colbut ws--pre" onClick={() => handleContactsClick()}>{TR('Показать \n контакты')}</button>
                        <button className="button-27e" onClick={() => handleVacancyFeedback()}>{TR('Откликнуться')}</button>
                    </div>
                    {contacts && contactsShown ?
                        <PopupContacts
                            contacts={contacts}
                            onClose={() => setContactsShown(false)}
                        />
                        :
                        null
                    }
                </div>
            </div>
        </article>
    )
}

export default VacancyCard2;