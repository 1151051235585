import MyFeedbacks from "Pages/Home/Components/MyFeedbacks";
import { useEffect, useState } from "react";
import { DashData, getDashData } from "service/API";
import MyPurchases from "../Home/Components/MyPurchases";
import MySales from "../Home/Components/MySales";
import MyVacancies from "../Home/Components/MyVacancies";

const DashDoctor = () => {
    const [data, setData] = useState<DashData>()
    const [firstLoad] = useState()
    useEffect(() => {
        (async () => {
            //console.log('Dash data effect')
            const homeData = await getDashData({
                myAuctions: { actual: [], archived: [] },
                //myOffers: { actual: [], archived: [] },
                mySales: { actual: [], archived: [] },
                myVacancies: { actual: [], archived: [] },

                myAuctionFeedbacks: [],
                myVacancyFeedbacks: [],
            })
            //console.log('Dash data received:')
            //console.log(homeData);
            setData(homeData)
        })();
    }, [firstLoad]);

    return (
        <main className="main">
            <div className="container ws--pre">
                {/* Доска доктора<br /> */}
                <div className="main-flex">
                    <div className="main-flex__item">
                        <MyPurchases {...data?.myAuctions} />
                        <MySales {...data?.mySales} />
                    </div>
                    <div className="main-flex__item">
                        <MyVacancies {...data?.myVacancies} />
                        <MyFeedbacks
                            auctions={data?.myAuctionFeedbacks}
                            vacancies={data?.myVacancyFeedbacks} />
                    </div>
                    {/* <div className="main-flex__item">
                        <MyPurchases {...data?.myAuctions} />
                        <MySales {...data?.mySales} />
                        <MyOffers {...data?.myOffers} />
                        <MyVacancies {...data?.myVacancies} />
                    </div> */}
                </div>
            </div>
        </main>
    )
}

export default DashDoctor;