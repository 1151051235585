import AuthContext from "AuthContext";
import { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDateHuman, getDateStr } from "service/API";
import { MyAuctionFeedback, MyVacancyFeedback, maxCabinetCardItems } from "service/Data/DentTypes";
import { TR, TR_ } from "service/T10N";
import { getSalaryStr } from "service/TextTools";

//const maxItems = maxCabinetCardItems

interface MyFeedbacksProps {
    auctions?: MyAuctionFeedback[]
    vacancies?: MyVacancyFeedback[]
}

const MyFeedbacks: FC<MyFeedbacksProps> = ({ auctions: auctionsArg, vacancies: vacanciesArg }) => {
    const auctions = auctionsArg ? auctionsArg : []
    const vacancies = vacanciesArg ? vacanciesArg : []

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isVacancy, setIsVacancy] = useState(false)
    const [maxVacancies, setMaxVacancies] = useState(maxCabinetCardItems)
    const [maxAuctions, setMaxAuctions] = useState(maxCabinetCardItems)

    async function handleAuction(my: MyAuctionFeedback) {
        history.push('/auction-view-feedback', { auction: my.auction, feedback: my.feedback })
    }

    async function handleVacancy(my: MyVacancyFeedback) {
        history.push('/vacancy-view-feedback', { vacancy: my.vacancy, feedback: my.feedback })
    }

    const AuctionItem = (feedback: MyAuctionFeedback) => {
        const { id, title, untilDate, maxBudget } = feedback.auction
        return (
            <article className="card__text-item">
                <div className="card-data-box" onClick={() => { handleAuction(feedback) }}>
                    <h3 className="card-item__title">{title}</h3>
                    <p className="card-item__details">
                        {maxBudget ? <span>{maxBudget} {TR('₽')}</span> : <span className="blind--text">{TR('Не указ.')}</span>}
                        <time dateTime={getDateStr(untilDate)}>{TR('до')} {getDateHuman(untilDate)}</time>
                    </p>
                </div>
                {/* <div className="data-controls-box">
                    <button className="edit-btn"></button>
                    <button className="delete-btn"></button>
                </div> */}
            </article>
        )
    }

    const VacancyItem = (feedback: MyVacancyFeedback) => {
        const { id, title, salaryFrom, salaryUpto } = feedback.vacancy
        return (
            <article className='card__text-item'>
                <div className='card-data-box' onClick={() => { handleVacancy(feedback) }}>
                    <h3 className='card-item__title'>{title}</h3>
                    <p className='card-item__details'>
                        <span>{getSalaryStr(salaryFrom, salaryUpto)}</span>
                    </p>
                </div>
                {/* <div className="card-rank-box">
                    {oldFeedbacks}
                    {newFeedbacks ? <span>+{newFeedbacks}</span> : null}
                </div> */}
                {/* <div className='data-controls-box'>
                    <button className='edit-btn'></button>
                    <button className='delete-btn'></button>
                </div> */}
            </article>
        )
    }

    //const feedbacks: MySale[] = isVacancy ? vacancies : auctions;

    return (
        <section className="section-wrapper">
            <div className="card-header">
                <h2 className="card-title">{TR_('Мои отклики')}
                    {/* <span className="card-title__counter">{isVacancy ? vacancies.length : auctions.length}</span> */}
                </h2>
                {/* <button className="button-27e" onClick={() => history.push('/sale-new')}>{TR('Создать объявление')}</button> */}
            </div>
            <div className="card-filter">
                <div className="swiper-checkbox">
                    <input type="checkbox" id="feedbackPurVac" checked={isVacancy} onChange={() => setIsVacancy(!isVacancy)} />
                    <label htmlFor="feedbackPurVac">
                        <span className="actual">{TR_('Закупки')}</span>
                        <span className="not__actual">{TR_('Вакансии')}</span>
                    </label>
                </div>
            </div>
            {isVacancy ?
                <div className="card-body">
                    {vacancies.slice(0, maxVacancies).
                        map((item) =>
                            <VacancyItem
                                key={item.vacancy.id}
                                vacancy={item.vacancy}
                                feedback={item.feedback} />)
                    }
                    {vacancies.length > maxCabinetCardItems && maxVacancies == maxCabinetCardItems ?
                        <button className="rest-items" onClick={() => setMaxVacancies(vacancies.length)}>
                            <span>{TR_('Ещё') + ' ' + (vacancies.length - maxVacancies)}</span>
                            <span />
                            <span>{TR('Смотреть все')}</span>
                        </button>
                        : maxVacancies != maxCabinetCardItems ?
                            <button className="rest-items" onClick={() => setMaxVacancies(maxCabinetCardItems)}>
                                <span />
                                <span>{TR_('Свернуть')}</span>
                            </button>
                            :
                            null
                    }
                </div>
                :
                <div className="card-body">
                    {auctions.slice(0, maxAuctions).
                        map((item) =>
                            <AuctionItem
                                auction={item.auction}
                                feedback={item.feedback}
                                key={item.auction.id} />)
                    }
                    {auctions.length > maxCabinetCardItems && maxAuctions == maxCabinetCardItems ?
                        <button className="rest-items" onClick={() => setMaxAuctions(auctions.length)}>
                            <span>{TR_('Ещё') + ' ' + (auctions.length - maxAuctions)}</span>
                            <span />
                            <span>{TR('Смотреть все')}</span>
                        </button>
                        : maxAuctions != maxCabinetCardItems ?
                            <button className="rest-items" onClick={() => setMaxAuctions(maxCabinetCardItems)}>
                                <span />
                                <span>{TR_('Свернуть')}</span>
                            </button>
                            :
                            null
                    }
                </div>
            }
        </section>
    )
}

export default MyFeedbacks;