import { firstPhoto } from 'Pages/Offers/Components/OfferCard'
import PopupContacts from 'Pages/Popups/PopupContacts'
import { FC, useState } from 'react'

import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { downloadFile, getUserReviews } from 'service/API'
import { DentFile, DentURL, User, isDoctor, isTechnician, isTrader } from 'service/Data/DentTypes'
import { avatarUrl, fileUrl } from 'service/Globals'
import { TR } from 'service/T10N'

export function correctLink(link: string): string {
    if (link.toLowerCase().startsWith('http')) {
        return link
    }
    return "https://" + link
}

export const DoctorAddon = ({ scanner = '', chairs = 0 }) => {
    return (
        // <div className='list-add__adons view--adons'>
        <div className='list-add__adons'>
            <div className='list-add__doc'>
                <p className='scan'>
                    {TR('Интраоральный сканер')}:{scanner ?
                        <span className='scan__data'> <span className='scan__is'>{TR('Да')}</span>{scanner}</span>
                        :
                        <span className='scan__data'> <span className='blind--text'>{TR('Нет')}</span></span>
                    }
                </p>
            </div>
            {chairs > 3 ?
                <p className='chair'>3+</p> : <p className='chair'>1-3</p>
            }
        </div>
    )
}

interface TechnicianAddonProps {
    cadcam: string,
    pricelist: DentURL
}

export const TechnicianAddon: FC<TechnicianAddonProps> = ({ cadcam = '', pricelist = '' }) => {
    return (
        // <div className='list-add__adons view--adons'>
        <div className='list-add__adons'>
            <div className='list-add__doc'>
                <p className='scan'>
                    CAD/CAM:{cadcam ?
                        <span className='scan__data'> <span className='scan__is'>{TR('Да')}</span>{cadcam}</span>
                        :
                        <span className='scan__data'> <span className='blind--text'>{TR('Нет')}</span></span>
                    }
                </p>
            </div>
            {pricelist ? <a className='pdf-link' href={correctLink(pricelist)} target='_blank'>{TR('Прайс-лист')}</a> : null}
        </div>
    )
}

interface TraderAddonProps {
    catalog: DentURL
    counterpartyCard?: DentFile
}

export const TraderAddon: FC<TraderAddonProps> = ({ catalog = '', counterpartyCard }) => {
    return (
        // <div className='list-add__adons view--adons'>
        <div className='list-add__adons'>
            <div className='list-add__doc'>
                {catalog ? <a className='catalog-link' href={correctLink(catalog)} target='_blank'>{TR('Посмотреть каталог')}</a> : null}
            </div>
            {counterpartyCard ?
                <button className='pdf-link'
                    onClick={() => downloadFile(fileUrl(counterpartyCard.fuid), counterpartyCard.name)}>
                    {TR('Карточка контрагента')}
                </button>
                :
                null
            }
        </div>
    )
}

export interface UserCardProps {
    user: User
}

const UserCard: FC<UserCardProps> = ({ user }) => {
    var { id, name, photos, rating, city, street, avatar, description } = user
    const history = useHistory()

    const [isFeedbackShown, setFeedbackShown] = useState(false)
    const [isNewFeedbackShown, setNewFeedbackShown] = useState(false)
    const [areContactsShown, setContactsShown] = useState(false)

    const rateSum = rating ? rating.rateSum : 0
    const rateCount = rating ? rating.rateCount : 0
    const rate = rateCount ? rateSum / rateCount : 0

    const handleUserClick = async () => {
        const reviews = await getUserReviews(user.id)
        console.log('Got reviews:')
        console.log(reviews)

        history.push('/user-view-reviews', { user: user, reviews: reviews })
    }

    //console.log("IsMobile: " + isMobile)

    if (isMobile) {
        return (
            <article className="list-add mobile">
                <div className="list-add__media">
                    {/* <div className="list-add__image-box" onClick={() => handleUserClick()}>
                        <img
                            className="list-add__image"
                            src={firstPhoto(photos)}
                            alt="dental portal"
                        />
                    </div> */}
                    <div className="list-add__image-box" onClick={() => handleUserClick()}>
                        <div className="view__media-logo">
                            <img className="inner-img" src={firstPhoto(photos)} />
                        </div>
                    </div>
                    {/* </div><div className="list-add__sub" onClick={() => setFeedbackShown(true)}> */}
                    <div className="list-add__sub" onClick={() => handleUserClick()}>
                        <div className="rate-container">
                            <img src={avatarUrl(avatar)} alt="" />
                            <button className="rate-box">
                                <div className="rate__stars">
                                    <div className="rating">
                                        <div className="rating__inner rating__base"></div>
                                        {/* <div className="rating__inner rating__low" style={{ width: '30%' }}/> */}
                                        <div className="rating__inner rating__hight" style={{ width: ((rate * 20) + '%') }} />
                                    </div>
                                </div>
                                <span className="rate__number">{rate.toFixed(1)}</span>
                                <span className="rate__amo">({rateCount})</span>
                            </button>
                        </div>
                        <div className="mobile-title-box">
                            <h2 className="mobile-title">
                                {name}
                            </h2>
                        </div>
                    </div>
                    {/* {isFeedbackShown ?
                        <PopupFeedback
                            cardId={id}
                            title={name}
                            rate={rate}
                            rateCount={rateCount}
                            onClose={() => setFeedbackShown(false)}
                            onNewFeedback={() => {
                                setFeedbackShown(false)
                                setNewFeedbackShown(true)
                            }} />
                        :
                        null}
                    {isNewFeedbackShown ?
                        <PopupFeedbackNew
                            title={name}
                            onClose={() => { setNewFeedbackShown(false) } }
                            onFeedback={(stars, text) => {
                                setNewFeedbackShown(false)
                                console.log(stars)
                                console.log(text)
                            } } text={''} stars={0} isNew={false} />
                        :
                        null} */}
                </div>
                <div className="list-add__content">
                    <div className="list-add__content-details">
                        <p>
                            {description}
                        </p>
                    </div>
                    <div className="list-add__controls">
                        {isDoctor(user) ? <DoctorAddon {...user.doctor} /> :
                            isTechnician(user) ? <TechnicianAddon {...user.technician} /> :
                                isTrader(user) ? <TraderAddon catalog={user.trader.catalog} counterpartyCard={user.counterpartyCard} /> : null}
                        <div className="list-add__buttons">
                            <div className="location-container-2">
                                <p className="location">
                                    {city}
                                    <span className="blind--text loc-street">{street}</span>
                                </p>
                            </div>
                            <button className="colbut contact-modify" onClick={() => setContactsShown(true)}>{TR('Показать \n контакты')}</button>
                            <button className="button-27e chat-modify" onClick={() => history.push('/chat')}>{TR('Начать чат')}</button>
                            {areContactsShown ?
                                <PopupContacts
                                    contacts={user.contacts}
                                    onClose={() => setContactsShown(false)}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </article>
        )
    } else {
        return (
            <article className='list-add'>
                <div className="list-add__media">
                    {/* <div className="list-add__image-box ptr" onClick={() => handleUserClick()}>
                        <img className="list-add__image" src={firstPhoto(photos)} alt="dental portal" />
                    </div> */}
                    <div className="list-add__image-box" onClick={() => handleUserClick()}>
                        <div className="view__media-logo">
                            <img className="inner-img" src={firstPhoto(photos)} />
                        </div>
                    </div>
                    {/* <div className="list-add__sub" onClick={() => setFeedbackShown(true)}> */}
                    <div className="list-add__sub" onClick={() => handleUserClick()}>
                        <div className="rate-container">
                            <img src={avatarUrl(avatar)} alt="" />
                            <button className="rate-box">
                                <div className="rate__stars">
                                    <div className="rating">
                                        <div className="rating__inner rating__base"></div>
                                        {/* <div className="rating__inner rating__low" style={{ width: "30%" }}></div> */}
                                        <div className="rating__inner rating__hight" style={{ width: ((rate * 20) + '%') }}></div>
                                    </div>
                                </div>
                                <span className="rate__number">{rate.toFixed(1)}</span>
                                <span className="rate__amo">({rateCount})</span>
                            </button>
                        </div>
                        <div className="mobile-title-box ptr" onClick={() => handleUserClick()}>
                            <h2 className="mobile-title">
                                {name}
                            </h2>
                        </div>
                    </div>
                    {/* {isFeedbackShown ?
                        <PopupFeedback
                            cardId={id}
                            title={name}
                            rate={rate}
                            rateCount={rateCount}
                            onClose={() => setFeedbackShown(false)}
                            onNewFeedback={() => {
                                setFeedbackShown(false)
                                setNewFeedbackShown(true)
                            }} />
                        :
                        null} */}
                    {/* {isNewFeedbackShown ?
                        <PopupFeedbackNew
                            title={name}
                            onClose={() => { setNewFeedbackShown(false) }}
                            onFeedback={(stars, text) => {
                                setNewFeedbackShown(false)
                                console.log(stars)
                                console.log(text)
                            }} />
                        :
                        null} */}
                </div>
                <div className="list-add__content">
                    <div className="list-add__content-details">
                        <div className="desctop-title-box ptr" onClick={() => handleUserClick()}>
                            <img src={avatarUrl(avatar)} alt="" />
                            <h2 className="list-add__title">
                                {name}
                            </h2>
                        </div>
                        <p className="location">
                            {city}
                            <span className="blind--text loc-street">{street}</span>
                        </p>
                        <p>
                            {description}
                        </p>
                    </div>
                    <div className="list-add__controls">
                        {isDoctor(user) ? <DoctorAddon {...user.doctor} /> :
                            isTechnician(user) ? <TechnicianAddon {...user.technician} /> :
                                isTrader(user) ? <TraderAddon catalog={user.trader.catalog} counterpartyCard={user.counterpartyCard} /> : null}
                        <div className="list-add__buttons">
                            <button className='colbut ws--pre' onClick={() => setContactsShown(true)}>{TR('Показать \n контакты')}</button>
                            <button className='button-27e' onClick={() => history.push('/chat')}>{TR('Начать чат')}</button>
                        </div>
                        {areContactsShown ?
                            <PopupContacts
                                contacts={user.contacts}
                                onClose={() => setContactsShown(false)}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </article>
        )
    }
}

export default UserCard
