import AuthContext from 'AuthContext'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { getAuctionFeedback, getAuctionFeedbacks, getDateHuman, getDateStr } from 'service/API'
import { Auction } from 'service/Data/DentTypes'
import { TR } from 'service/T10N'
import { getPriceStr } from 'service/TextTools'

const AuctionMiniCard = (auction: Auction) => {
    const auth = useContext(AuthContext)
    const history = useHistory()

    const handleAuctionClick = async () => {
        if (auth.session) {
            if (auction.creator.id == auth.session.id) {// Own auction, list feedbacks
                const feedbacks = await getAuctionFeedbacks(auction.id)
                //console.log('Got feedbacks:');
                //console.log(feedbacks);
                history.push('/auction-view-feedbacks', { auction: auction, feedbacks: feedbacks })
                return
            }

            // Not our own auction
            const reply = await getAuctionFeedback(auction.id, auth.session.id)

            //console.log('Get feedback reply:');
            //console.log(reply);

            // TODO: Check if it is possible to unite '/auction-view-feedback' and '/auction-new-feedback'
            if (reply.feedback) { // We've already written feedback before
                history.push('/auction-view-feedback', { auction: auction, feedback: reply.feedback })
                return
            }

            history.push('/auction-new-feedback', { auction: auction })
        }
    }

    return (
        <article className='card__text-item' onClick={() => handleAuctionClick()}>
            <div className='card-data-box'>
                <a className='card-item__title'>{auction.title}</a>
                <p className='card-item__desc'>{auction.description}</p>
                <p className='card-item__details'>
                    <span className='blind--text'>{auction.maxBudget ? getPriceStr(auction.maxBudget) : TR('Не указ.')}</span>
                    <time dateTime={auction.untilDate && auction.untilDate.valueOf() ? getDateStr(auction.untilDate) : ''}>{auction.untilDate && auction.untilDate.valueOf() ? TR('До') + ' ' + getDateHuman(auction.untilDate) : TR('Не указ.')}</time>
                </p>
            </div>
        </article>
    )
}

export default AuctionMiniCard