
const PopupMyData = ({ onClose = () => { } }) => {
    return (
        <>
            {/* <MyDataDoctor onClose={onClose} /> */}
            {/* <MyDataTechnician />
                <MyDataTrader /> */}
            <div className="popy-cover" onMouseDown={() => { console.log('cover'); onClose() }}></div>
        </>
    )
}

export default PopupMyData;