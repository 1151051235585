import { FC, useEffect, useState } from 'react';
import { getDateHuman, getDateStr, getUserReviews } from 'service/API';
import { UserID, UserReview } from 'service/Data/DentTypes';
import { TR } from 'service/T10N';

const Item: FC<UserReview> = ({ creator, publicDate, rate, description }) => {
    return (
        <article className="feedback__item">
            <h2 className="v--hidden"></h2>
            <div className="feedback__item-title">
                <p className="feedback-user">{creator.name}</p>
                <div className="feedback__inner-title">
                    <time dateTime={getDateStr(publicDate)}>{getDateHuman(publicDate)}</time>
                    <div className="rate__stars">
                        {
                            [...Array(rate)].map((x, i) =>
                                <span className="star star--good"></span>
                            )
                        }
                    </div>
                </div>
            </div>
            <p className="feedback__auction">
                {/* Аукцион: <span>{title}</span> */}
            </p>
            <p className="feedback__text">
                {description}
            </p>
        </article>
    )
}

export interface PopupFeedbackProps {
    cardId: UserID // TODO: ?? realy UserID
    rate: number
    rateCount: number
    title: string
    onClose: () => void
    onNewFeedback: () => void
}

const PopupFeedback: FC<PopupFeedbackProps> = ({ cardId, rate, rateCount, title, onClose, onNewFeedback }) => {

    const [justInitialized, setJustInitizlized] = useState(true);
    const [reviews, setReview] = useState<UserReview[] | null>(null)

    useEffect(() => {
        console.log('Review effect')
        getUserReviews(cardId).
            then(result => {
                setReview(result.reviews)
                setJustInitizlized(false)
            })

    }, [reviews]);

    return (
        <div className="cover" onMouseDown={() => onClose()}>
            <div className="pop-up popup--600" onMouseDown={(e) => e.stopPropagation()}>
                <button className="killer" onClick={() => onClose()}></button>
                <header className="popup-header">
                    <div className="feedback-title__box">
                        <h1 className="popup-title">
                            {TR('Отзывы')}
                        </h1>
                        <div className="rate-box">
                            <div className="rate__stars">
                                <span className="star star--good"></span>
                                <span className="star star--good"></span>
                                <span className="star star--good"></span>
                                <span className="star star--good"></span>
                                <span className="star star--good"></span>
                            </div>
                            <span className="rate__number">{rate}</span>
                            <span className="rate__amo">{'(' + rateCount + ')'}</span>
                        </div>
                        <button className="button-27e" onClick={() => onNewFeedback()}>{TR('Оставить отзыв')}</button>
                    </div>
                    <p className="rate-company__name">{title}</p>
                </header>
                <div className="popup-body">
                    <div className="feedback-list">
                        {reviews ? reviews.map((item) =>
                            <Item key={item.id} {...item} />
                        ) : justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupFeedback;