import AuthContext from 'AuthContext';
import { TextInput, useInputFocus } from 'Pages/Home/Components/MyDataDoctor';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { forgotPassword } from 'service/API';
import { TR, TR_ } from 'service/T10N';
//import 'Pages/Signup/style.css'

function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email)
}

const ForgotPassword = () => {
    const history = useHistory();
    const [errorStr, setErrorStr] = useState("")
    const auth = useContext(AuthContext)

    const [emailRef, setEmailFocus] = useInputFocus()

    const [email, setEmail] = useState('')

    const handleSubmit = async () => {
        console.log('Submit: ' + email)
        if (!validateEmail(email)) {
            console.log('Invalid email');

            return
        }

        try {
            const success = await forgotPassword(email)

            if (success) {
                history.push('/forgotpasswordsuccess')
            }
        } catch (e) {

        }
    }

    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="/" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="dental portal image" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--in">
                    <h1 className="sign-title">
                        {TR_('Забыли пароль') + '?'}
                    </h1>
                    <p className="sgn__sub">{TR_('На указанный e-mail вам будет отправлено сообщение со ссылкой изменения пароля')}</p>
                    <div className="sign-form">
                        <div className="sign-fields">
                            <TextInput
                                reff={emailRef}
                                tooltip_id='email-tooltip'
                                title={TR_('E-mail')}
                                inputType='email'
                                placeholder={TR('Электронная почта...')}
                                value={email}
                                setValue={setEmail}
                            />
                        </div>
                        <button className="button-f w--100 h--44" onClick={() => handleSubmit()}>{TR_('Продолжить')}</button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ForgotPassword;