import { FC, Fragment, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { BlobCache, DentFile } from 'service/Data/DentTypes';
import { TR } from 'service/T10N';
import OutsideClicker from './OutsideClicker';
//import * as S from './Header.styles';

interface FileUploaderProps {
    reff?: any
    filesLimit?: number
    files: DentFile[]
    setFiles: (v: DentFile[]) => void
    maxFileSize?: number
    formats?: RegExp
}

const FileUploader: FC<FileUploaderProps> = ({ reff, filesLimit, files, setFiles, maxFileSize = 52428800, formats = /\.(pdf|xls)$/i }) => {
    const [shown, setShown] = useState(false);
    const [errorText, setErrorText] = useState("");

    const setError = (text: string) => {
        setErrorText(text)
        setShown(true)
    }

    const handleFiles = async (selectedFiles: FileList | null) => {
        if (selectedFiles) {
            const file = selectedFiles[0]

            if (!file)
                return

            if (!file.name.match(formats)) {
                setError(TR('Поддерживаемые форматы .pdf .xls — не более 50 мб.'))
                return
            }

            {// ~~ temp
                //updateMyData(file.name, file)
                //return
            }

            if (filesLimit && files.length >= filesLimit)
                return

            //const fileInfo = await uploadFile(file.name, file)
            const fileInfo: DentFile = { name: file.name, fuid: new BlobCache(file) }

            console.log("New file")
            console.log(fileInfo)
            setFiles(files.concat(fileInfo))
        }
    }

    const handleFileRemove = async (removeFile: DentFile) => {
        console.log("Removing file: " + removeFile)
        const newFiles = files.filter((file) => file !== removeFile)
        console.log(newFiles)
        setFiles(newFiles)
    }

    const Item = ({ file, index }: { file: DentFile, index: number }) => {
        return (
            <div className="upload-item uploaded">
                {file.name}
                <button className="unupload" onClick={(e) => {
                    e.preventDefault();
                    handleFileRemove(file)
                }} />
                {/* <div className="upload-bar">
                    <div className="upload__indicator" style={{ width: "100%" }}></div>
                </div> */}
            </div>
        )
    }

    return (
        <Fragment>
            <div className="input-upload" data-tooltip-id="file-tooltip"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    handleFiles(e.dataTransfer.files)
                }}>
                <div className="upload-data">
                    <p className="upload__info">
                        {TR('Перетащите файлы в выбранную область или нажмите кнопку загрузить файл')}
                    </p>
                    <p className="upload__sub">{TR('Поддерживаемые форматы .pdf .xls — не более 50 мб.')}</p>
                </div>
                <img className="upload__img" src="/assets/images/document.svg" alt="dental portal" />
                <input type="file" name="files" id="file"
                    onChange={(event) => {
                        handleFiles(event.target.files)
                    }} />
                <label ref={reff} tabIndex={0} htmlFor="file" className="button-40">{TR('Загрузить файл')}</label>
            </div>
            <OutsideClicker onClickOutside={() => setShown(false)} />
            <Tooltip id="file-tooltip" isOpen={!!errorText && shown} content={errorText} />
            <div className="upload-box">
                {files.map((file, index) => {
                    return <Item
                        key={file.fuid instanceof BlobCache ? file.fuid.blobUrl : file.fuid}
                        file={file}
                        index={index} />
                })}
                {/* <div className="upload-item uploading">
                    Хирургический шаблон
                    <button className="unupload"></button>
                    <div className="upload-bar">
                        <div className="upload__indicator" style={{ width: "100%" }}></div>
                    </div>
                </div>
                <div className="upload-item uploaded">
                    Хирургический шаблон
                    <button className="unupload"></button>
                    <div className="upload-bar">
                        <div className="upload__indicator" style={{ width: "100%" }}></div>
                    </div>
                </div> */}
            </div>
            {/* <div className="upload-box  mb--30">
                <div className="upload-item uploading">
                    Хирургический шаблон
                    <button className="unupload"></button>
                    <div className="upload-bar">
                        <div className="upload__indicator"></div>
                    </div>
                </div>
                <div className="upload-item uploaded">
                    Док 244_отк
                    <button className="unupload"></button>
                    <div className="upload-bar">
                        <div className="upload__indicator"></div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}

export default FileUploader;