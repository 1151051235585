// const checkOptions = {
//     title: "Интраоральный сканнер",
//     value: hasScanner,
//     setValue: setHasScanner
// }

const TextFilter = ({ value, setValue, placeholder, counter, onSearch = () => { }, onExpand = () => { } }) => {
    return (
        <div className="search-box">
            <div className="input-search">
                <input type="text" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} />
                <button className="loop" onClick={() => onSearch}></button>
            </div>
            <button className="filter-button" onClick={() => onExpand()}>
                <span className="filter-counter">{counter}</span>
            </button>
        </div>
    )
}

export default TextFilter;