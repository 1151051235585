import AuthContext from "AuthContext";
import PopupMore from "Pages/Popups/PopupMore";
import { FC, useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { getAuctionFeedbacks, getDateHuman, getDateStr } from "service/API";
import { Auction, MyAuction, maxCabinetCardItems } from "service/Data/DentTypes";
import { TR, TR_ } from "service/T10N";

interface MyPurchasesProps {
    actual?: MyAuction[]
    archived?: MyAuction[]
}

const MyPurchases: FC<MyPurchasesProps> = ({ actual, archived }) => {
    actual = actual ? actual : []
    archived = archived ? archived : []

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isArchive, setIsArchive] = useState(false)
    const [maxItems, setMaxItems] = useState(maxCabinetCardItems)

    async function handleFeedback(myAuction: MyAuction) {
        try {
            if (auth.session) {
                const auction: Auction = { ...myAuction, creator: { ...auth.session } }
                const feedbacks = await getAuctionFeedbacks(myAuction.id)
                history.push('/auction-view-feedbacks', { auction: auction, feedbacks: feedbacks })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const Item = (auction: MyAuction) => {
        const { id, title, untilDate, maxBudget, oldFeedbacks, newFeedbacks } = auction
        const [isPopupMoreShown, setPopupMoreShown] = useState(false)
        return (
            <article className="card__text-item">
                <div className="card-data-box" onClick={() => { handleFeedback(auction) }}>
                    <h3 className="card-item__title">{title}</h3>
                    <p className="card-item__details">
                        {maxBudget ? <span>{maxBudget} {TR('₽')}</span> : <span className="blind--text">{TR('Не указ.')}</span>}
                        <time dateTime={getDateStr(untilDate)}>{TR('до')} {getDateHuman(untilDate)}</time>
                    </p>
                </div>
                <div className="card-rank-box">
                    {oldFeedbacks}
                    {newFeedbacks ? <span>+{newFeedbacks}</span> : null}
                </div>
                {/* <div className="data-controls-box">
                    <button className="push-btn" onClick={() => setPopupMoreShown(true)}></button>
                    <button className="edit-btn"></button> 
                    <button className="delete-btn"></button>
                </div> */}
                {isPopupMoreShown ? <PopupMore onClose={() => setPopupMoreShown(false)} /> : null}
            </article>
        )
    }

    const handleCreate = () => {
        history.push('/auction-new')
    }

    const items = isArchive ? archived : actual

    return (
        <section className="section-wrapper">
            <div className="card-header">
                <h2 className="card-title">{TR('Мои закупки')}
                    {/* <span className="card-title__counter">{actual.length}</span> */}
                </h2>
                <button className="button-27e" onClick={() => handleCreate()}>{TR('Создать закупку')}</button>
            </div>
            <div className="card-filter">
                <div className="swiper-checkbox">
                    <input type="checkbox" id="oldData" checked={isArchive} onChange={() => setIsArchive(!isArchive)} />
                    <label htmlFor="oldData">
                        <span className="actual">{TR('Актуальные')}</span>
                        <span className="not__actual">{TR('Архив')}</span>
                    </label>
                </div>
            </div>
            <div className="card-body">
                {items.slice(0, maxItems).map((item) => <Item key={item.id} {...item} />)}
                {items.length > maxCabinetCardItems && maxItems == maxCabinetCardItems ?
                    <button className="rest-items" onClick={() => setMaxItems(items.length)}>
                        <span>{TR_('Ещё') + ' ' + (items.length - maxItems)}</span>
                        <span />
                        <span>{TR('Смотреть все')}</span>
                    </button>
                    : maxItems != maxCabinetCardItems ?
                        <button className="rest-items" onClick={() => setMaxItems(maxCabinetCardItems)}>
                            <span />
                            <span>{TR_('Свернуть')}</span>
                        </button>
                        :
                        null
                }
            </div>
        </section>
    )
}

export default MyPurchases;