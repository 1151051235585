
const PopupCity = (props) => {
    return (
        <div className="cover">
            <div className="sideup">
                <button className="killer"></button>
                <header className="sideup-header">
                    <h1 className="sideup-title">
                        Город
                    </h1>
                </header>
                <div className="sideup-body">
                    <form action="">
                        <div className="side-search">
                            <div className="input-search">
                                <input type="text" placeholder="Поиск" />
                                <button className="loop"></button>
                            </div>
                        </div>
                        <div className="city-list">
                            <button className="city__item">Москва</button>
                            <button className="city__item">Санкт-петербург</button>
                            <button className="city__item">Новосибирск</button>
                            <button className="city__item">Нижний новгород</button>
                            <button className="city__item">Краснодар</button>
                            <button className="city__item">Ижевск</button>
                            <button className="city__item">Подольск</button>
                            <button className="city__item">Нижнекамск</button>
                            <button className="city__item">Москва</button>
                            <button className="city__item">Санкт-петербург</button>
                            <button className="city__item">Новосибирск</button>
                            <button className="city__item">Нижний новгород</button>
                            <button className="city__item">Краснодар</button>
                            <button className="city__item">Ижевск</button>
                            <button className="city__item">Подольск</button>
                            <button className="city__item">Нижнекамск</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PopupCity;