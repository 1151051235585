import ResultsCounter from 'Components/ResultsCounter';
import SearchFilters from 'Components/SearchFilters';
import TopSwitch from 'Components/TopSwitch';
import { useEffect, useState } from 'react';
import { getOffers } from 'service/API';
import { Offer } from 'service/Data/DentTypes';
import { _traders } from 'service/Data/TradersData';
import { TR, TR_ } from 'service/T10N';
import OfferCard from './Components/OfferCard';

const companyPopupData = _traders[0] // TODO: get data from server

const Offers = () => {
    const [justInitialized, setJustInitizlized] = useState(true);
    const [offers, setOffers] = useState<Offer[] | null>(null);

    const resetFilters = () => {
        setCities([])
        setPriceFrom('')
        setPriceTo('')
        setFindText('')
    }

    const [findText, setFindText] = useState('')
    const [forRole, setForRole] = useState('')
    const [cities, setCities] = useState([])
    const [priceFrom, setPriceFrom] = useState('')
    const [priceUpto, setPriceTo] = useState('')
    const [sortBy, setSortBy] = useState('date')

    useEffect(() => {
        (async () => {
            //console.log('Offers effect')
            //console.log(sortBy)
            const offers = await getOffers(findText, forRole, cities, parseInt(priceFrom), parseInt(priceUpto), sortBy)
            setOffers(offers)
            setJustInitizlized(false)
        })();
    }, [forRole, findText, cities, priceFrom, priceUpto, sortBy]);

    const filter = [
        {
            id: "text",
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
                // onSearch: handleSearchButton,
            }
        },
        // {
        //     id: "cities",
        //     subset: {
        //         from: getAvailableCities(),
        //         value: cities,
        //         setValue: setCities,
        //         nonEmptyTitle: TR('Выбранные города'),
        //         whenEmptyTitle: TR('По всем городам'),
        //         popupTitle: TR('Город'),
        //     }
        // },
        {
            id: "priceRange",
            range: {
                title: TR('Цена'),
                from: priceFrom,
                to: priceUpto,
                setFrom: setPriceFrom,
                setTo: setPriceTo,
                fromPlaceholder: TR('От ₽'),
                toPlaceholder: TR('До ₽'),
            }
        },
    ]

    const switchData = {
        switchName: 'roles',
        value: forRole,
        setValue: setForRole,
        items: [
            { title: TR('Все'), value: '' },
            //{ title: TR('Для клиник & врачей'), value: 'doctor' },
            { title: TR_('Для клиник'), value: 'doctor' },
            { title: TR_('Для лабораторий'), value: 'technician' },
        ]
    }

    return (
        <main className="main">
            <div className="container">
                <h1 className="section-title">{TR('Предложения')}</h1>
                <div className="list-controls">
                    <TopSwitch {...switchData} />
                    <div className="list-controls__sort">
                        {/* <SortBy value={sortBy} setValue={setSortBy} /> */}
                        <ResultsCounter value={offers ? offers.length : 0} />
                    </div>
                </div>
                <div className="list-base">
                    <SearchFilters options={filter} onResetFilters={resetFilters} />
                    <div className="list-ads">
                        {/* add class list-map--open */}
                        <div className="list-map">
                        </div>
                        {offers?.length ?
                            offers.map((offer) => {
                                return <OfferCard key={offer.id} {...offer} />
                            })
                            :
                            justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Offers;