import AuthContext from 'AuthContext'
import AvatarSelector from 'Components/AvatarSelector'
import { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { resizeImage, updateMyData } from 'service/API'
import { BlobCache, Doctor } from 'service/Data/DentTypes'
import { gAvatarSize } from 'service/Globals'
import { TR, TR_ } from 'service/T10N'
import { MAX_NAME_LENGTH, TextInput, useInputFocus, useLabelFocus } from './MyDataDoctor'

interface MyDataDoctorProps {
    user: Doctor
}

const MyDataDoctorMini: FC<MyDataDoctorProps> = ({ user }) => {
    const history = useHistory()
    const auth = useContext(AuthContext);

    const [nameRef, setNameFocus] = useInputFocus()
    const [avatarRef, setAvatarFocus] = useLabelFocus()

    const [errorShown, setErrorShown] = useState(false);

    const [isHelpShown, setHelpShown] = useState(false)

    const [name, setName] = useState(user.name)
    const [avatar, setAvatar] = useState(user.avatar)

    function validateForm(): boolean {
        if (!name) {
            setErrorShown(true)
            setNameFocus()
            return false
        }

        if (!avatar) {
            setErrorShown(true)
            setAvatarFocus()
            return false
        }

        return true
    }

    const doSave = async () => {
        if (!validateForm()) {
            //console.log('Validation error...')
            return
        }

        const doctor: Doctor = {
            doctor: {
                chairs: 0,
                scanner: '',
                licenses: []
            },
            createdAt: user.createdAt,
            city: '',
            street: '',
            description: '',
            id: '',
            role: 'doctor',
            name: name,
            avatar: avatar,
            photos: [],
            contacts: {
                email: '',
                phone: '',
                hasTelegram: false,
                hasWhatsapp: false,
                site: '',
                youtube: '',
            },
        }

        //console.log('Updating my data:')
        //console.log(doctor)

        try {
            const reply = await updateMyData(doctor)

            if (reply.session) {
                // Update success
                //console.log('Successful reply with avatar: ' + reply.session.avatar)

                auth.updateSession(reply.session)
                history.push('/')
            }
        } catch (e) {
            if (!auth.session?.avatar) {
                auth.logout()
                history.push('/signin')
            }
        }
    }

    return (
        <main className='main' onMouseDown={() => setErrorShown(false)}>
            <div className='container'>
                <section className='respond'>
                    <div className='respond-cover'>
                        <h1 className='respond-title'>{TR('Мои данные')}</h1>
                        <div className='add'>
                            <div className='respond__form'>
                                <div className='respond__main'>
                                    <button className='how' onClick={() => setHelpShown(true)}>
                                        {TR('Рекомендация по заполнению')}
                                    </button>
                                    <form className='data__form'>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={nameRef}
                                                    tooltip_id='name-tooltip'
                                                    title={TR('Название клиники (или ФИО)')}
                                                    value={name}
                                                    maxLength={MAX_NAME_LENGTH}
                                                    setValue={setName} />
                                                <Tooltip id="name-tooltip" isOpen={errorShown && !name} content={TR_("Укажите название")} />
                                            </div>
                                            <div className='contact-item'>
                                                <div className='field-set'>
                                                    <p className='field-set__title'>
                                                        {TR_('Аватар')}
                                                    </p>
                                                    <AvatarSelector reff={avatarRef} tooltip="avatar-tooltip" value={avatar} onFileSelected={async (file) => {
                                                        try {
                                                            //console.log(file)
                                                            const blob = await resizeImage(file, gAvatarSize, gAvatarSize)
                                                            if (blob instanceof Blob) {
                                                                //const fuid = await uploadAvatar(blob)
                                                                //const url = avatarUrl(fuid)
                                                                // const url = URL.createObjectURL(blob)
                                                                // console.log(url)
                                                                setAvatar(new BlobCache(blob))
                                                                //auth.setAvatar(url)
                                                            }
                                                        }
                                                        catch (error) {
                                                            //console.log('error aru1')
                                                            //console.log(error)
                                                        }

                                                    }} />
                                                    <Tooltip id="avatar-tooltip" isOpen={errorShown && !avatar} content={TR_("Загузите ваш аватар")} />
                                                    <p className='fied-set__error'></p>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className='add__footer'>
                                    <div className='data-controls'>
                                        {/* <button className='button-e h--44' onClick={() => history.push('/')}>{TR('Отмена')}</button> */}
                                        <button className='button-f h--44' onClick={() => doSave()}>{TR('Сохранить')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className={'respond__description data__info' + (isHelpShown ? '' : ' hide_me')}>
                                <div className=' respond__description-inner'>
                                    <header className='add__header'>
                                        <button className='add__header-btn' onClick={() => setHelpShown(false)}></button>
                                        <h2 className='add__header-title'>{TR('Рекомендация по заполнению')}</h2>
                                    </header>
                                    <div className='add__main'>
                                        <ol className='gide-list'>
                                            <li className='gide__item'>
                                                {TR_('Укажите название и загрузите логотип.')}
                                            </li>
                                        </ol>
                                    </div>
                                    <div className='add__footer'>
                                        <button className='main-button is--blue  too--wide'>{TR('Закрыть')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main >
    )
}

export default MyDataDoctorMini