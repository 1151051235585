import FileUploader from 'Components/FileUploader';
import PhotoSelector from 'Components/PhotoSelector';
import TopSwitch from 'Components/TopSwitch';
import { MAX_DESCR_LENGTH, MAX_NAME_LENGTH, useInputFocus, useTextFocus } from 'Pages/Home/Components/MyDataDoctor';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { createOrUpdateAuction, getDateStr } from 'service/API';
import { Auction, AuctionRequest, AuctionTargetRole, DentFUID, DentFile, UnixTime, boolToTarget } from 'service/Data/DentTypes';
import { TR, TR_ } from 'service/T10N';

export function isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d.getTime())
}

export function getDateUnixUTC(dateStr: string): UnixTime | undefined {
    if (isValidDate(new Date(dateStr))) {
        const date = new Date(dateStr)
        const dateUTC = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds());
        return Math.floor(dateUTC / 1000) // utc unix timestamp
    }

    return undefined
}

interface LocationState {
    auction?: Auction
}

const AuctionNew = () => {
    const location = useLocation<LocationState>(); // '/auction-new'
    const auction: Auction | undefined = location.state?.auction

    const history = useHistory()
    const [isDescriptionShown, setDescriptionShown] = useState(false)

    const deadlineRef = useRef<HTMLInputElement>(null)

    const [titleRef, setTitleFocus] = useInputFocus()
    const [descriptionRef, setDescriptionFocus] = useTextFocus()

    const [errorShown, setErrorShown] = useState(false);

    // Scroll to top once on page load
    const [scrollToTop, setScrollToTop] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [scrollToTop])

    const [targetRole, setTargetRole] = useState<AuctionTargetRole>(auction ? auction.targetRole : '')
    const [title, setTitle] = useState(auction ? auction.title : '')
    const [budget, setBudget] = useState(auction ? auction.maxBudget : '')
    const [deadline, setDeadline] = useState(auction?.untilDate ? getDateStr(auction.untilDate) : '')
    const [description, setDescription] = useState(auction ? auction.description : '')
    const [isCadCam, setIsCadCam] = useState<boolean>(auction ? auction.targetCadcam == 2 : false)
    const [starredPhoto, setStarredPhoto] = useState<DentFUID>(auction?.photos?.length ? auction.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(auction?.photos ? auction.photos : [])
    const [files, setFiles] = useState<DentFile[]>(auction?.files ? auction.files : [])

    const switchData = {
        switchName: 'role',
        value: targetRole as string,
        setValue: setTargetRole as (v: string) => void,
        items: [
            { title: TR('Для всех'), value: '' },
            { title: TR('Для лабораторий'), value: 'technician' },
            { title: TR('Для торговых компаний'), value: 'trader' },
        ]
    }

    function validateForm(): boolean {
        if (!title) {
            setErrorShown(true)
            setTitleFocus()
            return false
        }

        if (!description) {
            setErrorShown(true)
            setDescriptionFocus()
            return false
        }

        return true
    }

    const publish = async () => {
        // console.log("Targeting: " + targeting)
        // console.log("Title: " + title)
        // console.log("Budget: " + budget)
        // console.log("Deadline: " + deadline)
        // console.log("Description: " + description)
        // console.log("CadCam: " + isCadCam)
        // console.log("Files: " + files)
        if (!validateForm()) {
            console.log('Validation error...')
            return
        }

        var request: AuctionRequest = {
            auctionId: auction?.id,
            targetRole: targetRole,
            title: title,
            description: description,
            photos: photos,
            files: files,
            untilDate: getDateUnixUTC(deadline),
            maxBudget: budget ? +budget : undefined,
            targetCadcam: boolToTarget(isCadCam) // TODO: fix target all state (neet to update UI)
        }

        console.log(request);

        const reply = await createOrUpdateAuction(request)

        if (reply.status === 'success') {
            // Update success
            console.log('Auction was created');
            history.push('/dash')
        }
        else {
            // TODO: show error to user somehow
            console.log('Auction creation error');
        }
    }

    return (
        <main className="main">
            <div className="container">
                <div className="add-cover">
                    <h1 className="add-title">{TR('Создать закупку')}</h1>
                    <div className="add">
                        <div className="add__form">
                            <header className="add__header">
                                <button className="add__header-btn" onClick={history.goBack}></button>
                                <h2 className="add__header-title">{TR('Создать закупку')}</h2>
                            </header>
                            <div className="add__main">
                                <button className="how" onClick={() => setDescriptionShown(true)}>
                                    {TR('Как создать успешную закупку')}
                                </button>
                                <form className="new-add-form" action="post">
                                    <TopSwitch {...switchData} />
                                    <div className="field-set mt--30">
                                        <p className="field-set__title">
                                            {TR('Заголовок')}
                                        </p>
                                        <div className="input" data-tooltip-id="title-tooltip">
                                            <input ref={titleRef} maxLength={MAX_NAME_LENGTH} type="text" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                        <p className="fied-set__error"></p>
                                        <Tooltip id="title-tooltip" isOpen={errorShown && !title} content={TR_("Заголовок не может быть пустым")} />
                                    </div>
                                    <div className="field-flex">
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR_('Максимальный бюджет')}
                                                </p>
                                                <div className="input">
                                                    <input placeholder={TR('Не могу оценить стоимость')} type="number" value={budget} onChange={(e) => setBudget(e.target.value)} />
                                                </div>
                                                {/* <input type="checkbox" className="checkbox-e" id="period" checked={!!!budget} />
                                                <label htmlFor="period">{TR('Не могу оценить стоимость')} </label> */}
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                        <div className="fiels-flex__item">
                                            <div className="field-set">
                                                <p className="field-set__title">
                                                    {TR('Сроки (дедлайн)')}
                                                </p>
                                                <div className="input-date">
                                                    {/* <input
                                                        ref={deadlineRef}
                                                        placeholder={TR('Заказ с открытой датой завершения')}
                                                        type="text"
                                                        min={getDateStr(new Date())}
                                                        value={deadline}
                                                        onChange={(e) => setDeadline(e.target.value)}
                                                        onFocus={() => deadlineRef.current ? deadlineRef.current.type = "date" : null}
                                                        onBlur={() => deadlineRef.current ? deadlineRef.current.type = "text" : null}
                                                    /> */}
                                                    <input
                                                        ref={deadlineRef}
                                                        placeholder={TR('Заказ с открытой датой завершения')}
                                                        type="date"
                                                        min={getDateStr(new Date())}
                                                        value={deadline}
                                                        onChange={(e) => setDeadline(e.target.value)}
                                                    />
                                                </div>
                                                {/* <input type="checkbox" className="checkbox-e" id="deadline" checked={!isValidDate(new Date(deadline))} />
                                                <label htmlFor="deadline">{TR('Заказ с открытой датой завершения')} </label> */}
                                                <p className="fied-set__error"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-set">
                                        <p className="field-set__title">
                                            {TR('Описание')}
                                        </p>
                                        <div className="textarea h--90" data-tooltip-id="description-tooltip">
                                            <textarea ref={descriptionRef} maxLength={MAX_DESCR_LENGTH} name="some-text" id="someText" placeholder={TR_('Опишите детально, что Вам нужно')} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                        </div>
                                        <p className="fied-set__error"></p>
                                        <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Описание не может быть пустым")} />
                                    </div>
                                    <PhotoSelector
                                        photos={photos}
                                        setPhotos={setPhotos}
                                        starred={starredPhoto}
                                        setStarred={setStarredPhoto}
                                    />
                                    <FileUploader files={files} setFiles={setFiles} />
                                    <div className="slide-flex mt--30">
                                        {targetRole == 'technician' ?
                                            <Fragment>
                                                <input type="checkbox" className="checkbox-slide" id="chbxsld" checked={isCadCam} onChange={() => setIsCadCam(!isCadCam)} />
                                                <label htmlFor="chbxsld"> <span></span> {TR_('CAD/CAM технология')}</label>
                                            </Fragment>
                                            :
                                            null
                                        }
                                    </div>
                                </form>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide" onClick={() => publish()}>{TR('Опубликовать')}</button>
                            </div>
                        </div>
                        <div className={"add__description" + (isDescriptionShown ? "" : " hide_me")}>
                            <header className="add__header">
                                <button className="add__header-btn" onClick={() => setDescriptionShown(false)}></button>
                                <h2 className="add__header-title">{TR('Как создать успешную закупку')}</h2>
                            </header>
                            <div className="add__main">
                                <ol className="gide-list">
                                    <li className="gide__item">
                                        {TR('Название должно быть кратким и понятным для участников портала.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Постарайтесь указать планируемый бюджет и сроки выполнения работ.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('В описательной части Закупки укажите на все особенности и требования, которые необходимо соблюсти при выполнении данных работ (поставке товаров).')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Обязательно подгружайте файлы, фотографии, позволяющие судить о сложности работ или специфике поставляемого товара.')}
                                    </li>
                                    <li className="gide__item">
                                        {TR('Определите категорию потенциальных Исполнителей по данной Закупки - Для всех/Лаборатория/Поставщик')}
                                    </li>
                                </ol>
                            </div>
                            <div className="add__footer">
                                <button className="main-button is--blue  too--wide">{TR('Закрыть')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default AuctionNew;