import { ReactNode } from "react"

type SignupThanksProps = {
    title: string
    buttonText?: string
    verifyUrl?: string
    children?: ReactNode
}

const SignupThanks = (props: SignupThanksProps) => {
    return (
        <div className="sign-cover">
            <header className="sign-header">
                <a href="#" className="logo">
                    <img className="logo-img" src="/assets/images/logo.png" alt="" />
                </a>
            </header>
            <main className="sign-main">
                <div className="sign-body sign--fin">
                    <img className="final-img" src="/assets/icons/final-img.svg" alt="" />
                    <div className="final-msg">
                        <h2 className="final-msg__title">{props.title}</h2>
                        <p className="final-msg__txt">
                            {props.children}
                        </p>
                        {/* <a className="button-f" href={props.verifyUrl}>{props.buttonText}</a> */}
                    </div>
                </div>
            </main>
        </div>
        // <main>
        //     <section className="layout registrationPopup">
        //         <h1 className="title">{props.title}</h1>
        //         <p>
        //             {props.children}
        //         </p>
        //         <button type="button" className="btn RegistrationBtn" onClick={props.handler}>{props.buttonText}</button>
        //     </section>
        // </main>
    )
}

export default SignupThanks;