import AuthContext from 'AuthContext';
import ResultsCounter from 'Components/ResultsCounter';
import SearchFilters from 'Components/SearchFilters';
import { useContext, useEffect, useState } from 'react';
import { getSales } from 'service/API';
import { Sale } from 'service/Data/DentTypes';
import { TR } from 'service/T10N';
import SaleCard from './Components/SaleCard';

const Sales = () => {
    const auth = useContext(AuthContext);

    const [justInitialized, setJustInitizlized] = useState(true);
    const [sales, setSales] = useState<Sale[] | null>(null);

    const resetFilters = () => {
        setCities([])
        setPriceFrom("")
        setPriceTo("")
        setFindText('')
    }

    const [findText, setFindText] = useState('')
    const [cities, setCities] = useState([])
    const [priceFrom, setPriceFrom] = useState("")
    const [priceTo, setPriceTo] = useState("")
    const [sortBy, setSortBy] = useState('date')

    useEffect(() => {
        (async () => {
            //console.log('Sales effect')
            //console.log(sortBy)
            const sales = await getSales(findText, cities, parseInt(priceFrom), parseInt(priceTo), sortBy)
            setSales(sales)
            setJustInitizlized(false)
        })();

    }, [findText, cities, priceFrom, priceTo, sortBy]);

    const filter = [
        {
            id: "text",
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
                // onSearch: handleSearchButton,
            }
        },
        // {
        //     id: "cities",
        //     subset: {
        //         from: getAvailableCities(),
        //         value: cities,
        //         setValue: setCities,
        //         nonEmptyTitle: TR('Выбранные города'),
        //         whenEmptyTitle: TR('По всем городам'),
        //         popupTitle: TR('Город'),
        //     }
        // },
        {
            id: "priceRange",
            range: {
                title: TR('Цена'),
                from: priceFrom,
                to: priceTo,
                setFrom: setPriceFrom,
                setTo: setPriceTo,
                fromPlaceholder: TR('От ₽'),
                toPlaceholder: TR('До ₽'),
            }
        },
    ]

    const ownUserId: string = auth.session ? auth.session.id : ''

    return (
        <main className="main">
            <div className="container">
                <h1 className="section-title">{TR('Барахолка')}</h1>
                <div className="list-controls">
                    <div className="list-controls__switch" /* don't delete */ />
                    <div className="list-controls__sort">
                        {/* <SortBy value={sortBy} setValue={setSortBy} /> */}
                        <ResultsCounter value={sales ? sales.length : 0} />
                    </div>
                </div>
                <div className="list-base">
                    <SearchFilters options={filter} onResetFilters={resetFilters} />
                    <div className="list-ads">
                        <div className="list-map" /* To show add class 'list-map--open' */ >
                        </div>
                        {sales?.length ?
                            sales.map((sale) => {
                                return <SaleCard key={sale.id} sale={sale} ownId={ownUserId} />
                            })
                            :
                            justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Sales;