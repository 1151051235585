// const rangeOptions = {
//     title: "Цена",
// }

const RangeFilter = ({ title, from, setFrom, to, setTo, fromPlaceholder = '', toPlaceholder = '' }) => {
    return (
        <li className="filter__list-item">
            <div className="filter-range">
                <p className="filter-type__title">{title}</p>
                <div className="range-box">
                    <div className="input">
                        <input type="number" placeholder={fromPlaceholder} value={from} onChange={(e) => setFrom(e.target.value)} />
                    </div>
                    <div className="input">
                        <input type="number" placeholder={toPlaceholder} value={to} onChange={(e) => setTo(e.target.value)} />
                    </div>
                </div>
            </div>
        </li>
    )
}

export default RangeFilter;