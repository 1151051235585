import { TR } from 'service/T10N';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    //role: Yup.string().required(TR('Не указана роль')),//.oneOf(['doctor', 'technician', 'trader'], 'Необходимо выбрать тип регистрации'),
    email: Yup.string().email(TR('Неверный формат электронной почты')).required(TR('Обязательное поле')),
    password: Yup.string().min(6, TR('Слишком короткий пароль')).max(32, TR('Слишком длинный пароль')).required(),
    acceptedTerms: Yup.boolean().required(TR('Подтвердите согласие')).oneOf([true], TR('Подтвердите согласие')),
});

const useSendRegistrationData = () => {
    return {  schema };
}

export default useSendRegistrationData;