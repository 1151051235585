import AuthContext from 'AuthContext'
import AvatarSelector from 'Components/AvatarSelector'
import FileUploader from 'Components/FileUploader'
import PhotoSelector from 'Components/PhotoSelector'
import { SubsetCityItem } from 'Components/SearchFilters/SubsetFilter2'
import SearchFilters2 from 'Components/SearchFilters2'
import { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { resizeImage, updateMyData } from 'service/API'
import { BlobCache, DentFUID, DentFile, Trader, TraderSpecTags } from 'service/Data/DentTypes'
import { gAvatarSize } from 'service/Globals'
import { TR, TR_ } from 'service/T10N'
import { MAX_DESCR_LENGTH, MAX_EMAIL_LENGTH, MAX_NAME_LENGTH, MAX_SITE_LENGTH, TextAreaInput, TextInput, useInputFocus, useLabelFocus, useTextFocus } from './MyDataDoctor'

interface SubsetFilterItem {
    id: string,
    subset2: {
        from: SubsetCityItem[],
        value: SubsetCityItem[],
        setValue: (sv: (v: SubsetCityItem[]) => SubsetCityItem[]) => void,
        whenEmptyTitle: string,
        nonEmptyTitle: string,
        popupTitle: string,
    }
}

export const gFilterTraders: SubsetFilterItem[] = [
    // {
    //     id: "cadcam_",
    //     subset: {
    //         from: [
    //         ],
    //         value: temp,
    //         setValue: setTemp,
    //         whenEmptyTitle: '',
    //         nonEmptyTitle: '',
    //         popupTitle: '',
    //     }
    // },
    {
        id: "cadcams",
        subset2: {
            from: [
                { text: TR('3D принтеры'), tag: 'cadcam-3dprinters' },
                { text: TR('Лабораторные сканеры'), tag: 'cadcam-labscanners' },
                { text: TR('Интраоральные сканеры'), tag: 'cadcam-ioscanners' },
                { text: TR('Фрезерные станки'), tag: 'cadcam-frezstan' },
                { text: TR('Диски'), tag: 'cadcam-disks' },
            ],
            value: [],
            setValue: () => { },
            whenEmptyTitle: TR('CAD/CAM технологии'),
            nonEmptyTitle: TR('Выбранные CAD/CAM технологии'),
            popupTitle: "CAD/CAM",
        }
    },
    {
        id: "stomatologs",
        subset2: {
            from: [
                { text: TR('Стоматологические установки'), tag: 'stom-dentunits' },
                { text: TR('Наконечники и микромоторы'), tag: 'stom-tipsmicromotors' },
                { text: TR('Стулья'), tag: 'stom-chairs' },
                { text: TR('Компрессоры и помпы'), tag: 'stom-pumps' },
                { text: TR('Стерилизационное оборудование'), tag: 'stom-steril' },
                { text: TR('Рентгендиагностика'), tag: 'stom-xraydiag' },
                { text: TR('Светильники'), tag: 'stom-fixtures' },
                { text: TR('Микроскопы'), tag: 'stom-microscopes' },
                { text: TR('Инструменты'), tag: 'stom-tools' },
                { text: TR('Шприцы и иглы'), tag: 'stom-needles' },
                { text: TR('Анестетик'), tag: 'stom-anesthetic' },
                { text: TR('Слепочная масса'), tag: 'stom-imass' },
                { text: TR('Композит'), tag: 'stom-composite' },
                { text: TR('Нить ретракционная'), tag: 'stom-rthread' },
                { text: TR('Цемент'), tag: 'stom-cement' },
                { text: TR('Реставрационный материал'), tag: 'stom-rmaterial' },
                { text: TR('Пломбировачный материал'), tag: 'stom-fmaterial' },
            ],
            value: [],
            setValue: () => { },
            whenEmptyTitle: TR('Стоматологическое оборудование и материалы'),
            nonEmptyTitle: TR('Стоматологическое оборудование и материалы'),
            popupTitle: TR('Стоматологическое оборудование и материалы'),
        }
    },
    {
        id: "zubtechs",
        subset2: {
            from: [
                { text: TR('Артикуляторы'), tag: 'zubteh-articul' },
                { text: TR('Вакуумные смесители'), tag: 'zubteh-vacmixers' },
                { text: TR('Вибростолы'), tag: 'zubteh-vibrtables' },
                { text: TR('Воскотопки'), tag: 'zubteh-waxmelters' },
                { text: TR('Микромоторы и турбины'), tag: 'zubteh-turbines' },
                { text: TR('Печи'), tag: 'zubteh-ovens' },
                { text: TR('Пароструйные аппараты'), tag: 'zubteh-steamjets' },
                { text: TR('Пескоструйные аппараты'), tag: 'zubteh-sandblasters' },
                { text: TR('Керамические массы'), tag: 'zubteh-ceramicmass' },
                { text: TR('Воска'), tag: 'zubteh-waxes' },
                { text: TR('Гипсы'), tag: 'zubteh-gypsum' },
                { text: TR('Сплавы'), tag: 'zubteh-alloys' },
                { text: TR('Инструменты'), tag: 'zubteh-tools' },
            ],
            value: [],
            setValue: () => { },
            whenEmptyTitle: TR('Зуботехническое оборудование и материалы'),
            nonEmptyTitle: TR('Зуботехническое оборудование и материалы'),
            popupTitle: TR('Зуботехническое оборудование и материалы'),
        }
    },
    {
        id: "implants",
        subset2: {
            from: [
                { text: "Astra Tech", tag: 'impl-astratech' },
                { text: "Sewon Medix", tag: 'impl-sewonmedix' },
                { text: "Ankylos", tag: 'impl-ankylos' },
                { text: "Strauman", tag: 'impl-strauman' },
                { text: "Anthogyr", tag: 'impl-anthogyr' },
                { text: "Alpha Bio", tag: 'impl-alphabio' },
                { text: "Nobel Biocare", tag: 'impl-nobelbiocare' },
                { text: "Osstem", tag: 'impl-osstem' },
                { text: "Geo Medi", tag: 'impl-geomedi' },
                { text: "Anyridge", tag: 'impl-anyridge' },
                { text: "Biotech", tag: 'impl-biotech' },
            ],
            value: [],
            setValue: () => { },
            whenEmptyTitle: TR('Имплантология и хирургия'),
            nonEmptyTitle: TR('Имплантология и хирургия'),
            popupTitle: TR('Имплантология и хирургия'),
        }
    },
    {
        id: "furnitures",
        subset2: {
            from: [
                { text: TR('Для стоматологий'), tag: 'furn-dentistry' },
                { text: TR('Для зуботехнических лабораторий'), tag: 'furn-zubteh' },
            ],
            value: [],
            setValue: () => { },
            whenEmptyTitle: TR('Мебель'),
            nonEmptyTitle: TR('Мебель'),
            popupTitle: TR('Мебель'),
        }
    },
]

export function updateFilter(filter: SubsetFilterItem[], itemId: string, value: SubsetCityItem[], setValue: (sv: (v: SubsetCityItem[]) => SubsetCityItem[]) => void) {
    for (var v of filter) {
        if (v.id === itemId) {
            v.subset2.value = value
            v.subset2.setValue = setValue
        }
    }
}

function getTaggedItems(filter: SubsetFilterItem[], itemId: string, tags: string): SubsetCityItem[] {
    for (var v of filter) {
        if (v.id === itemId) {
            return v.subset2.from.filter((item) => tags.includes('#' + item.tag + ' '))
        }
    }

    return []
}

interface MyDataTraderProps {
    user: Trader
}

const MyDataTrader: FC<MyDataTraderProps> = ({ user }) => {
    const history = useHistory()
    const auth = useContext(AuthContext);

    const [nameRef, setNameFocus] = useInputFocus()
    const [avatarRef, setAvatarFocus] = useLabelFocus()
    const [photosRef, setPhotosFocus] = useLabelFocus()
    const [phoneRef, setPhoneFocus] = useInputFocus()
    const [emailRef, setEmailFocus] = useInputFocus()
    const [cityRef, setCityFocus] = useInputFocus()
    const [streetRef, setStreetFocus] = useInputFocus()
    const [descriptionRef, setDescriptionFocus] = useTextFocus()
    const [licensesRef, setLicensesFocus] = useLabelFocus()

    const [errorShown, setErrorShown] = useState(false);
    const [errorText, setErrorText] = useState("");
    const setError = (text: string) => {
        setErrorText(text)
        setErrorShown(true)
    }

    const [isHelpShown, setHelpShown] = useState(false)

    const [name, setName] = useState(user.name)
    const [avatar, setAvatar] = useState(user.avatar)
    //const [avatar, setAvatar] = useState<DentFUID>('')
    const [city, setCity] = useState(user.city)
    const [street, setStreet] = useState(user.street)
    const [phone, setPhone] = useState(user.contacts.phone)
    const [hasTelegram, setHasTelegram] = useState(user.contacts.hasTelegram)
    const [hasWhatsapp, setHasWhatsapp] = useState(user.contacts.hasWhatsapp)

    const [site, setSite] = useState(user.contacts.site)
    const [email, setEmail] = useState(user.contacts.email)
    const [youtube, setYoutube] = useState(user.contacts.youtube)
    const [description, setDescription] = useState(user.description)

    const [goods, setGoods] = useState(user.trader.goods)
    const [catalog, setCatalog] = useState(user.trader.catalog)

    const [starredPhoto, setStarredPhoto] = useState(user.photos.length ? user.photos[0] : '')
    const [photos, setPhotos] = useState<DentFUID[]>(user.photos)

    const [files, setFiles] = useState<DentFile[]>(user.counterpartyCard ? [user.counterpartyCard] : [])

    //console.log('Avatar: \'' + user.avatar + '\'');

    function validateForm(): boolean {
        setAvatarFocus()

        if (!name) {
            setErrorShown(true)
            setNameFocus()
            return false
        }

        if (!avatar) {
            setErrorShown(true)
            setAvatarFocus()
            return false
        }

        // if (!photos.length) {
        //     setErrorShown(true)
        //     setPhotosFocus()
        //     return false
        // }

        // if (!phone) {
        //     setErrorShown(true)
        //     setPhoneFocus()
        //     return false
        // }

        // if (!email) {
        //     setErrorShown(true)
        //     setEmailFocus()
        //     return false
        // }

        // if (!city) {
        //     setErrorShown(true)
        //     setCityFocus()
        //     return false
        // }

        // if (!street) {
        //     setErrorShown(true)
        //     setStreetFocus()
        //     return false
        // }

        // if (!description) {
        //     setErrorShown(true)
        //     setDescriptionFocus()
        //     return false
        // }

        // if (!files.length) {
        //     setErrorShown(true)
        //     setLicensesFocus()
        //     return false
        // }

        return true
    }

    function genTraderTags(): TraderSpecTags {
        function genTags(items: SubsetCityItem[][]): string {
            var result = ''
            for (var item of items) {
                for (var v of item) {
                    result += '#' + v.tag + ' '
                }
            }

            return result
        }

        return genTags([
            cadcams,
            stomatologs,
            zubtechs,
            implants,
            furnitures,
        ])
    }

    const doSave = async () => {
        //console.log(calcSpecialization())

        if (!validateForm()) {
            //console.log('Validation error...')
            return
        }

        const trader: Trader = {
            trader: {
                goods: goods,
                catalog: catalog,
                tags: genTraderTags()
            },
            createdAt: user.createdAt,
            city: city,
            street: street,
            description: description,
            id: '',
            role: 'trader',
            name: name,
            avatar: avatar,
            photos: [...photos],
            counterpartyCard: files ? files[0] : undefined,
            contacts: {
                email: email,
                phone: phone,
                hasTelegram: hasTelegram,
                hasWhatsapp: hasWhatsapp,
                site: site,
                youtube: youtube,
            },
        }

        //console.log('Updating my data:')
        //console.log(trader)

        try {
            const reply = await updateMyData(trader)

            if (reply.session) {
                // Update success
                //console.log('Successful reply with avatar: ' + reply.session.avatar)

                auth.updateSession(reply.session)
                history.push('/')
            }
        } catch (e) {
            if (!auth.session?.avatar) {
                auth.logout()
                history.push('/signin')
            }
        }
    }

    const [cadcams, setCadcams] = useState<SubsetCityItem[]>(getTaggedItems(gFilterTraders, 'cadcams', user.trader.tags))
    const [stomatologs, setStomatologs] = useState<SubsetCityItem[]>(getTaggedItems(gFilterTraders, 'stomatologs', user.trader.tags))
    const [zubtechs, setZubtechs] = useState<SubsetCityItem[]>(getTaggedItems(gFilterTraders, 'zubtechs', user.trader.tags))
    const [implants, setImplants] = useState<SubsetCityItem[]>(getTaggedItems(gFilterTraders, 'implants', user.trader.tags))
    const [furnitures, setFurnitures] = useState<SubsetCityItem[]>(getTaggedItems(gFilterTraders, 'furnitures', user.trader.tags))

    updateFilter(gFilterTraders, 'cadcams', cadcams, setCadcams)
    updateFilter(gFilterTraders, 'stomatologs', stomatologs, setStomatologs)
    updateFilter(gFilterTraders, 'zubtechs', zubtechs, setZubtechs)
    updateFilter(gFilterTraders, 'implants', implants, setImplants)
    updateFilter(gFilterTraders, 'furnitures', furnitures, setFurnitures)

    const resetFilters = () => {
        setCadcams([])
        setStomatologs([])
        setZubtechs([])
        setImplants([])
        setFurnitures([])
    }

    return (
        <main className='main' onMouseDown={() => setErrorShown(false)}>
            <div className='container'>
                <section className='respond'>
                    <div className='respond-cover'>
                        <h1 className='respond-title'>{TR('Мои данные')}</h1>
                        <div className='add'>
                            <div className='respond__form'>
                                <div className='respond__main'>
                                    <button className='how' onClick={() => setHelpShown(true)}>
                                        {TR('Рекомендация по заполнению')}
                                    </button>
                                    <form className='data__form'>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={nameRef}
                                                    tooltip_id='name-tooltip'
                                                    title={TR_('Название компании (или ФИО)')}
                                                    maxLength={MAX_NAME_LENGTH}
                                                    value={name}
                                                    setValue={setName} />
                                                <Tooltip id="name-tooltip" isOpen={errorShown && !name} content={TR_("Укажите название клиники")} />
                                            </div>
                                            <div className='contact-item'>
                                                <div className='field-set'>
                                                    <p className='field-set__title'>
                                                        {TR_('Аватар')}
                                                    </p>
                                                    <AvatarSelector reff={avatarRef} tooltip="avatar-tooltip" value={avatar} onFileSelected={async (file) => {
                                                        try {
                                                            //console.log(file)
                                                            const blob = await resizeImage(file, gAvatarSize, gAvatarSize)
                                                            if (blob instanceof Blob) {
                                                                //const fuid = await uploadAvatar(blob)
                                                                //const url = avatarUrl(fuid)
                                                                // const url = URL.createObjectURL(blob)
                                                                // console.log(url)
                                                                setAvatar(new BlobCache(blob))
                                                                //auth.setAvatar(url)
                                                            }
                                                        }
                                                        catch (error) {
                                                            //console.log('error aru1')
                                                            //console.log(error)
                                                        }

                                                    }} />
                                                    <Tooltip id="avatar-tooltip" isOpen={errorShown && !avatar} content={TR_("Загузите ваш аватар")} />
                                                    <p className='fied-set__error'></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='field-set'>
                                            <span className='field-set__title flex--between'>
                                                {TR_('Фотографии')}
                                                <div className='main-foto' id='main-foto' />
                                                <label htmlFor='main-foto'>{TR('Главное фото')}</label>
                                            </span>
                                            <PhotoSelector
                                                reff={photosRef}
                                                tooltip='photo-tooltip'
                                                photos={photos}
                                                setPhotos={setPhotos}
                                                starred={starredPhoto}
                                                setStarred={setStarredPhoto}
                                            />
                                            {/* <Tooltip id="photo-tooltip" isOpen={errorShown && !photos.length} content={TR_("Загузите хотя бы одно фото")} /> */}
                                        </div>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <div className='field-set'>
                                                    <p className='field-set__title flex--between'>
                                                        {TR('Телефон')}
                                                        <input type='checkbox' className='checkbox-slide' id='chbxsld1' checked={hasTelegram} onChange={(e) => setHasTelegram(!hasTelegram)} />
                                                        <label htmlFor='chbxsld1'> <span></span> {TR('Telegram')}</label>
                                                        <input type='checkbox' className='checkbox-slide' id='chbxsld2' checked={hasWhatsapp} onChange={(e) => setHasWhatsapp(!hasWhatsapp)} />
                                                        <label htmlFor='chbxsld2'> <span></span> {TR('WhatsApp')}</label>
                                                    </p>
                                                    <div className='input' data-tooltip-id="phone-tooltip">
                                                        <input ref={phoneRef} type='text' placeholder='' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                    </div>
                                                    {/* <Tooltip id="phone-tooltip" isOpen={errorShown && !phone} content={TR_("Укажите телефон")} /> */}
                                                    <p className='fied-set__error'></p>
                                                </div>
                                            </div>
                                            <div className='contact-item'>
                                                <TextInput
                                                    title={TR('Сайт')}
                                                    maxLength={MAX_SITE_LENGTH}
                                                    value={site}
                                                    setValue={setSite} />
                                            </div>
                                        </div>
                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={emailRef}
                                                    maxLength={MAX_EMAIL_LENGTH}
                                                    tooltip_id='email-tooltip'
                                                    title={TR('E-mail')}
                                                    value={email}
                                                    setValue={setEmail}
                                                />
                                                {/* <Tooltip id="email-tooltip" isOpen={errorShown && !email} content={TR_("Укажите ваш email")} /> */}
                                            </div>
                                            <div className='contact-item'>
                                                <TextInput
                                                    title={TR('YouTube')}
                                                    value={youtube}
                                                    setValue={setYoutube}
                                                />
                                            </div>
                                        </div>

                                        <div className='my-contacts'>
                                            <div className='contact-item'>
                                                <TextInput
                                                    reff={cityRef}
                                                    tooltip_id='city-tooltip'
                                                    title={TR('Город')}
                                                    value={city}
                                                    setValue={setCity}
                                                />
                                                {/* <Tooltip id="city-tooltip" isOpen={errorShown && !city} content={TR_("Укажите город")} /> */}
                                                <TextInput
                                                    reff={streetRef}
                                                    tooltip_id='street-tooltip'
                                                    title={TR('Улица, дом, офис')}
                                                    value={street}
                                                    setValue={setStreet}
                                                />
                                                {/* <Tooltip id="street-tooltip" isOpen={errorShown && !street} content={TR_("Укажите улицу")} /> */}
                                            </div>
                                            <div className='contact-item'>
                                                <div className='cadcam-fieldset'>
                                                    {/* <input type='checkbox' className='checkbox-slide' id='chbxsld' readOnly checked={!!cadcam} /> */}
                                                    {/* <label className='label--split' htmlFor='chbxsld'> */}
                                                    <label className='label--split'>
                                                        <p className='field-set__title'>
                                                            {TR_('Организационно-правовая форма')}
                                                        </p><span></span>
                                                    </label>
                                                    <div className='radio-string'>
                                                        <input className='radio-ball' type='radio' id='less3' name='amo' readOnly checked={goods == 0} onClick={() => setGoods(0)} />
                                                        <label htmlFor='less3'><span></span><div />ИП</label>
                                                        <input className='radio-ball' type='radio' id='more3' name='amo' readOnly checked={goods == 6} onClick={() => setGoods(6)} />
                                                        <label htmlFor='more3'><span></span><div />ООО</label>
                                                    </div>
                                                    {/* <div className='field-set field--white-cadcam'>
                                                        <div className='input'>
                                                            <input type='text' placeholder={TR_('Название')} value={cadcam} onChange={(e) => setCadcam(e.target.value)} />
                                                        </div>
                                                    </div> */}
                                                </div>

                                                {/* <div className='field-set'>
                                                    <p className='field-set__title'>
                                                        {TR('Количество установок')}
                                                    </p>
                                                    <div className='radio-string'>
                                                        <input className='radio-ball' type='radio' id='less3' name='amo' readOnly checked={pricelist == 0} onClick={() => setPricelist(0)} />
                                                        <label htmlFor='less3'><span></span><div className='chair' />1-3</label>
                                                        <input className='radio-ball' type='radio' id='more3' name='amo' readOnly checked={pricelist == 4} onClick={() => setPricelist(4)} />
                                                        <label htmlFor='more3'><span></span><div className='chair' />3+</label>
                                                    </div>
                                                    <p className='fied-set__error'></p>
                                                </div> */}
                                            </div>
                                        </div>

                                        <TextInput
                                            title={TR_('Ссылка на каталог')}
                                            value={catalog}
                                            setValue={setCatalog}
                                        />
                                        {/* <div className='field-set'>
                                            <p className='field-set__title'>{TR_('Описание компании')}</p>
                                            <textarea ref={descriptionRef} data-tooltip-id="description-tooltip" className='textarea textarea--my-data' name='some-text' id='someText' placeholder=''
                                                value={description} onChange={(e) => setDescription(e.target.value)} />
                                            <p className='fied-set__error'></p>
                                            <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Необходимо описание")} />
                                        </div> */}
                                        <TextAreaInput
                                            title={TR_('Описание')}
                                            value={description}
                                            setValue={setDescription}
                                            maxLength={MAX_DESCR_LENGTH}
                                            tooltip_id='description-tooltip'
                                        />
                                        {/* <Tooltip id="description-tooltip" isOpen={errorShown && !description} content={TR_("Необходимо описание")} /> */}
                                        <div className='field-set'>
                                            <p className='field-set__title'>
                                                {TR_('Карточка контрагента')}
                                            </p>
                                            <FileUploader filesLimit={1} reff={licensesRef} files={files} setFiles={setFiles} />
                                            {/* <Tooltip id="file-tooltip" isOpen={errorShown && !files.length} content={TR_("Загузите карточку контрагента")} /> */}
                                        </div>
                                    </form>
                                    <br />
                                    <p className='field-set__title'>
                                        {TR_('Специализация')}
                                    </p>
                                    <SearchFilters2 options={gFilterTraders} onResetFilters={resetFilters} />
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className='add__footer'>
                                    <div className='data-controls'>
                                        <button className='button-e h--44' onClick={() => history.push('/')}>{TR('Отмена')}</button>
                                        <button className='button-f h--44' onClick={() => doSave()}>{TR('Сохранить')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className={'respond__description data__info' + (isHelpShown ? '' : ' hide_me')}>
                                <div className=' respond__description-inner'>
                                    <header className='add__header'>
                                        <button className='add__header-btn' onClick={() => setHelpShown(false)}></button>
                                        <h2 className='add__header-title'>{TR('Рекомендация по заполнению')}</h2>
                                    </header>
                                    <div className='add__main'>
                                        <ol className='gide-list'>
                                            <li className='gide__item'>
                                                {TR_('Размещайте информативные и качественные фотографии в своём профиле.')}
                                            </li>
                                            <li className='gide__item'>
                                                {TR_('Указывайте актуальные контактные данные, это повысит эффективность Вашего профиля.')}
                                            </li>
                                            <li className='gide__item'>
                                                {TR_('В описании деятельности своей компании укажите на сильные стороны. Кратко сформулируйте Ваши отличительные особенности по сравнению с конкурентами.')}
                                            </li>
                                            <li className='gide__item'>
                                                {TR_('Укажите название и адрес Вашей компании. Загрузите логотип.')}
                                            </li>
                                            {/* <li className='gide__item'>
                                                {TR_('Обязательным условием регистрации на портале является предоставление документов подтверждающих Вашу деятельность на рынке стоматологических услуг (лицензия, диплом, карточка контрагента).')}
                                            </li> */}
                                        </ol>
                                    </div>
                                    <div className='add__footer'>
                                        <button className='main-button is--blue too--wide'>{TR('Закрыть')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main >
    )
}

export default MyDataTrader