import TopSwitch from 'Components/TopSwitch';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auction, AuctionFeedback, AuctionFeedbacks } from 'service/Data/DentTypes';
import { TR_ } from 'service/T10N';
import AuctionFeedbackCard from './Respond/AuctionFeedbackCard';
import AuctionViewHeader from './Respond/AuctionViewHeader';

interface LocationState {
    auction: Auction
    feedbacks: AuctionFeedbacks
}

const AuctionViewFeedbacks = () => {
    const location = useLocation<LocationState>(); // '/auction-view-feedbacks'

    const auction = location.state.auction // look AuctionViewHeader
    const responds = location.state.feedbacks // look AuctionFeedbacks

    if (!location.state) {
        //console.log('No state in AuctionViewResponds')
        return null
    }

    const [isOwn, setOwn] = useState(true)

    //console.log(responds)

    const [newFeedbacks, oldFeedbacks] = responds.feedbacks.reduce((result, curr) => {
        //console.log(result)
        if (curr.publicDate > responds.lastSeenFeedback) {
            result[0].push(curr)
        }
        else {
            result[1].push(curr)
        }

        return result;
    }, [[], []] as AuctionFeedback[][]);

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const [filter, setFilter] = useState('')

    const switchData = {
        switchName: 'filter',
        value: filter,
        setValue: setFilter,
        items: [
            { title: TR_('Все'), value: '' },
            { title: TR_('Новые'), value: 'new' },
            { title: TR_('Просмотренные'), value: 'old' },
        ]
    }

    return (
        <main className="main">
            <div className="container">
                <AuctionViewHeader auction={auction} />
                <section className="replies">
                    <h2 className="reply__title mb--20">
                        {TR_('Отклики')} <span className="reply__count">{oldFeedbacks.length + newFeedbacks.length}</span>
                        <span className="reply__count-new">{newFeedbacks.length ? '+' + newFeedbacks.length : ''}</span>
                    </h2>
                    <TopSwitch {...switchData} />
                    <div className="replies-box">
                        {filter === '' || filter === 'new' ?
                            newFeedbacks.map((feedback) => {
                                return <AuctionFeedbackCard
                                    key={feedback.id}
                                    feedback={feedback}
                                    isNew={true}
                                    isOwn={!isOwn}
                                    onDelete={() => { }}
                                    onEdit={() => { }}
                                />
                            })
                            : null
                        }
                        {filter === '' || filter === 'old' ?
                            oldFeedbacks.map((feedback) => {
                                return <AuctionFeedbackCard
                                    key={feedback.id}
                                    feedback={feedback}
                                    isNew={false}
                                    isOwn={!isOwn}
                                    onDelete={() => { }}
                                    onEdit={() => { }}
                                />
                            })
                            : null
                        }
                    </div>
                </section>
            </div>
        </main>
    )
}

export default AuctionViewFeedbacks;