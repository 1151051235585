import UserCard from 'Pages/Users/Components/UserCard'
import { FC, Fragment } from 'react'
import { User } from 'service/Data/DentTypes'
import { TR } from 'service/T10N'

// {
//     id: 1,
//     title: 'ООО "СТОМАТОРГ"',
//     image: "/assets/temp/images/180x160/Trade1.png",
//     rate: 5.0,
//     rateCount: 38,

//     city: "Москва, Коломенская",
//     street: "Нагатинская набережная, д.35",
//     description: `Продажа стоматологического и зуботехнического оборудования, стоматологических и зуботехнических материалов по невысоким ценам с доставкой по России.`,
//     trader: {
//         catalog: "https://blog.lib.uiowa.edu/conservation/files/2011/01/closed.jpg",
//         counterpartyCard: "#",
//     },
// }

export interface PopupCompanyProps {
    user: User
    onClose: () => void
}

const PopupCompany: FC<PopupCompanyProps> = ({ user, onClose }) => {

    return (
        <Fragment>
            <div className="popy popy-company is--visible">
                <header className="popy-header">
                    <button className="popy-killer" onClick={() => onClose()}></button>
                    <h2 className="popy-title">{TR('Назад')}</h2>
                </header>
                <main className="popy-main">
                    <UserCard user={user} />
                </main>
            </div>
            <div className="popup-cover" onMouseDown={() => onClose()}></div>
        </Fragment>
    )
}

export default PopupCompany;