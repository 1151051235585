import AuthContext, { SessionExt } from 'AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const SignupCheck = () => {
    // @ts-ignore
    const { verification } = useParams()
    const auth = useContext(AuthContext)

    let history = useHistory();
    const [text, setText] = useState("Please wait...");

    const checkRegistration = async (verificationString: string) => {
        type Reply = {
            status: string
            message?: string
            session?: SessionExt
        }

        try {
            console.log("Checking: " + verificationString)
            //const response = await net.get<Reply>("/api/v1/auth/check/" + verificationString)
            const response = await fetch("/api/v1/auth/check/" + verificationString)
                .then((response) => response.json())

            console.log(response)

            if (response.status == "success" && response.session) {
                auth.signin(response.session)
                history.replace('/')
                return
            }

            setText("Validation error")
            history.push('/')
        } catch (e) {
            setText("Server error")
        }
    };

    useEffect(() => {
        checkRegistration(verification)
    }, []);

    return (
        <main>
            <section className="layout registrationPopup">
                <h1 className="title">{/*Проверка регистрации!*/}</h1>
                <p>
                    {text}
                </p>
            </section>
        </main>
    )
}

export default SignupCheck;