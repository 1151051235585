// const checkOptions = {
//     title: "Интраоральный сканнер",
//     value: hasScanner,
//     setValue: setHasScanner
// }

const CheckFilter = ({ id, title, value, setValue }) => {
    return (
        <li className="filter__list-item">
            <div className="filter-slide">
                <input type="checkbox" className="checkbox-slide" id={id} readOnly checked={value} onClick={() => setValue(!value)} />
                <label htmlFor={id}> <span></span> {title}</label>
            </div>
        </li>
    )
}

export default CheckFilter;