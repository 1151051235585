import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSendRegistrationData from './hooks/useSendRegistrationData';
//import './style.css';
import { yupResolver } from '@hookform/resolvers/yup';
// import { sendData } from 'service/API';
// import login from 'Components/Signin/Signin';
// import * as Yup from 'yup';
import AvatarSelector from 'Components/AvatarSelector';
import { MAX_NAME_LENGTH, TextInput, useInputFocus, useLabelFocus } from 'Pages/Home/Components/MyDataDoctor';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { UserSignupRequest, resizeImage, userSignup } from 'service/API';
import { BlobCache, DentFUID, Role } from 'service/Data/DentTypes';
import { gAvatarSize } from 'service/Globals';
import { TR, TR_ } from 'service/T10N';
import SignupThanks from './SignupThanks';

interface SignupForm {
    //role: string,
    email: string,
    password: string,
    acceptedTerms: boolean
}

const Signup = () => {
    const history = useHistory();
    const defaultValues = {
        //role: '',
        email: '',
        password: '',
        acceptedTerms: false
    }

    // vvv
    const [nameRef, setNameFocus] = useInputFocus()
    const [avatarRef, setAvatarFocus] = useLabelFocus()

    const [avatar, setAvatar] = useState<DentFUID>('')
    const [name, setName] = useState('')
    const [role, setRole] = useState<Role | ''>('')

    const [errorShown, setErrorShown] = useState(false)
    // ^^^

    // TODO: Remove after testing and email sending setup
    const [verificationUrl, setVerificationUrl] = useState('')

    const [userEmail, setUserEmail] = useState('')
    const [errorStr, setErrorStr] = useState('');
    const [isChecked, setChecked] = useState(false);
    const [showBody, setShowBody] = useState(false);
    const { schema } = useSendRegistrationData();
    const methods = useForm<SignupForm>({ defaultValues, resolver: yupResolver(schema) });
    //const methods = useForm({ defaultValues });

    function validateForm(data: SignupForm): boolean {
        if (role === '') {
            //setErrorStr('Не указана роль');
            setErrorShown(true)
            return false
        }

        if (!name) {
            console.log('Name not valid');
            setErrorShown(true)
            setNameFocus()
            return false
        }

        if (!avatar) {
            setErrorShown(true)
            setAvatarFocus()
            return false
        }

        return true
    }

    const submitHandler = async (data: SignupForm) => {
        //console.log(data)
        setErrorStr('');

        if (!validateForm(data)) {
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        }

        if (role === '')
            return

        const request: UserSignupRequest = {
            name: name,
            role: role,
            avatar: avatar,
            email: data.email,
            password: data.password
        }

        try {
            const response = await userSignup(request)

            console.log(response)

            if (response.status == 'success') {
                setErrorStr('')
                setUserEmail(data.email)
                setChecked(false)
                methods.reset()
            }
        }
        catch (e) {
            if (e instanceof AxiosError) {
                const statusCode = e.response?.status
                if (statusCode == 409) {
                    setErrorStr(TR_('Такой адрес почты уже зарегестрирован'))
                } else {
                    setErrorStr(TR_('Ошибка сервера') + ' ' + e.response?.data.error_code)
                }
            }
        }
    }

    const getNameDescription = (role: Role | '') => {
        if (role === 'technician') {
            return TR_('Название лаборатории (или ФИО)')
        }

        if (role === 'trader') {
            return TR_('Название компании (или ФИО)')
        }

        if (role === 'doctor') {
            return TR('Название клиники (или ФИО)')
        }

        return TR_('Название')
    }

    const signupScreen =
        <div className='sign-cover'>
            <header className='sign-header'>
                <a href='welcome' className='logo'>
                    <img className='logo-img' src='/assets/images/logo.png' alt='dental portal image' />
                </a>
            </header>
            <div className='sign-main2'>
                <div className='sing-up-container'>
                    <div className='sign-up__info' style={showBody ? { display: 'none' } : {}}>
                        <div className='sign-up__info-container'>
                            <p className='sign-up__info-item'>
                                {TR('Мы рады приветствовать Вас на портале участников рынка стоматологических услуг.')}
                            </p>
                            <p className='sign-up__info-item'>
                                {TR('Не отвлекайтесь на поиски Поставщиков, Подрядчиков и сотрудников, все это Вы сможете найти на нашей онлайн-платформе.')}
                            </p>
                            <p className='sign-up__info-item'>
                                {TR('Экономим время, сберегаем ресурсы, оптимизируем процессы!')}
                            </p>
                            <p className='sign-up__info-item'>
                                {TR('С уважением, коллектив \'Дентал Портал\'.')}
                            </p>
                        </div>
                        <button className='button-f w--100 h--44 showon--mobile' onClick={() => setShowBody(true)}>{TR('Продолжить')}</button>
                    </div>
                    <div className='sign-up__body' style={showBody ? { display: 'block' } : {}}>
                        <div className='sign-body sign--up'>
                            <h1 className='sign-title'>
                                {TR('Регистрация')}
                            </h1>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(submitHandler)} className='sign-form'>
                                    <div className='sign-fields'>
                                        <div className='choice-set' data-tooltip-id='role-tooltip'>
                                            <button className='button-info'></button>
                                            {/* <div className='radio-balls'>
                                                <input className='radio-ball' type='radio' {...methods.register('role')} id='doctor' value='doctor' />
                                                <label htmlFor='doctor'><span />{TR('Клиника или Врач')}</label>
                                                <input className='radio-ball' type='radio' {...methods.register('role')} id='technician' value='technician' />
                                                <label htmlFor='technician'><span />{TR('Лаборатория или Техник')}</label>
                                                <input className='radio-ball' type='radio' {...methods.register('role')} id='trader' value='trader' />
                                                <label htmlFor='trader'><span />{TR('Торговая компания')}</label>
                                            </div> */}
                                            <div className='radio-balls'>
                                                <input className='radio-ball' type='radio' id='doctor' readOnly checked={role === 'doctor'} onClick={() => setRole('doctor')} />
                                                <label htmlFor='doctor'><span />{TR('Клиника или Врач')}</label>
                                                <input className='radio-ball' type='radio' id='technician' readOnly checked={role === 'technician'} onClick={() => setRole('technician')} />
                                                <label htmlFor='technician'><span />{TR('Лаборатория или Техник')}</label>
                                                <input className='radio-ball' type='radio' id='trader' readOnly checked={role === 'trader'} onClick={() => setRole('trader')} />
                                                <label htmlFor='trader'><span />{TR('Торговая компания')}</label>
                                            </div>
                                            <Tooltip id='role-tooltip' isOpen={errorShown && !role} content={TR_('Не указана роль')} />
                                        </div>
                                        {/* <div className='contact-item'> */}
                                        <div>
                                            <TextInput
                                                reff={nameRef}
                                                tooltip_id='name-tooltip'
                                                title={getNameDescription(role)}
                                                value={name}
                                                maxLength={MAX_NAME_LENGTH}
                                                setValue={setName} />
                                            <Tooltip id='name-tooltip' isOpen={errorShown && !name} content={TR_('Укажите название')} />
                                        </div>
                                        {/* <div className='contact-item'> */}
                                        <div>
                                            <div className='field-set2'>
                                                <p className='field-set__title'>{TR_('Аватар')}</p>
                                                <AvatarSelector reff={avatarRef} tooltip='avatar-tooltip' value={avatar} onFileSelected={async (file) => {
                                                    try {
                                                        const blob = await resizeImage(file, gAvatarSize, gAvatarSize)
                                                        if (blob instanceof Blob) {
                                                            setAvatar(new BlobCache(blob))
                                                        }
                                                    }
                                                    catch (error) {
                                                        //console.log('error aru1')
                                                        //console.log(error)
                                                    }

                                                }} />

                                            </div>
                                            <Tooltip id='avatar-tooltip' isOpen={errorShown && !avatar} content={TR_('Загузите ваш аватар')} />
                                            {/* <p className='fied-set__error'></p> */}
                                        </div>
                                        <div className='field-set2'>
                                            <p className='field-set__title'>
                                                {TR('E-mail')}
                                                <button className='button-info'></button>
                                            </p>
                                            <div className='input' data-tooltip-id='email-tooltip'>
                                                <input type='email' {...methods.register('email')} name='email' id='email' placeholder={TR('Введите ваш email...')} required />
                                            </div>
                                        </div>
                                        {/* <p className='fied-set__error'>{errorStr}</p> */}
                                        <Tooltip id='email-tooltip' isOpen={!!methods.formState.errors.email} content={methods.formState.errors.email?.message} />
                                        <Tooltip id='email-tooltip' isOpen={!!errorStr} content={errorStr} />
                                        <div className='field-set2'>
                                            <p className='field-set__title'>
                                                {TR('Пароль')}
                                            </p>
                                            <div className='input' data-tooltip-id='pass-tooltip'>
                                                <input type='password' {...methods.register('password')} name='password' id='password' placeholder={TR('Пароль не менее 6 символов...')} />
                                            </div>
                                            {/* <br /> */}
                                            {/* <p className='sub-field'>Ваш пароль должен содержать не менее 6 символов</p> */}
                                            {/* <p className='fied-set__error'>{methods.formState.errors.password?.message}</p> */}
                                            <Tooltip id='pass-tooltip' isOpen={methods.formState.errors.password ? true : false} content={methods.formState.errors.password?.message} />
                                        </div>
                                        {/* <input type='checkbox' className='checkbox' name='acceptedTerms' id='acceptedTerms' checked={isChecked}
                                            {...methods.register('acceptedTerms')}
                                            onChange={() => setChecked(!isChecked)} /> */}
                                        <input type='checkbox' className='checkbox' id='acceptedTerms' {...methods.register('acceptedTerms')} />
                                        <label className='sign-label' htmlFor='acceptedTerms' data-tooltip-id='check-tooltip'>
                                            <span>{TR('Нажимая кнопку “зарегистрироваться” я соглашаюсь с ')}
                                                {/* <a onClick={() => history.push('/policy')}> */}
                                                <a href={TR('/docs/ru/policy.pdf', 'Политика конфиденциальности')}>
                                                    {TR('политикой конфиденциальности')}
                                                </a>
                                            </span>
                                        </label>
                                        <Tooltip id='check-tooltip' isOpen={methods.formState.errors.acceptedTerms ? true : false} content={methods.formState.errors.acceptedTerms?.message} />
                                    </div>
                                    <button className='button-f w--100 h--44' type='submit'>{TR('Зарегистрироваться')}</button>
                                    <a className='button-e w--100 h--44' onClick={() => history.push('/signin')}>{TR('Войти')}</a>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    const successScreen =
        // <SignupThanks title={TR_('Спасибо за регистрацию!')} buttonText={TR_('Продолжить')} verifyUrl={verificationUrl}>
        <SignupThanks title={TR_('Спасибо за регистрацию!')}>
            {TR('Проверьте электронную почту ')}<b>{userEmail}</b>{TR(' и перейдите по ссылке в ней для заполнения вашей анкеты.')}
            {/* <p>
                А вот и ссылка из письма <a href={verificationUrl}>{verificationUrl}</a>
            </p> */}
        </SignupThanks>

    return (
        <main>
            {userEmail ? successScreen : signupScreen}
        </main>
    )
};

export default Signup;

// Old variant of code
// const signupScreen =
//     <div className='RegistrationWrapper'>
//         <a className='Registration-logo'>
//             <img src='/playground_assets/logo232-4l9.svg' style={{ width: '238px', height: '26px' }} alt='Логотип Dental Portal' />
//         </a>
//         <section style={{ backgroundImage: 'url(/playground_assets/registration-bg.png)' }} className='Registration'>
//             <div className='promo'>
//                 <p className='promoIntro'>Мы рады приветствовать Вас
//                     на портале участников рынка стоматологических услуг.</p>

//                 <p>Не отвлекайтесь на поиски Поставщиков, Подрядчиков
//                     и сотрудников, все это Вы сможете найти на нашей онлайн-платформе.</p>

//                 <p>Экономим время, сберегаем ресурсы, оптимизируем процессы! </p>

//                 <p className='promoSubscribe'>С уважением, коллектив 'Дентал Портал'.</p>
//             </div>
//             <FormProvider {...methods}>
//                 <form onSubmit={methods.handleSubmit(submitHandler)} className='RegistrationForm'>
//                     <h1>Регистрация</h1>
//                     <fieldset className='formField RegistrationTypeField'>
//                         <button type='button' className='btn infoBtn infoBtnRegistrationType' title='info'></button>
//                         <ul className='RegistrationTypeList'>
//                             <li>
//                                 <input type='radio' {...methods.register('role')} id='doctor' value='doctor' />
//                                 <label htmlFor='doctor'>Клиника или Врач</label>
//                             </li>
//                             <li>
//                                 <input type='radio' {...methods.register('role')} id='technician' value='technician' />
//                                 <label htmlFor='technician'>Лаборатория или Техник</label>
//                             </li>
//                             <li>
//                                 <input type='radio' {...methods.register('role')} id='trader' value='trader' />
//                                 <label htmlFor='trader'>Торговая компания</label>
//                             </li>
//                         </ul>
//                     </fieldset>

//                     <fieldset className='formField formFieldEmail'>
//                         <label htmlFor='email'>E-mail</label>
//                         <button type='button' className='btn infoBtn infoBtn' title='info'></button>
//                         <input type='email' {...methods.register('email')} name='email' id='email' placeholder='Введите ваш email...' required />
//                         <p>{methods.formState.errors.email?.message}</p>
//                     </fieldset>

//                     <fieldset className='formField formFieldPassword'>
//                         <label htmlFor='password'>Пароль</label>
//                         <input type='password' {...methods.register('password')} name='password' id='password' />
//                         <p className='passwordInfo error'>{methods.formState.errors.password?.message}</p>
//                     </fieldset>

//                     <fieldset className='formField RegistrationCheck'>
//                         <input type='checkbox' name='RegistrationCheck' id='RegistrationCheck' checked={isChecked} onChange={() => setChecked(!isChecked)} />
//                         <label htmlFor='RegistrationCheck'>Нажимая кнопку “зарегистрироваться”<br />
//                             я соглашаюсь с <a className='confPolicy' href='#'>политикой
//                                 конфиденциальности{isChecked}</a></label>
//                     </fieldset>

//                     <button className='btn RegistrationBtn' type='submit' disabled={!isChecked}>Зарегистрироваться</button>
//                     <a className='btn loginBtn' href='/signin'>Войти</a>
//                 </form>
//             </FormProvider>
//         </section>
//     </div>