import { Fragment, useEffect, useState } from 'react'

import ResultsCounter from 'Components/ResultsCounter'
import SearchFilters from 'Components/SearchFilters'
import SortBy from 'Components/SortBy'
import TopSwitch from 'Components/TopSwitch'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { getDoctors } from 'service/API'
import { Doctor } from 'service/Data/DentTypes'
import { TR, TR_ } from 'service/T10N'
import UserCard from './Components/UserCard'


const ListDoctors = () => {
    const history = useHistory()

    const [isMapShown, setMapShown] = useState(false)

    const [justInitialized, setJustInitizlized] = useState(true)
    const [doctors, setDoctors] = useState<Doctor[] | null>(null)

    const resetFilters = () => {
        setFindText('')
        setCities([])
        setChairs(0)
        setHasScanner(false)
    }

    const [findText, setFindText] = useState('')
    const [cities, setCities] = useState([])
    const [chairs, setChairs] = useState(0)
    const [hasScanner, setHasScanner] = useState(false)
    const [sortBy, setSortBy] = useState('date')

    useEffect(() => {
        (async () => {
            const doctors = await getDoctors(findText, cities, chairs, hasScanner, sortBy)
            //setDoctors(doctors.slice(0, 0)) // ~~ disabling list
            setDoctors(doctors)
            setJustInitizlized(false)
        })()

    }, [findText, cities, chairs, hasScanner, sortBy])

    const filterDoctors = [
        {
            id: "text",
            text: {
                value: findText,
                setValue: setFindText,
                placeholder: TR('Поиск'),
                // onSearch: handleSearchButton,
            }
        },
        // {
        //     id: "cities",
        //     subset: {
        //         from: getAvailableCities(),
        //         value: cities,
        //         setValue: setCities,
        //         nonEmptyTitle: TR('Выбранные города'),
        //         whenEmptyTitle: TR('По всем городам'),
        //         popupTitle: TR('Город'),
        //     }
        // },
        {
            id: "chairs",
            oneof: {
                title: TR('Количество установок'),
                from: [
                    { text: TR('Любое количество'), value: 0 },
                    { text: "1-3", icon: "chair", value: 1 },
                    { text: "3+", icon: "chair", value: 2 }
                ],
                value: chairs,
                setValue: setChairs
            }
        },
        {
            id: "scanner",
            check: {
                id: "scanner",
                title: TR('Интраоральный сканнер'),
                value: hasScanner,
                setValue: setHasScanner
            }
        }
    ]

    const [role, setRole] = useState('doctor')

    useEffect(() => {
        if (role !== 'doctor')
            history.push('/' + role + 's')
    }, [role])

    const switchData = {
        switchName: 'roles',
        value: role,
        setValue: setRole,
        items: [
            { title: TR_('Клиники'), value: 'doctor' },
            { title: TR_('Лаборатории'), value: 'technician' },
            { title: TR('Торговые компании'), value: 'trader' },
        ]
    }

    return (
        <main className="main">
            <div className="container">
                <h1 className="section-title">{TR('Клиники & Врачи')}</h1>
                <div className="list-controls">
                    <div className="list-controls__switch">
                        <TopSwitch {...switchData} />
                        {/* <div className="from-who-box"> ~~ temporarily disabling
                            <input className="from-who" type="radio" id="card" name="cards" onClick={() => setMapShown(false)} defaultChecked />
                            <label htmlFor="card">Карточки</label>
                            <input className="from-who" type="radio" id="map" name="cards" onClick={() => setMapShown(true)} />
                            <label htmlFor="map">На карте</label>
                        </div> */}
                    </div>
                    <div className="list-controls__sort">
                        {!isMobile ?
                            < Fragment >
                                < SortBy value={sortBy} setValue={setSortBy} />
                                <ResultsCounter value={doctors ? doctors.length : 0} />
                            </Fragment>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="list-base">
                    <SearchFilters options={filterDoctors} onResetFilters={resetFilters} banner={2} />
                    <div className="list-ads">
                        {/* add class list-map--open */}
                        <div className={"list-map" + (isMapShown ? " list-map--open" : "")}></div>
                        {/* <div className="list-map list-map--open" hidden={!isMapShown} /> */}
                        {doctors ? doctors.map((doc) =>
                            <UserCard key={doc.id} user={doc} />)
                            : justInitialized ? TR('Ожидание данных...') : TR('Ничего не найдено...')}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ListDoctors
