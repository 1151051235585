import AuthContext from "AuthContext";
import { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Creator, MySale, Sale, maxCabinetCardItems } from "service/Data/DentTypes";
import { TR, TR_ } from "service/T10N";
import { getPriceStr } from "service/TextTools";

interface MySalesProps {
    actual?: MySale[]
    archived?: MySale[]
}

const MySales: FC<MySalesProps> = ({ actual, archived }) => {
    actual = actual ? actual : []
    archived = archived ? archived : []

    const auth = useContext(AuthContext)
    const history = useHistory()
    const [isArchive, setIsArchive] = useState(false)
    const [maxItems, setMaxItems] = useState(maxCabinetCardItems)

    async function fetchSale(mySale: MySale) {
        try {
            //const sale = await getSale(saleId)
            const creator: Creator = {
                id: auth.session ? auth.session.id : '',
                name: '',
                avatar: '',
            }
            const sale: Sale = { ...mySale, creator: creator }
            history.push('/sale-view', { sale: sale })
        } catch (e) {
            console.log(e)
        }
    }

    const Item = (mySale: MySale) => {
        return (
            <article className="card__text-item">
                <div className="card-data-box" onClick={() => { fetchSale(mySale) }}>
                    <h3 className="card-item__title">{mySale.title}</h3>
                    <p className="card-item__details">
                        <span>{getPriceStr(mySale.price)}</span>
                    </p>
                </div>
                {/* <div className="data-controls-box">
                    <button className="push-btn"></button>
                    <button className="edit-btn"></button>
                    <button className="delete-btn"></button>
                </div> */}
            </article>
        )
    }

    const items: MySale[] = isArchive ? archived : actual;

    return (
        <section className="section-wrapper">
            <div className="card-header">
                <h2 className="card-title">{TR('Моя барахолка')}
                    {/* <span className="card-title__counter">{actual.length}</span> */}
                </h2>
                <button className="button-27e" onClick={() => history.push('/sale-new')}>{TR('Создать объявление')}</button>
            </div>
            <div className="card-filter">
                <div className="swiper-checkbox">
                    <input type="checkbox" id="salesNewOld" checked={isArchive} onChange={() => setIsArchive(!isArchive)} />
                    <label htmlFor="salesNewOld">
                        <span className="actual">{TR('Актуальные')}</span>
                        <span className="not__actual">{TR('Архив')}</span>
                    </label>
                </div>
            </div>
            <div className="card-body">
                {items.slice(0, maxItems).map((item) => <Item key={item.id} {...item} />)}
                {items.length > maxCabinetCardItems && maxItems == maxCabinetCardItems ?
                    <button className="rest-items" onClick={() => setMaxItems(items.length)}>
                        <span>{TR_('Ещё') + ' ' + (items.length - maxItems)}</span>
                        <span />
                        <span>{TR('Смотреть все')}</span>
                    </button>
                    : maxItems != maxCabinetCardItems ?
                        <button className="rest-items" onClick={() => setMaxItems(maxCabinetCardItems)}>
                            <span />
                            <span>{TR_('Свернуть')}</span>
                        </button>
                        :
                        null
                }
            </div>
        </section>
    )
}

export default MySales;