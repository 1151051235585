const PopupMyFotos = () => {
    return (
        <div className="popup-cover">
            <div className="popy popy-preview">
                <header className="popy-header">
                    <button className="popy-killer hide-on__photo"></button>
                    <div className="photo-counter">
                        <span className="boldy">2</span> из <span>9</span>
                    </div>
                    <span className="close-note">Закрыть</span>
                    {/* <h2 className="popy-title">Тариф на 3 месяца</h2> */}
                </header>
                <main className="popy-main full--size">
                    <div className="photo-box">
                        <div className="photo-control">
                            <button className="slider slide__left"></button>
                        </div>
                        <div className="photo-container">
                            <div className="photo-item">
                                <img src="/assets/images/cabinet.png" alt="" />
                            </div>
                        </div>
                        <div className="photo-control">
                            <button className="slider slide__right"></button>
                        </div>
                    </div>
                </main>
                <footer className="popy-footer">
                    {/* <button className="main-button is--gray">Продолжить без изменений</button> */}
                    <button className="main-button is--blue">Сделать главной</button>
                    <span />
                    <button className="main-button is--red">Удалить</button>
                </footer>
            </div>
        </div>
    )
}

export default PopupMyFotos;