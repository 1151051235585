import { FC, ReactNode, createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getDentNotifications, net, registerUnauthorizedHandler, updateLastSeenNotification } from 'service/API'
import { DentFUID, DentNotification, UnixTime, UserID } from 'service/Data/DentTypes'

export interface IAuthContext {
    session?: SessionExt
    isLoggedIn: () => boolean
    updateSession: (session: Session) => void
    signin: (session: SessionExt) => void
    logout: () => void

    // Test
    onNotificationsOpened: () => void
    doNotificationsUpdate: () => void
    notifications: DentNotification[]
    newNotifications: number
}

const AuthContext = createContext<IAuthContext>({
    session: undefined,
    isLoggedIn: () => { return false },
    updateSession: (session) => { },
    signin: (session) => { },
    logout: () => { },

    onNotificationsOpened: () => { },
    doNotificationsUpdate: () => { },
    notifications: [],
    newNotifications: 0
})

interface IAuthContextProvider {
    children?: ReactNode
}

export interface Session {
    id: UserID
    role: string
    name: string
    city: string
    street: string
    avatar: DentFUID
}

export interface SessionExt extends Session {
    email: string
}

const oldTokenKey = 'dentsToken'
const sessionKey = 'dentsSession'
const lastNotificationKey = "dentsLsnt"

const pollingInterval = 60 // seconds

export const AuthContextProvider: FC<IAuthContextProvider> = ({ children }) => {
    localStorage.removeItem(oldTokenKey) // Removing old data
    const history = useHistory()

    const [session, setSession] = useState<SessionExt | undefined>(loadSession())

    const loggedIn = !!session

    const saveSession = (session: SessionExt) => {
        setSession(session)
        localStorage.setItem(sessionKey, JSON.stringify(session))
    }

    function loadSession(): SessionExt | undefined {
        const sessionData = localStorage.getItem(sessionKey)
        return sessionData ? JSON.parse(sessionData) : undefined
    }

    const resetSession = () => {
        setSession(undefined)
        localStorage.removeItem(sessionKey)
    }

    // useEffect(() => {
    //     (async () => {
    //         if (!session) {
    //             type Reply = {
    //                 status: string
    //                 message?: string
    //                 session?: SessionInfo
    //             }

    //             const reply = await net.get<Reply>('/api/v1/users/session')

    //             if (reply.session) {
    //                 saveSession(reply.session)
    //             }
    //         }
    //     })()
    // }, [session])

    const [unauthHandler] = useState('')
    useEffect(() => {
        registerUnauthorizedHandler(() => {
            // This is global handler which is called when HttpStatusCode.Unauthorized is received
            console.log('Inside unauthorized handler')

            logoutHandler()
            history.push('/signin')
        })
    }, [unauthHandler])

    const [notifications, setNotifications] = useState<DentNotification[]>([])
    const [newNotifications, setNewNotifications] = useState(-1)
    const [notificationsUpdater, setNotificationsUpdater] = useState(0)
    useEffect(() => {
        //console.log('▶ Notifications effect: ' + notificationsUpdater)

        if (loggedIn) {
            const timeout = notificationsUpdater == 0 ? 0 : pollingInterval * 1000

            {// Refetch on change logic
                const timer = setTimeout(async () => {
                    //console.log('Notifications effect timeout')

                    try {
                        const result = await getDentNotifications()
                        //console.log('Got notifications:')
                        //console.log(result)

                        updateNotifications(result.notifications, result.lastSeenTime)
                    } catch (e) { }

                    // Forcing recall of useEffect if error
                    setNotificationsUpdater((prev) => prev + 1)
                }, timeout)

                return () => {
                    clearTimeout(timer)
                }
            }
        }
    }, [notificationsUpdater])

    const handleNotificationsOpened = () => {
        if (notifications && newNotifications) {

            setNewNotifications(0)

            const lastSeenTime = notifications[0].publicDate
            //console.log('LastSeenTime: ' + lastSeenTime);

            if (lastSeenTime) {
                updateLastSeenNotification(lastSeenTime)
            }
        }
    }

    const handleUpdate = () => {
        //console.log('Force update')
        setNotificationsUpdater(0) // Forcing to update notifications
    }

    const updateNotifications = (ns: DentNotification[], lastSeen: UnixTime) => {
        // Notification id's are ordered as [...,5,4,3,2,1]
        let n = 0
        for (let i = 0; i < ns.length; i++) {
            const v = ns[i]
            if (v.publicDate && v.publicDate > lastSeen) {
                n++
            }
            else {
                break
            }
        }

        setNotifications(ns)
        setNewNotifications(n)
    }

    const updateSessionHandler = (newSession: Session) => {
        if (session) {
            saveSession({
                id: newSession.id,
                role: newSession.role,
                name: newSession.name,
                city: newSession.city,
                street: newSession.street,
                avatar: newSession.avatar,
                email: session.email
            })
        }
        else {
            console.log("No session")
        }
    }

    const signinHandler = (session: SessionExt) => {
        saveSession(session)
        //console.log('>>>>> Forcing notifications update');

        setNewNotifications(-2) // Forcing notifications update
    }

    const logoutHandler = async () => {
        // Protection from infinite loop when reply on logout is 401 unauthorized
        if (session) {
            type Reply = {
                status: string
            }

            try {
                // Cleaning cookies
                const reply = await net.get<Reply>('/api/v1/auth/logout')
            } catch (e) {
                // Catching any error
            }

            resetSession()
        }
    }

    const contextValue: IAuthContext = {
        session: session,
        isLoggedIn: () => {
            return !!session
        },
        updateSession: updateSessionHandler,
        signin: signinHandler,
        logout: logoutHandler,

        onNotificationsOpened: handleNotificationsOpened,
        doNotificationsUpdate: handleUpdate,
        notifications: notifications,
        newNotifications: newNotifications
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
